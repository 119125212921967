import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getSupportTickets } from '../../services/contactSupport';

const SupportTicketsPage = () => {

    const [tickets, setTickets] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getSupportTickets()
        if (code == 200) {
            let sortedItems = message.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTickets(sortedItems)
        }
    }

    const getBadgeVariant = (status) => {
        if (status === 'new') {
            return 'success'
        } else if (status === 'open') {
            return 'primary'
        } else {
            return 'dark'
        }
    }

    return <>
        <section>
            <Row>
                <Col>
                    <Row>
                        <Col lg="10">
                            {tickets && tickets.length > 0 ?
                                <>
                                {tickets.map((ticket, index) => (
                                <Link key={`ticket-${index}`}  to={`/support-tickets/${ticket._id}`}>
                                    <Card className="mb-3 card-hover">
                                        <Card.Body>
                                            <Card.Title>
                                                {ticket.topic}

                                                {/* <Dropdown className="more-options">
                                                    <Dropdown.Toggle id="dropdown-custom-components" variant="transparent color-primary">
                                                        <i className=" fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => {}}><i className="fas fa-check mr-3"></i> Mark as Solved</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                                
                                            </Card.Title>
                                            
                                            <Row>
                                                <Col className="pt-1">
                                                    <Row>
                                                        <Col xs="9">
                                                            <p className="mb-n1"><small>{ticket.email}</small></p>
                                                            <p className="text-muted"><small>{Moment.utc(ticket.date).format('DD.MM.YYYY @ HH:mm')}</small></p>

                                                            <p>{ticket.message}</p>
                                                        </Col>
                                                        <Col className="text-right">
                                                            <Badge variant={getBadgeVariant(ticket.status)}>{ticket.status}</Badge>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            
                                        </Card.Body>
                                    </Card>
                                </Link>
                                ))}
                                </>
                            :
                                <p>Nothing found.</p>
                            } 
                            {/* <Card className="mb-3">
                                <Card.Body>
                                    <Row>
                                        <Col xs="9">
                                            <h5 className="mb-0">Topic Title</h5>
                                            <p><small>John Doe | email@gmail.com</small></p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus ex vitae eleifend fermentum. Phasellus laoreet massa et elit lacinia consectetur.</p>
                                        </Col>
                                        <Col className="text-right">
                                            <Badge variant="success">Open</Badge>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs="9">
                                            <h5 className="mb-0">Topic Title</h5>
                                            <p><small>John Doe | email@gmail.com</small></p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus ex vitae eleifend fermentum. Phasellus laoreet massa et elit lacinia consectetur. Nam ut nisi ultricies velit iaculis consectetur ut sed dolor. Mauris a sapien vulputate, mollis nunc sed, sodales velit. Phasellus non lobortis est.</p>
                                        </Col>
                                        <Col className="text-right">
                                            <Badge variant="dark">Closed</Badge>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    </>
}

export default SupportTicketsPage