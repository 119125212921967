import React from 'react';
import AirlineCreation from '../components/admin/airlineCreation';

const AirlinesPage = () => {
    return <>
        <section>
            <AirlineCreation />
        </section>
    </>
}

export default AirlinesPage