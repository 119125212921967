import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap'
import { getCarCategories } from '../../services/carCategories'
import { removeFromArray } from '../../utils/utils'

const EditDestinationTaxiModal = ({ display, toggle, 
    modalCarCategories, setModalCarCategories,
    carCategoriesToAdd, carCategoriesToAddDisplay,
    setCarCategoriesToAdd, setCarCategoriesToAddDisplay,
    saveDestination }) => {

    useEffect(() => {
        fetchCarCategories()
    }, [])

    const fetchCarCategories = async () => {
        const {data: {code, message}} = await getCarCategories()
        if (code == 200) {
            let _modalCarCategories = message.filter(x => !isExists(x._id));
            setModalCarCategories([..._modalCarCategories])
        } else {
            //TODO: toast
        }
    }

    const isExists = (id) =>{
        const obj = carCategoriesToAdd.find(x => x.car_category_id === id);
        return obj ? true: false;
    }

    const handleModalCarCategoriesStatusClass = (statusClass, index) => {
        let _modalCarCategories = modalCarCategories
        _modalCarCategories[index]['statusClass'] = statusClass
        setModalCarCategories([..._modalCarCategories])
    }

    const handleSetCarCategoryPrice = (price, index) => {
        handleModalCarCategoriesStatusClass('', index)

        let _carCategories = modalCarCategories
        _carCategories[index]['price'] = price;
        setModalCarCategories([..._carCategories])
    }

    const handleSetCarCategoriesToAdd = (carCategory, index) => {

        if (!carCategory.price) {
            handleModalCarCategoriesStatusClass('is-invalid', index)
            return
        } 

        let _carCategoriesToAdd = carCategoriesToAdd
        let _carCategoriesToAddDisplay = carCategoriesToAddDisplay
        _carCategoriesToAdd.push({
            "car_category_id": carCategory._id,
            "price": carCategory.price,
        })
        _carCategoriesToAddDisplay.push({
            "car_category_id": carCategory._id,
            "price": carCategory.price,
            "name": carCategory.name ?? carCategory.category_info.name
        })
        setCarCategoriesToAdd([..._carCategoriesToAdd])
        setCarCategoriesToAddDisplay([..._carCategoriesToAddDisplay])

        let _modalCarCategories = modalCarCategories
        _modalCarCategories.splice(index, 1)
        setModalCarCategories([..._modalCarCategories])
        
    }

    const handleSetCarCategoriesToRemove = (carCategory, index) => {
        let _carCategoriesToAdd = carCategoriesToAdd
        let _carCategoriesToAddDisplay = carCategoriesToAddDisplay

        _carCategoriesToAdd.splice(index, 1)
        setCarCategoriesToAdd([..._carCategoriesToAdd])
        _carCategoriesToAddDisplay.splice(index, 1)
        setCarCategoriesToAddDisplay([..._carCategoriesToAddDisplay])

        let _modalCarCategories = modalCarCategories
        _modalCarCategories.push({
            "_id": carCategory.car_category_id,
            "price": carCategory.price,
            "name": carCategory.name ?? carCategory.category_info.name
        })
        setModalCarCategories([..._modalCarCategories])
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        onEnter= {() => fetchCarCategories()}
        size="lg"
        centered
        >
            <Modal.Body>
                <h3 className="text-center">Edit Taxi Destination</h3>
                <hr />
                <Row className="mb-3">
                    <Col lg="6">
                        <Form.Label className="mb-0">Car Categories</Form.Label>
                        <Form.Text className="mb-2">Select car categories</Form.Text>
                        <div className="p-2 border rounded" style={{minHeight:'53px'}}>
                        {modalCarCategories && modalCarCategories.map((category, index) => (
                            <Row key={`category-${index}`} className="mb-1">
                                <Col xs="7">
                                    <FormControl value={category.name ?? category.category_info.name} size="sm" disabled />
                                </Col>
                                <Col xs="3" className="px-0">
                                    <FormControl className={`${category.statusClass}`} defaultValue={0} onChange={(e) => handleSetCarCategoryPrice(e.target.value, index)} placeholder="price" size="sm" type="number" />
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => handleSetCarCategoriesToAdd(category, index)} size="sm" title="add category"><i className="fas fa-plus"></i></Button>
                                </Col>
                            </Row>
                        ))}
                        </div>
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">&nbsp;</Form.Label>
                        <Form.Text className="mb-2">Car categories to add</Form.Text>
                        <div className="p-2 border rounded" style={{minHeight:'53px'}} >
                        {carCategoriesToAddDisplay.length > 0 && carCategoriesToAddDisplay.map((category, index) => (
                            <Row key={`category-${index}`} className="mb-1">
                                <Col xs="7">
                                    <FormControl value={category.name ?? category.category_info.name} size="sm" disabled />
                                </Col>
                                <Col xs="3" className="px-0">
                                    <FormControl value={`${category.price} CHF`} size="sm" disabled />
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => handleSetCarCategoriesToRemove(category, index)} size="sm" title="remove category"><i className="fas fa-minus"></i></Button>
                                </Col>
                            </Row>
                        ))}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => saveDestination()}>Save</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default EditDestinationTaxiModal

