import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import  NameWithRole  from '../common/nameWithRole';

const SidebarShuttleCompany = () => {

    return ( 
        <>
        <NameWithRole/>
        <ul className='nav flex-column'>
            <LinkContainer to='/dashboard'>
                <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
            </LinkContainer>
            {/* <LinkContainer to='/timetable'>
                <li><a className='nav-link'><i className='fas fa-tachometer-alt text-muted mr-2'></i> Timetable</a></li>
            </LinkContainer>
            <LinkContainer to='/shuttle-units'>
                <li><a className='nav-link'><i className='fas fa-tachometer-alt text-muted mr-2'></i> Shuttle Units</a></li>
            </LinkContainer> */}
            <LinkContainer to='/timetable'>
                <li><a className='nav-link'><i className='fas fa-tachometer-alt mr-2'></i> Shuttle Timetable</a></li>
            </LinkContainer>
            <LinkContainer to='/bookings'>
                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Shuttle Bookings</a></li>
            </LinkContainer>
            <LinkContainer to='/accounts'>
                <li><a className='nav-link'><i className="fas fa-user-friends mr-2"></i> Accounts</a></li>
            </LinkContainer>
            <LinkContainer to='/vehicles'>
                <li><a className='nav-link'><i className="fas fa-bus mr-2"></i> Vehicles</a></li>
            </LinkContainer>
        </ul>
        </>
     );
}

 
export default SidebarShuttleCompany;
