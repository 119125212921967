import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactHowler from 'react-howler';
import bellRing from '../../assets/sounds/bell-ring.wav';

const ConfirmationTimerModal = ({ 
    display, 
    toggle, 
    title = "Confirmation", 
    message = "Are you sure you want to do this?", 
    confirmAction,
    confirmText = 'Yes',
    cancelText = 'No',
    size = 'sm',
    countdown = 30,
    bellIcon = false
    }) => {

    const [secondsLeft, setSecondsLeft] = useState(countdown)
    const [playSound, setPlaySound] = useState(false)
    
    const handleConfirmAction = () => {
        setPlaySound(false)
        confirmAction()
        toggle(false)
    }

    const startCountdown = () => {
        setPlaySound(true)

        var timeleft = countdown;
        var downloadTimer = setInterval(function () {

            timeleft -= 1
            
            setSecondsLeft(timeleft)
            if (timeleft <= 0) {
                clearInterval(downloadTimer);
                setPlaySound(false)
                toggle(false)
            }

        }, 1000);
    }

    const handleModalHide = () => {
        setPlaySound(false)
        toggle(false)
    }

    return (
        <>
        <ReactHowler
            src={bellRing}
            loop={true}
            html5={true}
            playing={playSound}
        />
        <Modal
        show={display}
        onHide={() => toggle(false)}
        onShow={() => startCountdown()}
        size={size}
        backdrop="static"
        keyboard={false}
        backdropClassName="level-2"
        centered
        >
            <Modal.Body>
                {bellIcon &&
                <div className="text-center my-2">
                    <i className="far fa-bell faa-ring animated fa-4x"></i>
                </div>
                }
                <h3 className="text-center">{title}</h3>
                <p className="text-center mb-0">{message}</p>

                <hr />
                <div className="text-center">
                    <p><strong><small>This modal will close in</small></strong></p>
                    <h1>{secondsLeft}</h1>
                    <p><small>seconds</small></p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleConfirmAction()}>{confirmText}</Button>
                <Button variant="secondary" onClick={() => handleModalHide()}>{cancelText}</Button>
            </Modal.Footer>
        </Modal>
        </>
        
  )
}

export default ConfirmationTimerModal

