import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createHotel, getHotels } from '../../services/hotels'
import PageLoader from '../common/pageLoader'
import HotelCreator from './hotelCreator'
import HotelList from './hotelList'

const HotelCreation = () => {

    const [hotels, setHotels] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { message } } = await getHotels()
        setIsLoading(false)
        if(Array.isArray(message))
            setHotels(message)
        else {
            setHotels([])
            //TODO: toast when getting hotel admins error
        }
    }

    const handleCreateHotel = async (payload) => {

        const {data :{code,message}} = await createHotel(payload)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newHotel = {
                "_id": message._id,
                "hotel_name": payload.hotel_name,
                "address": payload.address,
                "number_of_rooms": payload.number_of_rooms,
                "max_number_of_guests": payload.max_number_of_guests,
                "routes_require_guest_payment": payload.routes_require_guest_payment,
                "allowed_features": payload.allowed_features
            }

            let _hotels = hotels
            _hotels.push(_newHotel)
            setHotels([..._hotels])

            toast.success(`${payload.hotel_name} was successfull added!`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <>
        {isLoading && <PageLoader />}
        <Row className="mb-4">
            <Col>
                <HotelCreator createHotel={handleCreateHotel} />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <HotelList hotels={hotels} setHotels={setHotels} />
            </Col>
        </Row>
        </>
    )
}

export default HotelCreation