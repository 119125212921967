import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import PublicAirlineCrewSelect from './airlineCrewSelect';
import PublicBookAirlineCrewForm from './bookAirlineCrewForm';
import { verifyPublicKey } from '../../services/airlineCrews';

const PublicBookAirlineCrewPage = () => {

    const params = new URLSearchParams(window.location.search)
    const key = params.get('key')

    const [isVerifiedKey, setIsVerifiedKey] = useState(false)
    const [hasSelectedAirCrew, setHasSelectedAirCrew] = useState(false)
    const [selectedAirCrew, setSelectedAirCrew] = useState({})

    useEffect(() => {
        let _key = key ? key : localStorage.getItem('simul-public-key')
        verify(_key)
    }, [])

    const verify = async (key) => {
        const { data: { code } } = await verifyPublicKey(key)
        if(code === 200) {
            localStorage.setItem('simul-public-key', key)
            setIsVerifiedKey(true)
        } else {
            setIsVerifiedKey(false)
        }
    }

    const selectAirCrew = (airCrew) => {
        setHasSelectedAirCrew(true)
        setSelectedAirCrew(airCrew)
    }

    return (
        <section className="py-3">
            <Col>
                <>
                    {!hasSelectedAirCrew ?
                        <>
                            <PublicAirlineCrewSelect selectAirCrew={selectAirCrew} isVerifiedKey={isVerifiedKey} />
                        </>
                        :
                        <>
                            <PublicBookAirlineCrewForm airCrew={selectedAirCrew} setHasSelectedAirCrew={setHasSelectedAirCrew} isVerifiedKey={isVerifiedKey} />
                        </>
                    }
                </>

            </Col>
        </section>
    );
};

export default PublicBookAirlineCrewPage;