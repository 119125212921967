import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap'
import { addVehicle, removeVehicle, updateSeatPrice, updateTime, updateSeatPriceList } from '../../services/shuttleDestinations'
import { toast } from 'react-toastify'
import ConfirmationModal from '../modals/confirmation';
import { removeFromArray } from '../../utils/utils'
import PageLoader from '../common/pageLoader'
import DialogBoxModal from './dialogBox';

const EditTimetableModal = ({ 
    hotels,
    vehicles,
    display, 
    toggle, 
    timetable, 
    timetableTime, 
    timeEdited, 
    timeSeatPriceEdited,
    timeSeatPriceListEdited,
    timeVehicleRemoved,
    timeVehicleAdded,
    establishmentsPricesToAdd, setEstablishmentsPricesToAdd,
    establishmentsPricesToAddDisplay, setEstablishmentsPricesToAddDisplay,
    getEstablishmentName}) => {

    const [availableVehicles, setAvailableVehicles] = useState([])
    const [otherVehicles, setOtherVehicles] = useState([])
    const [vehiclesToAdd, setVehiclesToAdd] = useState([])
    const [time, setTime] = useState(timetableTime.time)
    const [seatPrice, setSeatPrice] = useState(timetableTime.seat_price)

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [seatPriceMsgModal, setSeatPriceMsgModal] = useState(false)
    const [vehicleDeleteCandidate, setVehicleDeleteCandidate] = useState({});

    const [establishmentsPrices, setEstablishmentsPrices] = useState([])
    const [hotel, setHotel] = useState({})

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        init()
        
    }, [])

    const init = async () => {
        
        let _establishmentsPrices = hotels.filter(x => !isExists(x._id));

        if(timetable.origin_type === 'hotel') {
            setHotel(hotels.find(x => x._id === timetable.origin_id))
        } else {
            setHotel(hotels.find(x => x._id === timetable.destination_id))
        }

        setEstablishmentsPrices([..._establishmentsPrices])

        const _availableVehicles = vehicles.filter(x => {
            return timetableTime.vehicle_ids.some(y => y == x._id)
        })
        setAvailableVehicles(_availableVehicles)

        const _otherVehicles = vehicles.filter(x => {
            return !timetableTime.vehicle_ids.some(y => y == x._id)
        })
        setOtherVehicles(_otherVehicles)

        setIsLoading(false)
    }

    const hasMonthlyServiceFee = (_hotel) => {
        if(!_hotel) return false;
        if(!_hotel.fees) return false;
        return _hotel.fees.monthly_service_fee && _hotel.fees.monthly_service_fee > 0;
    }

    const isExists = (id) =>{
        const obj = establishmentsPricesToAdd.find(x => x.establishment_id === id);
        return obj ? true: false;
    }

    const handleTryRemoveVehicle = (id, index) =>{
        setVehicleDeleteCandidate({id,index})
        setConfirmationModal(true)
    }

    const handleRemoveVehicle = async () => {
        
        const {data: {code, message}} = await removeVehicle(timetable._id, timetableTime._id, vehicleDeleteCandidate.id)
        if (code !== 200) {
            toast.error(`An error occured. Unable to remove vehicle. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            let _vehicles = availableVehicles.filter(x => x._id !== vehicleDeleteCandidate.id)
            setAvailableVehicles(_vehicles)
            
            timeVehicleRemoved(timetable, timetableTime._id, vehicleDeleteCandidate.id)
        }
        setConfirmationModal(false)
    }

    const handleVehiclesToAdd = (id) => {

        if (vehiclesToAdd.some(ids => ids === id)) {
            let _vehicles = vehiclesToAdd
            _vehicles = removeFromArray(_vehicles, id);
            setVehiclesToAdd([..._vehicles]);
        } else {
            let _vehicles = vehiclesToAdd
            setVehiclesToAdd([..._vehicles, id])
        }
    }

    const handleAddVehicles = async () => {
        setIsLoading(true)
        const {data: {code, message}} = await addVehicle(timetable._id, timetableTime._id, vehiclesToAdd)
        if (code !== 200) {
            toast.error(`An error occured. Unable to add vehicle. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            toggle(false)
            timeVehicleAdded(timetable, timetableTime._id, vehiclesToAdd)
        }
        setIsLoading(false)
    }

    const handleUpdateSeatPrice = async () => {
        if(seatPrice < 0)
            return setSeatPriceMsgModal(true)
        
        if(!hasMonthlyServiceFee(hotel) && seatPrice <= 0)
            return setSeatPriceMsgModal(true)

        setIsLoading(true)
        const payload = {
            "timetable_id": timetableTime._id,
            "seat_price": seatPrice
        }

        const {data: {code, message}} = await updateSeatPrice(timetable._id, payload)
        if (code !== 200) {
            toast.error(`An error occured. Unable to update seat price. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setIsLoading(false)
            return
        } else {
            toggle(false)
            timeSeatPriceEdited(timetable, timetableTime._id, seatPrice)
        }
        setIsLoading(false)
    }

    const handleUpdateSeatPriceList = async () => {
        setIsLoading(true)
        const payload = {
            "timetable_id": timetableTime._id,
            "seat_price_list": reduceEstablishmentsPricesDocument(establishmentsPricesToAdd)
        }

        const {data: {code, message}} = await updateSeatPriceList(timetable._id, payload)
        if (code !== 200) {
            toast.error(`An error occured. Unable to update seat price. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            toggle(false)
            timeSeatPriceListEdited(timetable, timetableTime._id, establishmentsPricesToAdd)
        }
        setIsLoading(false)
    }

    const reduceEstablishmentsPricesDocument = (establishmentPrices) => {
        var _establishmentPrices = []
        establishmentPrices.forEach(x => {
            if(x.hasOwnProperty('_id')) {
                _establishmentPrices.push({
                    "establishment_id": x.establishment_id,
                    "amount": x.amount
                })
            } else {
                _establishmentPrices.push(x)
            }
        })
        return _establishmentPrices
    }

    const handleUpdateTime = async () => {
        setIsLoading(true)
        const {data: {code, message}} = await updateTime(timetable._id, timetableTime._id, time)
        if (code !== 200) {
            toast.error(`An error occured. Unable to update time. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            toggle(false)
            timeEdited(timetable, timetableTime._id, time)
        }
        setIsLoading(false)
    }

    const handleEstablishmentsPricesStatusClass = (statusClass, index) => {
        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices[index]['statusClass'] = statusClass
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleSetEstablishmentPrice = (price, index) => {
        handleEstablishmentsPricesStatusClass('', index)
        
        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices[index]['amount'] = price;
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleEstablishmentPricesToAdd = (establishmentPrice, index) => {
        if (!establishmentPrice.amount || establishmentPrice.amount < 0) {
            handleEstablishmentsPricesStatusClass('is-invalid', index)
            return
        }
        
        if( !hasMonthlyServiceFee(establishmentPrice) && establishmentPrice.amount <= 0) {
            handleEstablishmentsPricesStatusClass('is-invalid', index)
            return
        }

        let _establishmentsPricesToAdd = establishmentsPricesToAdd
        _establishmentsPricesToAdd.push({
            "establishment_id": establishmentPrice._id,
            "amount": establishmentPrice.amount,
        })
        setEstablishmentsPricesToAdd([..._establishmentsPricesToAdd])

        let _establishmentsPricesToAddDisplay = establishmentsPricesToAddDisplay
        _establishmentsPricesToAddDisplay.push({
            "establishment_id": establishmentPrice._id,
            "amount": establishmentPrice.amount,
            "hotel_name": establishmentPrice.hotel_name
        })
        setEstablishmentsPricesToAddDisplay([..._establishmentsPricesToAddDisplay])

        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices.splice(index, 1)
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleEstablishmentPricesToRemove = (establishmentPrice, index) => {
        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices.push({
            "_id": establishmentPrice.establishment_id,
            "amount": establishmentPrice.amount,
            "hotel_name": establishmentPrice.hotel_name ?? getEstablishmentName(establishmentPrice.establishment_id)
        })
        setEstablishmentsPrices([..._establishmentsPrices])

        let _establishmentsPricesToAdd = establishmentsPricesToAdd
        _establishmentsPricesToAdd.splice(index, 1)
        setEstablishmentsPricesToAdd([..._establishmentsPricesToAdd])

        let _establishmentsPricesToAddDisplay = establishmentsPricesToAddDisplay
        _establishmentsPricesToAddDisplay.splice(index, 1)
        setEstablishmentsPricesToAddDisplay([..._establishmentsPricesToAddDisplay])
    }

    return (
        <>
            {isLoading && <PageLoader />}
            <ConfirmationModal 
                title="Delete Vehicle"
                message="Are you sure you want to delete this vehicle?"
                display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleRemoveVehicle} />
            <Modal
            show={display}
            onHide={() => toggle(false)}
            size={timetable.is_common_origin ? 'lg' : 'md'}
            centered
            >
                <Modal.Body>
                    <h4 className="mb-4"> Edit Timetable</h4>
                    <hr className="mb-2" />

                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label><strong>Time</strong></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl
                                    onChange={(e) => setTime(e.target.value)}
                                    defaultValue={timetableTime.time}
                                    placeholder="Input Time"
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => handleUpdateTime()}>Save</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        {!timetable.is_common_origin &&
                        <Col>
                            <Form.Group>
                                <Form.Label><strong>Seat Price</strong></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                        onChange={(e) => setSeatPrice(e.target.value)}
                                        defaultValue={timetableTime.seat_price}
                                        placeholder="Input Seat Price"
                                        />
                                        <InputGroup.Append>
                                            <Button disabled variant="outline-secondary">{timetableTime.seat_price_currency}</Button>
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <Button onClick={() => handleUpdateSeatPrice()}>Save</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                            </Form.Group>
                        </Col>
                        }
                    </Row>

                    {timetable.is_common_origin && 
                    <>
                    <Row className="mb-4">
                        <Col lg="6">
                            <strong>Establishments Prices</strong>
                            <Form.Text className="mb-2">Select establishments and set prices</Form.Text>
                            <div className="p-2 border rounded" style={{minHeight:'53px'}}>
                                {establishmentsPrices && establishmentsPrices.map((establishmentPrice, index) => (
                                    <Row key={`establishment-price-${index}`} className="mb-1">
                                        <Col xs="7">
                                            <FormControl value={establishmentPrice.hotel_name} size="sm" disabled />
                                        </Col>
                                        <Col xs="3" className="px-0">
                                            <FormControl className={`${establishmentPrice.statusClass}`} value={establishmentPrice.amount ?? ''} onChange={(e) => handleSetEstablishmentPrice(e.target.value, index)} placeholder="price" size="sm" type="number" />
                                        </Col>
                                        <Col xs="2">
                                            <Button onClick={() => handleEstablishmentPricesToAdd(establishmentPrice, index)} size="sm" title="add category"><i className="fas fa-plus"></i></Button>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Col>
                        <Col>
                            <Form.Label className="mb-0">&nbsp;</Form.Label>
                            <Form.Text className="mb-2">Establishments prices to add</Form.Text>
                            <div className="p-2 border rounded" style={{minHeight:'53px'}} >
                            {establishmentsPricesToAddDisplay.length > 0 && establishmentsPricesToAddDisplay.map((establishmentPrice, index) => (
                                <Row key={`establishment-price-to-add-${index}`} className="mb-1">
                                    <Col xs="7">
                                        <FormControl value={establishmentPrice.hotel_name ?? getEstablishmentName(establishmentPrice.establishment_id)} size="sm" disabled />
                                    </Col>
                                    <Col xs="3" className="px-0">
                                        <FormControl value={`${establishmentPrice.amount} CHF`} size="sm" disabled />
                                    </Col>
                                    <Col xs="2">
                                        <Button onClick={() => handleEstablishmentPricesToRemove(establishmentPrice, index)} size="sm" title="remove price"><i className="fas fa-minus"></i></Button>
                                    </Col>
                                </Row>
                            ))}
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button onClick={() => handleUpdateSeatPriceList()}>Save</Button>
                    </div>
                    </>
                    }
                    
                    <hr></hr>
                    <div className="mb-3">
                        <p className="mb-2"><strong>Vehicles</strong></p>
                    
                        {availableVehicles.length > 0 ?
                        <>
                        {availableVehicles.map((vehicle, index) => (
                            <p key={`timetable-vehicle-${index}`} className="mb-1">
                                <span className="mr-3">{`${vehicle.name} - ${vehicle.model}`}</span>
                                <span className="cursor-pointer text-muted" title="remove vehicle" onClick={() => handleTryRemoveVehicle(vehicle._id, index)}><i className="fas fa-times"></i></span>
                            </p>
                        ))}
                        </>
                        :
                        <p>No Vehicles</p>
                        }
                    </div>
                    <hr></hr>
                    <div className="mb-3">
                        <div className="mb-2">
                            <p className="mb-2"><strong>Add Vehicles</strong></p>
                            {(otherVehicles && otherVehicles.length > 0) ?
                            <>
                            {otherVehicles.map((vehicle, index) => (
                                <Button key={`othervehicles-${index}`} size="sm" variant={vehiclesToAdd.some(ids => ids == vehicle._id) ? "outline-primary" : "outline-secondary"}
                                    className="btn-rounded mr-2 mb-2" 
                                    onClick={() => handleVehiclesToAdd(vehicle._id)}>
                                    {vehicle.name}
                                </Button>
                            ))}
                            </>
                            :
                            <p>No vehicles to add</p>
                            }
                        </div>
                        {vehiclesToAdd.length > 0 &&
                        <div>
                            <Button className="px-3" size="sm" onClick={() => handleAddVehicles()}>Add Selected Vehicles</Button>
                        </div>
                        }
                    </div>
                    
                    <hr></hr>
                    <Button variant="outline-primary" className="px-3 float-right" onClick={() => toggle(false)}>Close</Button>
                
                </Modal.Body>
            </Modal>
            <DialogBoxModal display={seatPriceMsgModal} toggle={setSeatPriceMsgModal} title='Seat Price Required' message='The seat price you entered is invalid. Please enter a valid seat price.' />
        </>
  )
}

export default EditTimetableModal

