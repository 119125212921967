import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';
import { updateHotel } from '../../services/hotels';
import { toast } from 'react-toastify'
import ConfirmationModal from '../modals/confirmation';

const HotelOptions = (props) => {
    
    const [hotel, _] = useState(props.hotel)
    const [logo, setLogo] = useState()
    const [commissionSharing, setCommissionSharing] = useState(props.hotel.commission_sharing_option ?? 'Option A')
    const [confirmCommissionSharingChange, setConfirmCommissionSharingChange] = useState(false)
    const [numberOfRooms, setNumberOfRooms] = useState(props.hotel.number_of_rooms ?? '')
    const [maxNumberOfGuests, setMaxNumberOfGuests] = useState(props.hotel.max_number_of_guests ?? '')
    const [invoiceName, setInvoiceName] = useState(props.hotel?.invoice_info?.name ?? '')
    const [invoiceAddress, setInvoiceAddress] = useState(props.hotel?.invoice_info?.address ?? '')

    const _currentuser = getCurrentUser()

    const handleChangeLogo = () => {
        const file = document.querySelector('#file-upload').files[0];;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            setLogo(reader.result)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const saveLogo = async () => {

        const hotelLogo = {
            'hotel_logo': logo
        }

        const {data: {code, message}} = await updateHotel(_currentuser.hotel_id, hotelLogo)
        if (code !== 200) {
            //TODO: toast
            return
        } else {
            toast.success(` Logo Saved!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            document.getElementById("hotel-logo").setAttribute("src", logo);
        }
    }

    const handleSetCommissionSharing = async () => {

        const payload = {
            "commission_sharing_option": commissionSharing
        }
        const { data: { code, message } } = await updateHotel(_currentuser.hotel_id, payload);
        if (code == 200) {
            setCommissionSharing(commissionSharing)
        } else {
            //TODO: toast
        }
        
    }

    const handleUpdateHotelInfo = async () => {
        const payload = {
            "number_of_rooms": numberOfRooms,
            "max_number_of_guests": maxNumberOfGuests
        }
        const { data: { code, message } } = await updateHotel(_currentuser.hotel_id, payload);
        if (code == 200) {
            toast.success(` Hotel info updated.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            //TODO: toast
        }
    }

    const handleUpdateInvoiceInfo = async () => {
        const payload = {
            "invoice_info": {
                "name": invoiceName, 
                "address": invoiceAddress
            }
        }

        const { data: { code, message } } = await updateHotel(_currentuser.hotel_id, payload);
        if (code == 200) {
            toast.success(` Invoice info updated.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            //TODO: toast
        }
    }

    const imgPreview = {
        'maxWidth': '100%',
        'maxHeight': '200px'
    }

    return ( 
        <>
        {hotel && 
        <>
        <ConfirmationModal 
            display={confirmCommissionSharingChange} 
            toggle={setConfirmCommissionSharingChange} 
            message={`Are you sure you want to change commission sharing option?`}
            confirmAction={handleSetCommissionSharing} />
        <Card>
            <Card.Body>
                <p><strong>HOTEL OPTIONS</strong></p>
                <hr></hr>
                <Row>
                    <Col lg="3" className="mb-3">
                        <input className="mb-3" id="file-upload" type="file" onChange={() => handleChangeLogo()} /><br />
                        {logo ? 
                        <img src={logo} style={imgPreview} alt="Image preview..." />
                        :
                            hotel.hotel_logo ?
                            <img src={hotel.hotel_logo} style={imgPreview} alt="Image preview..." />
                            :
                            <div className="center-xy" style={{height:'150px',width:'150px',backgroundColor:'#eee'}}><span>LOGO</span></div>
                        }
                        <Button size="sm" className="mt-3" onClick={() => saveLogo()}>Save Hotel Logo</Button>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col lg="5" className="mb-4">
                        <h4>Commission Sharing Options</h4>
                        <Form.Group>
                            <Form.Label>Commission Sharing Options for Shuttle Bookings</Form.Label>
                            <Form.Control as="select" value={commissionSharing} onChange={(e) => setCommissionSharing(e.target.value)}>
                                <option value="Option A">Option A</option>
                                <option value="Option B">Option B</option>
                            </Form.Control>
                            <Form.Text className="text-muted">
                            {commissionSharing == "Option A" ?
                            `3% and 2% of the reservation amount for hotel staff and hotel admin respectively when the former made the booking. Otherwise, hotel admin gets all 5%`
                            :
                            `5% of the reservation amount divided for all members`
                            }
                            </Form.Text>

                            <Button size="sm" className="mt-3" onClick={() => setConfirmCommissionSharingChange(true)}>Save Sharing Option</Button>
                        </Form.Group>
                    </Col>
                    <Col lg="7" className="mb-4">
                        <h4>Invoice Info</h4>
                        <Row>
                            {
                                hotel.fees &&
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Monthly Service Fee</Form.Label>
                                        <Form.Control value={hotel.fees.monthly_service_fee ? hotel.fees.monthly_service_fee + ' CHF': 'Not Set'} disabled/>
                                    </Form.Group>
                                </Col>
                            }
                            <Col lg="8">
                                <Form.Group>
                                    <Form.Label>Invoice Name</Form.Label>
                                    <Form.Control value={invoiceName} onChange={(e) => setInvoiceName(e.target.value)} placeholder="Enter invoice name" />
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group>
                                    <Form.Label>Invoice Address</Form.Label>
                                    <Form.Control value={invoiceAddress} onChange={(e) => setInvoiceAddress(e.target.value)} placeholder="Enter invoice address" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button size="sm" className="mt-3" onClick={() => handleUpdateInvoiceInfo()}>Update Invoice Info</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg="5" className="mb-4">
                        <h4>Hotel Info</h4>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Number of Rooms</Form.Label>
                                    <Form.Control value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)} type="number" />
                                </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group>
                                <Form.Label>Max number of Guests</Form.Label>
                                    <Form.Control value={maxNumberOfGuests} onChange={(e) => setMaxNumberOfGuests(e.target.value)} type="number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Button size="sm" className="mt-3" onClick={() => handleUpdateHotelInfo()}>Save Hotel Info</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
        }
        </>
     );
}
 
export default HotelOptions;