import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BookingsList from '../../components/common/bookingsList';

const SystemAdminBookingsPage = () => {

    return <>
        <section>
            <Row className="mb-4">
                <Col>
                    <BookingsList/>
                </Col>
            </Row>
        </section>
    </>
}

export default SystemAdminBookingsPage