import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import UpdateVehicleModal from '../modals/updateVehicle'

const VehicleList = ({vehicles, setVehicles, simulAccounts}) => {

    const [updateVehicleModal, setUpdateVehicleModal] = useState(false)
    const [vehicleToEdit, setVehicleToEdit] = useState()

    const handleUpdateVehicleModal = (vehicle) => {
        setVehicleToEdit(vehicle)
        setUpdateVehicleModal(true)
    }

    const vehicleUpdated = (vehicle) => {

        let _vehicles = vehicles

        var objIndex = _vehicles.findIndex((x => x._id == vehicle._id))
        _vehicles[objIndex] = vehicle

        setVehicles(_vehicles)
    }

    const getDriverName = (id, simulAccounts) => {
        
        if(!simulAccounts || simulAccounts.length == 0 || !id){
            return '';
        }
        const driver = simulAccounts.find(x => x._id == id)
    
        let name = driver ? `${driver.first_name} ${driver.last_name}` : 'no driver'

        if(name.trim() === "" || !driver.first_name) name = driver.username

        return name
    }

    const dotGreen = {
        height: 10,
        width: 10,
        backgroundColor: 'green',
        borderRadius: '50%',
        display: 'inline-block'
    };

    const dotRed = {
        height: 10,
        width: 10,
        backgroundColor: 'red',
        borderRadius: '50%',
        display: 'inline-block'
    };

    return <>
        {vehicleToEdit &&
        <UpdateVehicleModal
            display={updateVehicleModal}
            toggle={setUpdateVehicleModal}
            vehicle={vehicleToEdit}
            vehicleUpdated={vehicleUpdated}
            simulAccounts={simulAccounts}
        />
        }
        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Vehicles</Card.Header>
            <Card.Body>
                <Table striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Model</th>
                            <th>Year</th>
                            <th>Color</th>
                            <th>Seats</th>
                            <th>Driver</th>
                            <th><div className='text-center'>Available</div></th>
                            <th>Priority</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vehicles.length > 0 &&
                            vehicles.map((vehicle, index) =>
                                <tr key={`vehicle-${index}`}>
                                    <td>{vehicle.name}</td>
                                    <td>{vehicle.model}</td>
                                    <td>{vehicle.year}</td>
                                    <td>{vehicle.color}</td>
                                    <td>{vehicle.seats}</td>
                                    <td>{getDriverName(vehicle.driver_id, simulAccounts)}</td>
                                    <td style={{textAlign:'center',verticalAlign:'middle'}}>
                                        <div style={!vehicle.available ? dotRed : dotGreen}></div>                                       
                                    </td>
                                    <td>{vehicle?.auto_assignment_priority_no}</td>
                                    <td className="text-center">
                                        <Button size="sm" onClick={() => handleUpdateVehicleModal(vehicle)}>Edit</Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default VehicleList
