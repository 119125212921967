import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import ConfirmationModal from '../../components/modals/confirmation';
import { getHotelById } from '../../services/hotels'
import { getTaxiDrivers } from '../../services/taxiAccounts'
import { updateTaxiDriver } from '../../services/taxiBookings'
import { toast } from 'react-toastify';

const AssignTaxiDriverModal = ({ display, toggle, booking, taxiDrivers, driverUpdated }) => {

    const [driverId, setDriverId] = useState('');

    //Can now set any taxi driver, regardless of hotel city
    const [filteredTaxiDrivers, setFilteredTaxiDrivers] = useState(taxiDrivers);

    const [confirmationModal, setConfirmationModal] = useState(false);

    // useEffect(() => {
    //     fetchHotelDetails();
    // },[filteredTaxiDrivers])

    // const fetchHotelDetails = async () => {

    //     if(!booking.booking_source_id){
    //         setFilteredTaxiDrivers(taxiDrivers);
    //         return;
    //     }

    //     const { data: { code, message } } = await getHotelById(booking?.booking_source_id);
        // if(code === 200){
        //     filterTaxiDriversByHotelAddress(taxiDrivers, message);
        // }
    //}

    // const filterTaxiDriversByHotelAddress = (taxiDrivers, hotelDetails) => {

    //     let _filteredTaxiDrivers = taxiDrivers;
    //     _filteredTaxiDrivers = _filteredTaxiDrivers.filter(x => 
    //         x.operating_cities.map(x => x.toLowerCase().trim())
    //             .includes(hotelDetails?.address?.city.toLowerCase().trim())
    //         );
    //     setFilteredTaxiDrivers(_filteredTaxiDrivers);
    // }

    const handleAssignDriver = async () => {
        
        const driver = filteredTaxiDrivers.find(x => x._id === driverId);
        if(!driver.car_categories || !driver.car_categories.some(c => c.car_category.seats >= booking.number_of_persons)){
            setConfirmationModal(true);
        }
        else{
            handleAssignDriverFinal();
        }
    }

    const handleAssignDriverFinal = async () => {
        
        const { data: { code, message } } = await updateTaxiDriver(booking._id, driverId);
        if (code !== 200) {
            toastError(`An error occured on assigning taxi driver. ${message}.`);
        } else {
            toast.success('Successfully set taxi driver.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            driverUpdated(driverId)
        }

        toggle(false);
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    return (
        <>
        <ConfirmationModal 
            title="Warning"
            message="The driver don't have any car that matched the required seats. Do you want to proceed?"
            confirmText="Proceed"
            cancelText="Cancel"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleAssignDriverFinal} />

        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-2">
                    Choose Taxi Driver
                </Card.Title>   
                <div className="mb-4">
                <Form.Control as="select" onChange={(e) => setDriverId(e.target.value)}>
                    <option value="">Choose Taxi Driver</option>
                    {filteredTaxiDrivers.map((driver, index) => (
                        <option key={`driver-${index}`} value={driver._id}>{driver.first_name || driver.last_name ? `${driver.first_name} ${driver.last_name}` : driver.username}</option>
                    ))}
                </Form.Control>
                </div>
                <div>
                    <Button className="w-100" onClick={() => handleAssignDriver()}>Select</Button>
                </div>
            </Modal.Body>
        </Modal>
        </>
  )
}

export default AssignTaxiDriverModal

