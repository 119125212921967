import http from './http'
import { getToken } from './auth'

const base = 'notifications'

export async function getUnacknowledgedNotifications() {

    return await http.get(`${base}/unacknowledged`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
