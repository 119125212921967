import http from './http'
import { getToken } from './auth'

const base = 'users'

export async function getUser(id) {
    return await http.get(`${base}/user/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAllHotelStaffs() {
    return await http.get(`${base}/hotel-staffs`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getHotelAdmins() {
    return await http.get(`${base}/hotel-admins`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAirlineAdmins() {
    return await http.get(`${base}/airline-admins`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getShuttleAdmins() {
    return await http.get(`${base}/shuttle-admins`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getShuttleDrivers() {
    return await http.get(`${base}/shuttle-drivers`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getSystemAdmins() {
    return await http.get(`${base}/system-admins`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateUser(id, payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`${base}/${id}`, payload, config)
}

export async function deleteUser(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}
