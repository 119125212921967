import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { generateAirCrewInvoice } from '../../services/airlineCrewInvoices'
import { get } from '../../services/airlineCrews'
import { getHotels } from '../../services/hotels'
import DatePicker from 'react-datepicker';

const GenerateAirCrewInvoiceModal = ({ display, toggle, update }) => {

    const [companies, setCompanies] = useState([])

    const [dateRange, setDateRange] = useState([null, null])
    const [startDate, endDate] = dateRange
    const [companyId, setCompanyId] = useState('')

    const [isGenerating, setIsGenerating] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        // const { data: { message: hotels } } = await getHotels()
        // if (Array.isArray(hotels)) {
        //     let _companies = hotels
        //     const { data: { message: airlines } } = await getAirlines()
        //     if (Array.isArray(airlines)) {
        //         _companies = _companies.concat(airlines)
        //         setCompanies(_companies)
        //     }
        //     else {
        //         setCompanies([])
        //     }
        // }
        // else {
        //     setCompanies([])
        // }
        const { data: { message: airlineCrews } } = await get()
        if (Array.isArray(airlineCrews)) {
            setCompanies(airlineCrews)
        }
        else {
            setCompanies([])
        }
    }

    const handleGenerateInvoice = async (e) => {

        e.preventDefault()
        e.stopPropagation()

        let _payload = {
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            // companyType: companies.find(x => x.id === companyId)?.hotel_name ? "hotel" : "airline"
            companyType: "airline-crew"
        }

        setIsGenerating(true)
        const { data: { code, message } } = await generateAirCrewInvoice(_payload)
        setIsGenerating(false)
        if (code == 200) {

            if(typeof message === 'string' || message instanceof String) {
                toast.success(` ${message}.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                update(message)
                toggle(false)
            }
            
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }

    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            centered
            backdrop={isGenerating ? "static" : true}
            size="sm"
        >
            <Modal.Body>
                <h4 className="text-center mb-4">Generate Invoice</h4>

                <Form id="generate-aircrew-invoice" onSubmit={handleGenerateInvoice}>

                    <Row>
                        <Col xs="12">
                            <Form.Group>
                                <Form.Label>Date Range</Form.Label>
                                <div className="react-datepicker-w100">
                                    <DatePicker
                                        selectsRange={true}
                                        placeholderText='Select Date Range'
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        className="form-control"
                                        style={{ width: '400px' }}
                                        dateFormat="MMM d, yyyy"
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs="12">
                            <Form.Group>
                                <Form.Label>Airline Crew</Form.Label>
                                <Form.Control as="select" value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                                    <option value="" disabled>Select Airline Crew</option>
                                    {companies.length > 0 && companies.map((company) => (
                                        <option key={`company-${company.id}`} value={company.id}>{company.airline_name}</option>
                                    ))}

                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr className="my-4" />

                    <div className="d-flex justify-content-center">
                        <Button disabled={isGenerating} type="submit">{isGenerating ? 'Generating...' : 'Generate'}</Button>
                    </div>
                </Form>

            </Modal.Body>
        </Modal>
    )
}

export default GenerateAirCrewInvoiceModal