import { PDFDownloadLink } from '@react-pdf/renderer';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import LinkTD from '../components/common/linkTd';
import PageLoader from '../components/common/pageLoader';
import ShuttleInvoicePDF from '../components/pdf/invoiceShuttle';
import { getHotelById } from '../services/hotels';
import { getShuttleInvoiceById } from '../services/invoicesShuttle';

const ShuttleInvoicePage = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [invoice, setInvoice] = useState()
    const [hotel, setHotel] = useState()
    
    const { _id } = props.match.params

    useEffect(() => {
        fetch()
    }, [])
    
    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getShuttleInvoiceById(_id)
  
        if(code === 200) {
            setInvoice(message)
            fetchHotel(message.establishment_id)
        }
        else {
            //TODO: toast
        } 

        setIsLoading(false)
    }

    const fetchHotel = async (id) => {
        const { data: { code, message } } = await getHotelById(id)
   
        if(code === 200)
            setHotel(message)
        else {
            //TODO: toast
        } 
    }

    const getInvoiceRecipientAddress = (hotel) => {
        return `${hotel.address?.street} ${hotel.address?.city} ${hotel.address?.zip} ${hotel.address?.country}`
    }

    return <>
        <section>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {isLoading ?
                            <PageLoader position="relative" backgroundColor="#f2ecec" />
                            :
                            <>
                                {invoice && 
                                <>
                                <h1 className="mb-2">Invoice</h1>
                                <h4 className="mb-1">{hotel?.hotel_name}</h4>
                                <p className="mb-3">{hotel && getInvoiceRecipientAddress(hotel)}</p>

                                <div className="invoice-general-info mb-4">
                                    <p className="mb-0">Invoice No.: <strong>{invoice.invoice_human_readable_number}</strong></p>
                                    <p className="mb-0">Date Covered: <strong>{Moment.utc(invoice.period_from).format('DD.MM.YYYY')} - {Moment.utc(invoice.period_to).format('DD.MM.YYYY')}</strong></p>
                                    <p className="mb-0">Number of Bookings: <strong>{invoice.number_of_sales}</strong></p>
                                    <p className="mb-0">Number of Seats: <strong>{invoice.seats}</strong></p>
                                    <p className="mb-2">Recipient: <strong style={{textTransform:'capitalize'}}>{invoice.establishment_type}</strong></p>
                                </div>

                                {hotel && 
                                <PDFDownloadLink id="download-pdf" className="mb-3 btn btn-outline-primary" document={<ShuttleInvoicePDF invoice={invoice} hotelName={hotel.hotel_name} recepientAddress={getInvoiceRecipientAddress(hotel)} />} fileName="shuttle-invoice.pdf">
                                    {({ blob, url, loading, error }) => (loading ? ' Loading document...' : ' Download PDF')}
                                </PDFDownloadLink>
                                }
                                <h4 className="mb-3">Sales</h4>

                                <Table className="mb-5">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Guest Name</th>
                                        <th>Paid At Hotel</th>
                                        {/* <th>Commission</th> */}
                                        <th>Invoice Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoice.sales.length > 0 && invoice.sales.map((sale, index) => (
                                        <tr key={`invoicesale-${index}`}>
                                            <LinkTD to={`/booking/${sale.booking_id}`}>{Moment.utc(sale.sale_date).format('DD.MM.YYYY @ HH:mm')}</LinkTD>
                                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}</LinkTD>
                                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale?.amount.toFixed(2)} CHF</LinkTD>
                                            {/* <LinkTD to={`/booking/${sale.booking_id}`}>{sale?.commission_amount?.toFixed(2)} CHF</LinkTD> */}
                                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale?.amount.toFixed(2)} CHF</LinkTD>
                                        </tr>
                                    ))}
                                    {/* <tr>
                                        <td></td>
                                        <td></td> 
                                        <td></td>
                                        <td>Total Commission</td>
                                        <td>{invoice.commission_amount ? invoice.commission_amount.toFixed(2) + ' CHF' : 'N/A'}</td>
                                    </tr> */}
                                    <tr>
                                        {/* <td className="border-top-0"></td>
                                        <td className="border-top-0"></td> 
                                        <td className="border-top-0"></td>  */}
                                        <td></td> 
                                        <td></td>
                                        <td>Sub Total</td>
                                        <td>{invoice.sub_amount ? invoice.sub_amount.toFixed(2) + ' CHF' : 'N/A'}</td>
                                    </tr>
                                    {
                                        invoice.fees && invoice.fees.monthly_service_fee &&
                                        <tr>
                                            <td className="border-top-0"></td>
                                            <td className="border-top-0"></td> 
                                            {/* <td className="border-top-0"></td>  */}
                                            <td>Monthly Service Fee</td>
                                            <td>{invoice.fees.monthly_service_fee.toFixed(2)} CHF</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className="border-top-0"></td>
                                        <td className="border-top-0"></td> 
                                        {/* <td className="border-top-0"></td>  */}
                                        <td>Total</td>
                                        <td><strong style={{fontSize:'20px'}}>{invoice.amount.toFixed(2)} CHF</strong></td>
                                    </tr>
                                    </tbody>
                                </Table>
                                
                                </>
                                }
                            </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default ShuttleInvoicePage