import React, { useEffect, useState } from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCarCategories } from '../../services/carCategories'
import { getTaxiCompanies } from '../../services/taxiCompanies'
import { deleteUser } from '../../services/users'
import PageLoader from '../common/pageLoader'
import AddTaxiDriverCreditsModal from '../modals/addTaxiDriverCreditsModal'
import ConfirmationModal from '../modals/confirmation'
import UpdateAccountTaxiModal from '../modals/updateAccountTaxi'

const TaxiDriverList = ({taxiDrivers, setTaxiDrivers, carCategories, isTableLoading, isLoading, setIsLoading}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [taxiCreditModal, setTaxiCreditModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [user, setUser] = useState()

    const [taxiCompanies, setTaxiCompanies] = useState([])
    const [citiesUpdated, setCitiesUpdated] = useState(false)

    useEffect(() => {
        fetchTaxiCompanies()
    },[])

    const handleRemoveUser = async (id) => {
        setIsLoading(true)
        const {data: {code, message}} = await deleteUser(id)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this user. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            userRemoved()
        }
    }

    const fetchTaxiCompanies = async () => {
        const {data: {code, message}} = await getTaxiCompanies();
        if(code == 200) {
            setTaxiCompanies(message)
        } else {
            //TODO: toast
        }
    }

    const accountUpdated = (account) => {
        const userIndex = taxiDrivers.indexOf(user)
        let _taxiDrivers = taxiDrivers
        if(account.new_password) _taxiDrivers[userIndex]['doc'].password = account.new_password
        if(account.first_name) _taxiDrivers[userIndex].first_name = account.first_name
        if(account.last_name) _taxiDrivers[userIndex].last_name = account.last_name
        if(account.taxi_specific.operating_cities) _taxiDrivers[userIndex].operating_cities = account.taxi_specific.operating_cities
        if(account.phone) _taxiDrivers[userIndex].phone = account.phone
        if(account.taxi_specific.license_number) _taxiDrivers[userIndex].license_number = account.taxi_specific.license_number
        if(account.taxi_specific.simul_invoicing_option) _taxiDrivers[userIndex].simul_invoicing_option = account.taxi_specific.simul_invoicing_option
        if(account.taxi_specific.simul_invoicing_option_fixed_charge_amount) _taxiDrivers[userIndex].simul_invoicing_option_fixed_charge_amount = account.taxi_specific.simul_invoicing_option_fixed_charge_amount
        if(account.taxi_specific.car_category_id) _taxiDrivers[userIndex].car_category_id = account.taxi_specific.car_category_id
        if(account.taxi_specific.car_categories) _taxiDrivers[userIndex].car_categories = account.taxi_specific.car_categories
        if(account.taxi_specific.taxi_company_id) _taxiDrivers[userIndex].taxi_company_id = account.taxi_specific.taxi_company_id
        if(account.taxi_specific.credits_deduction_option) _taxiDrivers[userIndex].credits_deduction_option = account.taxi_specific.credits_deduction_option
        setTaxiDrivers(_taxiDrivers)
    }

    const handleUpdateAccountModal = async (user) => {
        setCitiesUpdated(false)
        setUser(user)
        setUpdateModal(true)
    }

    const userRemoved = () => {
        const userIndex = taxiDrivers.indexOf(user)
        let _taxiDrivers = taxiDrivers
        _taxiDrivers.splice(userIndex, 1)
        setTaxiDrivers([..._taxiDrivers])
    }

    const handleConfirmationModal = (user) => {
        setUser(user)
        setConfirmationModal(true)
    }

    const handleCloseModal = (toggle) =>{
        setUser();
        setUpdateModal(false);
    }

    const handleSetTaxiCredit = (user) => {
        setUser(user)
        setTaxiCreditModal(true)
    }

    const handleOnSuccessSetCredit = (response) => {
        setTaxiDrivers( taxiDrivers.map(driver => 
            driver._id === response.taxi_driver_id ? {
                ...driver,
                credits_balance: response.credits_after_top_up,
                credits_since_latest_top_up: response.credits_after_top_up
            } : driver
        ))
    }

    const getInvoicingOption = (td) => {
        if(!td.simul_invoicing_option){
            return 'NONE';
        }
        return td.simul_invoicing_option === 'FIXED-CHARGE' ? `${td.simul_invoicing_option} (${td.simul_invoicing_option_fixed_charge_amount ?? 0})` : td.simul_invoicing_option;
    }

    const getCarCategoryName = (categoryId) => {
        if(!categoryId){
            return '';
        }
        const _carCategory = carCategories.find(x => x._id == categoryId)
        if(!_carCategory){
            return '';
        }
        return _carCategory.name
    }

    const getTaxiCompanyName = (companyId) => {
        if(!companyId){
            return '';
        }
        const _taxiCompany = taxiCompanies.find(x => x._id == companyId)
        if(!_taxiCompany){
            return '';
        }
        return _taxiCompany.name
    }

    return <>

        {isLoading && <PageLoader /> }

        {user && 
        <UpdateAccountTaxiModal display={updateModal} toggle={handleCloseModal} user={user} carCategories={carCategories} taxiCompanies={taxiCompanies} 
            accountUpdated={accountUpdated} setIsLoading={setIsLoading} 
            citiesUpdated={citiesUpdated} setCitiesUpdated={setCitiesUpdated} />
        }

        <AddTaxiDriverCreditsModal display={taxiCreditModal} toggle={setTaxiCreditModal} driver={user} onSuccess={handleOnSuccessSetCredit} />

        <ConfirmationModal
            title="Remove Taxi Driver"
            message="Are you sure you want to delete this user?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleRemoveUser(user._id ? user._id : user['doc']._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Taxi Drivers</Card.Header>
            <Card.Body>
                {!isTableLoading ?
                <Table striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Password</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Credits Deduction</th>
                            {/* <th>Simul Invoicing</th> */}
                            <th>Taxi Company</th>
                            <th>Car Categories</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxiDrivers.length > 0 ?
                        <>
                        { 
                            taxiDrivers.map((taxiDriver, index) =>
                                <tr key={`taxiDriver-${index}`}>
                                    <td>{taxiDriver.doc.username}</td>
                                    <td>{taxiDriver.doc.password}</td>
                                    <td>{taxiDriver.first_name}</td>
                                    <td>{taxiDriver.last_name}</td>
                                    <td>{taxiDriver.credits_deduction_option}</td>
                                    {/* <td>{getInvoicingOption(taxiDriver)}</td> */}
                                    <td>{getTaxiCompanyName(taxiDriver.taxi_company_id)}</td>
                                    <td>
                                        {taxiDriver.car_categories.map((category, index) => (
                                            <Button key={`carcategory-${index}`} size="sm" style={{fontSize:'.75rem'}} variant="outline-primary"
                                                className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                                {getCarCategoryName(category.car_category_id)} - {category.make} {category.model} {category.model_year}
                                            </Button>
                                        ))}
                                    </td>
                                    <td className="actions d-flex flex-column justify-content-center align-items-center">
                                        <i title="update account" className="far fa-edit mb-2" onClick={() => handleUpdateAccountModal(taxiDriver)}></i>
                                        <i title="add credits" className="far fa-credit-card mb-2" onClick={() => handleSetTaxiCredit(taxiDriver)}></i>
                                        <i title="delete user" className="far fa-trash-alt mb-2" onClick={() => handleConfirmationModal(taxiDriver)}></i>
                                    </td>
                                </tr>
                            )
                        }
                        </>
                        :
                        <tr className="bg-transparent"><td className="py-4 text-center" colSpan="5">No results found</td></tr>
                        }
                    </tbody>
                </Table>
                :
                <PageLoader position="relative" backgroundColor="#f2ecec" />
                }
            </Card.Body>
        </Card>
    </>
}

export default TaxiDriverList