import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import jwtDecode from 'jwt-decode'
import { createHotelStaff } from '../../services/accounts'
import { getCurrentUser } from '../../services/auth'

const HotelStaffCreator = ({ createHotelStaff }) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

    const _currentUser = getCurrentUser()

    const handleAccountCreationSubmit = async () => {
        createHotelStaff({ username: username, password: password, hotel_id: _currentUser.hotel_id, first_name: firstname, last_name: lastname }, password)
        document.getElementById('hotel-staff-creator').reset()
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Hotel Staff Account</Card.Header>
            <Card.Body>
                <Form id="hotel-staff-creator">
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Firstname</Form.Label>
                        <Form.Control type='text' placeholder='Enter firstname' onChange={e => setFirstname(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Lastname</Form.Label>
                        <Form.Control type='text' placeholder='Enter lastname' onChange={e => setLastname(e.target.value)} />
                    </Form.Group>
                </Form>
                <div className="text-center">
                    <Button variant='primary' onClick={() => handleAccountCreationSubmit()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default HotelStaffCreator