import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createAccount } from '../../services/systemAdmin'
import RegistrationForm from './../../components/common/registrationForm'

const Admin = () => {
    const [firstname, setFirstName] = useState('Elon')
    const [lastname, setLastName] = useState('Musk')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleAccountCreationSubmit = async () => {
        const payload = { username, password, role_name: 'system-admin' }
        const { code, message } = await createAccount(payload)
        // if (code !== 200) //TODO: toast
        if (code === 200) {
            localStorage.setItem('simul-token', message)
            alert('SUCCESS')
            window.location = '/'
        }
    }

    return (
        <Row>
            <Col md="6" lg="4">
                <RegistrationForm setUsername={setUsername} setPassword={setPassword} submitHandler={handleAccountCreationSubmit} />
            </Col>
        </Row>

    )
}

export default Admin