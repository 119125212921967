import http from './http'
import { getToken } from './auth'

const base = 'carCategories'

export async function createCarCategory(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getCarCategories() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateCarCategory(id, payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`${base}/${id}`, payload, config)
}

export async function deleteCarCategory(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}