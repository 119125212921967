import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, NavLink } from 'react-router-dom';

const NavigationHotelUser = ({ hotelData, airCrews }) => {
    
    const allowedAirCrewBooking = airCrews && airCrews.some(x => x.allowed_crew_bookings_hotels &&
        x.allowed_crew_bookings_hotels.some(y => y === hotelData.id));
    
    return (
        <Nav className="topbar-nav mr-auto pt-1 pt-md-0">
            {hotelData?.allowed_features?.includes('shuttle booking') &&
                <LinkContainer to='/book-shuttle' className="mr-2">
                    <Nav.Link ><i className="far fa-calendar-alt mr-2"></i> Book A Shuttle</Nav.Link>
                </LinkContainer>
            }
            <LinkContainer to='/book-taxi'>
                <Nav.Link ><i className="fas fa-car-alt mr-2"></i> Book A Taxi</Nav.Link>
            </LinkContainer>
            {hotelData?.allowed_features?.includes('swiss layover') &&
                < LinkContainer to='/swiss-layover' className="mr-2">
                    <Nav.Link ><i className="fas fa-car-alt mr-2"></i> Swiss Layover</Nav.Link>
                </LinkContainer>
            }
            {allowedAirCrewBooking &&
                < LinkContainer to='/aircrew' className="mr-2">
                    <Nav.Link ><i className="fas fa-bus-alt mr-2"></i> Crew</Nav.Link>
                </LinkContainer>
            }
        </Nav >
    );
}

export default NavigationHotelUser;