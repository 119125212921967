
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';
import { getHotelById } from '../../services/hotels';
import { getDestinationsByHotelUser } from '../../services/shuttleDestinations';
import { isHotelAccount } from '../../utils/utils';

const BookShuttleDestinations = ({selectDestination, hotel}) => {

    const [destinations, setDestinations] = useState()

    const _currentUser = getCurrentUser();
    
    useEffect(() => {
        fetchDestinations()
    }, [])

    const fetchDestinations = async () => {
        const { data: { code, message } } = await getDestinationsByHotelUser(_currentUser.hotel_id)

        if(code === 200) {
            //switch the two destinations (hotel origin should go first)
            let _destinations = message.sort(function(a, b) {
                if ( a.origin_type < b.origin_type ){
                    return -1;
                }
                if ( a.origin_type > b.origin_type ){
                    return 1;
                }
                return 0;
            })
           
            //restrict hotel user to book airport to hotel
            _destinations = _destinations.filter(x => !(x.origin === "Zurich Airport" && x.is_common_origin))

            setDestinations(_destinations)
        } else {
            //TODO: toast
        }
    }

    return <>
        
        <Row className="row-eq-height">
            {destinations && destinations.map((destination, index) => (
                <Col key={`destination-${index}`} lg="4" className="mb-4">
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">

                                <Button size="md" style={{fontSize:'1rem'}} variant="outline-primary" className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                    {destination.is_common_origin ? <i className="mr-2 fas fa-plane-departure"></i>  : <i className="mr-2 fas fa-hotel"></i> }
                                    {destination.origin}
                                </Button>

                                <i style={{color:'#FF6D00'}} className="mr-2 fas fa-arrow-circle-right"></i>
                                
                                <Button size="md" style={{fontSize:'1rem'}} variant="outline-primary" className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                    {destination.is_common_origin ? <i className="mr-2 fas fa-hotel"></i>  : <i className="mr-2 fas fa-plane-departure"></i> }
                                    {destination.is_common_origin ? hotel.hotel_name : destination.destination}
                                </Button>

                            </Card.Title>
                            <div className="mb-3">
                                <Button className="btn-rounded w-100" onClick={() => selectDestination(destination)}>Select</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </>
}

export default BookShuttleDestinations
