import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { getHotels } from '../../services/hotels';
import { generateShuttleInvoiceByMonth } from '../../services/invoicesShuttle';
import { toLocaleISOString } from '../../utils/utils';
import ShuttleInvoicePreviewModal from '../modals/shuttleInvoicePreview';
import { getAirlines } from '../../services/airlines';

const GenerateShuttleInvoiceModal = ({ display, toggle }) => {

  const [invoiceDataPreviewModal, setInvoiceDataPreviewModal] = useState(false)

  const [establishmentType, setEstablishmentType] = useState('hotel')
  const [hotels, setHotels] = useState([])
  const [airlines, setAirlines] = useState([])
  const [hotelId, setHotelId] = useState('')
  const [airlineId, setAirlineId] = useState('')
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [monthFilter, setMonthFilter] = useState(("0" + (new Date().getMonth() + 1)).slice(-2))
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear())

  const [dateType, setDateType] = useState('single')
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isLoading, setIsLoading] = useState(false);

  const [invoiceDataPreview, setInvoiceDataPreview] = useState()

  const months = [
    { "value": "", "label": "All" },
    { "value": "01", "label": "January" },
    { "value": "02", "label": "February" },
    { "value": "03", "label": "March" },
    { "value": "04", "label": "April" },
    { "value": "05", "label": "May" },
    { "value": "06", "label": "June" },
    { "value": "07", "label": "July" },
    { "value": "08", "label": "August" },
    { "value": "09", "label": "September" },
    { "value": "10", "label": "October" },
    { "value": "11", "label": "November" },
    { "value": "12", "label": "December" }
  ]
  const years = []
  for (var i = 2019; i <= new Date().getFullYear(); i++)
    years.push(i.toString())

  useEffect(() => {
    fetchHotels()
    fetchAirlines()
  }, [])

  const fetchHotels = async () => {
    const { data: { message } } = await getHotels()
    if (Array.isArray(message))
      setHotels(message)
    else {
      setHotels([])
    }
  }

  const fetchAirlines = async () => {
    const { data: { message } } = await getAirlines()
    if (Array.isArray(message))
      setAirlines(message)
    else {
      setAirlines([])
    }
  }

  const handleGenerateInvoiceByDate = async () => {

    const payload = {
      "establishment_id": establishmentType === "hotel" ? hotelId : airlineId,
      "establishment_type": establishmentType,
    }
    if (dateType === 'single') {
      payload['month'] = monthFilter
      payload['year'] = yearFilter.toString()
    } else {
      payload['start_date'] = toLocaleISOString(new Date(startDate.setHours(0, 0, 0, 0)))
      payload['end_date'] = toLocaleISOString(new Date(endDate.setHours(23, 59, 59, 999)))
    }

    return await generateShuttleInvoiceByMonth(payload)
  }

  const handleInvoiceDataPreview = async () => {

    // if (hotelId == "") {
    //   toast.error(` Please select a hotel to generate invoice.`, {
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   })
    //   return
    // }

    if (hotelId === "" && airlineId === "") {
      toast.error(` Please select a hotel or an airline to generate invoice.`, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }
    setIsLoading(true);
    const { data: { code, message } } = await handleGenerateInvoiceByDate()
    setIsLoading(false);
    if (code === 200) {
      let _invoiceData = message
      _invoiceData['title'] = establishmentType === "hotel" ? getHotelNameById(hotelId) : getAirlineNameById(airlineId)

      setInvoiceDataPreview(_invoiceData)
      setInvoiceDataPreviewModal(true)
    }
    else {
      toast.error(` ${message}.`, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }

  }

  const getHotelNameById = (hotelId) => {
    return hotels.find(x => x._id === hotelId).hotel_name
  }

  const getAirlineNameById = (airlineId) => {
    return airlines.find(x => x._id === airlineId).name
  }

  const toggleDateType = (type) => {
    if (type === 'single')
      setDateRange([null, null])
    else {
      setMonthFilter(("0" + (new Date().getMonth() + 1)).slice(-2))
      setYearFilter(new Date().getFullYear())
    }

    setDateType(type)
  }

  return (
    <>

      {invoiceDataPreview && <ShuttleInvoicePreviewModal display={invoiceDataPreviewModal} toggle={setInvoiceDataPreviewModal} invoice={invoiceDataPreview} />}

      <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
      >
        <Modal.Body>

          <Form.Group>
            <Form.Label>Select Establishment Type</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                name="establishment-type"
                id="est-hotel"
                label="Hotel"
                checked={establishmentType === "hotel"}
                onChange={() => setEstablishmentType("hotel")}
              />
              <Form.Check
                inline
                name="establishment-type"
                type="radio"
                id="est-airline"
                label="Airline"
                checked={establishmentType === "airline"}
                onChange={() => setEstablishmentType("airline")}
              />
            </div>
          </Form.Group>

          {establishmentType === "hotel" &&
            <Form.Group>
              <Form.Label>Select Hotel</Form.Label>
              <Form.Control as="select" value={hotelId} onChange={(e) => setHotelId(e.target.value)}>
                <option value="" disabled>Select Hotel</option>
                {hotels && hotels.map((hotel, index) => (
                  <option key={`hotel-${index}`} value={hotel._id} >
                    {hotel.hotel_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          }

          {establishmentType === "airline" &&
            <Form.Group>
              <Form.Label>Select Airline</Form.Label>
              <Form.Control as="select" value={airlineId} onChange={(e) => setAirlineId(e.target.value)}>
                <option value="" disabled>Select Airline</option>
                {airlines && airlines.map((airline, index) => (
                  <option key={`airline-${index}`} value={airline._id} >
                    {airline.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          }

          <Row className="mb-3">
            <Col>
              <Button variant={dateType === 'single' ? 'primary' : 'outline-primary'} size="sm" className="mr-2" onClick={() => toggleDateType('single')}>By Month</Button>
              <Button variant={dateType === 'dateRange' ? 'primary' : 'outline-primary'} size="sm" onClick={() => toggleDateType('dateRange')}>By Date Range</Button>
            </Col>
          </Row>

          {dateType === 'single' ?
            <>
              <Form.Group>
                <Form.Label>Select Month</Form.Label>
                <Form.Control as="select" defaultValue={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                  {months && months.map((month, index) => (
                    <option key={`month-${index}`} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <>
                {monthFilter &&
                  <Form.Group>
                    <Form.Label>Year</Form.Label>
                    <Form.Control as="select" defaultValue={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                      {years && years.map((year) => (
                        <option key={`year-${year}`} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                }
              </>
            </>
            :
            <Form.Group>
              <Form.Label>Select Date Range</Form.Label>
              <div className="react-datepicker-w100">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  className="form-control"
                  style={{ width: '400px' }}
                  dateFormat="MMM d, yyyy"
                />
              </div>
            </Form.Group>
          }

        </Modal.Body>

        <Modal.Footer>
          {
            isLoading ?
            <Button variant="primary" disabled>Generating, please wait...</Button>:
            <>
              <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
              <Button variant="primary"
                onClick={() => handleInvoiceDataPreview()}>Generate
              </Button>
            </>
          }
        </Modal.Footer>

      </Modal>

    </>
  );
};

export default GenerateShuttleInvoiceModal;