import React from 'react';
import SystemAdminBookingsPage from './admin/bookings';
import HotelAdminBookingsPage from './hotelAdmin/bookings';
import AirlineBookingsPage from './airlineAdmin/bookings';
import { useState } from 'react';
import { getCurrentUser } from '../services/auth';
import { useEffect } from 'react';
import ShuttleAdminBookingsPage from './shuttleCompany/bookings';

const BookingsPage = () => {

    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && user.role_name === 'hotel-admin' &&
            <HotelAdminBookingsPage />
        }

        {
            user && user.role_name === 'hotel-staff' &&
            <HotelAdminBookingsPage />
        }

        {
            user && (user.role_name === 'airline-admin' || user.role_name === 'airline-staff') &&
            <AirlineBookingsPage/>
        }

        {
            user && user.role_name === 'shuttle-admin' &&
            <ShuttleAdminBookingsPage />
        }

        {
            user && user.role_name === 'system-admin' &&
            <SystemAdminBookingsPage />
        }
        
    </>
}

export default BookingsPage