import http from './http'
import { getToken } from './auth'

const base = 'hotels'

export async function createHotel(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getHotels() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getHotelById(hotelId) {
    return await http.get(`${base}/by-id/${hotelId}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function updateHotel(id, payload) {

    return await http.patch(`${base}/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function deleteHotel(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}