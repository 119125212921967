import React, { useState } from 'react'
import { Badge, Card, Table } from 'react-bootstrap'
import { deleteHotel } from '../../services/hotels'
import ConfirmationModal from '../modals/confirmation'
import { toast } from 'react-toastify'
import UpdateHotelModal from '../modals/updateHotelModal'

const HotelList = ({hotels, setHotels}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [updateHotelInfoModal, setUpdateHotelInfoModal] = useState(false)
    const [hotel, setHotel] = useState()

    const getAddressText = (_hotel) => {
        if(!_hotel) return '';
        if(!_hotel.address) return '';
        const a = _hotel.address;
        return `${a.street} ${a.zip} ${a.city} ${a.country}`.trim();
    }

    const handleDeleteHotel = async (id) => {
        const {data: {code, message}} = await deleteHotel(id)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this hotel. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            hotelDeleted()
        }
    }

    const hotelUpdated = (hotel) => {
        const hotelIndex = hotels.findIndex(x => x._id === hotel._id)
        let _hotels = hotels

        if(hotel?.hotel_name) _hotels[hotelIndex].hotel_name = hotel?.hotel_name
        if(hotel.address?.street) _hotels[hotelIndex]['address'].street = hotel?.address?.street
        if(hotel.address?.zip) _hotels[hotelIndex]['address'].zip = hotel?.address?.zip
        if(hotel.address?.city) _hotels[hotelIndex]['address'].city = hotel?.address?.city
        if(hotel.address?.country) _hotels[hotelIndex]['address'].country = hotel?.address?.country
        if(hotel?.number_of_rooms) _hotels[hotelIndex].number_of_rooms = hotel?.number_of_rooms
        if(hotel?.max_number_of_guests) _hotels[hotelIndex].max_number_of_guests = hotel?.max_number_of_guests
        if(hotel?.routes_require_guest_payment) _hotels[hotelIndex].routes_require_guest_payment = hotel?.routes_require_guest_payment
        if(hotel?.allowed_features) _hotels[hotelIndex].allowed_features = hotel?.allowed_features

        if(hotel?.fees?.monthly_service_fee) {
            if(!_hotels[hotelIndex].fees) _hotels[hotelIndex].fees = {}

            _hotels[hotelIndex].fees.monthly_service_fee = hotel?.fees.monthly_service_fee
        }

        setHotels(_hotels)
    }

    const handleUpdateHotelInfoModal = async (hotel) => {
        setHotel(hotel)
        setUpdateHotelInfoModal(true)
    }

    const hotelDeleted = () => {
        const hotelIndex = hotels.indexOf(hotel)
        let _hotels = hotels
        _hotels.splice(hotelIndex, 1)
        setHotels([..._hotels])
    }

    const handleConfirmationModal = (hotel) => {
        setHotel(hotel)
        setConfirmationModal(true)
    }

    return <>

        {hotel && 
        <UpdateHotelModal display={updateHotelInfoModal} toggle={setUpdateHotelInfoModal} hotel={hotel} hotelUpdated={hotelUpdated} />
        }

        <ConfirmationModal 
            title="Delete Hotel"
            message="Are you sure you want to delete this hotel?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteHotel(hotel._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Hotels</Card.Header>
            <Card.Body>
                <Table responsive striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th style={{width:"200px"}}>Name</th>
                            <th style={{width:"200px"}}>Address</th>
                            <th>Flat Fee</th>
                            <th style={{width:"150px"}}>Number of Rooms</th>
                            <th style={{width:"110px"}}>Max Guests</th>
                            <th>Shuttle Routes Require Guest Payment</th>
                            <th>Allowed Features</th>
                            <th style={{width:"65px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hotels.length > 0 &&
                            hotels.map((hotel, index) =>
                                <tr key={`hotel-${index}`}>
                                    <td>{hotel?.hotel_name}</td>
                                    <td>{getAddressText(hotel)}</td>
                                    <td>{hotel?.fees?.monthly_service_fee ?? 0.0}</td>
                                    <td>{hotel?.number_of_rooms}</td>
                                    <td>{hotel?.max_number_of_guests}</td>
                                    <td>{hotel?.routes_require_guest_payment?.map((x, index) => (
                                        <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`${hotel?.id}-${index}`}>{x}</Badge>
                                    ))}</td>
                                     <td>{hotel?.allowed_features?.map((x, index) => (
                                        <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`allowed-feature-${hotel?.id}-${index}`}>{x}</Badge>
                                    ))}</td>
                                    <th className="actions text-center">
                                        <i title="update hotel" className="fas fa-edit mr-2" onClick={() => handleUpdateHotelInfoModal(hotel)}></i>
                                        <i onClick={() => handleConfirmationModal(hotel)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default HotelList