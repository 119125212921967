import { getDay, getWeek } from 'date-fns';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LinkTD from '../../components/common/linkTd';
import { getHotels } from '../../services/hotels';
import { getTaxiDrivers } from '../../services/taxiAccounts';
import { getHotelAccounts } from '../../services/hotelAccounts';
import { getDailyTaxiBookingSales, getFilteredTaxiBookingSales, getMonthlyTaxiBookingSales, getWeeklyTaxiBookingSales } from '../../services/taxiBookingFares';
import PageLoader from '../../components/common/pageLoader';
import { useReactToPrint } from 'react-to-print';

const TaxiSalesPage = () => {

    const [isTableLoading, setIsTableLoading] = useState(false)

    const [monthlyBookingSales, setMonthlyBookingSales] = useState()
    const [weeklyBookingSales, setWeeklyBookingSales] = useState()
    const [dailyBookingSales, setDailyBookingSales] = useState()
    const [filteredBookingSales, setFilteredBookingSales] = useState()

    const [hotels, setHotels] = useState()
    const [taxiDrivers, setTaxiDrivers] = useState()
    const [hotelPersonnels, setHotelPersonnels] = useState()
    const [filteredHotelPersonnels, setFilteredHotelPersonnels] = useState()

    const [hotelIdFilter, setHotelIdFilter] = useState('')
    const [monthFilter, setMonthFilter] = useState(("0" + (new Date().getMonth() + 1)).slice(-2))
    const [yearFilter, setYearFilter] = useState(new Date().getFullYear())
    const [hotelPersonnelIdFilter, setHotelPersonnelIdFilter] = useState('')
    const [taxiDriverIdFilter, setTaxiDriverIdFilter] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const componentRef = useRef();

    let history = useHistory()

    const months = [
        {"value":"01","label":"January"},
        {"value":"02","label":"February"},
        {"value":"03","label":"March"},
        {"value":"04","label":"April"},
        {"value":"05","label":"May"},
        {"value":"06","label":"June"},
        {"value":"07","label":"July"},
        {"value":"08","label":"August"},
        {"value":"09","label":"September"},
        {"value":"10","label":"October"},
        {"value":"11","label":"November"},
        {"value":"12","label":"December"}
    ]
    const years = []
    for(var i = 2019; i<= new Date().getFullYear(); i++) 
        years.push(i.toString())

    useEffect(() => {
        fetchMonthlyBookingSales()
        fetchWeeklyBookingSales()
        fetchDailyBookingSales()
        filterSales()
        fetchHotels()
        fetchHotelAccounts()
        fetchTaxiDrivers()
    }, [])

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()
        
        if(Array.isArray(message)) {
            setHotels(message)  
        }
        else {
            setHotels([])
        }
    }

    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if (code == 200) {
            setTaxiDrivers(message);
        }
    }

    const fetchHotelAccounts = async () => {
        const { data: { code, message } } = await getHotelAccounts()
        if (code == 200) {
            setHotelPersonnels(message);
        }
    }

    const fetchMonthlyBookingSales = async () => {
        const { data: { code, message } } = await getMonthlyTaxiBookingSales()
        if (code == 200) {
            setMonthlyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        }
    }

    const fetchWeeklyBookingSales = async () => {
        const { data: { code, message } } = await getWeeklyTaxiBookingSales()
        if (code == 200) {
            setWeeklyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        } 
    }

    const fetchDailyBookingSales = async () => {
        const { data: { code, message } } = await getDailyTaxiBookingSales()
        if (code == 200) {
            setDailyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        }
    }

    const getCurrentMonth = () => {
        return new Date().toLocaleString('default', { month: 'long' })
    }

    const getDayName = (dayNum) => {
        let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
        return dayNum === 0 ? 'Sunday' : days[dayNum-1]
    }

    const handleChangeHotel = (hotelId) => {
        setHotelIdFilter(hotelId)
        var _hotelPersonnels = hotelPersonnels

        const _filteredHotelPersonnels = _hotelPersonnels.filter(x => x.hotel_id == hotelId)
        setFilteredHotelPersonnels(_filteredHotelPersonnels)
    }

    const filterSales = async () => {
        setIsLoading(true)
        var payload = {
            'month': monthFilter,
            'year': yearFilter,
        }
        if (hotelIdFilter) { payload['hotel_id'] = hotelIdFilter }
        if (hotelPersonnelIdFilter) { payload['hotel_personnel_id'] = hotelPersonnelIdFilter }
        if (taxiDriverIdFilter) { payload['taxi_driver_id'] = taxiDriverIdFilter }

        setIsTableLoading(true)
        const { data: { code, message } } = await getFilteredTaxiBookingSales(payload)

        setIsTableLoading(false)
        if(code==200){
            let sortedItems = message.sort((a, b) => new Date(b.sale_date) - new Date(a.sale_date))
            setFilteredBookingSales(sortedItems.filter(sale => !sale.isCancelled))
        }
        else{
            //TODO: toast
        }
        setIsLoading(false)
    }

    const getTotalSales = () => {
        var total = 0
        filteredBookingSales.forEach((sale) => {
            total += getItemSaleAmount(sale);
        })
        return total.toFixed(2).replace(/[.,]00$/, "")
    }

    const getItemSaleAmount = (sale) => {
        if(sale?.sale_info?.is_fixed_price) 
            return sale?.sale_info?.fixed_amount ? sale?.sale_info?.fixed_amount : 0;
        else
            return sale?.sale_info?.latest_amount ? sale?.sale_info?.latest_amount : 0;
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getHotelName = () => {
        if(hotelIdFilter)
            return hotels.find(x => x._id === hotelIdFilter).hotel_name
        else
            return 'All'
    }

    const getHotelPeronnelName = () => {
        if (hotelPersonnelIdFilter) {
            var hotelpersonnel = filteredHotelPersonnels.find(x => x._id === hotelPersonnelIdFilter)
            return hotelpersonnel.first_name + ' ' + hotelpersonnel.last_name
        } else {
            return 'All'
        }
    }

    const getMonthName = () => {
        return months.find(x => x.value === monthFilter).label
    }

    const getTaxiDriverName = () => {
        if (taxiDriverIdFilter) {
            var driver = taxiDrivers.find(x => x._id === taxiDriverIdFilter)
            return driver.first_name + ' ' + driver.last_name
        } else {
            return 'All'
        }
    }

    return (
        <section>
            <Col>
                <Row className="mb-4">
                    <Col className="text-left">
                        <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/sales')}>{'<'} Back</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Taxi Sales</h1>
                        <hr className="mt-2 mb-4" />
                    </Col>
                </Row>
                <Row className="mb-4 row-eq-height">
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>THIS MONTH</strong></small></p>
                                <h3>{getCurrentMonth()}</h3>
                                <hr />
                                <h1 className="color-primary">{monthlyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>THIS WEEK</strong></small></p>
                                <h3>Week {getWeek(new Date())}</h3>
                                <hr />
                                <h1 className="color-primary">{weeklyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>TODAY</strong></small></p>
                                <h3>{getDayName(getDay(new Date()))}</h3>
                                <hr />
                                <h1 className="color-primary">{dailyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <Card className='w-100 filter-panel'>
                            <Card.Body>
                                {isLoading ?
                                <PageLoader position="relative" backgroundColor="#f2ecec" />
                                :
                                <>
                                    <Row className="mt-4">
                                        <Col lg="9">
                                            <Row>
                                                <Col lg="3">
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Control as="select" value={hotelIdFilter} onChange={(e) => handleChangeHotel(e.target.value)}>
                                                                    <option value=''>All Hotels</option>
                                                                    {hotels && hotels.map((hotel, index) => (
                                                                        <option key={`hotel-${index}`} value={hotel._id} >
                                                                            {hotel.hotel_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {hotelIdFilter && 
                                                    <Row>
                                                        <Col>
                                                            {filteredHotelPersonnels.length > 0 ?
                                                            <Form.Group>
                                                                <Form.Control as="select" defaultValue={hotelPersonnelIdFilter} onChange={(e) => setHotelPersonnelIdFilter(e.target.value)}>
                                                                    <option value=''>All Hotel Personnels</option>
                                                                    {filteredHotelPersonnels && filteredHotelPersonnels.map((personnel, index) => (
                                                                        <option key={`hotelpersonnel-${index}`} value={personnel._id}>
                                                                            {personnel.first_name || personnel.last_name ? `${personnel.first_name} ${personnel.last_name}` : personnel.username}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            :
                                                            <p className="mt-n2"><small>No hotel personnels to select</small></p>
                                                            }
                                                            
                                                        </Col>
                                                    </Row>
                                                    }
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" defaultValue={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                                                            {months && months.map((month, index) => (
                                                                <option key={`month-${index}`} value={month.value}>
                                                                    {month.label}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" defaultValue={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                                                            {years && years.map((year) => (
                                                                <option key={`year-${year}`} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" onChange={(e) => setTaxiDriverIdFilter(e.target.value)} value={taxiDriverIdFilter}>
                                                            <option value="">All Taxi Drivers</option>
                                                            {taxiDrivers && taxiDrivers.map((driver, index) => (
                                                                <option key={`driver-${index}`} value={driver._id}>{`${driver.first_name} ${driver.last_name}`}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="3">
                                            <Button variant="primary px-5" onClick={() => filterSales()}>Filter</Button>
                                        </Col>
                                    </Row>

                                    <div className="mx-n4">
                                        <div className="to-print px-4" ref={componentRef}>
                                        <div className="hide-on-screen mb-4">
                                            <p className="mb-0">
                                                Hotel: <strong>{hotels && getHotelName()}</strong> &emsp;
                                                {hotelIdFilter && <span>Hotel Personnel: <strong>{filteredHotelPersonnels && getHotelPeronnelName()}</strong> &emsp;</span>}
                                                Month: <strong>{getMonthName(monthFilter)}</strong> &emsp;
                                                Year: <strong>{yearFilter}</strong> &emsp;
                                                Driver: <strong>{taxiDrivers && getTaxiDriverName(taxiDriverIdFilter)}</strong>
                                            </p>
                                        </div>

                                        {!isTableLoading ?
                                        <>
                                        <Table responsive hover>
                                            <thead className="border-top-0">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Sale Date</th>
                                                    <th>Guest</th>
                                                    <th>Seats</th>
                                                    <th>Paid By Guest</th>
                                                    <th>Commission</th>
                                                    <th>Driver Income</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {filteredBookingSales && filteredBookingSales.length > 0 ?
                                                <>
                                                    {filteredBookingSales && filteredBookingSales.map((sale, index) => (
                                                    <tr key={`bookingsale-${index}`}>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{index+1}</LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{Moment(sale?.sale_info?.completed_time ).format('DD.MM.YYYY @ HH:mm')}</LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{sale?.booking_info?.customer.first_name} {sale?.booking_info?.customer.last_name}</LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{sale?.booking_info?.number_of_persons}</LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>
                                                            {getItemSaleAmount(sale).toFixed(2).replace(/[.,]00$/, "")} CHF 
                                                            {sale?.sale_info?.paid_over_counter && <i className="fas fa-hotel color-primary ml-2" title="Paid in the hotel"></i>}
                                                        </LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{sale?.sale_info.bookingsource_cashback.toFixed(2)} CHF</LinkTD>
                                                        <LinkTD to={`/taxi-booking/${sale?.booking_info?._id}`}>{(getItemSaleAmount(sale) - sale?.sale_info.bookingsource_cashback).toFixed(2)} CHF</LinkTD>
                                                    </tr>
                                                    ))}
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><strong>{filteredBookingSales && `${getTotalSales()}`} CHF</strong></td>
                                                        <td><strong>{filteredBookingSales.reduce((n, s) => n + s.sale_info.bookingsource_cashback, 0).toFixed(2)} CHF</strong></td>
                                                        <td><strong>{filteredBookingSales.reduce((n, s) => n + (getItemSaleAmount(s) - s?.sale_info.bookingsource_cashback), 0).toFixed(2)} CHF</strong></td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td colSpan="7" className="p-4 text-center">No records</td>
                                                    </tr>
                                                </>
                                                }
                                                
                                            </tbody>
                                        </Table>
                                        </>
                                        :
                                        <PageLoader position="relative" backgroundColor="#f2ecec" />
                                        }
                                        </div>
                                    </div>

                                    <Row>
                                        <Col>
                                            <div className="pt-2 text-right">
                                                <Button size="sm" variant="outline-secondary" onClick={handlePrint}><i className="fas fa-print"></i> Print Table Data</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                }
                            </Card.Body>
                        </Card>          
                    </Col>
                </Row>
            </Col>
        </section>
    );
};

export default TaxiSalesPage;