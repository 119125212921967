
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LatestBookings from '../../components/common/latestBookings';
import { getWeek } from 'date-fns';
import { getMonthlyBookings, getWeeklyBookings, getDailyBookings } from '../../services/shuttleBookings'
import LatestTaxiBookings from '../../components/common/latestBookingsTaxi';

const AirlineAdminDashboardPage = () => {

    const [monthlyBookings, setMonthlyBookings] = useState("")
    const [weeklyBookings, setWeeklyBookings] = useState("")
    const [dailyBookings, setDailyBookings] = useState("")

    const [monthlyBookingsLoaded, setMonthlyBookingsLoaded] = useState(false);
    const [weeklyBookingsLoaded, setWeeklyBookingsLoaded] = useState(false);
    const [dailyBookingsLoaded, setDailyBookingsLoaded] = useState(false);
  
    useEffect(() => {
        fetchMonthlyBookings()
        fetchWeeklyBookings()
        fetchDailyBookings()
    }, [])

    const fetchMonthlyBookings = async () => {
        const { data: { code, message } } = await getMonthlyBookings()
        if (code == 200) {
            setMonthlyBookings(message);
            setMonthlyBookingsLoaded(true);
        }
    }

    const fetchWeeklyBookings = async () => {
        const { data: { code, message } } = await getWeeklyBookings()
        if (code == 200) {
            setWeeklyBookings(message);
            setWeeklyBookingsLoaded(true);
        } 
    }

    const fetchDailyBookings = async () => {
        const { data: { code, message } } = await getDailyBookings()
        if (code == 200) {
            setDailyBookings(message);
            setDailyBookingsLoaded(true);
        }
    }

    const getCurrentMonth = () => {
        return new Date().toLocaleString('default', { month: 'long' })
    }

    return <>
        <section>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title className="mb-3">Total Bookings </Card.Title>
                            <Row>
                                <Col md="6" lg="4">
                                {
                                    monthlyBookingsLoaded &&
                                    <div className="total-sales-content text-center">
                                        <h1 className="color-primary"><strong>{monthlyBookings}</strong></h1>
                                        <h5>{getCurrentMonth()}</h5>
                                    </div> 
                                }
                                </Col>
                                <Col md="6" lg="4">
                                {
                                    weeklyBookingsLoaded &&
                                    <div className="total-sales-content text-center">
                                        <h1 className="color-primary"><strong>{weeklyBookings}</strong></h1>
                                        <h5>Week {getWeek(new Date())}</h5>
                                    </div>
                                }
                                </Col>
                                <Col md="6" lg="4">
                                {
                                    dailyBookingsLoaded &&
                                    <div className="total-sales-content text-center">
                                        <h1 className="color-primary"><strong>{dailyBookings}</strong></h1>
                                        <h5>Today</h5>
                                    </div>   
                                }
                                </Col>
                            </Row>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <LatestBookings />
                </Col>
            </Row>
            {/* <Row className="mb-5">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-3">Bookings Per Day </Card.Title>
                            <div style={{height:'200px'}} className="center-xy">
                                <div>
                                <p><i>Chart Here</i></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}
        </section>
    </>
}

export default AirlineAdminDashboardPage
