import React from 'react'

const StripeResponsePage = () => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id')
    
    return (
        <div className='text-center p-3' >
            <h2 className='m-2'>{sessionId ? 'Payment is successful. Thank you!' : 'Your payment is cancelled.'}</h2>
        </div>
    )
}

export default StripeResponsePage