import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { createAirlineCrewRoutePlaces, updateAirlineCrewRoutePlace } from '../../../services/airlineCrewRoutePlaces';
import { getHotels } from '../../../services/hotels';

const initRoutePlace = {
    name: '',
    type: 'none',
    hotelId: ''
}

const AirlineCrewRoutePlaceSetModal = ({ display, toggle, selectedRoutePlace, routePlaces, setRoutePlaces }) => {

    const [isSaving, setIsSaving] = useState(false)

    const [routePlace, setRoutePlace] = useState(initRoutePlace)
    const [hotels, setHotels] = useState([])

    useEffect(() => {
        fetchHotels()
    }, [])

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()
        if (Array.isArray(message))
            setHotels(message)
        else {
            setHotels([])
        }
    }

    const handleChange = (objVal) => {
        setRoutePlace({ ...routePlace, ...objVal })
    }

    const handleChangeType = (value) => {

        let _routePlace = { ...routePlace }
        //set to empty only if hotel
        if(value && value.toLowerCase() === 'hotel') _routePlace.name = ''
        _routePlace.hotelId = ''
        _routePlace.type = value

        setRoutePlace(_routePlace)
    }

    const handleAddRoutePlace = async () => {

        setIsSaving(true)
        let payload = {
            name: routePlace.type === 'hotel' ? hotels.find(x => x._id === routePlace.hotelId).hotel_name : routePlace.name,
            type: routePlace.type
        }

        if (routePlace.type === 'hotel')
            payload.hotelId = routePlace.hotelId

        const { data: { code, message } } = await createAirlineCrewRoutePlaces(payload)
        setIsSaving(false)

        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _routePlaces = routePlaces
            _routePlaces.push(message)
            setRoutePlaces([..._routePlaces])
            setRoutePlace(initRoutePlace)
            toggle(false)
        }
    }

    const handleEditRoutePlace = async () => {
        setIsSaving(true)

        let payload = {
            _id: routePlace.id,
            name: routePlace.type === 'hotel' ? hotels.find(x => x._id === routePlace.hotelId).hotel_name : routePlace.name,
            type: routePlace.type
        }

        if (routePlace.type === 'hotel')
            payload.hotelId = routePlace.hotelId

        const { data: { code, message } } = await updateAirlineCrewRoutePlace(payload)
        setIsSaving(false)

        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _routePlaces = routePlaces
            let selectedRoutePlaceIndex = _routePlaces.indexOf(selectedRoutePlace)
            _routePlaces[selectedRoutePlaceIndex] = { ...routePlace, ...payload }

            setRoutePlaces([..._routePlaces])
            toggle(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        selectedRoutePlace?.id ? handleEditRoutePlace() : handleAddRoutePlace()
    }

    const onShow = () => {
        setRoutePlace(selectedRoutePlace ?? initRoutePlace)
    }

    const onHide = () => {
        setRoutePlace(initRoutePlace)
        toggle(false)
    }

    return (
        <Modal
            show={display}
            onShow={onShow}
            onHide={onHide}
            centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">{selectedRoutePlace?.id ? 'Edit' : 'Add'} Route Place</Card.Title>
                <Form onSubmit={handleSubmit} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="6">
                            {routePlace?.type === 'hotel' ?
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control as="select" value={routePlace?.hotelId ?? ''} onChange={(e) => handleChange({ hotelId: e.target.value })}>
                                        <option value="" disabled>Select Hotel</option>
                                        {hotels && hotels.map((hotel, index) => (
                                            <option key={`hotel-${index}`} value={hotel._id} >
                                                {hotel.hotel_name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                :
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type='text' value={routePlace?.name ?? ''} onChange={e => handleChange({ name: e.target.value })} required />
                                </Form.Group>
                            }
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select" value={routePlace?.type} onChange={e => handleChangeType(e.target.value)} >
                                    <option value="none">None</option>
                                    <option value="hotel">Hotel</option>
                                    <option value="airport">Airport</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="12" className="text-right">
                            <Button type="submit" disabled={isSaving} className="mt-2">
                                {isSaving ? 'Saving...' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AirlineCrewRoutePlaceSetModal;