import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getHotelStaffs, deleteHotelStaff, createHotelStaff } from '../../services/accounts'
import { getCurrentUser } from '../../services/auth'
import HotelStaffCreator from './hotelStaffCreator'
import HotelStaffList from './hotelStaffList'

const HotelStaffCreation = () => {

    const _currentUser = getCurrentUser()

    const [hotelStaffs, setHotelStaffs] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { message } } = await getHotelStaffs(_currentUser.hotel_id)
        if(Array.isArray(message))
            setHotelStaffs(message)
        else {
            setHotelStaffs([])
            //TODO: toast when getting hotel admins error
        }
    }

    const handleCreateHotelStaff = async (payload, password) => {

        const {data :{code,message}} = await createHotelStaff(payload)
        
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newHotelStaff = {
                '_id': message['hotel-account-info']._id,
                'username': message['user-info'].username,
                'password': password,
                first_name: message['hotel-account-info'].first_name,
                last_name: message['hotel-account-info'].last_name
            }

            let _hotelStaffs = hotelStaffs
            _hotelStaffs.push(_newHotelStaff)
            setHotelStaffs([..._hotelStaffs])
        }
    }

    const handleDeleteHotelStaff = async (id, index) => {

        const { data: { code, message } } = await deleteHotelStaff(id)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _hotelStaffs = hotelStaffs
            _hotelStaffs.splice(index, 1)
            setHotelStaffs([..._hotelStaffs])
        }
    }

    return (
        <Row className="mb-4">
            <Col lg="5" >
                <HotelStaffCreator createHotelStaff={handleCreateHotelStaff} />
            </Col>
            <Col>
                <HotelStaffList hotelStaffs={hotelStaffs} setHotelStaffs={setHotelStaffs} deleteHotelStaff={handleDeleteHotelStaff} />
            </Col>
        </Row>
    )
}

export default HotelStaffCreation