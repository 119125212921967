import React, { useRef, useState } from 'react'
import { Badge, Card, Table } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import { toast } from 'react-toastify'
import { deleteAirlineCrew } from '../../../services/airlineCrews'
import UpdateAirlineCrewModal from '../../modals/airline-crew/updateAirlineCrewModal'
import ConfirmationModal from '../../modals/confirmation'

const AirlineCrewList = ({ airlines, hotels, airCrews, setAirCrews }) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [airCrew, setAirCrew] = useState()
    const [allowedAirlines, setAllowedAirlines] = useState([])
    const [allowedHotels, setAllowedHotels] = useState([])

    const handleDelete = async (id) => {
        const { data: { code, message } } = await deleteAirlineCrew(id)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this air crew. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else {
            deleted()
        }
    }

    const updated = (airCrew) => {
        const index = airCrews.findIndex(x => x._id === airCrew._id)
        let _airCrews = airCrews

        if (airCrew?.airline_name) _airCrews[index].airline_name = airCrew?.airline_name
        if (airCrew?.allowed_crew_bookings_airlines) _airCrews[index].allowed_crew_bookings_airlines_temp = airCrew.allowed_crew_bookings_airlines?.map(x => airlines.find(y => y.value === x).label)
        if (airCrew?.allowed_crew_bookings_hotels) _airCrews[index].allowed_crew_bookings_hotels_temp = airCrew.allowed_crew_bookings_hotels?.map(x => hotels.find(y => y.value === x).label)

        setAirCrews(_airCrews)
    }

    const handleUpdateModal = async (airCrew) => {
        setAllowedAirlines(airCrew.allowed_crew_bookings_airlines.map(x => { return { label: airlines.find(y => y.value === x).label, value: x } }))
        setAllowedHotels(airCrew.allowed_crew_bookings_hotels.map(x => { return { label: hotels.find(y => y.value === x).label, value: x } }))
        setAirCrew(airCrew)
        setUpdateModal(true)
    }

    const deleted = () => {
        const index = airCrews.indexOf(airCrew)
        let _airCrews = airCrews
        _airCrews.splice(index, 1)
        setAirCrews([..._airCrews])
    }

    const handleConfirmationModal = (airCrew) => {
        setAirCrew(airCrew)
        setConfirmationModal(true)
    }

    const imgPreview = {
        'maxWidth': '100%',
        'maxHeight': '200px'
    }

    const qrCodePreview = {
        'maxWidth': '100%',
        'maxHeight': '120px'
    }

    return <>

        <UpdateAirlineCrewModal display={updateModal} toggle={setUpdateModal}
            airlines={airlines} hotels={hotels}
            _allowedAirlines={allowedAirlines} _allowedHotels={allowedHotels}
            airCrew={airCrew} airCrewUpdated={updated} />

        <ConfirmationModal
            title="Delete Air Crew"
            message="Are you sure you want to delete this air crew?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDelete(airCrew._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Airline Crews</Card.Header>
            <Card.Body>
                <Table responsive striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Allowed Airlines</th>
                            <th>Allowed Hotels</th>
                            {/* <th>QR Code</th> */}
                            <th style={{ width: "65px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            airCrews.length > 0 &&
                            airCrews.map((airCrew, index) =>
                                <tr key={`airCrew-${index}`}>
                                    <td className="text-center" style={{ verticalAlign: 'middle' }}>{airCrew?.airline_name}<br />
                                        {
                                            airCrew.airline_logo &&
                                            <img src={airCrew.airline_logo} style={imgPreview} alt="Image preview..." />
                                        }
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{airCrew?.allowed_crew_bookings_airlines_temp?.map((x, index) => (
                                        <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`allowed-airline-${airCrew?.id}-${index}`}>{x}</Badge>
                                    ))}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{airCrew?.allowed_crew_bookings_hotels_temp?.map((x, index) => (
                                        <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`allowed-hotel-${airCrew?.id}-${index}`}>{x}</Badge>
                                    ))}</td>
                                    {/* <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                        {
                                            airCrew.public_booking_qrcode &&
                                            <img src={airCrew.public_booking_qrcode} style={qrCodePreview} alt="Image preview..." />
                                        }
                                    </td> */}
                                    <th className="actions text-center" style={{ verticalAlign: 'middle', width: '100px' }}>
                                        {/* {airCrew.public_booking_qrcode &&
                                            <i title="print qr" className="far fa-file-pdf mr-2" onClick={() => handlePrint(airCrew.public_booking_qrcode)}></i>
                                        } */}
                                        <i title="update air crew" className="fas fa-edit mr-2" onClick={() => handleUpdateModal(airCrew)}></i>
                                        <i onClick={() => handleConfirmationModal(airCrew)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default AirlineCrewList