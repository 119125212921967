import React from "react";
import { Button, Card } from "react-bootstrap";
import { submitError } from '../services/globalErrors'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch = async function(error, errorInfo) {
      // log the error to an error reporting service
      const payload = {
        message: error.message,
        stack: error.stack,
        error_info: errorInfo
      }

      const { data: { code, message } } = await submitError(payload)
    }
  
    render() {
      if (this.state.hasError) {
        // custom fallback UI
        return (
        <Card>
            <Card.Body>
                <h1>Something went wrong!</h1>
                <Button onClick={() => this.setState({ hasError: false })}>Try again</Button>
            </Card.Body>
        </Card>
      )}
  
      return this.props.children; 
    }
  }
  
  export default ErrorBoundary