import React from 'react';
import { Col, Row } from 'react-bootstrap';
import UnconfirmedBookingsList from '../../components/common/bookingsListUnconfirmed';

const UnconfirmedShuttleBookingsPage = () => {
    return <>
        <section>
            <Row>
                <Col>
                    <UnconfirmedBookingsList />
                </Col>
            </Row>
        </section>
    </>
}

export default UnconfirmedShuttleBookingsPage