import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { getAllHotelsPreviousEarnings, getPreviousEarnings } from '../../services/reserverCommissions';
import LinkTD from '../common/linkTd';
import PageLoader from '../common/pageLoader';

const ReserverPayableCommissionList = () => {

    const [earnings, setEarnings] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getAllHotelsPreviousEarnings()
        setIsLoading(false)
        if (code == 200) {
            let sortedItems = message.sort((a, b) => a.hotel_info.hotel_name - b.hotel_info.hotel_name)
            setEarnings(sortedItems)
        }
    }

    return (
        <>  
            {isLoading && <PageLoader />}
            <Table striped hover variant='light'>
                <thead>
                    <tr>
                        <th>Hotel Personnel</th>
                        <th>Hotel Name</th>
                        <th>Month/Year</th>
                        <th>Shuttle Bookings Amount</th>
                        <th>Taxi Bookings Amount</th>
                        <th>Total Amount</th>
                        <th>Is Paid</th>
                        <th>Payment Date</th>
                        <th>Paid Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        earnings.length > 0 &&
                        earnings.map((earning, index) =>
                            <tr key={`earning-${index}`} >
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.user_info.first_name} {earning.user_info.last_name}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.hotel_info.hotel_name}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{Moment.utc(earning.month_year).format('MM/YYYY')}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.shuttle_bookings_amount.toFixed(2)}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.taxi_bookings_amount.toFixed(2)}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.total_amount.toFixed(2)}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.is_paid ? <i style={{color:"Tomato"}} className="fas fa-money-bill-alt" title="Paid"></i> : <i style={{color:"LightGray"}} className="fas fa-money-bill-alt" title="Not Paid"></i>}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.payment_date ? Moment.utc(earning.payment_date).format('DD.MM.YYYY') : 'N/A'}</LinkTD>
                                <LinkTD to={`/reserver-payable-commissions/${earning._id}`}>{earning.paid_amount.toFixed(2)}</LinkTD>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
};

export default ReserverPayableCommissionList;