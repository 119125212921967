import React, { useEffect, useState } from 'react'
import '../sidebar/sidebar.css'
import { getCurrentUser } from './../../services/auth'
import NavigationAdmin from './adminNavigation'
import NavigationHotelUser from './hotelUserNavigation'
import NavigationAirlineUser from './airlineUserNavigation'

const Navigation = ({ hotelData, airlineData, airCrews }) => {
    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return (
        <>
            {
                user &&
                (user.role_name === 'system-admin') &&
                <NavigationAdmin />
            }

            {
                user &&
                (user.role_name === 'hotel-admin' || user.role_name === 'hotel-staff') &&
                <NavigationHotelUser hotelData={hotelData} airCrews={airCrews}/>
            }

            {
                user &&
                (user.role_name === 'airline-admin' || user.role_name === 'airline-staff') &&
                <NavigationAirlineUser airlineData={airlineData} airCrews={airCrews}/>
            }
        </>
    )
}

export default Navigation