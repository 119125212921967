import React from 'react';
import TimetableCalender from '../../components/common/timetableCalendar';

const HotelAdminTimetablePage = () => {

    return <>
        <TimetableCalender />
    </>
}

export default HotelAdminTimetablePage