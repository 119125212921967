import http from './http'
import { getToken } from './auth'

const base = 'airlineCrewSeatOptions'

export async function createSeatOption(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getSeatOptions() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getSeatOptionById(id) {
    return await http.get(`${base}/by-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function updateSeatOption(id, payload) {

    return await http.patch(`${base}/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function deleteSeatOption(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}