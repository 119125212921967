import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/auth';
import HotelAdminTimetablePage from './hotelAdmin/timetable';
import SystemAdminTimetablePage from './admin/timetable';
import HotelStaffTimetablePage from './hotelStaff/timetable';

const TimetablePage = () => {
    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && user.role_name === 'hotel-admin' &&
            <HotelAdminTimetablePage />
        }

        {
            user && user.role_name === 'hotel-staff' &&
            <HotelStaffTimetablePage />
        }

        {
            user && (user.role_name === 'system-admin' || user.role_name === 'shuttle-admin') &&
            <SystemAdminTimetablePage />
        }
    </>
};

export default TimetablePage;