import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { getCurrentUser } from './../../services/auth'
import { getHotelById } from '../../services/hotels';
import AirlineCrewPage from '../../components/admin/airlineCrew/airlineCrewPage'
import Accounts from '../../pages/accounts'
import AirCrewInvoicesPage from '../../pages/admin/airlineCrewInvoices'
import AdminBookTaxiPage from '../../pages/admin/bookTaxi'
import AdminBookTaxiSelectDestinationPage from '../../pages/admin/bookTaxiV2/selectDestination'
import CancelledBookingsPage from '../../pages/admin/bookingsCancelled'
import CancelledTaxiBookingsPage from '../../pages/admin/bookingsTaxiCancelled'
import ContactsPage from '../../pages/admin/contacts'
import ReleasesPage from '../../pages/admin/releases'
import ShuttleSalesPage from '../../pages/admin/salesShuttle'
import TaxiSalesPage from '../../pages/admin/salesTaxi'
import SupportTicketPage from '../../pages/admin/supportTicket'
import SupportTicketsPage from '../../pages/admin/supportTickets'
import SystemIssuesPage from '../../pages/admin/systemIssues'
import DriversTrackingMap from '../../pages/admin/tracking/driversTrackingMap'
import ZonesPage from '../../pages/admin/zones'
import AirlineSettings from '../../pages/airlineAdmin/airlineSettings'
import AirlineCrewBookingPage from '../../pages/airlineCrew/airlineCrewBooking'
import BookAirlineCrewPage from '../../pages/airlineCrew/bookAirlineCrew'
import AirlinesPage from '../../pages/airlines'
import BookingPage from '../../pages/booking'
import BookingTaxiPage from '../../pages/bookingTaxi'
import BookingsPage from '../../pages/bookings'
import BookingsTaxiPage from '../../pages/bookingsTaxi'
import UnassignedTaxiBookingsPage from '../../pages/bookingsTaxiUnassigned'
import CarCategoriesPage from '../../pages/carCategories'
import CommissionsPage from '../../pages/commissions'
import TaxiCompaniesPage from '../../pages/companiesTaxi'
import ContactPage from '../../pages/contact'
import DashboardPage from '../../pages/dashboard'
import Destinations from '../../pages/destinations'
import TaxiDestinations from '../../pages/destinationsTaxi'
import UnconfirmedShuttleBookingsPage from '../../pages/hotelAdmin/bookingsUnconfirmed'
import HotelSettings from '../../pages/hotelAdmin/hotelSettings'
import BookShuttlePage from '../../pages/hotelUser/bookShuttle'
import BookShuttleLayoverPage from '../../pages/hotelUser/bookShuttleLayover'
import BookTaxiPage from '../../pages/hotelUser/bookTaxi'
import HotelsPage from '../../pages/hotels'
import ShuttleInvoicePage from '../../pages/invoiceShuttle'
import TaxiInvoicePage from '../../pages/invoiceTaxi'
import EstablishmentTaxiInvoicesPage from '../../pages/invoicesEstablishmentTaxi'
import ShuttleInvoicesPage from '../../pages/invoicesShuttle'
import TaxiDriverInvoicesPage from '../../pages/invoicesTaxiDriver'
import NotfoundPage from '../../pages/notfound'
import ProfilePage from '../../pages/profile'
import SalesPage from '../../pages/sales'
import TimetablePage from '../../pages/timetable'
import VehiclesPage from '../../pages/vehicles'
import AirlineAdminCreation from '../admin/airlineAdminCreation'
import HotelAdminCreation from '../admin/hotelAdminCreation'
import ReserverPayableCommission from '../admin/reserverPayableCommission'
import ShuttleAdminCreation from '../admin/shuttleAdminCreation'
import TaxiDriverCreation from '../admin/taxiDriverCreation'
import ErrorBoundary from '../errorBoundary'
import NavigationBar from '../navigation/navigationbar'
import ProtectedRoute from '../route/protected'
import ShuttleDriverCreation from '../shuttleCompany/shuttleDriverCreation'
import Sidebar from '../sidebar/sidebar'
import './leftSidebar.css'
import AirCrewInvoicePage from '../admin/airlineCrew/airCrewInvoice'
import AirlineCrewBookingsPage from '../../pages/admin/airlineCrew/airlineCrewBookings'
import ImportAirCrewBooking from '../../pages/admin/airlineCrew/importAirCrewBooking';

const LeftSidebarTemplate = () => {

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [hotelData, setHotelData] = useState({})
    const [airlineData, setAirlineData] = useState({})

    const location = useLocation()

    return (
        <>
            <Container fluid={true} className="dashboard-template">
                <Row>
                    <Sidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} hotelData={hotelData} setHotelData={setHotelData} setAirlineData={setAirlineData} />
                    <main className="col-md-9 ml-sm-auto col-lg-10 px-0">
                        <NavigationBar container="fluid" setDrawerOpen={setDrawerOpen} hotelData={hotelData} airlineData={airlineData} />
                        <div className="content pt-4 px-4">
                            <ErrorBoundary key={location.pathname}>
                                <Switch>
                                    <ProtectedRoute
                                        path="/dashboard"
                                        render={props => <DashboardPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/bookings"
                                        render={props => <BookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/unconfirmed-bookings"
                                        render={props => <UnconfirmedShuttleBookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/cancelled-bookings"
                                        render={props => <CancelledBookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-bookings"
                                        restrictShuttleAccounts={true}
                                        render={props => <BookingsTaxiPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/cancelled-taxi-bookings"
                                        restrictShuttleAccounts={true}
                                        render={props => <CancelledTaxiBookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/unassigned-taxi-bookings"
                                        restrictShuttleAccounts={true}
                                        render={props => <UnassignedTaxiBookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/booking/:_id"
                                        render={props => <BookingPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-booking/:_id"
                                        restrictShuttleAccounts={true}
                                        render={props => <BookingTaxiPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/timetable"
                                        render={props => <TimetablePage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/book-taxi"
                                        restrictShuttleAccounts={true}
                                        render={props => <BookTaxiPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/admin-book-taxi"
                                        systemAdminOnly={true}
                                        exact
                                        render={props => <AdminBookTaxiPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/admin-book-taxi/select-destination"
                                        systemAdminOnly={true}
                                        render={props => <AdminBookTaxiSelectDestinationPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/book-shuttle"
                                        hotelData={hotelData}
                                        airlineData={airlineData}
                                        render={props => <BookShuttlePage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/aircrew"
                                        render={props => <BookAirlineCrewPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/aircrew-booking/:_id"
                                        render={props => <AirlineCrewBookingPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/swiss-layover"
                                        hotelData={hotelData}
                                        airlineData={airlineData}
                                        render={props => <BookShuttleLayoverPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/hotel-settings"
                                        hotelAdminOnly={true}
                                        render={props => <HotelSettings {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/accounts"
                                        render={props => <Accounts {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/accounts/hotel-admin"
                                        systemAdminOnly={true}
                                        render={props => <HotelAdminCreation {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/accounts/airline-admin"
                                        systemAdminOnly={true}
                                        render={props => <AirlineAdminCreation {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/accounts/shuttle-admin"
                                        systemAdminOnly={true}
                                        render={props => <ShuttleAdminCreation {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/accounts/shuttle-driver"
                                        simulAccountsOnly={true}
                                        render={props => <ShuttleDriverCreation {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/accounts/taxi-driver"
                                        systemAdminOnly={true}
                                        render={props => <TaxiDriverCreation {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/profile"
                                        render={props => <ProfilePage {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/commissions"
                                        render={props => <CommissionsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/reserver-payable-commissions/:_id"
                                        render={props => <ReserverPayableCommission {...props} />}
                                    />
                                    {/* <ProtectedRoute
                                        path="/hotel-staff-commissions/shuttle"
                                        render={props => <HotelUserShuttleCommissionsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/hotel-staff-commissions/taxi"
                                        render={props => <HotelUserTaxiCommissionsPage {...props} />}
                                    /> */}
                                    <ProtectedRoute
                                        path="/vehicles"
                                        simulAccountsOnly={true}
                                        render={props => <VehiclesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/car-categories"
                                        simulAccountsOnly={true}
                                        render={props => <CarCategoriesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/hotels"
                                        simulAccountsOnly={true}
                                        render={props => <HotelsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/airlines"
                                        simulAccountsOnly={true}
                                        render={props => <AirlinesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/airline-settings"
                                        airlineAdminOnly={true}
                                        render={props => <AirlineSettings {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/airline-crew-settings"
                                        simulAccountsOnly={true}
                                        render={props => <AirlineCrewPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/airline-crew-bookings"
                                        simulAccountsOnly={true}
                                        render={props => <AirlineCrewBookingsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/airline-crew-import"
                                        simulAccountsOnly={true}
                                        render={props => <ImportAirCrewBooking {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/destinations"
                                        simulAccountsOnly={true}
                                        render={props => <Destinations {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-destinations"
                                        simulAccountsOnly={true}
                                        render={props => <TaxiDestinations {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/location-tracking"
                                        systemAdminOnly={true}
                                        render={props => <DriversTrackingMap {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/sales"
                                        simulAccountsOnly={true}
                                        render={props => <SalesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/sales/shuttle"
                                        simulAccountsOnly={true}
                                        render={props => <ShuttleSalesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/sales/taxi"
                                        simulAccountsOnly={true}
                                        render={props => <TaxiSalesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/airline-crew-invoices"
                                        systemAdminOnly={true}
                                        render={props => <AirCrewInvoicesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/airline-crew-invoices/:_id"
                                        systemAdminOnly={true}
                                        render={props => <AirCrewInvoicePage {...props} />}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/shuttle-invoices"
                                        allowedUsers={['system-admin', 'shuttle-admin', 'hotel-admin', 'airline-admin']}
                                        render={props => <ShuttleInvoicesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/shuttle-invoices/:_id"
                                        simulAccountAndHotelAdminOnly={true}
                                        render={props => <ShuttleInvoicePage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-invoices/driver"
                                        simulAccountAndHotelAdminOnly={true}
                                        render={props => <TaxiDriverInvoicesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-invoices/establishment"
                                        simulAccountsOnly={true}
                                        render={props => <EstablishmentTaxiInvoicesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-invoice/:_id"
                                        simulAccountsOnly={true}
                                        render={props => <TaxiInvoicePage {...props} />}
                                    />

                                    <ProtectedRoute
                                        path="/taxi-companies"
                                        simulAccountsOnly={true}
                                        render={props => <TaxiCompaniesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-zones"
                                        simulAccountsOnly={true}
                                        render={props => <ZonesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/system-issues"
                                        systemAdminOnly={true}
                                        render={props => <SystemIssuesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/support-tickets"
                                        systemAdminOnly={true}
                                        exact
                                        render={props => <SupportTicketsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/support-tickets/:_id"
                                        systemAdminOnly={true}
                                        render={props => <SupportTicketPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/contact"
                                        render={props => <ContactPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/contacts"
                                        systemAdminOnly={true}
                                        render={props => <ContactsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/releases"
                                        systemAdminOnly={true}
                                        render={props => <ReleasesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-companies"
                                        simulAccountsOnly={true}
                                        render={props => <TaxiCompaniesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/taxi-zones"
                                        simulAccountsOnly={true}
                                        render={props => <ZonesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/system-issues"
                                        systemAdminOnly={true}
                                        render={props => <SystemIssuesPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/support-tickets"
                                        systemAdminOnly={true}
                                        exact
                                        render={props => <SupportTicketsPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/support-tickets/:_id"
                                        systemAdminOnly={true}
                                        render={props => <SupportTicketPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/contact"
                                        render={props => <ContactPage {...props} />}
                                    />
                                    <ProtectedRoute
                                        path="/releases"
                                        systemAdminOnly={true}
                                        render={props => <ReleasesPage {...props} />}
                                    />

                                    {/* <Route path="/dashboard" component={DashboardPage} />
                                <Route path="/accounts" component={Accounts} /> */}
                                    <Route path="/not-found" component={NotfoundPage} />
                                    <Redirect from="/" exact to="/dashboard" />
                                    <Redirect to="/not-found" />
                                </Switch>
                            </ErrorBoundary>
                        </div>
                    </main>
                </Row>
            </Container>

        </>
    )
}

export default LeftSidebarTemplate