import http from './http'
import { getToken } from './auth'

const base = 'taxiInvoices'

export async function getTaxiInvoices() {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.get(`${base}`, config)
}

export async function getTaxiInvoiceById(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.get(`${base}/${id}`, config)
}

export async function generateTaxiInvoiceByMonth(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/generate`, payload, config)
}

export async function generateTaxiInvoiceFinal(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/generate-final`, payload, config)
}

export async function updateInvoiceAsPaid(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`${base}/update-as-paid/${id}`, {}, config)
}

export async function cancelInvoice(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}