
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Moment from 'moment';
import React from 'react';

const TaxiInvoicePDF = ({invoice, recipientName, recepientAddress}) => {
    
    const styles = StyleSheet.create({
        page: { 
          backgroundColor: '#fff',
          padding:50
        },
        footerText: {
            fontSize: 10,
            marginBottom: 5,
        },
        headerText: {
            fontSize: 10,
            marginBottom: 3,
        },
        row:{
            display: 'flex',
            flexDirection: 'row',
        },
        tableCell: {
            padding: 10,
            fontSize: 9
        },
        marginTop:{
            marginTop: 10
        },
        marginTopMD:{
            marginTop: 30
        },
        marginBottom:{
            marginBottom: 10
        },
        marginBottomMD:{
            marginBottom: 30
        }
    });

    const getItemSaleAmount = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount.toFixed(2) : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount.toFixed(2) : 0;//TODO: we're not sure yet if this is accurate
    }

    const getItemSaleAmountInDecimal = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount : 0;//TODO: we're not sure yet if this is accurate
    }

    const getPaidAtHotelForTaxiDriverInvoice = (sale) =>{
        return sale?.paid_over_counter ? getItemSaleAmount(sale) : 0
    }

    const getPaidAtDriverForTaxiDriverInvoice = (sale) => {
        return sale?.paid_over_counter ? 0 : getItemSaleAmount(sale)
    }

    const getStaffName = (sale) =>{

        const bookingSourceUser = sale.booking_info.booking_source_user
        if(!bookingSourceUser) return ''

        return `${bookingSourceUser.first_name} ${bookingSourceUser.last_name}`
    }

    const isEstablishmentInvoice = (invoice) => {
        return invoice.recipient_type === 'establishment'
    }
 
    return (
        <Document
        >
            <Page size="A4" style={styles.page}>
                <View wrap={true}>
                    <View wrap={false} style={styles.marginBottom}>
                        <Text style={{fontSize:15,color:'#333'}}>Invoice</Text>
                    </View>

                    <View wrap={false} style={styles.marginBottomMD}>
                        <Text style={{fontSize:10}}>{recipientName}</Text>
                        <Text style={[styles.marginBottom, {fontSize:10}]}>{recepientAddress}</Text>

                        <Text style={[styles.headerText]}>Invoice No.: {invoice.invoice_human_readable_number}</Text>
                        <Text style={[styles.headerText]}>Date Covered: {Moment(invoice.period_from).format('DD.MM.YYYY')} - {Moment(invoice.period_to).format('DD.MM.YYYY')}</Text>
                        <Text style={[styles.headerText]}>Number of Bookings: {invoice.number_of_sales}</Text>
                        <Text style={[styles.headerText]}>Recipient: {isEstablishmentInvoice(invoice) ? 'Hotel' : 'Taxi Driver'}</Text>
                    </View>

                    <Table
                        data={invoice.sales}
                    >
                        <TableHeader>
                            <TableCell style={styles.tableCell}>
                                Date
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Guest Name
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Paid At Hotel
                            </TableCell>
                            {
                                !isEstablishmentInvoice(invoice) &&
                                <TableCell style={styles.tableCell}>
                                    Paid At Driver
                                </TableCell>
                            }
                            {
                                isEstablishmentInvoice(invoice) &&
                                <TableCell style={styles.tableCell}>
                                    Booked By Staff
                                </TableCell>
                            }
                            <TableCell style={styles.tableCell}>
                                Invoice Amount
                            </TableCell>
                        </TableHeader>
                        <TableBody>
                            <DataTableCell style={styles.tableCell} getContent={(sale) => Moment(sale.completed_time).format('DD.MM.YYYY')}/>
                            <DataTableCell style={styles.tableCell} getContent={(sale) => sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}/>
                            {
                                isEstablishmentInvoice(invoice) 
                                ?
                                <DataTableCell style={styles.tableCell} getContent={(sale) => getItemSaleAmount(sale)}/>
                                :
                                <DataTableCell style={styles.tableCell} getContent={(sale) => getPaidAtHotelForTaxiDriverInvoice(sale) }/>
                            }
                            {
                                !isEstablishmentInvoice(invoice) &&
                                <DataTableCell style={styles.tableCell} getContent={(sale) => getPaidAtDriverForTaxiDriverInvoice(sale) }/>
                            }
                            {
                                isEstablishmentInvoice(invoice) &&
                                // <DataTableCell style={styles.tableCell} getContent={(sale) => sale.commission_amount.toFixed(2)}/>
                                <DataTableCell style={styles.tableCell} getContent={(sale) => getStaffName(sale)}/>
                            }
                            {
                                isEstablishmentInvoice(invoice) ?
                                <DataTableCell style={styles.tableCell} getContent={(sale) => getItemSaleAmount(sale)}/>
                                :
                                <DataTableCell style={styles.tableCell} getContent={(sale) => sale.bookingsource_cashback.toFixed(2)}/>
                            }
                        </TableBody>

                    </Table>

                  
                    <View style={[styles.marginTopMD]}>
                        {/* {isEstablishmentInvoice(invoice) &&
                        <Text style={[styles.footerText]}>Total Booked By Staff: {invoice?.commission_amount?.toFixed(2)} CHF</Text>
                        } */}
                        {
                            !isEstablishmentInvoice(invoice) &&
                            <Text style={[styles.footerText]}>Less Amount Paid At Hotel: {invoice.sales.reduce((n, s) => n+ (s.paid_over_counter ? getItemSaleAmountInDecimal(s) : 0), 0).toFixed(2)} CHF</Text>
                        }
                        <Text style={[styles.footerText]}>SubTotal: {invoice?.sub_amount?.toFixed(2)} CHF</Text>
                        {invoice.others && invoice.others.monthly_charge &&
                        <Text style={[styles.footerText]}>Monthly Charge: {invoice.others.monthly_charge.toFixed(2)} CHF</Text>
                        }
                        <Text style={[{fontSize:10}]}>Total: {invoice.amount.toFixed(2)} CHF</Text>
                    </View>
                   
 
                </View>
            </Page>
        </Document>
    );
}

export default TaxiInvoicePDF
