import React, { useState } from 'react';
import { Col, Card, Form, Button, Row, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { getCurrentUser, login } from '../services/auth';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Logo from '../assets/images/logo_hor.svg';
import AndroidDownload from '../assets/images/android-download.png';

const LoginPage = () => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState()

    let history = useHistory()
    const _currentUser = getCurrentUser()

    useEffect(() => {
        if(_currentUser)
            history.push('dashboard')
    }, [])

    const handleLogin = async () => {

        let account = {
            username,
            password
        }

        try {
            const { data: { message, code } } = await login(account)
            if (code !== 200) {
                setError(message)
                return
            } else {
                localStorage.setItem('simul-token', message)
                history.push('dashboard')
            }
        } catch (e) {
            setError(e.message)
            //TODO: toast message here
        }
    }

    return (
        <section style={{ height: '100vh', backgroundColor: '#fff' }}>
            <Col className="h-100">
                <Row className="justify-content-center h-100">
                    <Col md="6" lg="4" className="h-100">
                        <div className="login-container d-flex align-items-center h-100">

                            <div className="w-100 mt-n5">

                                <div className="w-100 text-center mb-2">
                                    <img src={Logo} style={{'maxWidth':'250px'}} />
                                </div>

                                {error &&
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                }
                                <Card className="p-4 w-100 bg-secondary mb-4">
                                    <h5 className="text-center mb-5">Login to Simul</h5>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="bg-white border-right-0 color-primary"><i className="fas fa-user"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl className="border-left-0" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                                    </InputGroup>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text className="bg-white border-right-0 color-primary"><i className="fas fa-lock"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="password" className="border-left-0" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    </InputGroup>
                                    <p></p>
                                    {/* <p className="text-right"><a href="#"><small>Forgot Password?</small></a></p> */}
                                    <Button className="w-100" onClick={() => handleLogin()}>LOGIN</Button>
                                </Card>

                                <Row>
                                    <Col className="text-center">
                                        <a target="_blank" href="https://drive.google.com/file/d/1DeWHzdAafIyVeNTn-xTFBmwRzbkrKIP2/view">
                                            <img src={AndroidDownload} className="w-100" style={{maxWidth:'200px'}} />
                                        </a>
                                        <p>Version 6.5.1</p>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Col>
        </section>
    );
}

export default LoginPage;