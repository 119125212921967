import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row, Button } from 'react-bootstrap'
import TimetableList from '../../components/common/timetableList'
import PageLoader from '../../components/common/pageLoader'
import { getAllDestinations } from '../../services/shuttleDestinations'
import { getHotels } from '../../services/hotels';
import { getVehicles } from '../../services/vehicles';
import TimetableAdderModal from '../../components/modals/timetableAdderModal'
import { toast } from 'react-toastify'

const SystemAdminTimetablePage = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [hotels, setHotels] = useState()
    const [vehicles, setVehicles] = useState()

    const [timetables, setTimetables] = useState([])
    const [selectedTimetables, setSelectedTimetables] = useState([])
    const [addTimetableModal, setAddTimetableModal] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        setIsLoading(true)

        const {data} = await getHotels()
        if (data.code === 200) {
            setHotels(data.message)
        }

        const vehiclesResp = await getVehicles()
        if (vehiclesResp.data.code === 200) {
            setVehicles(vehiclesResp.data.message)
        }

        const {data: {code, message}} = await getAllDestinations()
        if(code == 200) {
            if(Array.isArray(message)) {
                setTimetables(message)
                setSelectedTimetables([message[0]])
            }
        } else {
            toast.error(`An error occured. Unable to fetch timetables.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }

        setIsLoading(false)
    }

    const timetableUpdated = (timetable) =>{

        let _timetables = timetables
        _timetables.push(timetable)
        setTimetables([..._timetables])
        //window.location.reload();
    }
      
    const handleFilterDestinations = (destinationId) => {
        let _timetables = timetables.filter(x => x._id == destinationId)
        setSelectedTimetables(_timetables)
    }

    const timeAdded = (timetable, timeAdded) => {
        const times = [timeAdded, ...timetable.timetable]
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeEdited = (timetable, timeId, newTime) => {

        const times = timetable.timetable.map(t =>{
            if(t._id == timeId){
                const newT = t
                newT.time = newTime
                return newT;
            }
            else{
                return t;
            }
        })
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeSeatPriceEdited = (timetable, timeId, newSeatPrice) => {

        const times = timetable.timetable.map(t =>{
            if(t._id == timeId){
                const newT = t
                newT.seat_price = newSeatPrice
                return newT;
            }
            else{
                return t;
            }
        })
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeSeatPriceListEdited = (timetable, timeId, newSeatPriceList) => {

        const times = timetable.timetable.map(t =>{
            if(t._id == timeId){
                const newT = t
                newT.seat_price_list = newSeatPriceList
                return newT;
            }
            else{
                return t;
            }
        })
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeVehicleRemoved = (timetable, timeId, removedVehicleId) => {

        const times = timetable.timetable.map(t =>{
            if(t._id == timeId){
                const newT = t
                newT.vehicle_ids = t.vehicle_ids.filter(x => x !== removedVehicleId)
                return newT;
            }
            else{
                return t;
            }
        })
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeVehicleAdded = (timetable, timeId, newVehicleIds) => {

        const times = timetable.timetable.map(t =>{
            if(t._id == timeId){
                const newT = t
                newT.vehicle_ids = [...newVehicleIds, ...t.vehicle_ids]
                return newT;
            }
            else{
                return t;
            }
        })
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const timeRemoved = (timetable, timeId) => {

        const times = timetable.timetable.filter(x => x._id != timeId)
        const _timetable = timetable
        _timetable.timetable = times;

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }

    const allTimeRemoved = (timetable) => {
        
        const _timetable = timetable
        _timetable.timetable = [];

        const _selectedTimetables = selectedTimetables.map((t) =>{
            if(t._id == _timetable._id){
                return _timetable;
            }
            else{
                return t;
            }
        })
        setSelectedTimetables(_selectedTimetables)
    }


    return <>

        {isLoading && <PageLoader />}

        <Button className="mb-4" variant='primary' onClick={() => setAddTimetableModal(true)}>Add Timetable</Button>
        {addTimetableModal &&
            <TimetableAdderModal display={addTimetableModal} toggle={setAddTimetableModal} timetableUpdated={timetableUpdated}/>
        }
        <section>
            <Row className="mb-4">
                <Col lg="4">
                    <Card className='w-100 filter-panel'>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-0">
                                        <Form.Label><strong>Select Timetable</strong></Form.Label>
                                        <Form.Control as="select" onChange={(e) => handleFilterDestinations(e.target.value)}>
                                            {timetables && timetables.map((timetable, index) => (
                                                <option key={`destination-${index}`} value={timetable._id} >
                                                    {timetable.origin} to {timetable.destination ? timetable.destination : 'Multiple Establishments'}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                selectedTimetables.map((timetable, index) =>
                    <Row key={`timetable-${index}`}>
                        <Col>
                            <TimetableList 
                                hotels={hotels}
                                vehicles={vehicles}
                                timetable={timetable} 
                                timeAdded={timeAdded} 
                                timeRemoved={timeRemoved} 
                                allTimeRemoved={allTimeRemoved} 
                                timeEdited={timeEdited}
                                timeSeatPriceEdited={timeSeatPriceEdited}
                                timeSeatPriceListEdited={timeSeatPriceListEdited}
                                timeVehicleRemoved={timeVehicleRemoved}
                                timeVehicleAdded={timeVehicleAdded}/>
                        </Col>
                    </Row>
                )
            }
            
        </section>
    </>
}

export default SystemAdminTimetablePage