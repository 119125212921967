import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

const SidebarShuttleDriver = () => {
    return ( 
        <>
        <div className='d-flex py-2 px-3'>
            <h3>Shuttle Driver</h3>
        </div>
        <ul className='nav flex-column'>
            <LinkContainer to='/dashboard'>
                <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
            </LinkContainer>
            <LinkContainer to='/timetable'>
                <li><a className='nav-link'><i className="far fa-calendar-alt mr-2"></i> Timetable</a></li>
            </LinkContainer>
        </ul>
        </>
     );
}
 
export default SidebarShuttleDriver;
