import React, { useEffect } from 'react'
import { Modal, Card, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { getSimulAccounts } from '../../services/accounts'
import { updateDriver } from '../../services/shuttleBookings'

const AssignDriverModal = ({ display, toggle, booking, driverUpdate }) => {

    const [simulAccounts, setSimulAccounts] = useState([])
    const [driverId, setDriverId] = useState('')

    useEffect(() => {
        fetch()
    },[])

    const fetch = async () => {
        const { data: { message } } = await getSimulAccounts()
        if(message == 'No simul accounts found.'){
            return;
        }
        setSimulAccounts(message)
    }

    const handleAssignDriver = async () => {
        const { data: { code, message } } = await updateDriver(booking._id, driverId)
        if (code !== 200) {
            //TODO: toast
        } else {
            driverUpdate(driverId)
        }

        toggle(false)
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-2">
                    Choose Driver
                </Card.Title>   
                <div className="mb-4">
                <Form.Control as="select" onChange={(e) => setDriverId(e.target.value)}>
                    <option value="">Choose Driver</option>
                    {simulAccounts.map((account, index) => (
                        <option key={`driver-${index}`} value={account._id}>{account.first_name || account.last_name ? `${account.first_name} ${account.last_name}` : account.username}</option>
                    ))}
                </Form.Control>
                </div>
                <div>
                    <Button className="w-100" onClick={() => handleAssignDriver()}>Select</Button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default AssignDriverModal

