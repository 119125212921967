import React from 'react';
import { Link } from 'react-router-dom';

const LinkTD = ({ children, to, className = '' }) => {

    const ContentTag = to ? Link : 'div';

    return (
        <td className={`link-td ${className}`}>
            <ContentTag to={to} target="_blank">{children}</ContentTag>
        </td>
    )
}

export default LinkTD

