import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createHotelAdmin } from '../../services/systemAdmin'
import { getHotelAdmins } from '../../services/users'
import HotelAdminCreator from './hotelAdminCreator'
import HotelAdminList from './hotelAdminList'

const HotelAdminCreation = () => {

    let history = useHistory()

    const [hotelAdmins, setHotelAdmins] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsTableLoading(true)
        const { data: { message } } = await getHotelAdmins()
        setIsTableLoading(false)
        if(Array.isArray(message))
            setHotelAdmins(message)
        else {
            setHotelAdmins([])
            //TODO: toast when getting hotel admins error
        }
    }

    const handleCreateHotelAdmin = async (payload, hotelName, password) => {
        setIsLoading(true)
        const {data :{code,message}} = await createHotelAdmin(payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newHotelAdmin = {
                '_id': message['hotel-account-info']._id,
                'hotel_name': hotelName,
                'username': message['user-info'].username,
                'password': password
            }

            let _hotelAdmins = hotelAdmins
            _hotelAdmins.push(_newHotelAdmin)
            setHotelAdmins([..._hotelAdmins])
        }
    }

    return (
        <>
            <Row className="mb-4">
                <Col className="text-left">
                    <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/accounts')}>{'<'} Back</Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="8" >
                    <HotelAdminCreator createHotelAdmin={handleCreateHotelAdmin} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <HotelAdminList hotelAdmins={hotelAdmins} setHotelAdmins={setHotelAdmins} 
                        isTableLoading={isTableLoading}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading} />
                </Col>
            </Row>
        </>
    )
}

export default HotelAdminCreation