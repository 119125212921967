import http from './http'
import { getToken } from './auth'

const base = 'airlines'

export async function createAirline(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getAirlines() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAirlineById(airlineId) {
    return await http.get(`${base}/by-id/${airlineId}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function updateAirline(id, payload) {

    return await http.patch(`${base}/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function deleteAirline(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}