
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';
import { cancelBooking, getBookingsToday } from '../../services/shuttleBookings';
import { getAllDestinations } from '../../services/shuttleDestinations';
import { getDestinationName, isDateTimePassedCurrentTime, isSimulAccount, isDateToday, isDateTimePassedMidnight, isHotelAccount } from '../../utils/utils';
import ChangeBookingDateModal from '../modals/changeBookingDate';
import ConfirmationModal from '../modals/confirmation';
import ElementLoader from './elementLoader';
import LinkTD from './linkTd';

const LatestBookings = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)

    const [bookings, setBookings] = useState([])
    const [selectedBooking, setSelectedBooking] = useState()
    const [destinations, setDestinations] = useState()
    Moment.locale('en');

    const _currentUser = getCurrentUser()

    useEffect(() => {
        fetch()
        fetchDestinations()
    }, [])

    const fetch = async () => {
        
        setIsLoading(true)
        const { data: { message } } =  await getBookingsToday()
        setIsLoading(false)

        if(Array.isArray(message))
        {
            let sortedItems = message.sort((a, b) => new Date(a.booking_date) - new Date(b.booking_date));
            sortedItems = sortedItems.filter(booking => !booking.isCancelled);
            sortedItems = sortedItems.filter(booking => isDateToday(booking.booking_date));
            setBookings(sortedItems);
        }
    }

    const fetchDestinations = async () => {

        const { data: { message } } = await getAllDestinations()
        setDestinations(message)
    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleCancelBooking = async () => {
        await cancelBooking(selectedBooking._id)
        var _bookings = bookings
        var index = _bookings.indexOf(selectedBooking);
        _bookings.splice(index, 1);
        setBookings([..._bookings])
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const isPastBooking = booking => {
        return Moment(Moment().local().utcOffset(0, true).format())
            .isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canChangeTime = booking => {
        const is1HourBeforeDeparture = Moment(Moment(Moment().add(1, 'hours')).local().utcOffset(0, true).format())
            .isSameOrAfter(Moment.utc(booking.booking_date))
        return !is1HourBeforeDeparture && !booking.is_confirmed
    }

    const canCancel = (booking) => {

        const isSuperAdmin =  _currentUser.role_name === 'system-admin'
        if(isSuperAdmin) return true
        
        //const is30MinsBeforeDeparture = Moment(Moment(Moment().add(30, 'minutes')).local().utcOffset(0, true).format())
        //    .isSameOrAfter(Moment.utc(booking.booking_date))

        //NOTE: if you created the booking today, you can no longer cancel the booking starting midnight
        const createdDateHasPassedMidnight = isDateTimePassedMidnight(booking.created_date);

        return !createdDateHasPassedMidnight //!is30MinsBeforeDeparture && 
            && !isPastBooking(booking)
            && !booking.is_confirmed
        
    }

    const isConfirmedByReceptionist = (booking) =>{
        return booking.reception && booking.reception.is_confirmed;
    }

    const isBookingConfirmed = (booking) => {
        return booking.is_confirmed || isConfirmedByReceptionist(booking)
    }

    const bookingStatus = (booking) => {

        if(booking.fulfilled) return "Completed"

        if(booking.is_confirmed && isConfirmedByReceptionist(booking)) return "Reception and Driver Confirmed"

        return isConfirmedByReceptionist(booking) ? 'Reception Confirmed' : 
        (booking.is_confirmed ? 'Driver Confirmed' : 'Not Confirmed')
    }

    return <>
        {selectedBooking && 
        <ChangeBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} destinations={destinations} />
        }
        <ConfirmationModal 
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />
        <Card className='bookings-list'>
            <Card.Body>
                <Card.Title>Upcoming Shuttle Bookings </Card.Title>
                <Table responsive>
                    <thead className="border-top-0">
                        <tr>
                            <th></th>
                            <th style={{minWidth:'120px'}}>Booked Date</th>
                            <th>Time</th>
                            <th style={{minWidth:'130px'}}>Guest Name</th>
                            {isSimulAccount() &&
                            <th style={{minWidth:'120px'}}>Origin</th>
                            }
                            <th style={{minWidth:'80px'}}>Room #</th>
                            <th>Seats</th>
                            <th style={{minWidth:'170px'}}>Destination</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{position:'relative'}}>
                        {isLoading && <tr><td><ElementLoader /></td></tr>}
                        {
                        bookings.length > 0 &&
                        bookings.map((booking, index) => 

                            <tr key={`booking-${index}`} className={isPastBooking(booking) ? 'booking-past' : ''}>
                                <LinkTD to={`/booking/${booking._id}`}>
                                    {   
                                        <i style={{color:(isBookingConfirmed(booking) || booking.fulfilled ? 'Tomato': 'LightGray')}} 
                                            className={(booking.fulfilled ? 'fas fa-check-circle': 'fas fa-check')} 
                                            title={bookingStatus(booking)}></i>
                                    }
                                </LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{Moment.utc(booking.booking_date).format('DD.MM.YYYY')}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{Moment.utc(booking.booking_date).format('HH:mm')}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                {isSimulAccount() &&
                                <LinkTD to={`/booking/${booking._id}`}>{booking.origin}</LinkTD>
                                }
                                <LinkTD to={`/booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{destinations && getDestinationName(booking, destinations)}</LinkTD>
                                <td>
                                    {canChangeTime(booking) && isHotelAccount() && 
                                    <Button className="mr-2" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>Change Time</Button>
                                    }
                                    {canCancel(booking) &&
                                    <Button size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                    }
                                </td>
                            </tr>
                        )
                        
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
                
    </>
}

export default LatestBookings