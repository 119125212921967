
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LatestBookings from '../../components/common/latestBookings';
import SalesOverview from '../../components/simulUserCommon/salesOverview';

const ShuttleAdminDashboardPage = () => {

    return <>
        <section>
            <Row className="mb-3">
                <Col>
                    <SalesOverview />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <LatestBookings />
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-3">Bookings Per Day <i className="more-options fas fa-ellipsis-h"></i></Card.Title>
                            <div style={{height:'200px'}} className="center-xy">
                                <div>
                                <p><i>Chart Here</i></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default ShuttleAdminDashboardPage