
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';
import { getTaxiDestinationsByHotelId } from '../../services/taxiDestinations';
import { isHotelAccount } from '../../utils/utils';

const BookTaxiDestinations = ({selectDestination}) => {

    const [destinations, setDestinations] = useState()

    const _currentUser = getCurrentUser();
    
    useEffect(() => {
        fetchDestinations()
    }, [])

    const fetchDestinations = async () => {
        const { data: { code, message } } = await getTaxiDestinationsByHotelId(_currentUser.hotel_id)

        if(code === 200) {
            setDestinations(message)
        } else {
            //TODO: toast
        }
    }

    const handlSelectDestination = (bookingType, destination = 'open-destination') => {
        selectDestination(bookingType, destination)
    }

    return <>
        
        <Row className="row-eq-height">
            <Col lg="3" className="mb-4">
                <Card className="h-100 border border-primary">
                    <Card.Body>
                        <Card.Title className="text-center mb-4 color-primary">Open Destination</Card.Title>
                        <div className="mb-3">
                            <Button className="w-100" variant="outline-primary" onClick={() => handlSelectDestination('reservation')}>Reservation</Button>
                        </div>
                        <div className="mb-3">
                            <Button className="w-100" variant="outline-primary" onClick={() => handlSelectDestination('walk-in')}>Walk In</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {destinations && destinations.map((destination, index) => (
                <Col key={`destination-${index}`} lg="3" className="mb-4">
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">
                                {!isHotelAccount() && 
                                    <>
                                    {destination.establishment_origin_name} <i className=" fas fa-arrow-circle-right"></i> 
                                    </>
                                }
                                {destination.destination_name}
                            </Card.Title>
                            <div className="mb-3">
                                <Button className="w-100" onClick={() => handlSelectDestination('reservation', destination)}>Reservation</Button>
                            </div>
                            <div className="mb-3">
                                <Button className="w-100" onClick={() => handlSelectDestination('walk-in', destination)}>Walk In</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </>
}

export default BookTaxiDestinations
