import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Row, Col, Card, Table, Button, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import PageLoader from '../../components/common/pageLoader'
import ConfirmationModal from '../../components/modals/confirmation'
import { getZones, createZone, deleteZone, editZone } from '../../services/zones'

const SetZoneModal = ({ display, toggle, onSuccess, selectedZone }) => {

    const [name, setName] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSubmitting(true)

        const { data: { code, message } } = selectedZone._id ? 
            await editZone(selectedZone._id, {name}) :
            await createZone({ name })

        setIsSubmitting(false)

        if (code === 200) {
            onSuccess(message)
            toggle(false)
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const onShow = () => {
        setName(selectedZone.name || '')
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            onShow={onShow}
            size="sm"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Card.Title className="mb-2">
                        {selectedZone._id ? 'Edit' : 'Create'}  Zone
                    </Card.Title>
                    <div className="mb-4">
                        <Form.Group>
                            <p className="mb-2">Name</p>
                            <Form.Control value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter zone name" required />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => toggle(false)}>Cancel</Button>
                    <Button type='submit' disabled={isSubmitting}>{isSubmitting ? 'Submitting' : 'Submit'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

const ZonesPage = () => {

    const [zones, setZones] = useState([])
    const [showSetZoneModal, setShowSetZoneModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [searchName, setSearchName] = useState('')
    const isUnmounted = useRef(false)
    const [selectedZone, setSelectedZone] = useState({})

    const fetchZones = useCallback(async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getZones()
        if (isUnmounted.current) return

        setIsLoading(false)

        if (code === 200) {

            setZones(message.sort((a, b) => {
                const result = b.waiting_list.length - a.waiting_list.length
                // if result is equal 0
                if (!result) {
                    return a.name.localeCompare(b.name)
                }

                return result
            }))

        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }, [])

    useEffect(() => {
        fetchZones()

        return () => {
            isUnmounted.current = true
        }
    }, [fetchZones])

    const searchedZones = useMemo(
        () => zones.filter(zone => zone.name.toLowerCase().includes(searchName?.toLowerCase())),
        [zones, searchName]
    )

    const handleShowConfirmation = (zone) => {
        setSelectedZone(zone)
        setShowConfirmationModal(true)
    }

    const handleDelete = async () => {
        setIsLoading(true)
        const {data: { code }} = await deleteZone(selectedZone._id)
        setIsLoading(false)

        if(code === 200) {
            setZones(zones.filter( x => x._id !== selectedZone._id))
        } else {
            toast.error('Error: Fail to delete zone', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const handleSetZone = (zone) => {
        setSelectedZone(zone)
        setShowSetZoneModal(true)
    }

    return (
        <section>
            <SetZoneModal display={showSetZoneModal} toggle={setShowSetZoneModal} onSuccess={fetchZones} selectedZone={selectedZone} />
            <ConfirmationModal display={showConfirmationModal} toggle={setShowConfirmationModal} title='Delete Zone' confirmAction={handleDelete}/>

            <Row>
                <Col>
                    <Button className='mb-3' onClick={() => handleSetZone({})}>Create Zone</Button>

                    <Row>
                        <Col lg='4'>
                            <Form.Group>
                                <p className="mb-2"><strong>Search by zone name</strong></p>
                                <Form.Control onChange={(e) => setSearchName(e.target.value)} placeholder="Search name" required />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Card className='w-100 bookings-list'>
                        <Card.Header className='text-center bg-primary text-white'>Taxi Zones</Card.Header>
                        <Card.Body style={{ position: 'relative' }}>
                            <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>Zone Name</th>
                                        <th>Waiting Drivers</th>
                                        <th className='text-center'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {searchedZones.map(zone =>
                                        <tr key={zone._id}>
                                            <td>{zone.name}</td>
                                            <td>
                                                {zone.waiting_list.map((driver, index) =>
                                                    <React.Fragment key={driver.driver_id}>
                                                        {driver.lat && driver.lng ?
                                                            <a href={`https://www.google.com/maps/dir/'${driver.lat},${driver.lng}'/@${driver.lat},${driver.lng},16z`} target="_blank" className="badge badge-warning mr-1">
                                                                {`${index + 1}. ${driver.driver_name}`}
                                                            </a>
                                                            :
                                                            <p className="badge badge-warning mr-1">
                                                                {`${index + 1}. ${driver.driver_name}`}
                                                            </p>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </td>
                                            <td className='actions text-center'>
                                                <i title='Edit zone name'  className="far fa-edit mr-2" onClick={() => handleSetZone(zone)}></i>
                                                <i title='Delete zone' className="far fa-trash-alt" onClick={() => handleShowConfirmation(zone)}></i>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                            {isLoading &&
                                <PageLoader position='absolute' />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}

export default ZonesPage