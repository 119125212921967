import React from 'react';
import { Col } from 'react-bootstrap';

const NotfoundPage = () => {
    return (
        <section>
            <Col>
            <h1>Page not found</h1>
            </Col>
        </section>
    );
};

export default NotfoundPage;