import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/auth';
import SytemAdminUnassignedTaxiBookings from './admin/bookingsTaxiUnassigned';

const UnassignedTaxiBookingsPage = () => {

    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && (user.role_name === 'system-admin' || user.role_name === 'shuttle-admin') &&
            <SytemAdminUnassignedTaxiBookings />
        }
        
    </>
}

export default UnassignedTaxiBookingsPage