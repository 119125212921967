import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { getCurrentUser } from "../../services/auth"

export default function ProtectedRoute({
    path,
    component: Component,
    render,
    systemAdminOnly,
    simulAccountsOnly,
    hotelAdminOnly,
    airlineAdminOnly,
    simulAccountAndHotelAdminOnly,
    restrictShuttleAccounts,
    hotelData,
    airlineData,
    allowedUsers,
    ...rest
}) {

    return (
        <Route
            {...rest}
            render={props => {

                const currentUser = getCurrentUser();

                if (!currentUser) return <Redirect to="/login" />
                if (systemAdminOnly && (currentUser.role_name != 'system-admin')) return <Redirect to="/" />
                if (simulAccountsOnly && (!(currentUser.role_name == 'system-admin' || currentUser.role_name == 'shuttle-admin'))) return <Redirect to="/" />
                if (hotelAdminOnly && (currentUser.role_name != 'hotel-admin')) return <Redirect to="/" />
                
                if (hotelData && Object.keys(hotelData).length && path === '/swiss-layover' &&
                    (!hotelData?.allowed_features?.includes("swiss layover"))) return <Redirect to="/" />

                if (hotelData && Object.keys(hotelData).length && path === '/book-shuttle' && 
                    (!hotelData?.allowed_features?.includes("shuttle booking"))) return <Redirect to="/" />

                if (airlineData && Object.keys(airlineData).length && path === '/swiss-layover' &&
                    (!airlineData?.allowed_features?.includes("swiss layover"))) return <Redirect to="/" />

                if (airlineData && Object.keys(airlineData).length && path === '/book-shuttle' && 
                    (!airlineData?.allowed_features?.includes("shuttle booking"))) return <Redirect to="/" />    

                if (airlineAdminOnly && (currentUser.role_name != 'airline-admin')) return <Redirect to="/" />
                if (allowedUsers && (!allowedUsers.includes(currentUser.role_name))) return <Redirect to="/" />

                if (simulAccountAndHotelAdminOnly
                    && currentUser.role_name !== 'system-admin' 
                    && currentUser.role_name !== 'shuttle-admin'
                    && currentUser.role_name !== 'hotel-admin') 
                    return <Redirect to="/" />

                if (restrictShuttleAccounts && (currentUser.role_name == 'shuttle-admin' || currentUser.role_name == 'shuttle-driver')) return <Redirect to="/" />
                return Component ? <Component {...props} /> : render(props)
            }}
        />
    )
}
