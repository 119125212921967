import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';

const HotelInfo = (props) => {
    
    const [hotel, _] = useState(props.hotel)

    const _currentuser = getCurrentUser()

    return ( 
        <>
        {hotel && 
        <>
        <Card>
            <Card.Body>
                <p><strong>HOTEL INFORMATION</strong></p>
                <hr></hr>
                <Row>
                    <Col className="mb-3">
                        <h4>{hotel?.hotel_name}</h4>
                        {
                            hotel?.address &&
                            <>
                                <p>{hotel.address.street} {hotel.address.city} {hotel.address.zip} {hotel.address.country}</p>
                            </>
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
        }
        </>
     );
}
 
export default HotelInfo;