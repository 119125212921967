import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createAirline, getAirlines } from '../../services/airlines'
import PageLoader from '../common/pageLoader'
// import AirlineCreator from './airlineCreator'
import AirlineList from './airlineList'
import AirlineCreator from './airlineCreator'

const AirlineCreation = () => {

    const [airlines, setAirlines] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { message } } = await getAirlines()
        setIsLoading(false)
        if (Array.isArray(message))
            setAirlines(message)
        else {
            setAirlines([])
            //TODO: toast when getting airline admins error
        }
    }

    const handleCreateAirline = async (payload) => {
        setIsCreating(true)
        const { data: { code, message } } = await createAirline(payload)
        setIsCreating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newAirline = message

            let _airlines = airlines
            _airlines.push(_newAirline)
            setAirlines([..._airlines])

            toast.success(`${payload.name} was successfull added!`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <>
            {isLoading && <PageLoader />}
            <Row className="mb-4">
                <Col>
                    <AirlineCreator createAirline={handleCreateAirline} isCreating={isCreating} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <AirlineList airlines={airlines} setAirlines={setAirlines} />
                </Col>
            </Row>
        </>
    )
}

export default AirlineCreation