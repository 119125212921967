
import enGb from 'date-fns/locale/en-GB';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/modals/confirmation';
import Autocomplete from "react-google-autocomplete";
import { handleAutocompleteAddressChange } from '../../utils/autocompleteAddressChangeHandler';
import YesNoModal from '../../components/modals/yesNoModal';
import BookingTaxiPrint from '../../components/print/bookingTaxi';
import { getCountries, getCities } from '../../services/address';
import { getCurrentUser } from '../../services/auth';
import { getCarCategories } from '../../services/carCategories';
import { getHotels } from '../../services/hotels';
import { getTaxiDrivers } from '../../services/taxiAccounts';
import { bookTaxi } from '../../services/taxiBookings';
import AdminBookTaxiDestinations from './bookTaxiDestinations';
import HotelsSelect from '../../components/common/hotelsSelect';
import DriversTrackingMap from './tracking/driversTrackingMap';

const AdminBookTaxiPage = () => {

    const history = useHistory()

    const [dialogModalTitle, setDialogModalTitle] = useState('');
    const [dialogModalDesc, setDialogModalDesc] = useState('');
    const [dialogModal, setDialogModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [numberOfPersons, setNumberOfpersons] = useState()
    const [roomNumber, setRoomNumber] = useState()
    const [isWalkIn, setIsWalkIn] = useState('yes')
    const [isPaid, setIsPaid] = useState('no')
    const [selectedBookingDate, setSelectedBookingDate] = useState(new Date());
    const [hotels, setHotels] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState([])
    const [carCategories, setCarCategories] = useState([])
    const [filteredTaxiDrivers, setFilteredTaxiDrivers] = useState([])

    const [selectedHotel, setSelectedHotel] = useState()
    const [selectedHotelId, setSelectedHotelId] = useState('')
    const [selectedHotelName, setSelectedHotelName] = useState('')
    const [driverId, setDriverId] = useState('')
    const [noteToDriver, setNoteToDriver] = useState('')
    const [chargeToHotel, setChargeToHotel] = useState(false)

    const [formTitle, setFormTitle] = useState();

    const [pickupStreet, setPickupStreet] = useState('')
    const [pickupCity, setPickupCity] = useState('')
    const [pickupZip, setPickupZip] = useState('')
    const [pickupCountry, setPickupCountry] = useState('Switzerland')
    const [pickupFormattedAddress, setPickupFormattedAddress] = useState('')
    const [freeDestination, setFreeDestination] = useState('')
    const [carCategoryIdForOpenDestination, setCarCategoryIdForOpenDestination] = useState('')

    const [countries, setCountries] = useState()
    const [countryCities, setCountryCities] = useState()
    const [hasSelectedDestination, setHasSelectedDestination] = useState(false)
    const [selectedDestination, setSelectedDestination] = useState()
    const [filteredCarCategories, setFilteredCarCategories] = useState([])
    const [matchedCarCategory, setMatchedCarCategory] = useState()
    const [selectedDriverId, setSelectedDriverId] = useState()

    const [bookingCreated, setBookingCreated] = useState()

    const _currentUser = getCurrentUser()
    const componentRef = useRef();

    registerLocale('en-gb', enGb);

    useEffect(() => {
        fetchHotels();
        fetchTaxiDrivers();
        fetchCarCategories();
        fetchCountries();
        fetchCountryCities();
    }, [])

    useEffect(() => {
        setSelectedBookingDate(isWalkIn === 'yes' ? '' : new Date())
    }, [isWalkIn])

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()

        if (Array.isArray(message)) {
            setHotels(message);
        }
    }

    const fetchTaxiDrivers = async () => {
        const { data: { message } } = await getTaxiDrivers()

        if (Array.isArray(message)) {
            const _drivers = message.filter((x => x.is_available))
            setTaxiDrivers(_drivers)
            setFilteredTaxiDrivers(_drivers)
        }
    }

    const fetchCountries = async () => {
        const { data: { code, message } } = await getCountries();
        if (code == 200) {
            setCountries(message)
        }
    }

    const fetchCountryCities = async () => {
        const { data: { code, message } } = await getCities(pickupCountry);
        if (code == 200) {
            setCountryCities(message)
        }
    }

    const fetchCarCategories = async () => {
        const { data: { code, message } } = await getCarCategories();
        if (code == 200) {
            setCarCategories(message)
        }
    }

    const handleBookTaxi = async () => {

        const payload = {
            "origin": selectedHotelId ? selectedHotelName : pickupFormattedAddress.formatted_address ? pickupFormattedAddress.formatted_address : pickupFormattedAddress,
            "booking_source_type": "hotel",
            "booking_source_user_id": _currentUser._id,
            "booking_source_id": selectedHotelId || "",
            "booking_source_username": _currentUser.username,
            "booking_date": isWalkIn === 'yes' ? new Date() : selectedBookingDate,
            "is_walk_in": isWalkIn === 'yes' ? true : false,
            "customer": {
                "first_name": firstname,
                "last_name": lastname,
                "phone": phoneNumber,
                "booking_source_number": roomNumber,
                "email": email
            },
            "notes": noteToDriver,
            "charge_to_hotel": chargeToHotel,
            "fulfilled": false,
        }
        if (numberOfPersons) payload["number_of_persons"] = numberOfPersons
        if (isFixedDestination(selectedDestination)) {
            payload["destination"] = {
                "fixed_destination_id": selectedDestination._id,
                "fixed_destination_name": selectedDestination.destination_name,
                "fixed_destination_car_category_id": matchedCarCategory ? matchedCarCategory.car_category_id : '',
                "fixed_destination_car_category_price": matchedCarCategory ? matchedCarCategory.price : 0
            }
        } else {
            payload["destination"] = {
                "free_input_address": freeDestination?.formatted_address ? freeDestination?.formatted_address : freeDestination,
                "free_input_car_category_id": carCategoryIdForOpenDestination
            }
            const pa = pickupFormattedAddress.address;
            payload["pickup_address_free_input"] = {
                "street": pickupStreet ? pickupStreet : (pa && pa.street ? `${pa.street_number} ${pa.street}`.trim() : ''),
                "city": pickupCity ? pickupCity : (pa && pa.city ? pa.city : ''),
                "zip": pickupZip ? pickupZip : (pa && pa.postal_code ? pa.postal_code : ''),
                "country": pickupCountry ? pickupCountry : (pa && pa.country ? pa.country : ''),
                "formatted_address": pickupFormattedAddress.formatted_address ? pickupFormattedAddress.formatted_address : pickupFormattedAddress
            }
            if (pa && pa.lat) payload["pickup_address_free_input"]["lat"] = pa.lat
            if (pa && pa.lng) payload["pickup_address_free_input"]["lng"] = pa.lng
        }
        if (driverId) payload["admin_assigned_candidate_driver_id"] = driverId

        payload.is_paid = isPaid === 'yes' ? true : false;
        if (payload.is_paid) {
            payload.payment_info = {
                paid_over_counter: true,
                other: "Paid by guest in the hotel"
            }
        }

        const { data: { code, message } } = await bookTaxi(payload)
        if (code == 200) {
            setBookingCreated(message)
            setDialogModalTitle('Good Job!')
            setDialogModalDesc('You have successfully created taxi booking.');
            setDialogModal(true)
            // document.getElementById("booktaxi-form").reset();
            // history.push(`/taxi-booking/${message._id}`)
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const handleSelectHotel = (hotelId) => {
        if (hotelId) {
            setSelectedHotelId(hotelId)
            const _selectedHotel = hotels.find((hotel => hotel._id == hotelId));
            setSelectedHotel(_selectedHotel)
            setSelectedHotelName(_selectedHotel.hotel_name)

            //unselect the pickup city
            setPickupCity("")

            filterTaxiDrivers(_selectedHotel.address.city, matchedCarCategory?.car_category_id || carCategoryIdForOpenDestination)
        } else {
            filterTaxiDrivers('', matchedCarCategory?.car_category_id || carCategoryIdForOpenDestination)
            setSelectedHotelId("")
            setSelectedHotelName("")
        }
    }

    const handleSelectCity = (city) => {
        if (city) {
            setPickupCity(city)

            //unselect the hotel
            setSelectedHotelId("")
            setSelectedHotel()
            setSelectedHotelName("")

            filterTaxiDrivers(city, matchedCarCategory?.car_category_id || carCategoryIdForOpenDestination)
        } else {
            setFilteredTaxiDrivers([])
            setPickupCity("")
        }
    }

    const filterTaxiDrivers = (city, carCategoryId) => {
        let _filteredTaxiDrivers = taxiDrivers;

        // if (city) {
        //     _filteredTaxiDrivers = _filteredTaxiDrivers.filter(x =>
        //         x.operating_cities.map(x => x.toLowerCase().trim())
        //             .includes(city.toLowerCase().trim())
        //     );
        // }

        if (carCategoryId) {
            _filteredTaxiDrivers = _filteredTaxiDrivers.filter(x => x.car_categories.some(y => y.car_category_id === carCategoryId))
        }

        setFilteredTaxiDrivers(_filteredTaxiDrivers);
    }

    const getBestMatchedCarCategory = (destination, _numberOfPersons) => {
        const matchedCarCats = destination.car_categories.filter(x => x?.category_info?.seats >= _numberOfPersons);
        return matchedCarCats.sort((a, b) => a.category_info.seats - b.category_info.seats)[0];
    }

    const getBestMatchedCarCategoryOpenDestination = (_numberOfPersons) => {
        const matchedCarCats = carCategories.filter(x => x.seats >= _numberOfPersons);
        return matchedCarCats.sort((a, b) => a.seats - b.seats)[0];
    }

    const handleNumberOfPersonsChange = (value) => {

        setNumberOfpersons(value);

        if (isFixedDestination(selectedDestination)) {
            if (selectedDestination.car_categories) {
                let _carCategories = selectedDestination.car_categories.filter(x => x?.category_info?.seats >= value).sort((a, b) => a.category_info.seats - b.category_info.seats);
                setFilteredCarCategories([..._carCategories])
            }

            if (hasMatchedCarCategory(selectedDestination, value)) {
                const bestMatch = getBestMatchedCarCategory(selectedDestination, value);
                setMatchedCarCategory(bestMatch);
                filterTaxiDrivers(selectedHotel?.address?.city || pickupCity, bestMatch.car_category_id);
            }
            else {
                setMatchedCarCategory(undefined);
            }
        } else {
            //NOTE: we don't setMatchedCarCategory
            //only setCarCategoryIdForOpenDestination since this is open destination and price is not yet identified
            let _carCategories = carCategories.filter(x => x.seats >= value).sort((a, b) => a.seats - b.seats);
            setFilteredCarCategories([..._carCategories])
            const bestMatch = getBestMatchedCarCategoryOpenDestination(value);
            filterTaxiDrivers(selectedHotel?.address?.city || pickupCity, bestMatch?._id ?? undefined);
            setCarCategoryIdForOpenDestination(bestMatch?._id ?? undefined)
        }
    }

    const handleSelectCarCategory = (carCategoryId) => {
        let _carCategory = selectedDestination.car_categories.find(x => x.car_category_id === carCategoryId)
        filterTaxiDrivers(selectedHotel?.address?.city || pickupCity, carCategoryId)
        setMatchedCarCategory(_carCategory)
    }

    const handleSetCarCategoryIdForOpenDestination = (carCategoryId) => {
        filterTaxiDrivers(selectedHotel?.address?.city || pickupCity, carCategoryId)
        setCarCategoryIdForOpenDestination(carCategoryId)
    }

    const isFixedDestination = (destination) => {
        return !(typeof destination === 'string' || destination instanceof String)
    }

    const hasCarCategories = (destination) => {
        return destination && destination.car_categories && destination.car_categories.length > 0;
    }

    const hasMatchedCarCategory = (destination, _numberOfPersons) => {

        if (!_numberOfPersons || _numberOfPersons <= 0) {
            return false;
        }

        if (!isFixedDestination(selectedDestination)) {
            return false;
        }

        if (!hasCarCategories(destination)) {
            return false;
        }

        const matchedCarCats = destination.car_categories.filter(x => x?.category_info?.seats >= _numberOfPersons);
        if (!matchedCarCats || matchedCarCats.length <= 0) {
            return false;
        }

        return true;
    }

    const selectDestination = (bookingType, destination) => {
        setSelectedDestination(destination)
        setSelectedHotelId(destination.establishment_origin_id)
        setSelectedHotelName(destination.establishment_origin_name)
        setFreeDestination(destination.destination_name)
        setHasSelectedDestination(true)
        setIsWalkIn(bookingType === "walk-in" ? 'yes' : 'no')
        setFormTitle(bookingType === "walk-in" ? 'TAXI WALK-IN' : 'TAXI RESERVATION');
        setFilteredCarCategories(destination.car_categories ? destination.car_categories : [])
    }

    const getDestination = (booking) => {
        if (!booking.has_destination) {
            return 'OPEN';
        }

        if (booking.destination.is_free_input) {
            return booking.destination.free_input_address;
        }

        return booking.destination.fixed_destination_name;
    }

    const printBooking = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintBooking = () => {
        printBooking()
        document.getElementById("booktaxi-form").reset();
        history.push(`/taxi-booking/${bookingCreated._id}`)
    }
    const handleOk = () => {
        document.getElementById("booktaxi-form").reset();
        history.push(`/taxi-booking/${bookingCreated._id}`)
    }

    const handleSetDriverId = (driverId) => {

        if (!driverId) {
            setDriverId('')
            return
        }

        setSelectedDriverId(driverId)
        const driver = taxiDrivers.find(x => x._id === driverId);

        if (!driver.car_categories || !driver.car_categories.some(c => c.car_category.seats >= numberOfPersons)) {
            setConfirmationModal(true);
        }
        else {
            setDriverId(driverId);
        }
    }

    const handleSetDriverIdFinal = () => {
        setDriverId(selectedDriverId)
    }

    const handleBack = () => {
        setHasSelectedDestination(false)
        history.push('admin-book-taxi')
    }

    return <>
        <YesNoModal
            display={dialogModal}
            toggle={setDialogModal}
            title={dialogModalTitle}
            message={dialogModalDesc}
            yesAction={handlePrintBooking}
            yesText='Print Booking'
            noAction={handleOk}
            noText='Ok'
            style='style2'
        />
        {bookingCreated &&
            <div className="hide-on-screen">
                <BookingTaxiPrint ref={componentRef} booking={bookingCreated} getDestination={getDestination} />
            </div>
        }
        <section>
            <Col>
                {!hasSelectedDestination ?
                    <AdminBookTaxiDestinations hotels={hotels} selectDestination={selectDestination} />
                    :
                    <>
                        {selectedDriverId &&
                            <ConfirmationModal
                                title="Warning"
                                message="The driver don't have any car that matched the required seats. Do you want to proceed?"
                                confirmText="Proceed"
                                cancelText="Cancel"
                                display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleSetDriverIdFinal} />
                        }
                        <Row>
                            <Col lg='6' className='p-1'>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col lg="12">
                                                <div>
                                                    <Form id="booktaxi-form">
                                                        <Row className='mb-3'>
                                                            <Col>
                                                                <strong className='color-primary'>
                                                                    {formTitle}
                                                                    {" "} <i className=" fas fa-arrow-circle-right"></i> {" "}
                                                                    {isFixedDestination(selectedDestination) ? selectedDestination.destination_name.toUpperCase() : 'OPEN DESTINATION'}
                                                                </strong>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <HotelsSelect hotels={hotels} label='Select Hotel' disabled={isFixedDestination(selectedDestination)} value={selectedHotelId}
                                                                    initialOption='No Hotel' onChange={(e) => handleSelectHotel(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {selectedDestination && (!isFixedDestination(selectedDestination)) &&
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label><strong>Or Enter Pickup Address</strong></Form.Label>
                                                                        <Autocomplete
                                                                            placeholder="Search location"
                                                                            className="form-control"
                                                                            // apiKey="AIzaSyByjT-kfojFHzn7VWxaM931kA2jEW-RVzo"
                                                                            options={{
                                                                                types: ["address"],
                                                                                componentRestrictions: { country: "ch" }
                                                                            }}
                                                                            onPlaceSelected={(place) => handleAutocompleteAddressChange(place, setPickupFormattedAddress)}
                                                                            onChange={(e) => handleAutocompleteAddressChange(e.target.value, setPickupFormattedAddress)}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Destination</Form.Label>
                                                                    {/* <Form.Control disabled={isFixedDestination(selectedDestination)} value={freeDestination} type='text' onChange={(e) => setFreeDestination(e.target.value)} /> */}
                                                                    <Autocomplete
                                                                        placeholder="Search location"
                                                                        className="form-control"
                                                                        // apiKey="AIzaSyByjT-kfojFHzn7VWxaM931kA2jEW-RVzo"
                                                                        options={{
                                                                            types: ["address"],
                                                                            componentRestrictions: { country: "ch" }
                                                                        }}
                                                                        defaultValue={selectedDestination.destination_name}
                                                                        disabled={isFixedDestination(selectedDestination)}
                                                                        onPlaceSelected={(place) => handleAutocompleteAddressChange(place, setFreeDestination)}
                                                                        onChange={(e) => handleAutocompleteAddressChange(e.target.value, setFreeDestination)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>First Name</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setFirstname(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Last Name</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setLastname(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Customer Email</Form.Label>
                                                                    <Form.Control type='email' onChange={(e) => setEmail(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm="6">
                                                                <Form.Group>
                                                                    <Form.Label>Phone Number</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setPhoneNumber(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    {selectedHotelId &&
                                                                        <Col>
                                                                            <Form.Group>
                                                                                <Form.Label>Room Number</Form.Label>
                                                                                <Form.Control type='number' placeholder='' onChange={(e) => setRoomNumber(e.target.value)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>Number of Persons</Form.Label>
                                                                            <Form.Control type='number' placeholder='' onChange={(e) => handleNumberOfPersonsChange(e.target.value)} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Booking Date</Form.Label>
                                                                    <DatePicker
                                                                        wrapperClassName="react-datepicker-wrapper-custom"
                                                                        placeholderText={isWalkIn === 'yes' ? 'Current date' : 'Select date'}
                                                                        disabled={isWalkIn === 'yes'}
                                                                        locale="en-gb"
                                                                        showTimeSelect
                                                                        selected={selectedBookingDate}
                                                                        onChange={(e) => setSelectedBookingDate(e)}
                                                                        dateFormat="dd.MM.yyyy HH:mm"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Car Category</Form.Label>
                                                                    <Form.Control disabled={filteredCarCategories.length <= 0 || numberOfPersons <= 0} as="select" onChange={(e) => isFixedDestination(selectedDestination) ? handleSelectCarCategory(e.target.value) : handleSetCarCategoryIdForOpenDestination(e.target.value)}>
                                                                        {filteredCarCategories.map((carCategory, index) => (
                                                                            <option key={`carcategory-${index}`} value={carCategory.car_category_id ?? carCategory._id} >
                                                                                {carCategory?.category_info?.name ?? carCategory.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Driver</Form.Label>
                                                                    <Form.Control disabled={filteredCarCategories.length <= 0 || numberOfPersons <= 0} as="select" value={driverId} onChange={(e) => handleSetDriverId(e.target.value)}>
                                                                        <option value="">Select Driver</option>
                                                                        {filteredTaxiDrivers && filteredTaxiDrivers.map((driver, index) => (
                                                                            <option key={`driver-${index}`} value={driver._id} >
                                                                                {driver.first_name + ' ' + driver.last_name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                    <Form.Text className="text-muted">
                                                                        Leave empty for auto driver assignment
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Note to Driver</Form.Label>
                                                                    <Form.Control as="textarea" value={noteToDriver} onChange={(e) => setNoteToDriver(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            {isFixedDestination(selectedDestination) &&
                                                                <Col>
                                                                    <Form.Group controlId="chargeToHotel">
                                                                        <Form.Label>&nbsp;</Form.Label>
                                                                        <Form.Check value={chargeToHotel} type="checkbox" label="Charge to Hotel" onChange={(e) => setChargeToHotel(e.target.checked)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                        </Row>

                                                        {
                                                            matchedCarCategory &&
                                                            <Row className="mt-2">
                                                                <Col lg="6">
                                                                    <strong>Total: {matchedCarCategory.price} CHF</strong>
                                                                    <Form.Group>
                                                                        <Form.Label><strong>Is the amount PAID?</strong></Form.Label>
                                                                        <Form.Control as="select" value={isPaid} onChange={(e) => setIsPaid(e.target.value)}>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        }

                                                    </Form>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col className="mt-3">
                                                <Button variant='outline-primary' className='mr-2' onClick={handleBack} disabled={hotels.length == 0}>Back</Button>
                                                <Button onClick={() => handleBookTaxi()} disabled={hotels.length == 0}>Submit</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='6' className='p-1' style={{ position: 'relative' }}>
                                <div style={{ height: '100%', minHeight: '400px' }}>
                                    <DriversTrackingMap height='100%' />
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
        </section>
    </>
}

export default AdminBookTaxiPage
