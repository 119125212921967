import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap';
import LinkTD from '../common/linkTd';
import { getAllHotelsPreviousEarnings, getCommissionsPaymentsDetails } from '../../services/reserverCommissions';
import CommissionPaymentModal from '../modals/commissionPayment';
import PageLoader from '../common/pageLoader';

const ReserverPayableCommission = (props) => {

    const { _id } = props.match.params

    const [commissionPaymentModal, setCommissionPaymentModal] = useState(false)
    const [commission, setCommission] = useState()
    const [bookingsCommissions, setBookingsCommissions] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getCommissionsPaymentsDetails(_id)
        setIsLoading(false)
        if (code === 200) {
            setBookingsCommissions(message)
            setCommission(message.main_reserver_commission_info)
        } else {
            // Toast
        }
    }

    const paymentUpdated = (obj) => {
        let _commission = commission
        _commission.payment_date = obj.payment_date
        _commission.paid_amount = obj.paid_amount
        _commission.is_paid = true
        setCommission(_commission)
    }

    return (
        <>
            {isLoading && <PageLoader />}
            {
                commission &&
                <>
                    <CommissionPaymentModal
                        display={commissionPaymentModal}
                        toggle={setCommissionPaymentModal}
                        commission={commission}
                        paymentUpdated={paymentUpdated} />


                    <Row className="mb-3 justify-content-center">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h3 className="text-center">Commission Info</h3>
                                    <hr />
                                    {commission &&
                                        <>
                                            <div>
                                                <h4 className="mb-0 color-primary">{commission.hotel_info.hotel_name}</h4>
                                                <p className="mb-0">{commission.hotel_info.address.street} {commission.hotel_info.address.city} {commission.hotel_info.address.zip} {commission.hotel_info.address.country}</p>
                                                {commission.is_paid && <Badge variant="primary">PAID</Badge>}
                                                <p className="mb-1 mt-3"><strong>Hotel Personnel Info:</strong></p>
                                                <p className="mb-0">Name: {commission.user_info.first_name || commission.user_info.last_name ?
                                                    commission.user_info.first_name + " " + (commission.user_info.last_name ? commission.user_info.last_name : "") :
                                                    "No Name"}</p>
                                                <p className="mb-0">Email: {commission.user_info.email}</p>
                                                <p className="mb-0">Phone: {commission.user_info.phone}</p>
                                                <p className="mb-0">Bank Account: {commission.user_info.bank_account}</p>
                                                <p className="mb-0">IBAN: {commission.user_info.iban}</p>
                                                <p className="mb-0">Address: {commission.user_info.address?.street} {commission.user_info.address?.city} {commission.user_info.address?.zip} {commission.user_info.address?.country}</p>
                                            </div>
                                            {
                                                !commission.is_paid &&
                                                <>
                                                    <hr />
                                                    <div className="text-center">
                                                        <Button onClick={() => setCommissionPaymentModal(true)}>Update As Paid</Button>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            }

            <Row>
                <Col lg="6">
                    <Card className='bookings-list'>
                        <Card.Body>
                            <Card.Title>Shuttle Commissions</Card.Title>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Guest</th>
                                        <th>Booked By</th>
                                        <th>Seats</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(bookingsCommissions.shuttle_commissions && bookingsCommissions.shuttle_commissions.length > 0) ?
                                        <>
                                            {bookingsCommissions.shuttle_commissions.map((commission, index) => (
                                                <tr key={`shuttlebookingcommission-${index}`}>
                                                    <LinkTD to={`/booking/${commission.booking_id}`}>{Moment.utc(commission.commission_date).format('DD.MM.YYYY')}</LinkTD>
                                                    <LinkTD to={`/booking/${commission.booking_id}`}>{commission.amount.toFixed(2)} CHF</LinkTD>
                                                    <LinkTD to={`/booking/${commission.booking_id}`}>{commission.booking_info.customer.first_name + " " + commission.booking_info.customer.last_name}</LinkTD>
                                                    <LinkTD to={`/booking/${commission.booking_id}`}>{commission.booking_info.booking_source_username}</LinkTD>
                                                    <LinkTD to={`/booking/${commission.booking_id}`}>{commission.booking_info.number_of_persons}</LinkTD>
                                                </tr>
                                            ))
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan="3"><i>no records found</i></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card className='bookings-list'>
                        <Card.Body>
                            <Card.Title>Taxi Commissions</Card.Title>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Guest</th>
                                        <th>Booked By</th>
                                        <th>Seats</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookingsCommissions.taxi_commissions && bookingsCommissions.taxi_commissions.length > 0 ?
                                        <>
                                            {bookingsCommissions.taxi_commissions.map((commission, index) => (
                                                <tr key={`taxibookingcommission-${index}`}>
                                                    <LinkTD to={`/taxi-booking/${commission.booking_id}`}>{Moment(commission.commission_date).format('DD.MM.YYYY')}</LinkTD>
                                                    <LinkTD to={`/taxi-booking/${commission.booking_id}`}>{commission.amount.toFixed(2)} CHF</LinkTD>
                                                    <LinkTD to={`/taxi-booking/${commission.booking_id}`}>{commission.booking_info.customer.first_name + " " + commission.booking_info.customer.last_name}</LinkTD>
                                                    <LinkTD to={`/taxi-booking/${commission.booking_id}`}>{commission.booking_info.booking_source_username}</LinkTD>
                                                    <LinkTD to={`/taxi-booking/${commission.booking_id}`}>{commission.booking_info.number_of_persons}</LinkTD>
                                                </tr>
                                            ))
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan="3"><i>no records found</i></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ReserverPayableCommission;