import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormControl, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { combineDateAndTime } from '../../utils/utils';
import { searchAirlineCrewBookingPublic } from '../../services/airlineCrewBookings';
import { toast } from 'react-toastify';
import EditAirlineCrewBookingModalPublic from './editAirlineCrewBooking';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PublicSearchAircrewBookingPage = () => {

    const params = new URLSearchParams(window.location.search)
    const airlineCrewId = params.get('airlineCrewId')

    const [editAirlineCrewBookingModal, setEditAirlineCrewBookingModal] = useState(false)

    const [isSearching, setIsSearching] = useState(false)

    const [pickupDate, setPickupDate] = useState('')
    const [pickupTime, setPickupTime] = useState('')
    const [flightNumber, setFlightNumber] = useState('')

    const [bookings, setBookings] = useState([])
    const [selectedBooking, setSelectedBooking] = useState()

    let history = useHistory()

    useEffect(() => {
        !airlineCrewId && history.push('/public/aircrew')
    }, [airlineCrewId, history])

    const handleSearch = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        searchAirCrewBooking()
    }

    const searchAirCrewBooking = async () => {

        let _pickupTime = combineDateAndTime(pickupDate, Moment(pickupTime).format('HH:mm'))

        setIsSearching(true)
        const { data: { code, message } } = await searchAirlineCrewBookingPublic(_pickupTime, flightNumber, airlineCrewId, localStorage.getItem('simul-public-key'))
        setIsSearching(false)

        if (code !== 200) {
            toast.error(`Error! ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            setBookings(message)
        }
    }

    const handleEditAirCrewBookingModal = (booking) => {
        if (new Date(booking.pickup_time) > new Date()) {
            setSelectedBooking(booking)
            setEditAirlineCrewBookingModal(true)
        } else {
            toast.error(` Cannot edit booking past current time.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <>
            <EditAirlineCrewBookingModalPublic display={editAirlineCrewBookingModal} toggle={setEditAirlineCrewBookingModal} selectedBooking={selectedBooking} onSuccess={searchAirCrewBooking} />

            <section className="py-3 d-flex justify-content-center">
                <Col>
                    <Row className="justify-content-center">
                        <Col>
                            <Form className="mb-3" onSubmit={handleSearch}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className="mb-3">
                                            <Row className="justify-content-between">
                                                <Col>Search Aircrew Booking to Edit</Col>
                                            </Row>
                                        </Card.Title>
                                        <Row>
                                            <Col lg="3" className="mb-2">
                                                <Form.Label>Pickup Date <span className="text-danger">*</span></Form.Label>
                                                <div className="react-datepicker-flex1">
                                                        <DatePicker required
                                                            className="form-control"
                                                            selected={pickupDate}
                                                            onChange={date => setPickupDate(date)}
                                                            dateFormat="dd.MM.yyyy"
                                                        />
                                                    </div>
                                            </Col>
                                            <Col lg="3" className="mb-2">
                                                <Form.Label>Pickup Time <span className="text-danger">*</span></Form.Label>
                                                <div className="react-datepicker-flex1">
                                                    <DatePicker required
                                                        className="form-control"
                                                        selected={pickupTime}
                                                        onChange={date => setPickupTime(date)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <Form.Label>Flight Number</Form.Label>
                                                <div className="d-flex">
                                                    <div className="mr-2 react-datepicker-flex1">
                                                        <FormControl
                                                            value={flightNumber}
                                                            onChange={(e) => setFlightNumber(e.target.value)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Button type="search">Search</Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </Col>
                    </Row>

                    <Card className='w-100 bookings-list mb-3'>
                        <Card.Header className='text-center bg-primary text-white'>Bookings</Card.Header>
                        <Card.Body>
                            <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th></th>
                                        <th>Pickup Date</th>
                                        <th>Time</th>
                                        <th>Company</th>
                                        <th>Booked By</th>
                                        <th>Pickup</th>
                                        <th>Destination</th>
                                        <th>Flight Arrival #</th>
                                        <th>Flight Arrival Time</th>
                                        <th>Persons</th>
                                        <th>Seat Name</th>
                                        <th>Price</th>
                                        <th>Crew Names</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookings.length > 0 ? bookings.map(booking =>
                                        <tr key={booking._id}>
                                            <td>
                                                <Button size="small" variant="outline-primary" className="py-0" onClick={() => handleEditAirCrewBookingModal(booking)}>edit</Button>
                                            </td>
                                            <td>{Moment(booking.pickup_time).format('DD.MM.YYYY')}</td>
                                            <td>{Moment(booking.pickup_time).format('HH:mm')}</td>
                                            <td>{booking.user_company}</td>
                                            <td>{booking.user}</td>
                                            <td>{booking.origin}</td>
                                            <td>{booking.destination}</td>
                                            <td>{booking.flight_arrival_number}</td>
                                            <td>{booking.flight_arrival_date && Moment(booking.flight_arrival_date).format('HH:mm')}</td>
                                            <td>{booking.number_of_persons}</td>
                                            <td>{booking.seat_name}</td>
                                            <td>{booking.seat_price.toFixed(2)}</td>
                                            <td>
                                                {booking.crew_names.map((crew, index) =>
                                                    <p key={index + ''} className="mb-2" >{crew}</p>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                        :
                                        <tr>
                                            <td colSpan={13}>{!isSearching && <i>No bookings found.</i>}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    <Button size="sm" variant="outline-dark" className="px-3" onClick={() => history.goBack()}> &lt; Back</Button>
                </Col>
            </section>
        </>

    );
};

export default PublicSearchAircrewBookingPage;