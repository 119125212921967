import Moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { generateTaxiInvoiceFinal } from '../../services/invoicesTaxi';
import LinkTD from '../common/linkTd';
import ConfirmationModal from './confirmation';

const TaxiInvoicePreviewModal = ({ 
    display, 
    toggle, 
    invoice,
    }) => {

    let history = useHistory()

    let invoiceData = invoice.invoice

    const [confirmationModal, setConfirmationModal] = useState(false)

    const extractIdsFromSales = (sales) => {
        return sales.map(x => x._id);
    }

    const getItemSaleAmount = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount.toFixed(2) : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount.toFixed(2) : 0;//TODO: we're not sure yet if this is accurate
    }

    const getItemSaleAmountInDecimal = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount: 0;//TODO: we're not sure yet if this is accurate
    }

    const getStaffName = (sale) =>{

        const bookingSourceUser = sale.booking_info.booking_source_user
        if(!bookingSourceUser) return ''

        return `${bookingSourceUser.first_name} ${bookingSourceUser.last_name}`
    }

    const handleGenerateInvoiceFinal = async () => {

        let _invoiceData = invoiceData
        _invoiceData['bookingsale_ids'] = extractIdsFromSales(invoice.sales)  

        const { data: { code, message } } = await generateTaxiInvoiceFinal(_invoiceData)
        if (code == 200) {
          history.push(`/taxi-invoice/${message.invoice._id}`)
        } else {
          toast.error(` ${message}.`, {
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
    }

    const handleFinalInvoiceModal = () => {
        setConfirmationModal(true)
    }

    const isEstablishmentInvoice = (invoice) => {
        return invoice.recipient_type === 'establishment'
    }

    return (
        <>
            <ConfirmationModal
            title="Generate Invoice"
            message="You are about to generate invoice"
            confirmText="Proceed"
            cancelText="Cancel"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleGenerateInvoiceFinal} />

            <Modal
            show={display}
            onHide={() => toggle(false)}
            size="xl"
            backdropClassName="level-2"
            centered
            >
                <Modal.Body>
                    <h3 className="text-center">Invoice Preview</h3>
                    <hr/>

                    <h5 className="mb-1">{invoice.title}</h5>

                    <div className="invoice-general-info mb-4">
                        <p className="mb-0">Invoice No.: <strong>{invoiceData.invoice_human_readable_number}</strong></p>
                        <p className="mb-0">Date Covered: <strong>{Moment(invoiceData.period_from).format('DD.MM.YYYY')} - {Moment(invoiceData.period_to).format('DD.MM.YYYY')}</strong></p>
                        <p className="mb-0">Number of Bookings: <strong>{invoiceData.number_of_sales}</strong></p>
                        <p className="mb-2">Recipient: <strong>{isEstablishmentInvoice(invoiceData) ? 'Hotel' : 'Taxi Driver'}</strong></p>
                    </div>

                    <Table className="mb-5">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Guest Name</th>
                            <th>Paid At Hotel</th>
                            {
                                !isEstablishmentInvoice(invoiceData) &&
                                <th>Paid At Driver</th>
                            }
                            {
                                isEstablishmentInvoice(invoiceData) &&
                                <th>Booked By Staff</th>
                            }
                            <th>Invoice Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.sales.length > 0 && invoice.sales.map((sale, index) => (
                            <tr key={`invoicesale-${index}`}>
                            <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{Moment(sale.completed_time).format('DD.MM.YYYY')}</LinkTD>
                            <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}</LinkTD>
                            {
                                isEstablishmentInvoice(invoiceData)
                                ?
                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getItemSaleAmount(sale)} CHF</LinkTD>
                                :
                                <>
                                    <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.paid_over_counter ? getItemSaleAmount(sale) : 0} CHF</LinkTD>
                                    <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.paid_over_counter ? 0 : getItemSaleAmount(sale)} CHF</LinkTD>
                                </>
                            }
                            {
                                isEstablishmentInvoice(invoiceData) &&
                                // <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.commission_amount.toFixed(2)} CHF</LinkTD>
                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getStaffName(sale)}</LinkTD>
                            }
                            {
                                isEstablishmentInvoice(invoiceData) 
                                ?
                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getItemSaleAmount(sale)} CHF</LinkTD> 
                                :
                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.bookingsource_cashback.toFixed(2)} CHF</LinkTD>
                            }
                            </tr>
                        ))}
                        {/* {
                            isEstablishmentInvoice(invoiceData) &&
                            <tr>
                                <td></td>
                                <td></td> 
                                <td></td> 
                               <td>Total Booked By Staff</td>
                               <td>{invoiceData.commission_amount.toFixed(2)} CHF</td>
                            </tr>
                        } */}
                        {
                            !isEstablishmentInvoice(invoiceData) &&
                            <tr>
                                <td></td>
                                <td></td> 
                                <td></td> 
                                <td>Less Amount Paid At Hotel</td>
                                <td>{invoice.sales.reduce((n, s) => n+ (s.paid_over_counter ? getItemSaleAmountInDecimal(s) : 0), 0).toFixed(2)} CHF</td>
                                <td></td>
                            </tr>
                        }
                        <tr>
                            {
                                isEstablishmentInvoice(invoiceData)
                                ?
                                <>
                                    <td></td>
                                    <td></td> 
                                    <td></td> 
                                </>
                                :
                                <>
                                    <td style={{borderTop:'0px'}}></td>
                                    <td style={{borderTop:'0px'}}></td> 
                                    <td style={{borderTop:'0px'}}></td> 
                                </>
                            }
                            <td>SubTotal</td>
                            <td>{invoiceData.sub_amount.toFixed(2)} CHF</td>
                        </tr>
                        {
                            invoiceData.others && invoiceData.others.monthly_charge &&
                            <tr>
                               <td style={{borderTop:'0px'}}></td>
                               <td style={{borderTop:'0px'}}></td> 
                               <td style={{borderTop:'0px'}}></td> 
                               <td>Monthly Charge</td>
                               <td>{invoiceData.others.monthly_charge.toFixed(2)} CHF</td>
                            </tr>
                        }
                        <tr>
                            <td style={{borderTop:'0px'}}></td>
                            <td style={{borderTop:'0px'}}></td> 
                            <td style={{borderTop:'0px'}}></td> 
                            <td>Total </td>
                            <td><strong style={{fontSize:'20px'}}>{invoiceData.amount.toFixed(2)} CHF</strong></td>
                        </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleFinalInvoiceModal()}>Generate</Button>
                    <Button variant="secondary" onClick={() => toggle(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TaxiInvoicePreviewModal

