import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import  NameWithRole  from '../common/nameWithRole';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCurrentUser } from '../../services/auth';
import { getAirlineById } from '../../services/airlines';
import { getUnconfirmedBookingsCount } from '../../services/shuttleBookings'

const SidebarAirlineAdmin = ({setAirlineData}) => {

    const [airline, setAirline] = useState()
    const [unconfirmedBookingsCount,setUnconfirmedBookingsCount] = useState(0)

    const _currentuser = getCurrentUser()

    useEffect(() => {
        fetchAirline()
        fetchUnconfirmedAirlineBookingsCount()
    }, [])

    const fetchAirline = async () => {
        const { data: { message } } = await getAirlineById(_currentuser.airline_id);
        setAirline(message);
        setAirlineData(message);
    }

    const fetchUnconfirmedAirlineBookingsCount = async()=>{
        const { data: {code, message}} = await getUnconfirmedBookingsCount();
        if(code === 200) setUnconfirmedBookingsCount(message)
    }

    return ( 
        <>
        {airline && airline.hasOwnProperty('logo') ? 
        <div className="mx-3">
            <img id="airline-logo" src={airline?.logo} className="w-100" alt="airline-logo" />
        </div>
        :
        <h1 className="px-2 text-center">{airline?.name}</h1>
        }
        <NameWithRole/>
        <ul className='nav flex-column'>
            <LinkContainer to='/dashboard'>
                <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
            </LinkContainer>
            <LinkContainer to='/bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Shuttle Bookings</a></li>
            </LinkContainer>
            {
                unconfirmedBookingsCount > 0 ?
                <LinkContainer to='/unconfirmed-bookings'>
                    <li><a className='nav-link'><span className="badge badge-danger">{unconfirmedBookingsCount}</span> Unconfirmed</a></li>
                </LinkContainer>
                :
                <LinkContainer to='/unconfirmed-bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Unconfirmed</a></li>
                </LinkContainer>
            }
            <LinkContainer to='/shuttle-invoices'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Invoices</a></li>
            </LinkContainer>
        </ul>
        </>
     );
}

 
export default SidebarAirlineAdmin;
