import { PDFDownloadLink } from '@react-pdf/renderer';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import ConfirmationModal from '../components/modals/confirmation';
import BookingTaxiPDF from '../components/pdf/bookingTaxi';
import TaxiBookingPosTicketPdf from '../components/pdf/taxiBookingPosTicketPdf';
import BookingTaxiPrint from '../components/print/bookingTaxi';
import { getCarCategories } from '../services/carCategories';
import { getTaxiBooking, printTaxiTicket } from '../services/taxiBookings';
import { isSimulAccount } from '../utils/utils';
import { toast } from 'react-toastify';

const BookingTaxiPage = (props) => {

    const [booking, setBooking] = useState()
    const [carCategories, setCarCategories] = useState()

    const componentRef = useRef();
    const { _id } = props.match.params

    const [printTicketConfirmation, setPrintTicketConfirmation] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        await fetchCarCategories()

        const { data: { code, message } } = await getTaxiBooking(_id)
        if (code == 200) {
            setBooking(message)
        } else {
            //TODO: toast
        }
    }

    const fetchCarCategories = async () => {

        const { data: { code, message } } = await getCarCategories()
        if (code == 200) {
            setCarCategories(message)
        } else {
            //TODO: toast
        }
    }

    const getCarCategoryName = (id) => {
        const carCategory = carCategories.find(x => x._id === id)
        return carCategory?.name ?? 'Not Found'
    }

    const getDestination = (booking) => {
        if (!booking.has_destination) {
            return 'OPEN';
        }

        if (booking.destination.is_free_input) {
            return booking.destination.free_input_address;
        }

        return booking.destination.fixed_destination_name;
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const yesNoIcon = (value) => {
        if (value)
            return <><i className="fas fa-check-circle text-success"></i> Yes </>
        else
            return <><i className="fas fa-times-circle text-danger"></i> No </>
    }

    const getFormattedAddress = (address) => {
        const _address = []

        if (address.formatted_address) {
            return address.formatted_address
        } else {
            if (address.street) _address.push(address.street)
            if (address.city) _address.push(address.city)
            if (address.zip) _address.push(address.zip)
            if (address.country) _address.push(address.country)
            return _address.join(', ')
        }

    }

    const handlePrint2 = async () => {

        if (!isSimulAccount()) {
            const { data: { code, message } } = await printTaxiTicket(_id)
            if (code !== 200) {
                toast.error(` ${message}.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else setBooking(message)
        }

        setTimeout(() => {
            document.querySelector('#pdf-frame').contentWindow.print();
        }, 500)
    }

    const setPdfFrameForPrint = (blob) => {
        var objectURL = URL.createObjectURL(blob);
        document.querySelector('#pdf-frame').src = '';
        document.querySelector('#pdf-frame').src = objectURL;
        objectURL = URL.revokeObjectURL(blob);
        // return "Download PDF"
        return ""
    }

    const handlePrintTicketConfirmation = () => {
        if (!isSimulAccount())
            if (booking.is_ticket_printed) {
                toast.warning(` Ticket has already been printed.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                setPrintTicketConfirmation(true)
            }
        else {
            handlePrint2()
        }
    }

    return <>
        {booking &&
            <>
                <ConfirmationModal
                    title="Print Ticket"
                    message="Ticket can only be printed once. You won't be able to print the ticket after this."
                    confirmText="Proceed"
                    cancelText="Cancel"
                    display={printTicketConfirmation} toggle={setPrintTicketConfirmation} confirmAction={() => handlePrint2()} />

                <iframe title='pos-ticket' style={{ position: 'absolute', top: '-9999999px' }} id="pdf-frame"></iframe>
                <Card className="mb-4">
                    <Card.Body>
                        <div style={{ position: 'absolute', top: '-9999999px' }} >
                            <BookingTaxiPrint ref={componentRef} booking={booking} getDestination={getDestination} />
                        </div>
                        <Row className="mb-3">
                            <Col lg="7" className="px-0 mx-n2">

                                <div className="px-4">
                                    <div className="customer-details d-flex mb-4">
                                        <img src={booking.qrcode} className="float-left qr-image" />
                                        <div className="float-left p-2">
                                            <p className="mb-0"><strong>CUSTOMER</strong></p>
                                            <h2 className="mb-0">{`${booking.customer.first_name} ${booking.customer.last_name}`}</h2>
                                            <h5>Room #: {booking.customer.booking_source_number}</h5>
                                            {booking.isCancelled &&
                                                <p><Badge variant="danger">Cancelled</Badge></p>
                                            }
                                        </div>
                                    </div>

                                    <div className="booking-details">
                                        <p className="mb-3"><strong>BOOKING DETAILS</strong></p>

                                        <Table className="booking-table" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="booking-table-label">Created on</td>
                                                    <td>{Moment(booking.created_date).format('DD.MM.YYYY')} @ {Moment(booking.created_date).format('HH:mm')}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Booked on</td>
                                                    <td>{Moment(booking.booking_date).format('DD.MM.YYYY')} @ {Moment(booking.booking_date).format('HH:mm')}</td>
                                                </tr>
                                                {
                                                    booking.fulfilled &&
                                                    <tr>
                                                        <td className="booking-table-label">Fulfilled on</td>
                                                        <td>{Moment(booking.fulfilled_date).format('DD.MM.YYYY')} @ {Moment(booking.fulfilled_date).format('HH:mm')}</td>
                                                    </tr>
                                                }
                                                {booking.number_of_persons &&
                                                    <tr>
                                                        <td className="booking-table-label">Number of persons</td>
                                                        <td>{booking.number_of_persons}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td className="booking-table-label">Origin</td>
                                                    <td>
                                                        <span>{booking.origin}</span>
                                                    </td>
                                                </tr>
                                                {booking.pickup_address_free_input &&
                                                    <tr>
                                                        <td className="booking-table-label">Pickup Address</td>
                                                        <td>
                                                            <span>{getFormattedAddress(booking.pickup_address_free_input)}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {booking.has_destination &&
                                                    <>
                                                        <tr>
                                                            <td className="booking-table-label">Destination</td>
                                                            <td>
                                                                <span>{getDestination(booking)}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="booking-table-label">Car Category</td>
                                                            <td>
                                                                {booking.destination.is_free_input ?
                                                                    <>
                                                                        {booking.destination.free_input_car_category_id ?
                                                                            <span>{getCarCategoryName(booking.destination.free_input_car_category_id)}</span>
                                                                            :
                                                                            <span>Nothing matched</span>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {booking.destination.fixed_destination_car_category_id ?
                                                                            <span>{getCarCategoryName(booking.destination.fixed_destination_car_category_id)}</span>
                                                                            :
                                                                            <span>Nothing matched</span>
                                                                        }
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                                <tr>
                                                    <td className="booking-table-label">Source Type</td>
                                                    <td>{booking.booking_source_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Walk-In <i className="fas fa-walking color-primary"></i></td>
                                                    <td>{booking.is_walk_in ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Confirmed</td>
                                                    <td>{booking.is_confirmed ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Fullfilled</td>
                                                    <td>{booking.fulfilled ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                </tr>
                                                {booking.payment_info &&
                                                    <tr>
                                                        <td className="booking-table-label">Paid</td>
                                                        <td>{booking.is_paid ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td className="booking-table-label">Charge to Hotel</td>
                                                    <td>{booking.charge_to_hotel ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Notes</td>
                                                    <td>{booking.notes ? booking.notes : <i>none</i>}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PDFDownloadLink id="download-pdf" className="btn btn-primary mr-2" document={<BookingTaxiPDF booking={booking} getDestination={getDestination} />} fileName="booking-taxi.pdf">
                                    {({ blob, url, loading, error }) => (loading ? ' Loading document...' : ' Download PDF')}
                                </PDFDownloadLink>
                                <Button variant="secondary" onClick={handlePrint}>Print Booking</Button>
                                <Button variant="secondary" className="ml-2" onClick={handlePrintTicketConfirmation}>Print Ticket</Button>
                                <PDFDownloadLink id="download-pdf-2"
                                    document={<TaxiBookingPosTicketPdf booking={booking} />} fileName="booking.pdf">
                                    {({ blob, url, loading, error }) => (loading ? '' : setPdfFrameForPrint(blob))}
                                </PDFDownloadLink>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </>
        }
    </>
}

export default BookingTaxiPage