import React, { useEffect } from 'react';
import { Card, Table, Dropdown, Button } from 'react-bootstrap';
import { deleteAllTime, deleteTime } from '../../services/shuttleDestinations';
import EditTimetableModal from '../modals/editTimetable';
import AddTimeTimetableModal from '../modals/timetableAddTime';
import { useState } from 'react';
import TimetableVehiclesModal from '../modals/timetableVehicles';
import ConfirmationModal from '../modals/confirmation';
import { toast } from 'react-toastify'

const TimetableList = ({
    hotels,
    vehicles,
    timetable, 
    timeAdded, 
    timeRemoved, 
    allTimeRemoved, 
    timeEdited, 
    timeSeatPriceEdited,
    timeSeatPriceListEdited,
    timeVehicleRemoved,
    timeVehicleAdded}) => {

    const [confirmDeleteAll, setConfirmDeleteAll] = useState(false)
    const [confirmDeleteOne, setConfirmDeleteOne] = useState(false)

    const [editTimetableModal, setEditTimetableModal] = useState(false)
    const [addTimeTimetableModal, setAddTimeTimetableModal] = useState(false)
    const [timetableVehiclesModal, setTimetableVehiclesModal] = useState(false)
    const [timetableTimeToEdit, setTimetableTimeToEdit] = useState()

    const [selectedTimetableTime, setSelectedTimetableTime] = useState()

    const [candidateTimeIdToBeDeleted, setCandidateTimeIdToBeDeleted] = useState();

    const [establishmentsPricesToAdd, setEstablishmentsPricesToAdd] = useState([])
    const [establishmentsPricesToAddDisplay, setEstablishmentsPricesToAddDisplay] = useState([])

    const handleEditTimetable = (time) => {
        setTimetableTimeToEdit(time)
        setEstablishmentsPricesToAdd([...time.seat_price_list])
        setEstablishmentsPricesToAddDisplay([...time.seat_price_list])
        setEditTimetableModal(true)
    }

    const handleAddTimeTimetable = () => {
        setAddTimeTimetableModal(true)
    }

    const handleTimetableVehiclesModal = (time) => {
        setSelectedTimetableTime(time)
        setTimetableVehiclesModal(true)
    }

    const handleTryRemoveTime = async (timeId) => {

        setConfirmDeleteOne(true)
        setCandidateTimeIdToBeDeleted(timeId)
    }

    const handleRemoveTime = async () => {
        const {data: {code, message}} = await deleteTime(timetable._id, candidateTimeIdToBeDeleted)
        if (code !== 200) {
            toast.error(`An error occured. Unable to remove time. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            timeRemoved(timetable, candidateTimeIdToBeDeleted)
        }
    }

    const handleDeleteAllTime = async () => {
        const {data: {code, message}} = await deleteAllTime(timetable._id)
        if (code !== 200) {
            toast.error(`An error occured. Unable to remove time. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            allTimeRemoved(timetable)
        }
    }

    const getSortedTimetable = () => {
        return timetable.timetable.sort((a, b) => parseInt((a.time).replace(':','')) - parseInt((b.time).replace(':','')));
    }

    const getEstablishmentName = (id) => {
        return hotels.find(x => x._id === id)?.hotel_name ?? "Unknown Hotel"
    }

    const getVehicleName = (id) => {
        return vehicles.find(x => x._id === id).name
    }

    const isAvailable = (id) => {
        return vehicles.find(x => x._id === id).available
    }

    return ( 
        <>
            {timetable &&
            <>
                {editTimetableModal &&
                    <EditTimetableModal display={editTimetableModal} 
                    hotels={hotels}
                    vehicles={vehicles}
                    toggle={setEditTimetableModal} 
                    timetable={timetable} 
                    timetableTime={timetableTimeToEdit}
                    timeEdited={timeEdited} 
                    timeSeatPriceEdited={timeSeatPriceEdited}
                    timeSeatPriceListEdited={timeSeatPriceListEdited}
                    timeVehicleRemoved={timeVehicleRemoved}
                    timeVehicleAdded={timeVehicleAdded}
                    establishmentsPricesToAdd={establishmentsPricesToAdd}
                    setEstablishmentsPricesToAdd={setEstablishmentsPricesToAdd}
                    establishmentsPricesToAddDisplay={establishmentsPricesToAddDisplay}
                    setEstablishmentsPricesToAddDisplay={setEstablishmentsPricesToAddDisplay}
                    getEstablishmentName={getEstablishmentName}
                     />
                }
                {addTimeTimetableModal &&
                    <AddTimeTimetableModal hotels={hotels} display={addTimeTimetableModal} timeAdded={timeAdded} toggle={setAddTimeTimetableModal} timetableTimes={getSortedTimetable()} timetable={timetable} vehicles={vehicles}/>
                }
                {timetableVehiclesModal &&
                    <TimetableVehiclesModal display={timetableVehiclesModal} toggle={setTimetableVehiclesModal} timetableTime={selectedTimetableTime} />
                }

                <ConfirmationModal 
                title="Delete all time"
                message="Are you sure you want to delete all time in this timetable?"
                display={confirmDeleteAll} toggle={setConfirmDeleteAll} confirmAction={handleDeleteAllTime} />

                <ConfirmationModal 
                title="Delete time"
                message="Are you sure you want to delete time in this timetable?"
                display={confirmDeleteOne} toggle={setConfirmDeleteOne} confirmAction={handleRemoveTime} />

                <Card className="mb-5">
                    <Card.Body>
                        <Card.Title>
                            {timetable.origin} <i className=" fas fa-arrow-circle-right"></i> {timetable.destination ? timetable.destination : 'Multiple Establishments'}

                            <Dropdown className="more-options">
                                <Dropdown.Toggle id="dropdown-custom-components" variant="transparent color-primary">
                                    <i className=" fas fa-ellipsis-h"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleAddTimeTimetable()}><i className="fas fa-plus mr-3"></i> Add Time</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setConfirmDeleteAll(true)}><i className="fas fa-trash-alt mr-3"></i> Delete All</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            
                        </Card.Title>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Seat Price{timetable.is_common_origin ? 's' : ''}</th>
                                    <th>Vehicles</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getSortedTimetable().length > 0 && getSortedTimetable().map((time, index) => (
                                    <tr key={`time-${index}`}>
                                        <td>{time.time}</td>
                                        {timetable.is_common_origin ?
                                        <td>
                                            {time.seat_price_list && time.seat_price_list.map((estPrices, index) => (
                                                <p className="mb-0" key={`est-price-${index}`}>{getEstablishmentName(estPrices.establishment_id)} - {time.seat_price_currency} {estPrices.amount}</p>
                                            ))}
                                        </td>
                                        :
                                        <td>{time.seat_price_currency} {time.seat_price}</td>
                                        }
                                        <td>
                                            {vehicles && time.vehicle_ids.map((id, index) => (
                                                <Button key={`vehicle-${index}`} size="sm" variant={isAvailable(id) ? "outline-primary" : "outline-secondary"} className="btn-rounded mr-2 mb-2 no-pointer-events">
                                                    {getVehicleName(id)}
                                                </Button>
                                            ))}
                                        </td>
                                        <td>
                                            <span className="text-muted cursor-pointer mr-2" title="edit" onClick={() => handleEditTimetable(time)}><i className="fas fa-pencil-alt"></i></span>
                                            <span className="text-muted cursor-pointer" title="delete" onClick={() => handleTryRemoveTime(time._id)}><i className="fas fa-trash-alt"></i></span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
            }
        </>
     );
}
 
export default TimetableList;