import React, { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { deleteUser } from '../../services/users'
import { toast } from 'react-toastify'
import ConfirmationModal from '../modals/confirmation'
import UpdateAccountModal from '../modals/updateAccount'
import PageLoader from '../common/pageLoader'

const ShuttleAdminList = ({shuttleAdmins, setShuttleAdmins, isTableLoading, isLoading, setIsLoading}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [user, setUser] = useState()

    const handleRemoveUser = async (id) => {
        setIsLoading(true)
        const {data: {code, message}} = await deleteUser(id)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this user. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            userRemoved()
        }
    }

    const accountUpdated = (account) => {
        const userIndex = shuttleAdmins.indexOf(user)
        let _shuttleAdmins = shuttleAdmins
        if(account.new_password) _shuttleAdmins[userIndex].password = account.new_password
        if(account.first_name) _shuttleAdmins[userIndex]['info'].first_name = account.first_name
        if(account.last_name) _shuttleAdmins[userIndex]['info'].last_name = account.last_name
        setShuttleAdmins(_shuttleAdmins)
    }

    const handleChangePasswordModal = async (user) => {
        setUser(user)
        setChangePasswordModal(true)
    }

    const userRemoved = () => {
        const userIndex = shuttleAdmins.indexOf(user)
        let _shuttleAdmins = shuttleAdmins
        _shuttleAdmins.splice(userIndex, 1)
        setShuttleAdmins([..._shuttleAdmins])
    }

    const handleConfirmationModal = (user) => {
        setUser(user)
        setConfirmationModal(true)
    }

    return <>

        {isLoading && <PageLoader />}

        {user && 
        <UpdateAccountModal display={changePasswordModal} toggle={setChangePasswordModal} user={user} accountUpdated={accountUpdated} setIsLoading={setIsLoading} />
        }

        <ConfirmationModal
            title="Remove Shuttle Admin"
            message="Are you sure you want to delete this user?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleRemoveUser(user._id)} />

        <Card className='w-100'  >
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Shuttle Admins</Card.Header>
            <Card.Body>
                {!isTableLoading ?
                <Table striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Password</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            shuttleAdmins.length > 0 && 
                            shuttleAdmins.map((shuttleAdmin, index) =>
                                <tr key={`hotelstaff-${index}`}>
                                    <td>{shuttleAdmin.username}</td>
                                    <td>{shuttleAdmin.password}</td>
                                    <td>{shuttleAdmin?.info?.first_name}</td>
                                    <td>{shuttleAdmin?.info?.last_name}</td>
                                    <td className="actions text-center">
                                        <i title="update account" className="fas fa-user-edit mr-2" onClick={() => handleChangePasswordModal(shuttleAdmin)}></i>
                                        <i title="delete user" className="fas fa-trash-alt" onClick={() => handleConfirmationModal(shuttleAdmin)}></i>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                :
                <PageLoader position="relative" backgroundColor="#f2ecec" />
                }
            </Card.Body>
        </Card>
    </>
}


export default ShuttleAdminList
