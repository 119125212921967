import React, { useState } from 'react'
import { Card, Modal, Button, Form, Col, Row } from 'react-bootstrap'

const CarCategoriesModal = ({ display, toggle, carCategories, setCarCategoriesToAdd, carCategoriesToAdd }) => {

    const [_carCategoriesToAdd, _setCarCategoriesToAdd] = useState([])
    const [error, setError] = useState('')

    const carCategoryIdAlreadySelected = (carCategoryId) => {
       return _carCategoriesToAdd.some(x => x.car_category_id === carCategoryId) ? true : false
    }

    const handleSetCarCategoryId = (carCategoryId, index) => {

        setError('')
        if (carCategoryIdAlreadySelected(carCategoryId)) {
            setError('Car category id already selected')
            return
        }

        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd[index]["car_category_id"] = carCategoryId 
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }

    const handleSetMake = (make, index) => {
        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd[index]["make"] = make 
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }

    const handleSetModel = (model, index) => {
        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd[index]["model"] = model 
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }

    const handleSetYear = (year, index) => {
        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd[index]["model_year"] = year 
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }

    const addInput = () => {
        setError('')
        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd.push({
            "car_category_id": "",
            "make": "",
            "model": "",
            "model_year": ""
        })
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }
    
    const removeInput = (index) => {
        setError('')
        let __carCategoriesToAdd = _carCategoriesToAdd
        __carCategoriesToAdd.splice(index, 1)
        _setCarCategoriesToAdd([...__carCategoriesToAdd])
    }

    const saveCarCategoriesToAdd = () => {
        setError('')
        setCarCategoriesToAdd([..._carCategoriesToAdd])
        toggle(false)
    }
    
    const handleModalOnEnter = () => {
        if(carCategoriesToAdd)
        _setCarCategoriesToAdd(carCategoriesToAdd)
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        onEnter={() => handleModalOnEnter()}
        backdropClassName="level-2"
        size="lg"
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">Car Categories</Card.Title>
                <div className="cities mb-3">
                <p className="text-danger text-center"><small>{error}</small></p>
                {_carCategoriesToAdd && _carCategoriesToAdd.map((carCategory, index) => (
                    <Row key={`carcategory-${index}`} className="mb-2">
                        <Col xs="11">
                            <Row>
                                <Col xs="3">
                                    <Form.Control as="select" value={carCategory.car_category_id} onChange={(e) => handleSetCarCategoryId(e.target.value, index)}>
                                        <option value="" disabled>Car Category</option>
                                        {carCategories && carCategories.map((_carCategory, index) => (
                                            <option key={`carCategory-${index}`} value={_carCategory._id} >
                                                {_carCategory.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col xs="3">
                                    <Form.Control type="text" value={carCategory.make} onChange={(e) => handleSetMake(e.target.value, index)} placeholder="Make" />
                                </Col>
                                <Col xs="3">
                                    <Form.Control type="text" value={carCategory.model} onChange={(e) => handleSetModel(e.target.value, index)} placeholder="Model" />
                                </Col>
                                <Col xs="3">
                                    <Form.Control type="number" value={carCategory.model_year} onChange={(e) => handleSetYear(e.target.value, index)} placeholder="Year" />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="1">
                            <div className="center-xy">
                                <span onClick={() => removeInput(index)} title="remove" className="cursor-pointer"><i className="fas fa-times"></i></span>
                            </div>
                        </Col>
                    </Row>
                ))}
                </div>
                <Row>
                    <Col>
                        <Button className="px-3" size="sm" onClick={() => addInput()}>Add</Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => saveCarCategoriesToAdd()}>Ok</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default CarCategoriesModal

