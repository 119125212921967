import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCarCategories } from '../../services/carCategories'
import { createTaxiDriver, getTaxiDrivers } from '../../services/taxiAccounts'
import TaxiDriverCreator from './taxiDriverCreator'
import TaxiDriverList from './taxiDriverList'

const TaxiDriverCreation = () => {

    let history = useHistory()

    const [taxiDrivers, setTaxiDrivers] = useState([])
    const [carCategories, setCarCategories] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
        fetchCarCategories()
    }, [])

    const fetch = async () => {
        setIsTableLoading(true)
        const { data: { message } } = await getTaxiDrivers()
        setIsTableLoading(false)
        if(Array.isArray(message))
            setTaxiDrivers(message)
        else {
            setTaxiDrivers([])
            //TODO: toast when getting shuttle admins error
        }
    }

    const fetchCarCategories = async () => {
        const {data: {code, message}} = await getCarCategories()
        if (code == 200) {
            setCarCategories(message)
        } else {
            //TODO: toast
        }
    }

    const handleCreateTaxiDriver = async (payload) => {
        setIsLoading(true)
        const {data :{code,message}} = await createTaxiDriver(payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            var _newTaxiDriver = {}
            var _newTaxiDriverDoc = {
                _id: message['taxi-account-info']._id,
                username: message['user-info'].username,
                password: payload.password
            }
            _newTaxiDriver['_id'] = message['taxi-account-info']._id
            _newTaxiDriver['doc'] = _newTaxiDriverDoc
            _newTaxiDriver.first_name = message['taxi-account-info'].first_name
            _newTaxiDriver.last_name = message['taxi-account-info'].last_name
            _newTaxiDriver.operating_cities = message['taxi-account-info'].operating_cities
            _newTaxiDriver.phone = message['taxi-account-info'].phone
            _newTaxiDriver.license_number = message['taxi-account-info'].license_number
            _newTaxiDriver.simul_invoicing_option = message['taxi-account-info'].simul_invoicing_option
            _newTaxiDriver.simul_invoicing_option_fixed_charge_amount = message['taxi-account-info'].simul_invoicing_option_fixed_charge_amount
            _newTaxiDriver.car_category_id = message['taxi-account-info'].car_category_id
            _newTaxiDriver.car_categories = message['taxi-account-info'].car_categories

            let _taxiDrivers = taxiDrivers
            _taxiDrivers.push(_newTaxiDriver)
            setTaxiDrivers([..._taxiDrivers])
        }
    }

    return (
        <>
        <Row className="mb-4">
            <Col className="text-left">
                <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/accounts')}>{'<'} Back</Button>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col >
                <TaxiDriverCreator createTaxiDriver={handleCreateTaxiDriver} carCategories={carCategories} />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <TaxiDriverList taxiDrivers={taxiDrivers} setTaxiDrivers={setTaxiDrivers} carCategories={carCategories}
                isTableLoading={isTableLoading}
                isLoading={isLoading}
                setIsLoading={setIsLoading} />
            </Col>
        </Row>
        </>
    )
}

export default TaxiDriverCreation