import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap"
import { cancelAirlineCrewBooking, getPaginatedAirlineCrewBookings } from '../../../services/airlineCrewBookings';
import LinkTD from '../../../components/common/linkTd';
import Moment from 'moment';
import Pages from '../../../components/pages';
import PageLoader from '../../../components/common/pageLoader';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/modals/confirmation';
import EditAirlineCrewBookingModal from '../../airlineCrew/editAirlineCrewBooking';

const AirlineCrewBookingsPage = () => {
  const [bookings, setBookings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [pagination, setPagination] = useState()
  const [searchVal, setSearchVal] = useState('')
  const [searchText, setSearchText] = useState()
  const [airlineCrewId, setAirlineCrewId] = useState()
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [editBookingModal, setEditBookingModal] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState()

  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearchText(event.target.value)
    }
  }

  const fetch = useCallback(async () => {
    setIsLoading(true)
    const { data: { code, message } } = await getPaginatedAirlineCrewBookings(airlineCrewId, currentPageNumber, pageSize, searchText)
    setIsLoading(false)
    if (code === 200) {
      setPagination(message)
      setBookings(message.bookings)
    } else {
      toast.error(` ${message}.`, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }, [airlineCrewId, currentPageNumber, pageSize, searchText])

  const handleConfirmationModal = (booking) => {
    setSelectedBooking(booking)
    setConfirmationModal(true)
  }

  const handleCancelBooking = async () => {
    const { data: { code, message } } = await cancelAirlineCrewBooking(selectedBooking._id)

    if (code === 200) {
      setBookings(bookings.filter(b => b._id !== selectedBooking._id))
      toast.success('Successfully cancelled booking.', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else {
      toast.error(` ${message}.`, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }

  const handleEditBookingModal = (booking) => {
    setSelectedBooking(booking)
    setEditBookingModal(true)
  }

  const handleOnEditBooking = (updatedBooking) => {
    setBookings(bookings.map(b =>
      updatedBooking._id === b._id ? { ...b, ...updatedBooking } : b
    ))
  }

  useEffect(() => {
    fetch()
  }, [fetch])


  return <>
    <ConfirmationModal
      title="Cancel Booking"
      message="Are you sure you want to cancel this booking?"
      display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking}
    />

    <EditAirlineCrewBookingModal display={editBookingModal} toggle={setEditBookingModal} selectedBooking={selectedBooking} onSuccess={handleOnEditBooking} />

    {isLoading && <PageLoader />}
    <Card className='w-100 bookings-list'>
      <Card.Header className='text-center bg-primary text-white'>Bookings</Card.Header>
      <Card.Body>

        <Row className="justify-content-center">
          <Col lg="6">
            <InputGroup className="mb-3 rounded-right">
              <InputGroup.Text ><i className="fa fa-search" aria-hidden="true"></i></InputGroup.Text>
              <Form.Control type="search" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} onKeyPress={onKeyUp} placeholder="Search bookings" />
              <div className="input-group-append rounded-right">
                <Button className="rounded-right" onClick={() => setSearchText(searchVal)}>search</Button>
              </div>
            </InputGroup>
          </Col>
          <Col lg="6">
            <div className="d-flex flex-row align-items-center justify-content-end">
              <span className='mr-3'>Page size:</span>
              <Form.Control style={{ width: '80px' }} value={pageSize} onChange={(e) => setPageSize(+e.target.value)} as="select">
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </Form.Control>
            </div>
          </Col>
        </Row>

        <Table responsive hover>
          <thead className="border-top-0">
            <tr>
              <th>Pickup Date</th>
              <th>Time</th>
              <th>Company</th>
              <th>Booked By</th>
              <th>Pickup</th>
              <th>Destination</th>
              <th>Flight Arrival #</th>
              <th>Flight Arrival Time</th>
              <th>Persons</th>
              <th>Seat Name</th>
              <th>Price</th>
              <th>Crew Names</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookings.length > 0 ? bookings.map(booking =>
              <tr key={booking._id}>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{Moment(booking.pickup_time).format('DD.MM.YYYY')}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{Moment(booking.pickup_time).format('HH:mm')}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.user_company}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.user}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.origin}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.destination}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.flight_arrival_number}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.flight_arrival_date && Moment(booking.flight_arrival_date).format('HH:mm')}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.seat_name}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.seat_price.toFixed(2)}</LinkTD>
                <LinkTD to={`/aircrew-booking/${booking._id}`}>
                  {booking.crew_names.map((crew, index) =>
                    <p key={index + ''} className="mb-2" >{crew}</p>
                  )}
                </LinkTD>
                <td>
                  {new Date(booking.pickup_time) > new Date() &&
                    <>
                    <Button size="sm" className="d-block w-100 mb-2" variant="outline-primary" onClick={() => handleEditBookingModal(booking)}>Edit</Button>
                    <Button size="sm" className="d-block w-100" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                    </>
                  }
                </td>
              </tr>
            )
              :
              <tr>
                <td colSpan={4}>{!isLoading && <i>No bookings found.</i>}</td>
              </tr>
            }
          </tbody>
        </Table>

        {pagination?.totalPages > 0 &&
          <div className="mt-3">
            <Pages paginationData={pagination} changePage={setCurrentPageNumber} pageNumber={currentPageNumber} />
          </div>
        }

      </Card.Body>
    </Card>
  </>
}

export default AirlineCrewBookingsPage