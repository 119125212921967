import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { update } from '../../../services/airlineCrews'

const UpdateAirlineCrewModal = ({ display, toggle, airlines, hotels, _allowedAirlines, _allowedHotels, airCrew, airCrewUpdated }) => {

    const [logo, setLogo] = useState()
    const [name, setName] = useState()
    const [allowedAirlines, setAllowedAirlines] = useState(_allowedAirlines)
    const [allowedHotels, setAllowedHotels] = useState(_allowedHotels)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        setAllowedAirlines(_allowedAirlines)
        setAllowedHotels(_allowedHotels)
    }, [_allowedAirlines, _allowedHotels])

    const handleUpdate = async (e) => {
        e.preventDefault()
        setIsUpdating(true)
        var payload = {}
        payload['_id'] = airCrew._id
        if (name) payload['airline_name'] = name
        if (allowedAirlines) payload['allowed_crew_bookings_airlines'] = allowedAirlines.map(x => x.value)
        if (allowedHotels) payload['allowed_crew_bookings_hotels'] = allowedHotels.map(x => x.value)
        if(logo) payload['airline_logo'] = logo

        const { data: { code, message } } = await update(airCrew._id, payload)
        setIsUpdating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            airCrewUpdated(message)
            onClose()
        }
    }

    const handleChangeLogo = () => {
        const file = document.querySelector('#file-upload').files[0];;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            const img = new Image();
    
            img.onload = function () {
                const MAX_SIZE = 150; // Set your desired maximum size
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    if (width > MAX_SIZE) {
                        height *= MAX_SIZE / width;
                        width = MAX_SIZE;
                    }
                } else {
                    if (height > MAX_SIZE) {
                        width *= MAX_SIZE / height;
                        height = MAX_SIZE;
                    }
                }
    
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
    
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                const resizedBase64 = canvas.toDataURL('image/png', 0.8); // Adjust quality as needed
                setLogo(resizedBase64);
            };
    
            img.src = reader.result;
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const onClose = () => {
        setLogo('')
        toggle(false)
    }

    const imgPreview = {
        'maxWidth': '100%',
        'maxHeight': '200px'
    }

    return (
        <Modal
            show={display}
            onHide={() => onClose()}
            centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">Update Air Crew</Card.Title>
                <Form onSubmit={handleUpdate} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='text' defaultValue={airCrew?.airline_name} onChange={e => setName(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Allowed Airlines</Form.Label>
                                <Select defaultValue={_allowedAirlines} isMulti={true} onChange={(value) => setAllowedAirlines(value)} placeholder={"Allowed airlines"}
                                    options={airlines} />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Allowed Hotels</Form.Label>
                                <Select defaultValue={_allowedHotels} isMulti={true} onChange={(value) => setAllowedHotels(value)} placeholder={"Allowed hotels"}
                                    options={hotels} />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <input className="mb-3" id="file-upload" type="file" onChange={() => handleChangeLogo()} /><br />
                            {logo ?
                                <img src={logo} style={imgPreview} alt="Image preview..." />
                                :
                                    airCrew && airCrew.airline_logo ?
                                    <img src={airCrew.airline_logo} style={imgPreview} alt="Image preview..." />
                                    :
                                    <div className="center-xy" style={{ height: '150px', width: '150px', backgroundColor: '#eee' }}><span>LOGO</span></div>
                            }
                        </Col>
                        <Col lg="12" className="text-right">
                            <Button type="submit" disabled={isUpdating} className="mt-2">
                                {isUpdating ? 'Saving...' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateAirlineCrewModal

