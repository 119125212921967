import { addDays, differenceInMinutes } from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useReactToPrint } from 'react-to-print';
import BookShuttleModal from '../../components/modals/bookShuttle';
import PageLoader from '../common/pageLoader'
import { getCurrentUser } from '../../services/auth';
import { getAvailableSeats, getBookings, getBookingsPerHour, getEstablishmentBookings, getWeeklyBookings } from '../../services/shuttleBookings';
import { combineDateAndTime, ignoreOffsetISOString, isAirlineAccount } from '../../utils/utils';
import BookingPrint from '../print/booking';
import ShuttleTimetablePrint from '../print/shuttleTimetable';

const TimetableCalender = ({ destination, hotel, airline, //, seatsPerVehicle
}) => {

    const [bookShuttleModal, setBookShuttleModal] = useState(false)

    const [selectedWeekNumberSource, setSelectedWeekNumberSource] = useState(new Date())
    const [selectedWeekNumber, setSelectedWeekNumber] = useState(Moment().isoWeek())
    const [mondayDate, setMondayDate] = useState(Moment(new Date().setHours(0, 0, 0, 0)).subtract(1, 's').day('Monday').toDate())
    const [selectedDateTime, setSelectedDateTime] = useState(new Date())
    const [selectedTime, setSelectedTime] = useState()
    const [hasBookingsIndicatorForBookingModal, setHasBookingsIndicatorForBookingModal] = useState(false)
    const [timetableTimes, setTimetableTimes] = useState([])
    const [bookings, setBookings] = useState([])
    const [weeklyBookings, setWeeklyBookings] = useState("")
    const [availableSeats, setAvailableSeats] = useState([])
    const [selectedTimeTotalSeats, setSelectedTimeTotalSeats] = useState([])
    const [booking, setBooking] = useState()
    const [newBookingAddedIndicator, setNewBookingAddedIndicator] = useState()
    const [bookingsPerHour, setBookingsPerHour] = useState([])
    const [bookingsRenderMap, setBookingsRenderMap] = useState({})

    const [isLoading, setIsLoading] = useState(false)

    const _currentUser = getCurrentUser()
    const componentRef = useRef();
    const shuttleTimetableRef = useRef();
    registerLocale('en-gb', enGb);

    useEffect(() => {
        fetchTimetableTimes()
        fetchWeeklyBookings()
    }, [])

    useEffect(() => {
        fetchBookings(selectedWeekNumberSource)
    }, [mondayDate, newBookingAddedIndicator])

    useEffect(() => {

        const map = bookingsPerHour.map(x => {
            const date = new Date(x.date.slice(0, -1))
            let bookingHour = date.getHours().toString().padStart(2, '0')
            let bookingMins = date.getMinutes().toString().padStart(2, '0')

            let dayIndex = date.getDay()
            dayIndex = dayIndex ? --dayIndex : 6

            let obj = {
                key: `${bookingHour}:${bookingMins}-${dayIndex}`,
                taken: x.count,
                total: x.total
            };
            return obj
        });

        setBookingsRenderMap(map)
    }, [bookingsPerHour, timetableTimes])

    const fetchTimetableTimes = async () => {
        const sortedTimes = destination.timetable.sort((a, b) => parseInt((a.time).replace(':', '')) - parseInt((b.time).replace(':', '')));
        setTimetableTimes(sortedTimes)
    }

    const fetchBookings = async (selectedDate) => {

        setIsLoading(true)

        const wn = Moment(selectedDate).isoWeek()
        const monday = Moment(selectedDate).week(wn).day(1).toDate()
        const sunday = Moment(selectedDate).week(wn).day(7).toDate()
        monday.setHours(0, 0, 0, 0)
        sunday.setHours(23, 59, 59, 999)

        const { data: { code, message } } = (_currentUser && _currentUser.role_name === 'system-admin') ? await getBookings() : await getEstablishmentBookings(999999, sunday, monday)

        if (code == 200) {
            if (Array.isArray(message))
                setBookings(message.filter(booking => !booking.isCancelled))
            else {
                setBookings([])
                //TODO: toast when getting hotel admins error
            }
        } else {
            setBookings([])
        }

        await fetchBookingsPerHour(selectedWeekNumberSource, wn)
        setIsLoading(false)
    }

    const fetchBookingsPerHour = async (selectedDate, weekNumber) => {

        const monday = Moment(selectedDate).week(weekNumber).day(1).toDate()
        const sunday = Moment(selectedDate).week(weekNumber).day(7).toDate()
        monday.setHours(0, 0, 0, 0)
        sunday.setHours(23, 59, 59, 999)
        const { data: { code, message } } = await getBookingsPerHour(destination._id, ignoreOffsetISOString(monday), ignoreOffsetISOString(sunday))

        if (code === 200) {
            setBookingsPerHour(message)
        }
    }

    const fetchWeeklyBookings = async () => {
        const { data: { code, message } } = await getWeeklyBookings(destination._id)
        if (code == 200) {
            setWeeklyBookings(message);
        }
    }

    const fetchAvailableSeats = async (dateTime) => {

        const _isoDateTime = ignoreOffsetISOString(dateTime)
        const { data: { message: { total, available } } } = await getAvailableSeats(_isoDateTime, destination._id)
        var _availableSeats = []
        for (let index = 1; index <= available; index++) {
            _availableSeats.push(index)
        }
        setSelectedTimeTotalSeats(total)
        setAvailableSeats(_availableSeats)
    }

    const changeWeekNumber = (operation, date = null) => {

        let tempDate = Moment(mondayDate);
        if (operation === 'increment') {
            tempDate = tempDate.add(1, 'w');
        } else if (operation === 'decrement') {
            tempDate = tempDate.subtract(1, 'w');
        } else {
            tempDate = Moment(date.setHours(0, 0, 0, 0))
        }

        setSelectedWeekNumberSource(tempDate.toDate());
        let _weekNumber = tempDate.isoWeek();
        setSelectedWeekNumber(_weekNumber);
        let _mondayOfWeek = Moment(tempDate.subtract(1, 's')).day('Monday').toDate();
        setMondayDate(_mondayOfWeek)
    }

    const handleBookShuttleModal = async (date, timedata) => {

        setIsLoading(true)

        const _dateTime = combineDateAndTime(date, timedata.time)
        setHasBookingsIndicatorForBookingModal(hasBookings(date, timedata.time))
        setSelectedDateTime(_dateTime)
        setSelectedTime(timedata)

        await fetchAvailableSeats(_dateTime)

        setIsLoading(false)

        setBookShuttleModal(true)
    }

    const hasBookings = (date, time) => {
        const _dateTime = combineDateAndTime(date, time)
        const hasBooking = bookings.some((booking => booking.booking_date == ignoreOffsetISOString(_dateTime)))
        return hasBooking;
    }

    // const getBookeableSeats = (time) => {

    //     const timeData = destination.timetable.find(x => x.time === time)
    //     const seats = seatsPerVehicle.filter(x => timeData.vehicle_ids.indexOf(x.id) >= 0)
    //         .reduce((a, b) => a + b.seats, 0)

    //     return seats
    // }

    const isTimePassedCurrentTime = (date, time, _hasBookings) => {

        const dateTimeNow = new Date()
        const minutesDifference = differenceInMinutes(combineDateAndTime(date, time), dateTimeNow)

        let disableCell = true;

        //if no bookings and current time is 10 minutes before departure time
        if (!_hasBookings && minutesDifference <= 10) disableCell = false
        //if there are bookings AND current time is 5 minutes ahead of the departure time
        else if (_hasBookings && minutesDifference <= -5) disableCell = false

        return disableCell
    }

    const addDaysToDate = (date, daysToAdd) => {
        const tempDate = Moment(date).add(daysToAdd, 'days').toDate();
        return tempDate.getDate();
    }

    const renderTableCell = (dayIndex, timedata) => {

        const time = timedata.time;

        const bookingRender = bookingsRenderMap.find(x => x.key === `${time}-${dayIndex}`)

        const mondayOfWeek = Moment(selectedWeekNumberSource).subtract(1, 's').day('Monday').toDate();
        const _date = addDays(mondayDate, dayIndex)

        const bookingtTimeHasNotPassed = isTimePassedCurrentTime(_date, time, bookingRender)

        let tdClass = bookingRender ? 'has-bookings ' : ''
        if (!bookingtTimeHasNotPassed && bookingRender) {
            tdClass = 'has-past-bookings '
        }

        return <>

            <td className={tdClass}
                style={bookingtTimeHasNotPassed ? {} : { background: "rgba(0,0,0,.1)", pointerEvents: "none" }}
                onClick={() => handleBookShuttleModal(_date, timedata)}>
                {bookingRender &&
                    <>
                        <p className="mb-0">Bookings</p>
                        <p className="mb-0">{bookingRender.taken} / {bookingRender.total}</p>
                    </>
                }
            </td>
        </>
    }

    const bookedShuttle = (obj) => {
        setNewBookingAddedIndicator(Date.now())
        setBooking(obj)
        // let _bookings = bookings
        // _bookings.push(obj)
        // setBookings(_bookings)
        // fetchWeeklyBookings()
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleShuttleTimetablePrint = useReactToPrint({
        content: () => shuttleTimetableRef.current,
    });

    return <>

        {isLoading && <PageLoader />}

        {selectedTime &&
            <BookShuttleModal
                display={bookShuttleModal}
                toggle={setBookShuttleModal}
                datetime={selectedDateTime}
                timedata={selectedTime}
                availableSeats={availableSeats}
                totalSeats={selectedTimeTotalSeats}
                shuttleDestination={destination}
                bookedShuttle={bookedShuttle}
                hotel={hotel}
                airline={airline}
                hasBookings={hasBookingsIndicatorForBookingModal}
                printBooking={handlePrint}
                setIsLoading={setIsLoading} />
        }

        {booking &&
            <div className="hide-on-screen">
                <BookingPrint ref={componentRef} booking={booking} destination={destination} />
            </div>
        }

        {timetableTimes &&
            <div className="hide-on-screen">
                <ShuttleTimetablePrint ref={shuttleTimetableRef} timetable={timetableTimes} destination={destination} hotel={hotel} />
            </div>
        }

        <section>
            <Row>
                <Col>
                    <Card className="mb-5">
                        <Card.Body>
                            <Row>
                                <Col lg="8" xl="9">
                                    <Row>
                                        <Col>
                                            {isAirlineAccount() ?
                                                <Card.Title className="text-center">
                                                    <img style={{ maxWidth: '200px', maxHeight: '150px' }} src={airline.logo} title={'origin'} />
                                                    <i className="mx-2 fas fa-arrow-circle-right"></i>
                                                    <img style={{ maxWidth: '200px', maxHeight: '150px' }} src={hotel.hotel_logo} title={'destination'} />
                                                </Card.Title>
                                                :
                                                <Card.Title className="text-center">
                                                    {airline?._id ?
                                                        <img style={{ maxWidth: '200px', maxHeight: '150px' }} src={hotel.hotel_logo} title={'origin'} />
                                                        :
                                                        <span>{destination.origin}</span>
                                                    }
                                                    <i className="mx-2 fas fa-arrow-circle-right"></i>
                                                    {airline?.logo ?
                                                        <img style={{ maxWidth: '200px', maxHeight: '150px' }} src={airline.logo} title={'destination'} />
                                                        :
                                                        <span>{destination.is_common_origin ? hotel.hotel_name : destination.destination}</span>
                                                    }
                                                </Card.Title>
                                            }
                                        </Col>
                                    </Row>
                                    <p className="text-center mt-3">
                                        <strong>
                                            <span className="cursor-pointer mr-2" onClick={() => changeWeekNumber('decrement')}>&lt;</span>
                                            WEEK {selectedWeekNumber}
                                            <span className="cursor-pointer ml-2" onClick={() => changeWeekNumber('increment')}>&gt;</span>
                                        </strong>
                                    </p>
                                    {mondayDate &&

                                        <>
                                            <table className="timetable w-100">
                                                <thead>
                                                    <tr>
                                                        <td className="no-hover"></td>
                                                        <td className="no-hover">Mon {mondayDate.getDate()}</td>
                                                        <td className="no-hover">Tue {addDaysToDate(mondayDate, 1)}</td>
                                                        <td className="no-hover">Wed {addDaysToDate(mondayDate, 2)}</td>
                                                        <td className="no-hover">Thu {addDaysToDate(mondayDate, 3)}</td>
                                                        <td className="no-hover">Fri {addDaysToDate(mondayDate, 4)}</td>
                                                        <td className="no-hover">Sat {addDaysToDate(mondayDate, 5)}</td>
                                                        <td className="no-hover">Sun {addDaysToDate(mondayDate, 6)}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {timetableTimes && timetableTimes.map((timetable, index) => (
                                                        <tr key={`time-${index}`}>

                                                            <>
                                                                <td className="no-hover">{timetable.time}</td>
                                                                {renderTableCell(0, timetable)}
                                                                {renderTableCell(1, timetable)}
                                                                {renderTableCell(2, timetable)}
                                                                {renderTableCell(3, timetable)}
                                                                {renderTableCell(4, timetable)}
                                                                {renderTableCell(5, timetable)}
                                                                {renderTableCell(6, timetable)}
                                                            </>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                </Col>
                                <Col lg="4" xl="3">
                                    <p className="text-right mb-0 mt-2">
                                        <Button onClick={() => handleShuttleTimetablePrint()} className="mr-1">Print Timetable</Button>
                                        <a href="/book-shuttle" className="btn btn-outline-primary mr-2">Back</a>
                                    </p>
                                    <p className="mt-3">&nbsp;</p>
                                    <div className="small-calendar text-center mb-4">
                                        <DatePicker
                                            locale="en-gb"
                                            selected={new Date()}
                                            inline
                                            onChange={(date) => changeWeekNumber('direct', date)}
                                        />
                                    </div>
                                    <h4 className="font-weight-bold text-center">Total bookings this week</h4>
                                    <h1 className="color-primary font-weight-bold text-center">{weeklyBookings}</h1>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </section >
    </>
}

export default TimetableCalender
