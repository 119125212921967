import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import HotelAdminCreation from '../../components/admin/hotelAdminCreation'
import ShuttleAdminCreation from '../../components/admin/shuttleAdminCreation'
import TaxiDriverCreation from '../../components/admin/taxiDriverCreation'
import ShuttleDriverCreation from '../../components/shuttleCompany/shuttleDriverCreation'

const SystemAdminAccountsPage = () => {

    let history = useHistory()

    return <>
        <section>
            <Row>
                <Col md="6" lg="3" className="mb-4">
                    <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/accounts/hotel-admin')}>
                        <Card.Body className="text-center p-4">
                            <span className="fa-stack fa-3x">
                                <i className="far fa-user fa-stack-2x"></i>
                                <i className="fas fa-building fa-stack-1x custom-stack color-primary"></i>
                            </span>
                            <hr />
                            <h4>Hotel Admins</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" lg="3" className="mb-4">
                    <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/accounts/shuttle-admin')}>
                        <Card.Body className="text-center p-4">
                            <span className="fa-stack fa-3x">
                                <i className="far fa-user fa-stack-2x"></i>
                                <i className="fas fa-bus-alt fa-stack-1x custom-stack color-primary"></i>
                            </span>
                            <hr />
                            <h4>Shuttle Admins</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" lg="3" className="mb-4">
                    <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/accounts/shuttle-driver')}>
                        <Card.Body className="text-center p-4">
                            <span className="fa-stack fa-3x">
                                <i className="fas fa-bus-alt fa-stack-2x"></i>
                                <i className="fas fa-user fa-stack-1x custom-stack color-primary"></i>
                            </span>
                            <hr />
                            <h4>Shuttle Drivers</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" lg="3" className="mb-4">
                    <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/accounts/taxi-driver')}>
                        <Card.Body className="text-center p-4">
                            <span className="fa-stack fa-3x">
                                <i className="fas fa-car-alt fa-stack-2x"></i>
                                <i className="fas fa-user fa-stack-1x custom-stack color-primary"></i>
                            </span>
                            <hr />
                            <h4>Taxi Drivers</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" lg="3" className="mb-4">
                    <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/accounts/airline-admin')}>
                        <Card.Body className="text-center p-4">
                            <span className="fa-stack fa-3x">
                                <i className="far fa-user fa-stack-2x"></i>
                                <i className="fas fa-plane-departure fa-stack-1x custom-stack color-primary"></i>
                            </span>
                            <hr />
                            <h4>Airline Admins</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <HotelAdminCreation />
            <hr className="my-5" />
            <ShuttleAdminCreation />
            <hr className="my-5" />
            <ShuttleDriverCreation />
            <hr className="my-5" />
            <TaxiDriverCreation />
            <hr className="my-5" /> */}
        </section>
    </>
}

export default SystemAdminAccountsPage