import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from 'react-bootstrap'
import { getVehicles } from '../../services/vehicles'

const TimetableVehiclesModal = ({ display, toggle, timetableTime }) => {

    const [vehicles, setVehicles] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { message } } = await getVehicles()

        if(Array.isArray(message)) {
            setVehicles(message.filter(x => {
                return timetableTime.vehicle_ids.some(y => y == x._id)
            }))
        }
        else {
            setVehicles([])
            //TODO: toast when getting hotel admins error
        }
        
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-3"><i className="far fa-clock"></i> {timetableTime.time}</Card.Title>
                <h5>Vehicles: {vehicles.length}</h5>
                <Row className="timetable-vehicles">
                    {vehicles && vehicles.map((vehicle, index) => (
                        <Col key={`ttvehicle-${index}`} xs="6">{vehicle.name}</Col>
                    ))}
                    {(vehicles.length%2 != 0) &&
                        <Col xs="6">&nbsp;</Col>
                    }
                </Row>
            </Modal.Body>
        </Modal>
  )
}

export default TimetableVehiclesModal

