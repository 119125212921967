import React, { useState } from 'react';
import { Col, Card, Form, Button, Row, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { getCurrentUser } from '../services/auth';
import { createSupportTicket } from '../services/contactSupport';

const ContactPage = () => {

    const [email, setEmail] = useState()
    const [topic, setTopic] = useState()
    const [supportMessage, setSupportMessage] = useState()

    let _currentUser = getCurrentUser()

    const handleCreateSupportTicket = async () => {

        const payload = {
            "user_id": _currentUser._id,
            "email": email,
            "topic": topic,
            "message": supportMessage
        }

        const { data: { code, message } } = await createSupportTicket(payload)
        if (code == 200) {
            toast.success(` Support ticket sent!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setEmail('')
            setTopic('')
            setSupportMessage('')
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <section>
            <Col>
                <Row>
                    <Col lg="5">
                        
                        <div className="w-100">

                            <Card className="p-4">
                                <h3 className="mb-1">Contact Support</h3>
                                <p className="text-muted mb-4">Fill up the form below for your inquiry</p>
                            
                                <Form.Group>
                                    <FormControl type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <FormControl placeholder="Topic" value={topic} onChange={(e) => setTopic(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control placeholder="Message" as="textarea" rows={5} value={supportMessage} onChange={(e) => setSupportMessage(e.target.value)} />
                                </Form.Group>

                                <Button onClick={() => handleCreateSupportTicket()}>Submit</Button>
                            </Card>
                        </div>

                    </Col>
                </Row>
            </Col>
        </section>
    );
}

export default ContactPage;