import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';
import { getUnresolvedIssues, markErrorResolved } from '../../services/logs';
import Moment from 'moment';
import ConfirmationModal from '../../components/modals/confirmation';
import { getUnacknowledgedNotifications } from '../../services/notifications';

const SystemIssuesPage = () => {

    const [issues, setIssues] = useState([])
    const [unacknowledgedNotifs, setUnacknowledgedNotifs] = useState([])
    const [confirmResolve, setConfirmResolve] = useState(false)
    const [selectedIssue, setSelectedIssue] = useState()

    useEffect(() => {
        fetch()
        fetchUnacknowledgedNotifs()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getUnresolvedIssues();
        if (code == 200) {
            setIssues(message)
        } else {
            //TODO: toast
        }
    }

    const fetchUnacknowledgedNotifs = async () => {
        const { data: { code, message } } = await getUnacknowledgedNotifications();
        if (code == 200) {
            setUnacknowledgedNotifs(message)
        } else {
            //TODO: toast
        }
    }

    const markResolveError = async () => {
        const { data: { code, message } } = await markErrorResolved(selectedIssue._id);
        if (code == 200) {
            let _issues = issues
            var index = _issues.indexOf(selectedIssue);
            _issues.splice(index, 1);
            setIssues([..._issues])
        } else {
            //TODO: toast
        }
    }

    const confirmMarkResolve = (issue) => {
        setSelectedIssue(issue)
        setConfirmResolve(true)
    }

    return (
        <>
            <ConfirmationModal display={confirmResolve} toggle={setConfirmResolve}
                title="Mark as Resolved"
                message={"Are you sure this error is already resolved?"}
                confirmAction={markResolveError} />
            <section>
                <Col>
                    <Card className='w-100 bookingsales-list mb-3'>
                        <Card.Header className='text-center bg-primary text-white'>System Issues</Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>#</th>
                                        <th>Datetime</th>
                                        <th width="200">Message</th>
                                        <th >Stack</th>
                                        <th width="150"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {issues.length > 0 && issues.map((issue, index) => (
                                        <tr key={`issue-${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{Moment.utc(issue.timestamp).format('DD.MM.YYYY')} @ {Moment.utc(issue.timestamp).format('HH:mm')}</td>
                                            <td>{issue.message}</td>
                                            <td>{issue.meta ? issue.meta.stack : ''}</td>
                                            <td><Button size="sm" variant="outline-primary" onClick={() => confirmMarkResolve(issue)}>Mark Resolve</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>

                    <Card className="mb-3">
                        <Card.Header className='text-center bg-primary text-white'>Unacknowledged Notifications</Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>#</th>
                                        <th>Datetime</th>
                                        <th>Booking Id</th>
                                        <th>Driver Id</th>
                                        <th>For</th>
                                        <th>Received</th>
                                        <th>Transpo Type</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unacknowledgedNotifs.length > 0 && unacknowledgedNotifs.map((notif, index) => (
                                        <tr key={`notif-${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{Moment.utc(notif.date).format('DD.MM.YYYY')} @ {Moment.utc(notif.date).format('HH:mm')}</td>
                                            <td>{notif.booking_id}</td>
                                            <td>{notif.driver_id}</td>
                                            <td>{notif.for}</td>
                                            <td>{notif.received ? 'True' : 'False'}</td>
                                            <td>{notif.transpo_type}</td>
                                            <td>{notif.type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </section>
        </>
    );
};

export default SystemIssuesPage;