import React, { useEffect } from 'react'
import { Modal, Card, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { addCredits } from '../../services/taxiDriverCredits'
import { toast } from 'react-toastify'

const AddTaxiDriverCreditsModal = ({ display, toggle, driver, onSuccess }) => {

    const [amount, setAmount] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        setIsLoading(true)
        const {data: {code, message}} = await addCredits({
            amount,
            taxi_driver_id: driver._id,
            top_up_method: 'SET-BY-SIMUL-ADMIN'
        })
        setIsLoading(false)

        if(code === 200) {
            toast.success('Successfully added credits', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            onSuccess(message)
            toggle(false)
        } else {
            toast.error(`An error occured. Unable add credits. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            size="sm"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Card.Title className="mb-2">
                        Add Driver Credits
                    </Card.Title>
                    <div className="mb-4">
                        <div>
                            <p><strong>Credits Since Latest Top Up:</strong> {driver?.credits_since_latest_top_up?.toLocaleString('en-US')}</p>
                            <p><strong>Credits Balance:</strong> {driver?.credits_balance?.toLocaleString('en-US')}</p>
                        </div>
                        <hr />
                        <Form.Group>
                            <p className="mb-2">Amount</p>
                            <Form.Control onChange={(e) => setAmount(e.target.value)} type="number" step='0.01' placeholder="Enter Amount" required />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => toggle(false)}>Cancel</Button>
                    <Button type='submit' disabled={isLoading}>{isLoading ? 'Submitting': 'Submit'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddTaxiDriverCreditsModal

