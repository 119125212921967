import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import HotelStaffCreation from '../../components/hotelAdmin/hotelStaffCreation'
import HotelStaffCreator from '../../components/hotelAdmin/hotelStaffCreator'
import HotelStaffList from '../../components/hotelAdmin/hotelStaffList'

const HotelAdminAccountsPage = () => {
    const [hotelStaffs, setHotelStaffs] = useState([])

    return <>
        <section>
            <HotelStaffCreation />
        </section>
    </>
}

export default HotelAdminAccountsPage