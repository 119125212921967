
import { Bar } from 'react-chartjs-2';
import React from 'react';

const ShuttleBookingsPerTimeChart = ({data, labels}) => {

    return (
        <>
            <Bar
                data={{
                    labels:labels,
                    datasets:[
                        {
                            data: data,
                            backgroundColor: 'rgb(255 109 0 / 35%)',
                            borderColor: 'rgb(255 109 0)',
                            borderWidth: 1
                        }
                    ]
                }}
                height={300}
                width={600}
                options={{
                    plugins: {
                        legend: {
                          display: false
                        }
                      },
                    maintainAspectRation: false,
                    scales:{
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }
                        ]
                    }
                }}
            />
        </>
    )
}

export default ShuttleBookingsPerTimeChart