import React from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import { useState } from 'react';
import { getUser } from '../../services/users';
import { getCurrentUser } from '../../services/auth';
import { useEffect } from 'react';
import { getAirlineById } from '../../services/airlines';
// import AirlineOptions from '../../components/airlineAdmin/airlineOptions';
import AirlineInfo from '../../components/airlineAdmin/airlineInfo';
import { toast } from 'react-toastify'
import AirlineOptions from '../../components/airlineAdmin/airlineOptions';

const AirlineSettings = () => {

    const [user, setUser] = useState()

    const [airline, setAirline] = useState(null)

    const currentUser = getCurrentUser()

    useEffect(() => {
        fetchUser()
        fetchAirline()
    }, [])

    const fetchUser = async () => {
        const { data: { code, message } } = await getUser(currentUser._id)
        if (code == 200) {
            setUser(message)
        } else {
            //TODO: toast
        }
    }

    const fetchAirline = async () => {
        const { data: { message } } = await getAirlineById(currentUser.airline_id);
        setAirline(message);
    }

    return (
        <section>
            {user &&
            <Col>
                {   currentUser.role_name == "airline-admin" && airline &&
                    <>
                        <Row className="mb-4">
                            <Col>
                                <AirlineInfo airline={airline}/>
                            </Col>
                        </Row>
                        
                        <Row className="mb-4">
                            <Col>
                                <AirlineOptions airline={airline}/>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
            }
        </section>
    );
};

export default AirlineSettings;