import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BookingsList from '../../components/common/bookingsList';

const ShuttleAdminBookingsPage = () => {
    return <>
        <section>
            <Row>
                <Col>
                    <BookingsList />
                </Col>
            </Row>
        </section>
    </>
}

export default ShuttleAdminBookingsPage