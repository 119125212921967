import React from 'react';
import Spinner from '../../assets/images/spinner.svg';

const PageLoader = ({position = "fixed", backgroundColor='#00000080'}) => {

    const styles = {
        container: {
            position: position,
            backgroundColor: backgroundColor,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            left: 0,
            top: 0 
        }
    }

    return (
        <div style={styles.container} className="center-xy">
            <img src={Spinner} alt='loading..' />
        </div>
    )
}

export default PageLoader

