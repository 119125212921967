import http from './http'
import { getToken } from './auth'

const base = 'vehicles'

export async function createVehicle(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getVehicles() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateVehicle(id, seats, available, driver_id, priority_no) {

    return await http.patch(`${base}/${id}?seats=${seats}&available=${available}&driver_id=${driver_id}&auto_assignment_priority_no=${priority_no}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}