import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const YesNoModal = ({ 
    display, 
    toggle, 
    title, 
    message = "Are you sure you want to do this?", 
    yesAction,
    noAction,
    yesText = 'Yes',
    noText = 'No',
    style = 'style1'
    }) => {

    const handleYesAction = () => {
        yesAction()
        toggle(false)
    }

    const handleNoAction = () => {
        noAction()
        toggle(false)
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        backdropClassName="level-2"
        centered
        >
            {style === 'style1' && 
            <>
            <Modal.Body>
                {title && <h3 className="text-center">{title}</h3> }
                <p className="text-center mb-0">{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleYesAction()}>{yesText}</Button>
                <Button variant="secondary" onClick={() => handleNoAction()}>{noText}</Button>
            </Modal.Footer>
            </>
            }

            {style === 'style2' && 
            <>
            <Modal.Body>
                {title && <h3 className="text-center">{title}</h3> }
                <p className="text-center">{message}</p>
                <Button className="w-100" onClick={() => handleYesAction()}>{yesText}</Button>
                <Button className="w-100 mt-2" variant="outline-dark" onClick={() => handleNoAction()}>{noText}</Button>
            </Modal.Body>
            </>
            }
        </Modal>
  )
}

export default YesNoModal

