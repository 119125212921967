import http from './http'
import { getToken } from './auth'

const base = 'shuttleinvoices'

export async function getShuttleInvoices() {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.get(`${base}`, config)
}

export async function getShuttleInvoiceById(id) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.get(`${base}/${id}`, config)
}

export async function generateShuttleInvoiceByMonth(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/generate`, payload, config)
}

export async function generateShuttleInvoiceFinal(payload) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/generate-final`, payload, config)
}

export async function cancelInvoice(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}