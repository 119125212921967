import { PDFDownloadLink } from '@react-pdf/renderer';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import BookingPDF from '../components/pdf/booking';
import BookingPosTicketPdf from '../components/pdf/bookingPosTicketPdf';
import BookingPrint from '../components/print/booking';
import PageLoader from '../components/common/pageLoader';
import { getBooking, printShuttleTicket } from '../services/shuttleBookings';
import { getAllDestinations } from '../services/shuttleDestinations';
import { getDestinationName, isSimulAccount } from '../utils/utils';
import simulLogo from '../assets/images/logo_white.png'
import ConfirmationModal from '../components/modals/confirmation';
import { toast } from 'react-toastify';

const BookingPage = (props) => {

    const [booking, setBooking] = useState()
    const [destinations, setDestinations] = useState()
    //const [loading, setLoading] = useState(true)

    const [printTicketConfirmation, setPrintTicketConfirmation] = useState(false)

    const componentRef = useRef();
    const { _id } = props.match.params

    useEffect(() => {
        // setTimeout(function(){

        // }, 500);
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getBooking(_id)
        if (code == 200) {
            await fetchDestinations()
            setBooking(message)
            //setLoading(false)
        } else {
            //TODO: toast
            //setLoading(false)
        }
    }

    const fetchDestinations = async () => {

        const { data: { message } } = await getAllDestinations()
        setDestinations(message)
    }

    const yesNoIcon = (value) => {
        if (value)
            return <><i className="fas fa-check-circle text-success"></i> Yes</>
        else
            return <><i className="fas fa-times-circle text-danger"></i> No</>
    }

    const isConfirmedByReceptionist = (booking) => {
        return booking.reception && booking.reception.is_confirmed;
    }

    const isBookingConfirmed = (booking) => {
        return booking.is_confirmed || isConfirmedByReceptionist(booking)
    }

    const bookingStatus = (booking) => {

        if(booking.is_confirmed && isConfirmedByReceptionist(booking)) return "Reception and Driver Confirmed"

        return booking.is_confirmed ? 'Driver Confirmed' : 
        (isConfirmedByReceptionist(booking) ? 'Reception Confirmed' : 'Not Confirmed')
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const handlePrint2 = async () => {

        if (!isSimulAccount()) {
            const { data: { code, message } } = await printShuttleTicket(_id)
            if (code !== 200) {
                toast.error(` ${message}.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else setBooking(message)
        }

        setTimeout(() => {
            document.querySelector('#pdf-frame').contentWindow.print();
        }, 500)
    }

    const setPdfFrameForPrint = (blob) => {
        var objectURL = URL.createObjectURL(blob);
        document.querySelector('#pdf-frame').src = '';
        document.querySelector('#pdf-frame').src = objectURL;
        objectURL = URL.revokeObjectURL(blob);
        // return "Download PDF"
        return ""
    }

    const handlePrintTicketConfirmation = () => {
        if (!isSimulAccount())
            if (booking.is_ticket_printed) {
                toast.warning(` Ticket has already been printed.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                setPrintTicketConfirmation(true)
            }
        else {
            handlePrint2()
        }
    }

    return <>
        {/* { loading && <PageLoader/>} */}
        {booking &&
            <>

                <ConfirmationModal
                    title="Print Ticket"
                    message="Ticket can only be printed once. You won't be able to print the ticket after this."
                    confirmText="Proceed"
                    cancelText="Cancel"
                    display={printTicketConfirmation} toggle={setPrintTicketConfirmation} confirmAction={handlePrint2} />

                <iframe className="hide-on-screen" id="pdf-frame"></iframe>
                <Card className="mb-4">
                    <Card.Body>
                        <div style={{ position: 'absolute', top: '-9999999px' }} >
                            <BookingPrint ref={componentRef} booking={booking} destination={destinations.find(x => x._id === booking.shuttle_destination_id)} />
                        </div>
                        <Row className="mb-3">
                            <Col lg="7" className="px-0 mx-n2">

                                <div className="px-4">
                                    <div className="customer-details d-flex mb-4">
                                        <img src={booking.qrcode} className="float-left qr-image" />
                                        <div className="float-left p-2">
                                            <p className="mb-0"><strong>CUSTOMER</strong></p>
                                            <h2 className="mb-0">{`${booking?.customer?.first_name} ${booking?.customer?.last_name  ?? ''}`}</h2>
                                            <h5 className="mb-0">Room #: {booking.customer.booking_source_number}</h5>
                                            <h5>Reservation #: {booking.reservation_number}</h5>
                                        </div>
                                    </div>

                                    <div className="booking-details">

                                        {booking?.isCancelled &&
                                            <Button className="mb-4" size="lg" variant="outline-danger" style={{ cursor: 'default', pointerEvents: 'none' }} ><strong>CANCELLED</strong></Button>
                                        }

                                        <p className="mb-3"><strong>BOOKING DETAILS</strong></p>

                                        <Table className="booking-table" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="booking-table-label">Booking Date</td>
                                                    {/* We will use UTC for this shuttle booking date 
                                            because it was not converted into UTC when saved in mongo db
                                            compared to 'created_date' */}
                                                    <td>{Moment.utc(booking.booking_date).format('DD.MM.YYYY')} @ {Moment.utc(booking.booking_date).format('HH:mm')}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Created on</td>
                                                    <td>{Moment(booking.created_date).format('DD.MM.YYYY')} @ {Moment(booking.created_date).format('HH:mm')}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Number of persons</td>
                                                    <td>{booking.number_of_persons}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Type</td>
                                                    <td>{booking.booking_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Origin</td>
                                                    <td>{booking.origin}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Destination</td>
                                                    <td>{destinations && getDestinationName(booking, destinations)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Source Type</td>
                                                    <td>{booking.booking_source_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Confirmed</td>
                                                    <td>{isBookingConfirmed(booking) ? yesNoIcon(true) : yesNoIcon(false)}, {bookingStatus(booking)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="booking-table-label">Paid By Guest</td>
                                                    <td>{booking.is_paid && booking.paid_by_guest ? yesNoIcon(true) : yesNoIcon(false)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <PDFDownloadLink id="download-pdf" className="btn btn-primary mr-2" document={<BookingPDF booking={booking} destinations={destinations} />} fileName="booking.pdf">
                                        {({ blob, url, loading, error }) => (loading ? ' Loading document...' : 'Download PDF')}
                                    </PDFDownloadLink>
                                    <Button variant="secondary" onClick={handlePrint}>Print PDF</Button>
                                    <Button variant="secondary" className="ml-2" onClick={handlePrintTicketConfirmation}>Print Ticket</Button>
                                    <PDFDownloadLink id="download-pdf-2"
                                        document={<BookingPosTicketPdf booking={booking} destinations={destinations} />} fileName="booking.pdf">
                                        {({ blob, url, loading, error }) => (loading ? '' : setPdfFrameForPrint(blob))}
                                    </PDFDownloadLink>
                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </>
        }
    </>
}

export default BookingPage