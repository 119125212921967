import React, { useState, useEffect } from 'react'
import SystemAdminAccountsPage from './admin/accounts'
import HotelAdminAccountsPage from './hotelAdmin/accounts'
import ShuttleCompanyAccountsPage from './shuttleCompany/accounts'
import { getCurrentUser } from './../services/auth'

const AccountsPage = () => {
    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && user.role_name === 'hotel-admin' &&
            <HotelAdminAccountsPage />
        }

        {
            user && user.role_name === 'shuttle-admin' &&
            <ShuttleCompanyAccountsPage />
        }

        {
            user && user.role_name === 'system-admin' &&
            <SystemAdminAccountsPage />
        }
    </>
}

export default AccountsPage