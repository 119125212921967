import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import PageLoader from '../../components/common/pageLoader';
import { get } from '../../services/airlineCrews';

const PublicAirlineCrewSelect = ({ selectAirCrew, isVerifiedKey }) => {

    const [isLoading, setIsLoading] = useState(false)

    const [airCrews, setAirCrews] = useState([])

    let history = useHistory()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        setIsLoading(true)

        const { data: { message } } = await get()

        setAirCrews(message)
        setIsLoading(false)
    }

    const handleSelectAirCrew = (airCrew) => {
        if(!isVerifiedKey) {
            toast.error(`Error! unauthorized access.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        selectAirCrew(airCrew)
    }

    const handleUpdateBooking = (airCrewId) => {
        if(!isVerifiedKey) {
            toast.error(`Error! unauthorized access.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
        history.push(`/public/aircrew/search?airlineCrewId=${airCrewId}`)
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <Row className="justify-content-between">
                        <Col>Book Airline Crew <i className="mr-2 fas fa-arrow-circle-right"></i> Select Crew</Col>
                    </Row>
                </Card.Title>
                {isLoading ?
                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                    :
                    <div className="bg-secondary p-4">
                        <Row className="row-eq-height">
                            {airCrews.length > 0 && airCrews.map((airCrew) => (
                                <Col sm="4" md="3" lg="2" className="mb-3" key={`crew-${airCrew.id}`}>

                                    <Card className="h-100">
                                        <Card.Body>
                                            <div className="position-relative center-xy">
                                                {airCrew.airline_logo ?
                                                    <img className="w-100" alt={airCrew.airline_name} src={airCrew.airline_logo} />
                                                    :
                                                    <span>{airCrew.airline_name}</span>
                                                }
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-center">
                                            <Button className="w-100 mb-2" onClick={() => handleSelectAirCrew(airCrew)}>New Booking</Button>
                                            <Button variant="outline-secondary" className="w-100" onClick={() => handleUpdateBooking(airCrew.id)}>Update Booking</Button>
                                        </Card.Footer>
                                    </Card>

                                </Col>
                            ))}
                        </Row>
                    </div>
                }
            </Card.Body>
        </Card>
    );
};

export default PublicAirlineCrewSelect;