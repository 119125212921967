import React, { useState } from 'react'
import { Button, Card, Form, FormInput, Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createSeatOption } from '../../../services/airlineCrewSeatOptions'
import AirlineCrewSelect from '../../common/airlineCrewSelect'

const AddAirlineCrewSeatOptionModal = ({ display, toggle, seatOptions, setSeatOptions }) => {

    const [name, setName] = useState()
    const [applicableRoute, setApplicableRoute] = useState()
    const [seats, setSeats] = useState(0)
    const [price, setPrice] = useState(0)
    const [pricePerPerson, setPricePerPerson] = useState(0)
    const [vehicleType, setVehicleType] = useState('shuttle')
    const [airlineCrewId, setAirlineCrewId] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const handleAdd = async (e) => {
        e.preventDefault()
        setIsCreating(true)
        const so = {
            name,
            seats,
            per_person_price: pricePerPerson,
            price,
            applicable_route: applicableRoute,
            vehicle_type: vehicleType,
            airline_crew_id: airlineCrewId || undefined
        }

        const { data: { code, message } } = await createSeatOption(so)
        setIsCreating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _seatOptions = seatOptions
            _seatOptions.push(message)
            setSeatOptions([..._seatOptions])
            setAirlineCrewId('')
            toggle(false)
        }
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">Create Seat Option</Card.Title>
                <Form onSubmit={handleAdd} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='text' onChange={e => setName(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <AirlineCrewSelect selected={airlineCrewId} onSelect={(airlineCrew) => setAirlineCrewId(airlineCrew?._id)} />
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Applicable Route</Form.Label>
                                <Form.Control as="select" onChange={(e) => setApplicableRoute(e.target.value)}>
                                    <option value=''>All Route</option>
                                    <option value='airport-hotel'>Airport to Hotel</option>
                                    <option value='hotel-airport'>Hotel to Airport</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Vehicle Type</Form.Label>
                                <Form.Control as="select" onChange={(e) => setVehicleType(e.target.value)} required>
                                    <option value='shuttle'>Shuttle</option>
                                    <option value='taxi'>Taxi</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Seats</Form.Label>
                                <Form.Control type='number' onChange={e => setSeats(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Price</Form.Label>
                                <Form.Control type='number' step='any' onChange={e => setPrice(e.target.value)} min={0} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Price per Person</Form.Label>
                                <Form.Control type='number' step='any' onChange={e => setPricePerPerson(e.target.value)} min={0} required />
                            </Form.Group>
                        </Col>
                        <Col lg="12" className="text-right">
                            <Button type="submit" disabled={isCreating} className="mt-2">
                                {isCreating ? 'Saving...' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddAirlineCrewSeatOptionModal

