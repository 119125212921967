import React, { useEffect, useState } from 'react';
import { Col, Card, Table } from 'react-bootstrap';
import { getContactMessages } from '../../services/contacts';
import Moment from 'moment';

const ContactsPage = () => {

    const [messages, setMessages] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getContactMessages()
        if (code === 200) {
            setMessages(message)
        } else {
            //TODO: toast
        }
    }

    return (
        <section>
            <Col>
                <Card className='w-100 bookingsales-list mb-3'>
                    <Card.Header className='text-center bg-primary text-white'>Contact Messages</Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead className="border-top-0">
                                <tr>
                                    <th>#</th>
                                    <th>Datetime</th>
                                    <th>Contact Number</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messages.length > 0 && messages.map((message, index) => (
                                    <tr key={`message-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>{Moment.utc(message.date).format('DD.MM.YYYY')} @ {Moment.utc(message.date).format('HH:mm')}</td>
                                        <td>{message.contact_number}</td>
                                        <td>{message.email}</td>
                                        <td style={{width:'500px'}}>{message.message}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </section>
    );
};

export default ContactsPage;