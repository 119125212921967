import http from './http'
import { getToken } from './auth'

const base = 'shuttleBookings'

export async function bookShuttle(payload, availableVehicleIds) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    const availableVehicleIdsQuery = !availableVehicleIds ? '' : availableVehicleIds.join(',');
    return await http.post(`${base}?availableVehicleIds=${availableVehicleIdsQuery}`, payload, config)
}

export async function getBookingsToday() {

    const startDate = new Date()
    startDate.setHours(0,0,0,0)
    const endDate = new Date()
    endDate.setHours(23,59,59,999)

    return await http.get(`${base}/filter?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getBookings(pageSize, bookingDate, searchText) {

    let url = `${base}/all?excludeCancelled=true`
    if(pageSize)
        url += `&pageSize=${pageSize}`
    if(bookingDate)
        url += `&bookingDate=${bookingDate}`
    if(searchText)
        url += `&searchText=${searchText}`
 
     return await http.get(url, {
         headers: {
             'x-auth-token': getToken()
         }
     })
}

export async function getEstablishmentBookings(pageSize, toDate, fromDate, searchText) {

    let url = `${base}/by-user-establishment-id?excludeCancelled=true`

    if(pageSize)
        url += `&pageSize=${pageSize}`
    if(toDate)
        url += `&toDate=${toDate}`
    if(fromDate)
        url += `&fromDate=${fromDate}`
    if(searchText)
        url += `&searchText=${searchText}`
 
     return await http.get(url, {
         headers: {
             'x-auth-token': getToken()
         }
     })
}

export async function getUnconfirmedHotelBookings(pageSize, toDate, fromDate) {

    let url = `${base}/by-user-establishment-id?excludeCancelled=true&confirmedByReceptionist=false`

    if(pageSize)
        url += `&pageSize=${pageSize}`
    if(toDate)
        url += `&toDate=${toDate}`
    if(fromDate)
        url += `&fromDate=${fromDate}`
 
     return await http.get(url, {
         headers: {
             'x-auth-token': getToken()
         }
     })
}

export async function getUnconfirmedBookingsCount() {

    let url = `${base}/unconfirmed-count`
 
     return await http.get(url, {
         headers: {
             'x-auth-token': getToken()
         }
     })
}

export async function getCancelledBookings() {
    return await http.get(`${base}/cancelled`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function getBooking(id) {

    return await http.get(`${base}/by-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAvailableSeats(datetime, shuttleDestinationId) {

    return await http.get(`${base}/available-seats-by-date-time/${datetime}/${shuttleDestinationId}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function printShuttleTicket(id) {

    return await http.patch(`${base}/print-ticket/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function cancelBooking(id) {

    return await http.patch(`${base}/cancel-booking/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateDriver(bookingId, driverId) {
    
    return await http.patch(`${base}/update-driver/${bookingId}/driver-id/${driverId}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateBookingDate(id, dateTime) {

    return await http.patch(`${base}/update-booking-date/${id}/${dateTime}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateBookingAsConfirmed(id) {

    return await http.patch(`${base}/update-as-confirmed-by-reception/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getMonthlyBookings() {
    return await http.get(`${base}/monthly`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getWeeklyBookings(shuttle_destination_id) {

    let uri = `${base}/weekly`
    if(shuttle_destination_id) uri += `?shuttle_destination_id=${shuttle_destination_id}`
    
    return await http.get(uri, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getDailyBookings() {

    return await http.get(`${base}/daily`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getTimeBooksToday() {

    return await http.get(`shuttle-bookings/charts/time-books/today`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function getTimeBooksWeek() {

    return await http.get(`shuttle-bookings/charts/time-books/this-week`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function getTimeBooksMonth() {

    return await http.get(`shuttle-bookings/charts/time-books/this-month`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getBookingsPerHour(shuttle_destination_id, startDate, endDate) {

    return await http.get(`${base}/bookings-per-hour/${shuttle_destination_id}`, {
        headers: {
            'x-auth-token': getToken()
        },
        params: {
            startDate,
            endDate,
        }
    })
}