import React, { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { deleteUser } from '../../services/users'
import PageLoader from '../common/pageLoader'
import ChangePasswordModal from '../modals/changePassword'
import ChangeUsernameModal from '../modals/changeUsername'
import ConfirmationModal from '../modals/confirmation'

const HotelAdminList = ({hotelAdmins, setHotelAdmins, isTableLoading, isLoading, setIsLoading}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [changeUsernameModal, setChangeUsernameModal] = useState(false)
    const [user, setUser] = useState()

    const handleRemoveUser = async (id) => {
        setIsLoading(true)
        const {data: {code, message}} = await deleteUser(id)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this user. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            userRemoved()
        }
    }

    const passwordChanged = (newPassword) => {
        const userIndex = hotelAdmins.indexOf(user)
        let _hotelAdmins = hotelAdmins
        _hotelAdmins[userIndex].password = newPassword
        setHotelAdmins(_hotelAdmins)
    }

    const usernameChanged = (newUsername) => {
        const userIndex = hotelAdmins.indexOf(user)
        let _hotelAdmins = hotelAdmins
        _hotelAdmins[userIndex].username = newUsername
        setHotelAdmins(_hotelAdmins)
    }

    const userRemoved = () => {
        const userIndex = hotelAdmins.indexOf(user)
        let _hotelAdmins = hotelAdmins
        _hotelAdmins.splice(userIndex, 1)
        setHotelAdmins([..._hotelAdmins])
    }

    const handleChangePasswordModal = async (user) => {
        setUser(user)
        setChangePasswordModal(true)
    }

    const handleChangeUsernameModal = async (user) => {
        setUser(user)
        setChangeUsernameModal(true)
    }

    const handleConfirmationModal = (user) => {
        setUser(user)
        setConfirmationModal(true)
    }

    return <>

        {isLoading && <PageLoader />}

        {user && 
        <>
        <ChangePasswordModal display={changePasswordModal} toggle={setChangePasswordModal} user={user} passwordChanged={passwordChanged} setIsLoading={setIsLoading} />
        <ChangeUsernameModal display={changeUsernameModal} toggle={setChangeUsernameModal} user={user} usernameChanged={usernameChanged} setIsLoading={setIsLoading} />
        </>
        }

        <ConfirmationModal
            title="Remove Hotel Admin"
            message="Are you sure you want to delete this user?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleRemoveUser(user._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Hotel Admins</Card.Header>
           
            <Card.Body>
                {!isTableLoading ?
                <Table striped bordered hover variant='light'>                  
                    <thead>
                        <tr>
                            <th>Hotel</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hotelAdmins.length > 0 &&
                            hotelAdmins.map((hotelAdmin, index) =>
                                <tr key={`hotelAdmin-${index}`}>
                                    <td>{hotelAdmin.hotel_name}</td>
                                    <td>{hotelAdmin.username}</td>
                                    <td>{hotelAdmin.password}</td>
                                    <td className="actions text-center">
                                        <i title="change username" className="fas fa-user-tag mr-2" onClick={() => handleChangeUsernameModal(hotelAdmin)}></i>
                                        <i title="change password" className="fas fa-user-lock mr-2" onClick={() => handleChangePasswordModal(hotelAdmin)}></i>
                                        <i title="delete user" className="fas fa-trash-alt" onClick={() => handleConfirmationModal(hotelAdmin)}></i>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                :
                <PageLoader position="relative" backgroundColor="#f2ecec" />
                }
                
            </Card.Body>
        </Card>
    </>
}

export default HotelAdminList
