import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCurrentUser } from '../../services/auth'
import { updateUser } from '../../services/users'

const UpdateAccountModal = ({ display, toggle, user, accountUpdated, setIsLoading }) => {

    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    // const [username, setUsername] = useState(user.username)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState()
    const [error, setError] = useState('')

    const _currentUser = getCurrentUser()

    const handleChangePassword = async () => {

        setError('')

        if (newPassword.length > 0 && (newPassword !== confirmPassword)) {
            setError('passwords do not match')
            return
        }

        const payload = {}

        if (firstname) payload['first_name'] = firstname
        if (lastname) payload['last_name'] = lastname
        // if(username !== user.username) {
        //     payload['old_username'] = user.username
        //     payload['new_username'] = username
        // }
        if (newPassword) {
            payload['old_password'] = user.password
            payload['new_password'] = newPassword
        }

        setIsLoading(true)
        const { data: { code, message } } = await updateUser(user._id, payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            accountUpdated(payload)
            toggle(false)
        }
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            centered
        >
            <Modal.Body className="update-account-modal">
                <Card.Title className="mb-3">Update Account</Card.Title>
                <div className="update-account">
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type='text' defaultValue={user.info ? user?.info?.first_name : user.first_name} placeholder='Enter first name' onChange={e => setFirstname(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type='text' defaultValue={user.info ? user?.info?.last_name : user.last_name} placeholder='Enter last name' onChange={e => setLastname(e.target.value)} />
                    </Form.Group>
                    <hr />
                    {/* <Card.Title className="mb-3">Change Username</Card.Title>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control defaultValue={user.username} placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                    </Form.Group>
                    <hr /> */}
                    <Card.Title className="mb-3">Change Password</Card.Title>
                    <Form.Group>
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control disabled={_currentUser.role_name === 'system-admin'} type='text' placeholder='Enter old password' defaultValue={user.password} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter new password' onChange={e => setNewPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control type='password' placeholder='Conform new password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Group>

                    {error &&
                        <p className="text-danger text-center"><small>{error}</small></p>
                    }
                    <Button className="w-100" onClick={() => handleChangePassword()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateAccountModal

