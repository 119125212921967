import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Card, FormControl, Button, Table } from 'react-bootstrap';
import { getCountries } from '../services/address';
import { createTaxiCompany, getTaxiCompanies } from '../services/taxiCompanies';

const TaxiCompaniesPage = () => {

    const [companyName, setCompanyName] = useState()
    const [street, setStreet] = useState()
    const [city, setCity] = useState()
    const [zip, setZip] = useState()
    const [country, setCountry] = useState()

    const [taxiCompanies, setTaxiCompanies] = useState([])

    const [countries, setCountries] = useState()

    useEffect(() => {
        fetchCountries()
        fetchTaxiCompanies()
    },[])

    const fetchCountries = async () => {
        const {data: {code, message}} = await getCountries();
        if(code == 200) {
            setCountries(message)
        } else {
            //TODO: toast
        }
    }

    const fetchTaxiCompanies = async () => {
        const {data: {code, message}} = await getTaxiCompanies();
        if(code == 200) {
            setTaxiCompanies(message)
        } else {
            //TODO: toast
        }
    }

    const handleCreateTaxiCompany = async () => {

        const payload = {
            "name": companyName,
            "address": {
                "street": street,
                "zip": zip,
                "city": city,
                "country": country
            }
        }

        const {data: {code, message}} = await createTaxiCompany(payload);
        if(code == 200) {
            document.getElementById('create-taxi-company-form').reset();
            let _taxiCompanies = taxiCompanies
            _taxiCompanies.push(message)
            setTaxiCompanies([..._taxiCompanies])
        } else {
            //TODO: toast
        }
    }

    const getFullAddress = (address) => {
        return `${address.street}, ${address.city}, ${address.zip}, ${address.country}`
    }

    return <>
        <section>
            <Col>
                <Row className="mb-4">
                    <Col lg="8">
                        <Card className="p-4">
                            <h3 className="mb-4">Create Taxi Company</h3>
                        
                            <Form id="create-taxi-company-form">
                            <Form.Group>
                                <Form.Label>Company Name</Form.Label>
                                <FormControl placeholder="Enter Company Name" onChange={(e) => setCompanyName(e.target.value)} />
                            </Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Group>
                                        <Form.Label>Street</Form.Label>
                                        <FormControl placeholder="Street" onChange={(e) => setStreet(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control placeholder="City" onChange={(e) => setCity(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group>
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control placeholder="City" onChange={(e) => setZip(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="3">
                                    <Form.Group>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control as="select" defaultValue="" onChange={(e) => setCountry(e.target.value)}>
                                            <option value="" disabled>Select Country</option>
                                            {countries && countries.map((_country, index) => (
                                                <option key={`country-${index}`} value={_country}>{_country}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            </Form>
                            
                            <Row className="mt-3">
                                <Col className="text-center">
                                    <Button className="px-4" onClick={() => handleCreateTaxiCompany()}>Submit</Button>
                                </Col>
                            </Row>
                            
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card className='w-100'>
                            <Card.Header className='text-center bg-primary text-white'>Taxi Companies</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover variant='light'>                  
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th>Taxi Drivers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            taxiCompanies.length > 0 &&
                                            taxiCompanies.map((taxiCompany, index) =>
                                                <tr key={`taxicompany-${index}`}>
                                                    <td>{taxiCompany.name}</td>
                                                    <td>{getFullAddress(taxiCompany.address)}</td>
                                                    <td>
                                                    {taxiCompany.taxi_drivers && taxiCompany.taxi_drivers.length > 0 ?
                                                    <>
                                                        {taxiCompany.taxi_drivers.map((driver, index) => (
                                                            <p key={`taxidriver-${index}`} className="mb-0">{`${driver.first_name} ${driver.last_name}`}</p>
                                                        ))}
                                                    </>
                                                    :
                                                    <small><i>no taxi drivers set</i> </small>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </section>
    </>
}

export default TaxiCompaniesPage