
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Moment from 'moment';
import React from 'react';
import simulLogo from '../../assets/images/logo_white.png'

const BookingTaxiPDF = ({booking, getDestination}) => {
    
    const styles = StyleSheet.create({
        page: { 
          backgroundColor: '#fff',
          padding:15
        },
        label: {
            fontSize: 8,
            fontWeight: 'bold',
            marginBottom: 2
        },
        value: {
            fontSize: 10,
            textTransform: 'uppercase',
            marginBottom: 10
        },
        p: {
            fontSize: 15
        },
        h4: {
            fontSize: 30
        },
        row:{
            display: 'flex',
            flexDirection: 'row',
            marginHorizontal: -15
        },
        col3: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '25%',
            paddingHorizontal: 15
        },
        col4: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '33.333333%',
            paddingHorizontal: 15
        },
        col5: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '41.666667%',
            paddingHorizontal: 15
        },
        col6: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '50%',
            paddingHorizontal: 15
        },
        col7: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '58.333333%',
            paddingHorizontal: 15
        },
        col8: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '66.666667%',
            paddingHorizontal: 15
        },
        col9: {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '75%',
            paddingHorizontal: 15
        },
        marginTop:{
            marginTop: 10
        },
        marginBottom:{
            marginBottom: 10
        },
        marginBottomMD:{
            marginBottom: 20
        },
        borderRight: {
            borderRight: 1,
            borderColor: '#eee'
        },
        header: {
            backgroundColor: '#FF6D00',
            padding: 5,
            marginHorizontal: -15,
            marginBottom: 15,
            height: 30
        },
        headerText: {
            color: 'white',
            fontSize: 13
        }
    });
 
    return (
        <Document
        >
            <Page size="letter" style={styles.page}>
                <View wrap={true}>
                    
                    <View style={[styles.row, {borderBottom:1,borderColor:'#eee'}]}>

                        {/* Left Column */}
                        <View style={[styles.col7, styles.borderRight]}>
                            <View style={styles.header}>
                                <View style={styles.row}>
                                    <View style={styles.col6}>
                                        <Image src={simulLogo} style={{height:'100%',width:90}} />
                                    </View>
                                    <View style={[styles.col6, {textAlign:'right', paddingTop: 2}]}>
                                        <Text style={{color:'#fff',fontSize:12}}>TAXI PASS</Text>
                                    </View>
                                </View>
                            </View>

                            <Text style={styles.label}>Name of passenger</Text>
                            <Text style={styles.value}>{`${booking.customer.first_name} ${booking.customer.last_name}`}</Text>

                            <View style={styles.row}>
                                <View style={styles.col6}>
                                    <Text style={styles.label}>Origin</Text>
                                    <Text style={styles.value}>{booking.origin}</Text>
                                </View>
                                <View style={styles.col6}>
                                    <View style={styles.row}>
                                        <View style={styles.col6}>
                                            <Text style={styles.label}>Date</Text>
                                            <Text style={styles.value}>{Moment(booking.booking_date).format('DD.MM.YYYY')}</Text>
                                        </View>
                                        <View style={styles.col6}>
                                            <Text style={styles.label}>Time</Text>
                                            <Text style={styles.value}>{Moment(booking.booking_date).format('HH:mm')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col6}>
                                    <Text style={styles.label}>Destination</Text>
                                    <Text style={styles.value}>{getDestination(booking)}</Text>
                                </View>
                                <View style={styles.col6}>
                                    <Text style={styles.label}>Created on</Text>
                                    <Text style={styles.value}>{Moment(booking.created_date).format('DD.MM.YYYY')} @ {Moment(booking.booking_date).format('HH:mm')}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.col9}>

                                    <View style={styles.row}>
                                        <View style={styles.col4}>
                                            <Text style={styles.label}>Walk-In</Text>
                                            <Text style={styles.value}>{booking.is_walk_in ? 'Yes' : 'No'}</Text>
                                        </View>
                                        <View style={styles.col4}>
                                            <Text style={styles.label}>Paid</Text>
                                            <Text style={styles.value}>{booking.is_paid ? 'Yes' : 'No'}</Text>
                                        </View>
                                        {booking.number_of_persons && 
                                        <View style={styles.col4}>
                                            <Text style={styles.label}>Seats</Text>
                                            <Text style={styles.value}>{booking.number_of_persons}</Text>
                                        </View>
                                        }
                                    </View>
                                    
                                    <View style={styles.row}>
                                        <View style={styles.col4}>
                                            <Text style={styles.label}>Type</Text>
                                            <Text style={styles.value}>Taxi</Text>
                                        </View>
                                        <View style={styles.col4}>
                                            <Text style={styles.label}>Source Type</Text>
                                            <Text style={styles.value}>{booking.booking_source_type}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.col3}>
                                    <Image src={booking.qrcode} style={{width:'100%'}} />
                                </View>
                            </View>
                        </View>

                        {/* Right Column */}
                        <View style={styles.col5}>
                            <View style={styles.header}>
                                <Image src={simulLogo} style={{height:'100%',width:90}} />
                            </View>

                            <Text style={styles.label}>Name of passenger</Text>
                            <Text style={styles.value}>{`${booking.customer.first_name} ${booking.customer.last_name}`}</Text>

                            <Text style={styles.label}>Origin</Text>
                            <Text style={styles.value}>{booking.origin}</Text>

                            <Text style={styles.label}>Destination</Text>
                            <Text style={styles.value}>{getDestination(booking)}</Text>

                            <View style={styles.row}>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Walk-In</Text>
                                    <Text style={styles.value}>{booking.is_walk_in ? 'Yes' : 'No'}</Text>
                                </View>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Paid</Text>
                                    <Text style={styles.value}>{booking.is_paid ? 'Yes' : 'No'}</Text>
                                </View>
                                {booking.number_of_persons && 
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Seats</Text>
                                    <Text style={styles.value}>{booking.number_of_persons}</Text>
                                </View>
                                }
                            </View>

                            <View style={styles.row}>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Pickup Date</Text>
                                    <Text style={styles.value}>{Moment(booking.booking_date).format('DD.MM.YYYY')}</Text>
                                </View>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Pickup Time</Text>
                                    <Text style={styles.value}>{Moment(booking.booking_date).format('HH:mm')}</Text>
                                </View>
                            </View>

                            <View style={styles.row}>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Type</Text>
                                    <Text style={styles.value}>Taxi</Text>
                                </View>
                                <View style={styles.col4}>
                                    <Text style={styles.label}>Source Type</Text>
                                    <Text style={styles.value}>{booking.booking_source_type}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                </View>
            </Page>
        </Document>
    );
}

export default BookingTaxiPDF