import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateAirline } from '../../services/airlines';
import { getCurrentUser } from '../../services/auth';

const AirlineOptions = (props) => {

    const [airline, _] = useState(props.airline)
    const [logo, setLogo] = useState()

    const _currentuser = getCurrentUser()

    const handleChangeLogo = () => {
        const file = document.querySelector('#file-upload').files[0];;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            setLogo(reader.result)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const saveLogo = async () => {

        const airlineLogo = {
            'logo': logo
        }

        const { data: { code, message } } = await updateAirline(_currentuser.airline_id, airlineLogo)

        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            toast.success(` Logo Saved!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            document.getElementById("airline-logo").setAttribute("src", logo);
        }
    }

    const imgPreview = {
        'maxWidth': '100%',
        'maxHeight': '200px'
    }

    return (
        <>
            {airline &&
                <>
                    <Card>
                        <Card.Body>
                            <p><strong>OPTIONS</strong></p>
                            <hr></hr>
                            <Row>
                                <Col lg="3" className="mb-3">
                                    <input className="mb-3" id="file-upload" type="file" onChange={() => handleChangeLogo()} /><br />
                                    {logo ?
                                        <img src={logo} style={imgPreview} alt="Image preview..." />
                                        :
                                        (airline.logo ?
                                            <img src={airline.logo} style={imgPreview} alt="Image preview..." />
                                            :
                                            <div className="center-xy" style={{ height: '150px', width: '150px', backgroundColor: '#eee' }}><span>LOGO</span></div>
                                        )
                                    }
                                    <Button size="sm" className="mt-3" onClick={() => saveLogo()}>Save Airline Logo</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            }
        </>
    );
}

export default AirlineOptions;