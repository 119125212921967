import React, { useState } from 'react'
import { Form, Card, Button, Row, Col } from 'react-bootstrap'

const ShuttleDriverCreator = ({createShuttleDriver}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

    const handleAccountCreationSubmit = async () => {

        createShuttleDriver({ username, password, first_name: firstname, last_name: lastname }, password)
        document.getElementById('shuttle-driver-creator').reset();
        //TODO: return Toast when account creation is error
    }
    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Shuttle Driver Account</Card.Header>
            <Card.Body>
                <Form id="shuttle-driver-creator">
                    <Row className="mb-3">
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control type='text' placeholder='Enter firstname' onChange={e => setFirstname(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control type='text' placeholder='Enter lastname' onChange={e => setLastname(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center">
                    <Button variant='primary' onClick={() => handleAccountCreationSubmit()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default ShuttleDriverCreator