import { PDFDownloadLink } from '@react-pdf/renderer';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Button, Badge } from 'react-bootstrap';
import LinkTD from '../components/common/linkTd';
import PageLoader from '../components/common/pageLoader';
import TaxiInvoicePDF from '../components/pdf/invoiceTaxi';
import { getHotelById } from '../services/hotels';
import { getTaxiInvoiceById, updateInvoiceAsPaid } from '../services/invoicesTaxi';
import { getUser } from '../services/users';

const TaxiInvoicePage = (props) => {

    const [invoice, setInvoice] = useState()
    const [hotel, setHotel] = useState()
    const [taxiDriver, setTaxiDriver] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const { _id } = props.match.params

    useEffect(() => {
        fetch()
    }, [])
    
    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getTaxiInvoiceById(_id)
        if(code === 200) {
            setInvoice(message)
            message.recipient_type === 'establishment' ? fetchHotel(message.establishment_id) : fetchTaxiDriver(message.recipient_person_id)
        }
        else {
            //TODO: toast
        } 
        setIsLoading(false)
    }

    const fetchHotel = async (id) => {
        const { data: { code, message } } = await getHotelById(id)
        if(code === 200)
            setHotel(message)
        else {
            //TODO: toast
        } 
    }

    const fetchTaxiDriver = async (id) => {
        const { data: { code, message } } = await getUser(id)
        if(code === 200)
            setTaxiDriver(message)
        else {
            //TODO: toast
        } 
    }

    const handleSetAsPaid = async () => {
        const { data: { code, message } } = await updateInvoiceAsPaid(_id)
        if(code === 200) {
            let _invoice = invoice
            _invoice['is_paid'] = true
            _invoice['payment_date'] = message.payment_date
            setInvoice({..._invoice})
        }
        else {
            //TODO: toast
        } 
    }

    const getRecipientName = (invoice) => {
        return invoice.recipient_type === 'establishment' ? hotel?.hotel_name : `${taxiDriver?.first_name} ${taxiDriver?.last_name}`
    }

    const getInvoiceRecipientAddress = (hotel) => {
        return `${hotel.address?.street} ${hotel.address?.city} ${hotel.address?.zip} ${hotel.address?.country}`
    }

    const getItemSaleAmount = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount.toFixed(2) : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount.toFixed(2) : 0;//TODO: we're not sure yet if this is accurate
    }

    const getItemSaleAmountInDecimal = (sale) => {
        if(sale?.is_fixed_price) 
            return sale?.fixed_amount ? sale?.fixed_amount : 0;
        else
            return sale?.latest_amount ? sale?.latest_amount : 0;//TODO: we're not sure yet if this is accurate
    }

    const getStaffName = (sale) =>{

        const bookingSourceUser = sale.booking_info.booking_source_user
        if(!bookingSourceUser) return ''

        return `${bookingSourceUser.first_name} ${bookingSourceUser.last_name}`
    }

    const isEstablishmentInvoice = (invoice) => {
        return invoice.recipient_type === 'establishment'
    }

    return <>
     
        <section>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {isLoading ?
                            <PageLoader position="relative" backgroundColor="#f2ecec" />
                            :
                            <>
                            {invoice && 
                                <>
                                <h1 className="mb-2">Invoice 
                                    {invoice.is_paid && 
                                    <sup className="ml-2"><Badge style={{fontSize:'15px'}} variant="primary">PAID</Badge></sup>
                                    }
                                </h1>
                        
                                <h4 className="mb-1">{getRecipientName(invoice)}</h4>
                                
                                <p className="mb-3">{hotel && getInvoiceRecipientAddress(hotel)}</p>

                                <div className="invoice-general-info mb-4">
                                    <p className="mb-0">Invoice No.: <strong>{invoice.invoice_human_readable_number}</strong></p>
                                    <p className="mb-0">Date Covered: <strong>{Moment(invoice.period_from).format('DD.MM.YYYY')} - {Moment(invoice.period_to).format('DD.MM.YYYY')}</strong></p>
                                    <p className="mb-0">Number of Bookings: <strong>{invoice.number_of_sales}</strong></p>
                                    <p className="mb-2">Recipient: <strong>{isEstablishmentInvoice(invoice) ? 'Hotel' : 'Taxi Driver'}</strong></p>
                                </div>

                                <div className="mb-3">
                                {(hotel || taxiDriver) && 
                                <PDFDownloadLink id="download-pdf" className="mr-2 btn btn-outline-primary" document={<TaxiInvoicePDF invoice={invoice} recipientName={getRecipientName(invoice)} recepientAddress={hotel && getInvoiceRecipientAddress(hotel)} />} fileName="taxi-invoice.pdf">
                                    {({ blob, url, loading, error }) => (loading ? ' Loading document...' : ' Download PDF')}
                                </PDFDownloadLink>
                                }
                                {!invoice.is_paid && <Button onClick={() => handleSetAsPaid()}>Set as Paid</Button>}
                                </div>

                                <h4 className="mb-3">Sales</h4>
                                <Table className="mb-5">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Guest Name</th>
                                        <th>Paid At Hotel</th>
                                        {
                                            !isEstablishmentInvoice(invoice) &&
                                            <th>Paid At Driver</th>
                                        }
                                        {
                                            isEstablishmentInvoice(invoice) &&
                                            <th>Booked By Staff</th>
                                        }
                                        <th>Invoice Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {invoice.sales.length > 0 && invoice.sales.map((sale, index) => (
                                            <tr key={`invoicesale-${index}`}>
                                            <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{Moment(sale.completed_time).format('DD.MM.YYYY')}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}</LinkTD>
                                            {
                                                isEstablishmentInvoice(invoice)
                                                ?
                                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getItemSaleAmount(sale)} CHF</LinkTD>
                                                :
                                                <>
                                                    <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.paid_over_counter ? getItemSaleAmount(sale) : 0} CHF</LinkTD>
                                                    <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.paid_over_counter ? 0 : getItemSaleAmount(sale)} CHF</LinkTD>
                                                </>
                                            }
                                            {
                                                isEstablishmentInvoice(invoice) &&
                                                // <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.commission_amount.toFixed(2)} CHF</LinkTD>
                                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getStaffName(sale)}</LinkTD>
                                            }
                                            {
                                                isEstablishmentInvoice(invoice) 
                                                ?
                                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{getItemSaleAmount(sale)} CHF</LinkTD> 
                                                :
                                                <LinkTD to={`/taxi-booking/${sale.booking_id}`}>{sale.bookingsource_cashback.toFixed(2)} CHF</LinkTD>
                                            }
                                            </tr>
                                        ))}
                                        {/* {
                                            isEstablishmentInvoice(invoice) &&
                                            <tr>
                                                <td></td>
                                                <td></td> 
                                                <td></td> 
                                            <td>Total Booked By Staff</td>
                                            <td>{invoice?.commission_amount?.toFixed(2)} CHF</td>
                                            <td></td>
                                            </tr>
                                        } */}
                                        {
                                            !isEstablishmentInvoice(invoice) &&
                                            <tr>
                                                <td></td>
                                                <td></td> 
                                                <td></td> 
                                                <td>Less Amount Paid At Hotel</td>
                                                <td>{invoice.sales.reduce((n, s) => n+ (s.paid_over_counter ? getItemSaleAmountInDecimal(s) : 0), 0).toFixed(2)} CHF</td>
                                                <td></td>
                                            </tr>
                                        }
                                        <tr>
                                            {
                                                isEstablishmentInvoice(invoice)
                                                ?
                                                <>
                                                    <td></td>
                                                    <td></td> 
                                                    <td></td> 
                                                </>
                                                :
                                                <>
                                                    <td style={{borderTop:'0px'}}></td>
                                                    <td style={{borderTop:'0px'}}></td> 
                                                    <td style={{borderTop:'0px'}}></td> 
                                                </>
                                            }
                                            <td>SubTotal</td>
                                            <td>{invoice?.sub_amount?.toFixed(2)} CHF</td>
                                            <td></td>
                                        </tr>
                                        {
                                            invoice.others && invoice.others.monthly_charge &&
                                            <tr>
                                            <td style={{borderTop:'0px'}}></td>
                                            <td style={{borderTop:'0px'}}></td> 
                                            <td style={{borderTop:'0px'}}></td> 
                                            <td>Monthly Charge</td>
                                            <td>{invoice.others.monthly_charge.toFixed(2)} CHF</td>
                                            </tr>
                                        }
                                        <tr>
                                            <td style={{borderTop:'0px'}}></td>
                                            <td style={{borderTop:'0px'}}></td> 
                                            <td style={{borderTop:'0px'}}></td> 
                                            <td>Total </td>
                                            <td><strong style={{fontSize:'20px'}}>{invoice.amount.toFixed(2)} CHF</strong></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </>
                                }
                            </>
                            }
                            
                            
                    
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default TaxiInvoicePage