import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const DialogBoxModal = ({ display, toggle, title, message, buttonText = 'OK', 
    extraButtonAction, extraButtonText = 'Go',
    extraButtonAction2, extraButtonText2 = 'Go' }) => {

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        backdropClassName="level-2"
        centered
        >
            <Modal.Body>
                <h2 className="text-center">{title}</h2>
                <p className="text-center">{message}</p>
                <Button className="w-100" onClick={() => toggle(false)}>{buttonText}</Button>
                {extraButtonAction && extraButtonText && 
                    <Button className="w-100 mt-2" variant="outline-dark" onClick={() => extraButtonAction()}>{extraButtonText}</Button>
                }
                {extraButtonAction2 && extraButtonText2 && 
                    <Button className="w-100 mt-2" variant="outline-dark" onClick={() => extraButtonAction2()}>{extraButtonText2}</Button>
                }
            </Modal.Body>
        </Modal>
  )
}

export default DialogBoxModal

