import { isSameDay } from 'date-fns/esm';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Pagination, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getAllHotelAccounts, getHotelAccountsByHotelId, getSimulAccounts } from '../../services/accounts';
import { getCurrentUser } from '../../services/auth';
import { cancelBooking, getUnconfirmedHotelBookings, updateBookingAsConfirmed, getEstablishmentBookings } from '../../services/shuttleBookings';
import { getAllDestinations } from '../../services/shuttleDestinations';
import { getDestinationName, isDateTimePassedMidnight, isHotelAccount, isHotelStaff, isSimulAccount, isAirlineAccount } from '../../utils/utils';
import AssignDriverModal from '../modals/assignDriverModal';
import ChangeBookingDateModal from '../modals/changeBookingDate';
import ConfirmationModal from '../modals/confirmation';
import InfoModal from '../modals/infoModal';
import LinkTD from './linkTd';
import PageLoader from './pageLoader';

const UnconfirmedBookingsList = () => {

    const PAGE_SIZE = 20
    
    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [bookingConfirmationModal, setBookingConfirmationModal] = useState(false)
    const [assignDriverModal, setAssignDriverModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)

    const [bookings, setBookings] = useState([])
    const [selectedBooking, setSelectedBooking] = useState()
    const [destinations, setDestinations] = useState()
    const [simulAccounts, setSimulAccounts] = useState()
    const [hotelAccounts, setHotelAccounts] = useState()
    const [bookingToConfirm, setBookingToConfirm] = useState()

    const _currentUser = getCurrentUser()

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [previouses,setPreviouses] = useState([])
    const [disableNext,setDisableNext] = useState(true)

    const [displayInfoModal, setDisplayInfoModal] = useState(false)
    const [infoModalTitle, setInfoModalTitle] = useState('')
    const [infoModalMessage, setInfoModalMessage] = useState([])

    Moment.locale('en');

    useEffect(() => {
        fetch()
        fetchDestinations()
        fetchHotelAccounts()

        if(isSimulAccount())
        {
            fetchSimulAccounts();
        }
        
    }, [])

    const fetch = async () => {

        setIsLoading(true);
        const { data: { message } } =  await getUnconfirmedHotelBookings(PAGE_SIZE+1, undefined, undefined)

        if(Array.isArray(message))
        {
            let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date))
                .filter(booking => !booking.isCancelled)
                
            setBookings(sortedItems.slice(0, PAGE_SIZE))

            setDisableNext(sortedItems.length <= PAGE_SIZE)
            // checkNextPage(sortedItems)
        }

        setIsLoading(false)
    }

    // const checkNextPage = async (sortedItems) =>{

    //     let tempBookings = sortedItems.filter(booking => !booking.isCancelled)
    //     const tempCurrentBooking = tempBookings[tempBookings.length-1] 

    //     const { data: {  message } } =  await getUnconfirmedHotelBookings(20, tempCurrentBooking.bookingDate, undefined)
        
    //     if(Array.isArray(message) && message.length >0){
            
    //         setDisableNext(false)
    //     }
    // }

    const renderPaginationButtons = () => {
        let items = []
        
        items.push(<Pagination.First className="pagination" disabled={currentPageNumber===1} key={`page-first`} onClick={() => changePage('first')}  title="first page"  style={{fontSize:20}}/>)
        items.push(<Pagination.Prev className="pagination" disabled={currentPageNumber===1} key={`page-previous`} onClick={() => changePage('previous')} title="previous page"  style={{fontSize:20}}/>)

        items.push(<Pagination.Next className="pagination" disabled={disableNext} key={`page-next`} onClick={() => changePage('next')} title="next page"  style={{fontSize:20}}/>)

        return items
    }

    const changePage =  (page) => {

        if(page === 'next'){
            renderNext()
            setCurrentPageNumber(currentPageNumber + 1)
            return
        }
        
        if(page === 'previous'){
            renderPrevious()
            setCurrentPageNumber(currentPageNumber - 1)
            return
        }

        if(page === 'first'){
            setPreviouses([]);
            setBookings([]);
            fetch();
            setCurrentPageNumber(1)
            return
        }
       
    }

    const renderNext = async () => {

        setIsLoading(true)

        const currentBooking = bookings.slice(-1)[0] 
      
        const previous = {
            'pageNumber': currentPageNumber,
            'items': bookings
        }

        const tempPreviouses = previouses
        tempPreviouses.push(previous)
        setPreviouses(tempPreviouses)

        const { data: { code, message } } =  await getUnconfirmedHotelBookings(PAGE_SIZE+1, currentBooking.booking_date, undefined)

        setDisableNext(true)
        if(code === 200) {
            if(Array.isArray(message) )
            {
                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date))
                    .filter(booking => !booking.isCancelled)

                setBookings(sortedItems.slice(0, PAGE_SIZE))

                setDisableNext(sortedItems.length <= PAGE_SIZE)

                // checkNextPage(sortedItems)

            }
        }

        setIsLoading(false)
    }

    const renderPrevious = () => {

        const previousItem = previouses[currentPageNumber-2]
        setBookings(previousItem['items'])
        previouses.splice(currentPageNumber-2, 1)
        setCurrentPageNumber(currentPageNumber - 1 )
        setDisableNext(false)

    }

    const fetchDestinations = async () => {
        const { data: { message } } = await getAllDestinations()
        
        setDestinations(message)
    }

    const fetchSimulAccounts = async () => {
        const { data: { message } } = await getSimulAccounts()
        if(message == 'No simul accounts found.'){
            return;
        }
        setSimulAccounts(message)
    }

    const fetchHotelAccounts = async () => {

        if(isHotelStaff() || isAirlineAccount()){
            return;
        }

        const { data: { code, message } } = isSimulAccount() ? await getAllHotelAccounts() : await getHotelAccountsByHotelId(_currentUser.hotel_id)
        if(code !== 200){
            return;
        } else {
            setHotelAccounts(message)
        }
    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleCancelBooking = async () => {
        await cancelBooking(selectedBooking._id)
        var _bookings = bookings
        var index = _bookings.indexOf(selectedBooking);
        _bookings.splice(index, 1);
        setBookings([..._bookings])
    }

    const handleAssignDriverModal = (booking) => {
        setSelectedBooking(booking)
        setAssignDriverModal(true)
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const bookingDateUpdated = (newBookingDate) => {
        const bookingIndex = bookings.indexOf(selectedBooking)
        let _bookings = bookings
        _bookings[bookingIndex].booking_date = newBookingDate
        setBookings(_bookings)
    }

    const getDriverName = (id) => {
        
        if(!simulAccounts || simulAccounts.length == 0){
            return '';
        }
        const driver = simulAccounts.find(x => x._id == id)
        if(driver) {
            if (driver.first_name && driver.last_name)
                return `${driver.first_name} ${driver.last_name}`
            else
                return driver.username
        } else {
            return 'no driver'
        }
    }

    const getBookerName = (booking) => {

        let bookerName = booking.booking_source_username;

        if(!hotelAccounts || hotelAccounts.length == 0){
            return bookerName;
        }
        const hotelAccount = hotelAccounts.find(x => x._id == booking.booking_source_user_id);
        bookerName = hotelAccount ? hotelAccount.username : bookerName;

        return bookerName;
    }

    const isPastBooking = booking => {
        return Moment(Moment().local().utcOffset(0, true).format())
            .isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canChangeTime = booking => {
        const is1HourBeforeDeparture = Moment(Moment(Moment().add(1, 'hours')).local().utcOffset(0, true).format())
            .isSameOrAfter(Moment.utc(booking.booking_date))
        return !is1HourBeforeDeparture && !booking.is_confirmed
    }

    const canCancel = (booking) => {

        const isSuperAdmin =  _currentUser.role_name === 'system-admin'
        if(isSuperAdmin) return true

        //const is30MinsBeforeDeparture = Moment(Moment(Moment().add(30, 'minutes')).local().utcOffset(0, true).format())
        //    .isSameOrAfter(Moment.utc(booking.booking_date))

        //NOTE: if you created the booking today, you can no longer cancel the booking starting midnight
        const createdDateHasPassedMidnight = isDateTimePassedMidnight(booking.created_date);

        return !createdDateHasPassedMidnight
            //!is30MinsBeforeDeparture 
            && !isPastBooking(booking)
            && !booking.is_confirmed
            
    }

    const handleInfoModalDisplayReasons = (booking) => {
        const reasons = getCancelRestrictionReasons(booking)
        setInfoModalTitle('Reservation cannot be canceled')
        setInfoModalMessage(reasons)
        setDisplayInfoModal(true)
    }

    const getCancelRestrictionReasons = (booking) => {

        //NOTE: if you created the booking today, you can no longer cancel the booking starting midnight
        const createdDateHasPassedMidnight = isDateTimePassedMidnight(booking.created_date);

        let reasons = []

        if(isPastBooking(booking)) reasons.push('Reservation has been processed already')
        else if(booking.is_confirmed) reasons.push('Reservation has been confirmed already')

        if(createdDateHasPassedMidnight) reasons.push('Creation date has passed midnight')

        return reasons
    }

    const driverUpdate = (driverId) => {
        let _bookings = bookings
        const index = _bookings.indexOf(selectedBooking)
        _bookings[index].assigned_driver_id = driverId
        setBookings(_bookings)
    }

    const renderAssignDriverButton = (booking) => {

        if(!booking.assigned_driver_id) {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                    <i className="fas fa-plus"></i>
                </Button>)
        } else {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                   <i className="fas fa-pencil-alt"></i>
                </Button>)
        }  
    }

    const getRowClassName = (booking, index) => {

        var rowClassName = ''
        if(isPastBooking(booking))
            rowClassName += 'booking-past '

        if(bookings[index+1] && (!isSameDay(new Date(bookings[index].booking_date), new Date(bookings[index+1].booking_date))))
            rowClassName += 'row-breakpoint '

        return rowClassName
    }

    const handleBookingConfrimationModal = (booking) => {
        setBookingToConfirm(booking)
        setBookingConfirmationModal(true)
    }

    const confirmGuestBooking = async () => {
        const {data: {code, message}} = await updateBookingAsConfirmed(bookingToConfirm._id)
            if (code !== 200) {
                toast.error(` Unable to confirm booking!.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                toast.success(` Booking successfully confirmed!.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
    }

    return <>

        {isLoading && <PageLoader />}
        {selectedBooking && 
        <ChangeBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} destinations={destinations} bookingDateUpdated={bookingDateUpdated}/>
        }
        {selectedBooking && isSimulAccount() &&
        <AssignDriverModal display={assignDriverModal} toggle={setAssignDriverModal} booking={selectedBooking} driverUpdate={driverUpdate} />
        }
        
        <ConfirmationModal 
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />

        <ConfirmationModal
            title="Confirm Booking"
            message="Do you want to confirm this booking?"
            display={bookingConfirmationModal} toggle={setBookingConfirmationModal} confirmAction={confirmGuestBooking} />
        
        <InfoModal 
            title={infoModalTitle}
            messages={infoModalMessage}
            display={displayInfoModal} toggle={setDisplayInfoModal} />

        <Card className='w-100 bookings-list'>
            <Card.Header className='text-center bg-primary text-white'>Unconfirmed Bookings</Card.Header>
            <Card.Body>
                <Table responsive hover>
                    <thead className="border-top-0">
                        <tr>
                            <th></th>
                            <th>Booked Date</th>
                            <th>Time</th>
                            <th>Guest Name</th>
                            <th>Booked By</th>
                            <th>Pickup</th>
                            <th>{isAirlineAccount() ? "Flight #" : "Room #"}</th>
                            <th>Seats</th>
                            <th>Destination</th>
                            { 
                                isSimulAccount() &&
                                <th>Driver</th>
                            }  
                            {
                                !isAirlineAccount() && <th>Actions</th>
                            }
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                        bookings.map((booking, index) =>
                            <React.Fragment key={`bookinglist-${index}`}>
                            <tr className={getRowClassName(booking, index)}>
                                <LinkTD to={`/booking/${booking._id}`}>
                                    <div className="d-flex align-items-center">
                                        <i style={{color:(booking.is_paid ? 'Tomato': 'LightGray')}} 
                                            className='fas fa-money-bill-alt' 
                                            title={(booking.is_paid && booking.paid_by_guest ? 'Paid By Guest' : '')}></i>
                                    </div>
                                </LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{Moment.utc(booking.booking_date).format('DD.MM.YYYY')}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{Moment.utc(booking.booking_date).format('HH:mm')}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{getBookerName(booking)}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.origin}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                <LinkTD to={`/booking/${booking._id}`}>{destinations && getDestinationName(booking, destinations)}</LinkTD>

                                {
                                    !isAirlineAccount() &&
                                    <td>
                                        {canChangeTime(booking) && isHotelAccount() &&
                                            <Button className="mr-2 mb-1" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>Change Time</Button>
                                        }
                                        {
                                            canCancel(booking)
                                                ? <Button className="mb-1 mr-1" size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                                : <Button className="mb-1 mr-1" disabled variant="secondary" size="sm"><span style={{ cursor: 'pointer' }} onClick={() => handleInfoModalDisplayReasons(booking)}>Cancel</span></Button>
                                        }
                                        <Button className="mr-2 mb-1" variant="outline-primary" size="sm" onClick={() => handleBookingConfrimationModal(booking)}>Confirm</Button>
                                    </td>
                                }

                            </tr>
                            </React.Fragment>
                        )
                        }
                    </tbody>
                </Table>

                <Pagination className="mb-0 justify-content-center override-pagination">

                                {renderPaginationButtons()}

                </Pagination>
            </Card.Body>
        </Card>
    </>
}

export default UnconfirmedBookingsList