import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { get } from '../../services/airlineCrews';
import { useState } from 'react';

const AirlineCrewSelect = ({ selected = '', label = 'Airline Crew', onSelect = () => {}, ...rest }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [airlineCrews, setAirlineCrews] = useState([])

    const handleChange = (e) => {
      const value = e.target.value
      onSelect(airlineCrews.find(v => v._id === value))
    }
  
    useEffect(() => {
        const fetchAirlineCrews = async () => {
            setIsLoading(true)
            const { data: { message, code } } = await get()
            setIsLoading(false)
            if (code === 200) {
                setAirlineCrews(message);
            }
        }

       fetchAirlineCrews()
    }, [])

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control as='select' value={selected} onChange={handleChange} {...rest} >
                <option value=""></option>
                { isLoading && <option disabled={true}>loading...</option> }
                {airlineCrews.map((crew) => (
                    <option key={crew._id} value={crew._id}>{crew.airline_name}</option>
                ))}
            </Form.Control>
        </Form.Group>
    )
}

export default AirlineCrewSelect