import { differenceInMinutes } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { getCurrentUser } from '../../services/auth'
import { getHotelById } from '../../services/hotels'
import { bookShuttle } from '../../services/shuttleBookings'
import { ignoreOffsetISOString, isAirlineAccount } from '../../utils/utils'
import DialogBoxModal from './dialogBox'
import LinkTD from '../common/linkTd';

const BookShuttleModal = ({ display, toggle, datetime, timedata, availableSeats, totalSeats, 
    shuttleDestination, bookedShuttle, hotel, airline, hasBookings, printBooking, setIsLoading }) => {

    const history = useHistory();

    const [dialogModalTitle, setDialogModalTitle] = useState('');
    const [dialogModalDesc, setDialogModalDesc] = useState('');
    const [dialogModal, setDialogModal] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [roomNumber, setRoomNumber] = useState('')
    const [numberOfSeats, setNumberOfSeats] = useState(1)
    const [reservationNumber, setReservationNumber] = useState()
    const [booking, setBooking] = useState()

    const _currentuser = getCurrentUser()

    const hasNoPriceSet = () => {
        const seatPriceText = shuttleDestination.is_common_origin ? getCommonOriginTotalPrice() : getTotalPrice()
        const seatPrice = parseFloat(seatPriceText.replace('CHF ', ''))
        return seatPrice < 0
    }

    const setSelectedDestination = (val) => {
    }

    const handleBookShuttle = async (e) => {

        e.preventDefault()
        setIsLoading(true)

        const isLayover = window.location.pathname.toLowerCase().includes('layover')

        const _bookingDetails = {
            "booking_type": "shuttle",
            "origin": shuttleDestination.origin,
            "shuttle_destination_id": shuttleDestination._id,
            "booking_source_type": isAirlineAccount() ? "airline" : "hotel",
            "booking_source_id": _currentuser[isAirlineAccount() ? "airline_id" : "hotel_id"],
            "booking_source_user_id": _currentuser._id,
            "booking_source_username": _currentuser.username,
            "created_date": new Date(),
            "booking_date": ignoreOffsetISOString(datetime),
            "booking_time_id": timedata._id,
            "dest_place_type": shuttleDestination.is_common_origin ? "hotel" : "",
            "dest_place_id": shuttleDestination.is_common_origin ? hotel._id : "",
            "layover_place_type": isLayover && airline?._id ? "airline" : "",
            "layover_place_id": isLayover && airline?._id ? airline._id : "",
            "customer": {
                "first_name": firstname,
                "last_name": lastname,
                "booking_source_number": roomNumber,
                "email": email
            },
            "number_of_persons": availableSeats.length > 0 ? numberOfSeats : null,
            "reservation_number": reservationNumber,
            "assigned_driver_id": null,
            "vehicle_id": null,
            "fulfilled": false,
            "fulfilled_date": null,
            "is_paid": true,
            "total_price": getTotalPriceValue()
        }

        const { data: { code, message } } = await bookShuttle(_bookingDetails, timedata.vehicle_ids)

        if (code !== 200) {
            toast.error(`Error! ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            //setBookingDetails(message)
            setDialogModalTitle('Good Job!')
            setDialogModalDesc('You have successfully created your booking.');
            setDialogModal(true)
            document.getElementById('book-shuttle-form').reset()
            setNumberOfSeats(1)
            bookedShuttle(message)
            toggle(false)
            setBooking(message)
        }

        setIsLoading(false)

    }

    const canBookTime = () => {

        const dateTimeNow = new Date()
        const minutesDifference = differenceInMinutes(datetime, dateTimeNow)

        let _canBookTime = true;

        //if no bookings and current time is 10 minutes before departure time
        if (!hasBookings && minutesDifference <= 10) _canBookTime = false
        //if there are bookings AND current time is 5 minutes ahead of the departure time
        else if (hasBookings && minutesDifference <= -5) _canBookTime = false

        return _canBookTime
    }

    const getSeatsTaken = () => {
        if (!availableSeats || !availableSeats.length) return 0
        return Math.max(0, totalSeats - parseInt(availableSeats[availableSeats.length - 1]))
    }
    const getTotalPrice = () => {
        const timeSched = shuttleDestination.timetable.find(x => x._id == timedata._id);
        const seatPrice = timeSched?.seat_price ?? 0.0
        const totalPrice = seatPrice * numberOfSeats
        return `${timeSched?.seat_price_currency ?? 'CHF'} ${totalPrice.toFixed(2)}`
    }

    const getCommonOriginTotalPrice = () => {
        const timeSched = shuttleDestination.timetable.find(x => x._id == timedata._id);
        const seatPrice = timeSched.seat_price_list.find(x => x.establishment_id == hotel._id)?.amount ?? 0.0
        const totalPrice = seatPrice * Math.max(1, numberOfSeats)
        return `${timeSched?.seat_price_currency ?? 'CHF'} ${totalPrice.toFixed(2)}`
    }

    const getTotalPriceValue = () => {
        const timeSched = shuttleDestination.timetable.find(x => x._id == timedata._id);

        if (shuttleDestination.is_common_origin) {
            const seatPrice = timeSched.seat_price_list.find(x => x.establishment_id == hotel._id)?.amount ?? 0.0
            const totalPrice = seatPrice * Math.max(1, numberOfSeats)
            return Math.round(totalPrice * 100) / 100
        } else {
            const seatPrice = timeSched?.seat_price ?? 0.0
            const totalPrice = seatPrice * numberOfSeats
            return Math.round(totalPrice * 100) / 100
        }
    }

    const hasNoAvailableSeats = () => {

        return availableSeats.length > 0 ? false : true
    }


    const handleModalOnEnter = () => {
        setNumberOfSeats(1)
        generateReservationNumber()
    }

    const generateReservationNumber = () => {
        const resNum = new Date().getTime()
        setReservationNumber('SB-' + resNum)
    }

    const handlePrintTicket = () => {
        history.push(`/booking/${booking._id}?print-ticket=true`);
    }

    return (
        <>
            <DialogBoxModal
                display={dialogModal}
                toggle={setDialogModal}
                title={dialogModalTitle}
                message={dialogModalDesc}
                extraButtonAction={printBooking}
                extraButtonText='Print Booking'
                extraButtonAction2={handlePrintTicket}
                extraButtonText2='Go to Booking Details'
            />
            <Modal
                show={display}
                onHide={() => toggle(false)}
                size="sm"
                onEnter={() => handleModalOnEnter()}
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col>
                            <div className="text-left pt-1"><i className="fas fa-bus"></i></div>
                        </Col>
                        <Col xs="10" className="p-0">
                            <div>{
                                hasNoAvailableSeats() ?
                                    <h4 className="text-danger">Not Available</h4>
                                    : <h4>Book Shuttle</h4>}
                            </div>
                        </Col>

                    </Row>

                    <hr className="my-2" />
                    <Form id="book-shuttle-form" onSubmit={(e) => handleBookShuttle(e)}>
                        <Row>
                            <Col xs='4'>
                                Price:
                            </Col>
                            <Col>
                                <h4 className="mb-0">{shuttleDestination.is_common_origin ? getCommonOriginTotalPrice() : getTotalPrice()}</h4>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Date:
                            </Col>
                            <Col className='text-uppercase'>
                                <strong>
                                    {datetime.toLocaleDateString('es-ES', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                    })}
                                    {' @'}{timedata?.time}
                                </strong>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Firstname:
                            </Col>
                            <Col>
                                <Form.Control required size="sm" onChange={(e) => setFirstname(e.target.value)} disabled={hasNoAvailableSeats()} />
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Lastname:
                            </Col>
                            <Col>
                                <Form.Control required size="sm" onChange={(e) => setLastname(e.target.value)} disabled={hasNoAvailableSeats()} />
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Email:
                            </Col>
                            <Col>
                                <Form.Control type="email" size="sm" onChange={(e) => setEmail(e.target.value)} disabled={hasNoAvailableSeats()} />
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='auto'>
                                Reservation #:
                            </Col>
                            <Col className="pl-0">
                                <strong>{reservationNumber}</strong>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        {!shuttleDestination.is_common_origin ?
                            <>
                                <div className="form-inline">
                                    <span className="mr-2">Room #: </span>
                                    <Form.Control required size="sm" style={{ width: '60px' }} onChange={(e) => setRoomNumber(e.target.value)} disabled={hasNoAvailableSeats()} />
                                </div>
                                <hr className="my-2" />
                            </> :
                            ''}

                        {isAirlineAccount() &&
                            <>
                                <div className="form-inline">
                                    <span className="mr-2">Flight #: </span>
                                    <Form.Control required size="sm" style={{ width: '100px' }} onChange={(e) => setRoomNumber(e.target.value)} />
                                </div>
                                <hr className="my-2" />
                            </>
                        }

                        <div className="form-inline"><span className="mr-2">Number of Seats: </span>

                            {availableSeats.length > 0 ?
                                <select className="form-control form-control-sm" onChange={(e) => setNumberOfSeats(e.target.value)}>
                                    {availableSeats.map((seat) => (
                                        <option key={`seat-${seat}`} value={seat}>{seat}</option>
                                    ))}
                                </select>
                                :
                                <p className="mb-0"><small>No available</small></p>
                            }
                        </div>
                        <hr className="my-2" />
                        <div className="form-inline"><span className="mr-2">Seats Taken: </span>
                            <p className="mb-0"><strong>{getSeatsTaken()}</strong></p>
                            <p><i><small>Orders from other hotels included</small></i></p>
                        </div>
                        <hr className="my-2" />
                        <div className="px-3 py-2 text-center">
                            {canBookTime() && !hasNoPriceSet() ?
                                <Button type="submit" variant="outline-primary"
                                    disabled={hasNoAvailableSeats() || hasNoPriceSet()}>Book Now</Button>
                                :
                                <Alert variant="danger" className="p-2 text-center">
                                    <small>
                                        {hasNoPriceSet() ? 'No price is set for this schedule. Please contact the administrator.' : 'You can not book this selected time.'}
                                    </small>
                                </Alert>
                            }
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BookShuttleModal

