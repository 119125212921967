
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import { getCurrentUser } from '../../services/auth';
import { getHotelById } from '../../services/hotels';
import { bookTaxi } from '../../services/taxiBookings';
import BookTaxiDestinations from './bookTaxiDestinations';
import { getCountries } from '../../services/address';
import { getCarCategories } from '../../services/carCategories';
import AutoComplete from 'react-google-autocomplete';
import { handleAutocompleteAddressChange } from '../../utils/autocompleteAddressChangeHandler';

const BookTaxiPage = () => {

    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [numberOfPersons, setNumberOfpersons] = useState()
    const [roomNumber, setRoomNumber] = useState()
    const [isWalkIn, setIsWalkIn] = useState('yes')
    const [isPaid, setIsPaid] = useState('no')
    const [selectedBookingDate, setSelectedBookingDate] = useState(new Date());
    const [hotel, setHotel] = useState()
    const [carCategories, setCarCategories] = useState([])

    const [formTitle, setFormTitle] = useState();

    const [freeDestination, setFreeDestination] = useState('')
    const [carCategoryId, setCarCategoryId] = useState('')

    const [noteToDriver, setNoteToDriver] = useState('')
    const [chargeToHotel, setChargeToHotel] = useState(false)

    const [hasSelectedDestination, setHasSelectedDestination] = useState(false)
    const [selectedDestination, setSelectedDestination] = useState()
    const [filteredCarCategories, setFilteredCarCategories] = useState([])
    const [selectedCarCategory, setSelectedCarCategory] = useState()
    const [matchedCarCategory, setMatchedCarCategory] = useState()

    const _currentUser = getCurrentUser();

    const history = useHistory()

    registerLocale('en-gb', enGb);

    useEffect(() => {
        fetchHotel();
        fetchCarCategories();
    }, [])

    useEffect(() => {
        setSelectedBookingDate(isWalkIn === 'yes' ? '' : new Date())
    }, [isWalkIn])

    const fetchHotel = async () => {
        const { data: { message } } = await getHotelById(_currentUser.hotel_id);
        setHotel(message);
    }

    const fetchCarCategories = async () => {
        const { data: { code, message } } = await getCarCategories();
        if (code == 200) {
            setCarCategories(message)
        }
    }

    const handleBookTaxi = async () => {

        const payload = {
            "origin": hotel.hotel_name,
            "booking_source_type": "hotel",
            "booking_source_id": _currentUser.hotel_id,
            "booking_source_user_id": _currentUser._id,
            "booking_source_username": _currentUser.username,
            "booking_date": isWalkIn === 'yes' ? new Date() : selectedBookingDate,
            "is_walk_in": isWalkIn === 'yes' ? true : false,
            "customer": {
                "first_name": firstname,
                "last_name": lastname,
                "phone": phoneNumber,
                "booking_source_number": roomNumber,
                "email": email
            },
            "notes": noteToDriver,
            "charge_to_hotel": chargeToHotel,
            "fulfilled": false
        }
        if (numberOfPersons) payload["number_of_persons"] = numberOfPersons
        if (isFixedDestination(selectedDestination)) {
            payload["destination"] = {
                "fixed_destination_id": selectedDestination._id,
                "fixed_destination_name": selectedDestination.destination_name,
                "fixed_destination_car_category_id": matchedCarCategory ? matchedCarCategory.car_category_id : '',
                "fixed_destination_car_category_price": matchedCarCategory ? matchedCarCategory.price : 0
            }
        } else {
            payload["destination"] = {
                "free_input_address": freeDestination?.formatted_address ? freeDestination?.formatted_address : freeDestination,
                "free_input_car_category_id": carCategoryId
            }
        }

        payload.is_paid = isPaid === 'yes' ? true : false;
        if (payload.is_paid) {
            payload.payment_info = {
                paid_over_counter: true,
                other: "Paid by guest in the hotel"
            }
        }

        const { data: { code, message } } = await bookTaxi(payload)
        if (code == 200) {
            toast.success(`Booking successful.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            document.getElementById("booktaxi-form").reset();
            history.push(`/taxi-booking/${message._id}`)
        } else {
            toast.error(`Taxi booking failed. ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const getMatchedCarCategory = (destination, _numberOfPersons) => {
        const matchedCarCats = destination.car_categories.filter(x => x?.category_info?.seats >= _numberOfPersons);
        return matchedCarCats.sort((a, b) => a.category_info.seats - b.category_info.seats)[0];
    }

    const getBestMatchedCarCategoryOpenDestination = (_numberOfPersons) => {
        const matchedCarCats = carCategories.filter(x => x.seats >= _numberOfPersons);
        return matchedCarCats.sort((a, b) => a.seats - b.seats)[0];
    }

    const handleNumberOfPersonsChange = (value) => {

        setNumberOfpersons(value);

        if (isFixedDestination(selectedDestination)) {
            if (selectedDestination.car_categories) {
                let _carCategories = selectedDestination.car_categories.filter(x => x?.category_info?.seats >= value).sort((a, b) => a.category_info.seats - b.category_info.seats);
                setFilteredCarCategories([..._carCategories])
            }

            if (hasMatchedCarCategory(selectedDestination, value)) {
                setMatchedCarCategory(getMatchedCarCategory(selectedDestination, value));
            }
            else {
                setMatchedCarCategory(undefined);
            }
        } else {
            //NOTE: we don't setMatchedCarCategory
            //only setCarCategoryIdForOpenDestination since this is open destination and price is not yet identified
            let _carCategories = carCategories.filter(x => x.seats >= value).sort((a, b) => a.seats - b.seats);
            setFilteredCarCategories([..._carCategories])
            const bestMatch = getBestMatchedCarCategoryOpenDestination(value)
            setCarCategoryId(bestMatch?._id ?? undefined)
        }
    }

    const handleSelectCarCategory = (carCategoryId) => {
        let _carCategory = selectedDestination.car_categories.find(x => x.car_category_id === carCategoryId)
        setMatchedCarCategory(_carCategory)
    }

    const isFixedDestination = (destination) => {
        return !(typeof destination === 'string' || destination instanceof String)
    }

    const hasCarCategories = (destination) => {
        return destination && destination.car_categories && destination.car_categories.length > 0;
    }

    const hasMatchedCarCategory = (destination, _numberOfPersons) => {

        if (!_numberOfPersons || _numberOfPersons <= 0) {
            return false;
        }

        if (!isFixedDestination(selectedDestination)) {
            return false;
        }

        if (!hasCarCategories(destination)) {
            return false;
        }

        const matchedCarCats = destination.car_categories.filter(x => x?.category_info?.seats >= _numberOfPersons);
        if (!matchedCarCats || matchedCarCats.length <= 0) {
            return false;
        }

        return true;
    }

    const selectDestination = (bookingType, destination) => {
        setSelectedDestination(destination)
        setFreeDestination(destination.destination_name)
        setHasSelectedDestination(true)
        setIsWalkIn(bookingType === "walk-in" ? 'yes' : 'no')
        setFormTitle(bookingType === "walk-in" ? 'TAXI WALK-IN' : 'TAXI RESERVATION');
    }

    const handleSetCarCategoryId = (carCategoryId) => {
        setCarCategoryId(carCategoryId)
    }

    return <>
        <section>
            <Col>
                {!hasSelectedDestination ?
                    <BookTaxiDestinations selectDestination={selectDestination} />
                    :
                    <>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col lg="8">
                                                <div>
                                                    <Form id="booktaxi-form">
                                                        <Row className='mb-3'>
                                                            <Col>
                                                                <strong className='color-primary'>
                                                                    {formTitle}
                                                                    {" "} <i className=" fas fa-arrow-circle-right"></i> {" "}
                                                                    {isFixedDestination(selectedDestination) ? selectedDestination.destination_name.toUpperCase() : 'OPEN DESTINATION'}
                                                                </strong>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Destination</Form.Label>
                                                                    {/* <Form.Control disabled={isFixedDestination(selectedDestination)} value={freeDestination} type='text' onChange={(e) => setFreeDestination(e.target.value)} /> */}
                                                                    <AutoComplete
                                                                        placeholder="Search location"
                                                                        className="form-control"
                                                                        apiKey="AIzaSyByjT-kfojFHzn7VWxaM931kA2jEW-RVzo"
                                                                        options={{
                                                                            types: ["address"],
                                                                            componentRestrictions: { country: "ch" }
                                                                        }}
                                                                        defaultValue={selectedDestination.destination_name}
                                                                        disabled={isFixedDestination(selectedDestination)}
                                                                        onPlaceSelected={(place) => handleAutocompleteAddressChange(place, setFreeDestination)}
                                                                        onChange={(e) => handleAutocompleteAddressChange(e.target.value, setFreeDestination)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>First Name</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setFirstname(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Last Name</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setLastname(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Customer Email</Form.Label>
                                                                    <Form.Control type='email' onChange={(e) => setEmail(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Phone Number</Form.Label>
                                                                    <Form.Control type='text' onChange={(e) => setPhoneNumber(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label>Room Number</Form.Label>
                                                                            <Form.Control type='number' placeholder='' onChange={(e) => setRoomNumber(e.target.value)} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <Form.Group>
                                                                            <Form.Label>Number of Persons</Form.Label>
                                                                            <Form.Control type='number' placeholder='' onChange={(e) => handleNumberOfPersonsChange(e.target.value)} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Car Category</Form.Label>
                                                                    <Form.Control disabled={filteredCarCategories.length <= 0 || numberOfPersons <= 0} as="select" onChange={(e) => isFixedDestination(selectedDestination) ? handleSelectCarCategory(e.target.value) : handleSetCarCategoryId(e.target.value)}>
                                                                        {filteredCarCategories.map((carCategory, index) => (
                                                                            <option key={`carcategory-${index}`} value={carCategory.car_category_id ?? carCategory._id} >
                                                                                {carCategory?.category_info?.name ?? carCategory.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Booking Date</Form.Label>
                                                                    <DatePicker
                                                                        wrapperClassName="react-datepicker-wrapper-custom"
                                                                        placeholderText={isWalkIn === 'yes' ? 'Current date' : 'Select date'}
                                                                        disabled={isWalkIn === 'yes'}
                                                                        locale="en-gb"
                                                                        showTimeSelect
                                                                        selected={selectedBookingDate}
                                                                        onChange={(e) => setSelectedBookingDate(e)}
                                                                        dateFormat="dd.MM.yyyy HH:mm"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <Form.Group>
                                                                    <Form.Label>Note to Driver</Form.Label>
                                                                    <Form.Control as="textarea" value={noteToDriver} onChange={(e) => setNoteToDriver(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            {isFixedDestination(selectedDestination) &&
                                                                <Col>
                                                                    <Form.Group controlId="chargeToHotel">
                                                                        <Form.Label>&nbsp;</Form.Label>
                                                                        <Form.Check value={chargeToHotel} type="checkbox" label="Charge to Hotel" onChange={(e) => setChargeToHotel(e.target.checked)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                        </Row>

                                                        {
                                                            matchedCarCategory &&
                                                            <Row className="mt-2">
                                                                <Col lg="6">
                                                                    <strong>Total: {matchedCarCategory.price} CHF</strong>
                                                                    <Form.Group>
                                                                        <Form.Label><strong>Is the amount PAID?</strong></Form.Label>
                                                                        <Form.Control as="select" value={isPaid} onChange={(e) => setIsPaid(e.target.value)}>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-3">
                                                <a href="/book-taxi" className="btn btn-outline-primary mr-2">Back</a>
                                                <Button onClick={() => handleBookTaxi()}>Submit</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
        </section>
    </>
}

export default BookTaxiPage
