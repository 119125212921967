import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './App.css'
import LeftSidebar from './components/template/leftSidebar'
import admin from './pages/admin/admin'
import LoginPage from './pages/login'
import StripeResponsePage from './pages/stripeResponse'
//import ContactPage from './pages/contact'
import SocketContext, { socket } from './context/socket'
import PublicBookAirlineCrewPage from './pages/public/airCrew'
import PublicSearchAircrewBookingPage from './pages/public/searchAircrewBooking'

function App() {
  const [gmapsLoaded, setGmapsLoaded] = useState(false)

  useEffect(() => {
      window.initMap = () => setGmapsLoaded(true)
      const gmapScriptEl = document.createElement(`script`)
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY_FOR_MAPS}&libraries=places&callback=initMap`
      document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
  }, [])

  return (
    <>
    { gmapsLoaded &&
      <SocketContext.Provider value={socket}>
        <ToastContainer />
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/public/aircrew" exact component={PublicBookAirlineCrewPage} />
          <Route path="/public/aircrew/search" exact component={PublicSearchAircrewBookingPage} />
          <Route path="/admin" component={admin} />
          <Route path="/stripe-response" component={StripeResponsePage} />
          <Route component={LeftSidebar} />
        </Switch>
      </SocketContext.Provider>
    }
    </>
  )
}

export default App