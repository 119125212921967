import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PageLoader from '../components/common/pageLoader';
import ConfirmationModal from '../components/modals/confirmation';
import CreateCarCategoryModal from '../components/modals/createCarCategory';
import EditCarCategoryModal from '../components/modals/editCarCategory';
import { deleteCarCategory, getCarCategories } from '../services/carCategories';

const CarCategoriesPage = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [createCarCategoryModal, setCreateCarCategoryModal] = useState(false)
    const [editCarCategoryModal, setEditCarCategoryModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [categories, setCategories] = useState([])
    const [selectedCarCategory, setSelectedCarCategory] = useState()
    const [selectedCarCategoryId, setSelectedCarCategoryId] = useState()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } =  await getCarCategories()
        if (code == 200) {
            setCategories(message)
            setIsLoading(false)
        } else {
            toast.error(` ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setIsLoading(false)
        }
    }

    const handleDeleteCarCategory = async () => {
        const { data: { code, message } } =  await deleteCarCategory(selectedCarCategoryId)
        if (code == 200) {
           let _categories = categories
           const index = _categories.findIndex(x => x._id === selectedCarCategoryId)
           _categories.splice(index, 1)
           setCategories([..._categories])
        } else {
            toast.error(` ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const categoryCreated = (category) => {
        let _categories = categories ;
        _categories.push(category);
        setCategories([..._categories]);
    }

    const categoryUpdated = (category) => {
        let _categories = categories
        const index = _categories.indexOf(selectedCarCategory)
        _categories[index].name = category.name;
        _categories[index].seats = category.seats;
        _categories[index].price = category.price;
        setCategories([..._categories])
    }

    const confirmDeleteCarCategory = (carCategoryId) => {
        setSelectedCarCategoryId(carCategoryId)
        setConfirmationModal(true)
    }

    const handleEditCarCategoryModal = (category) => {
        setSelectedCarCategory(category)
        setEditCarCategoryModal(true)
    }

    return <>
        {isLoading && <PageLoader />}

        <CreateCarCategoryModal display={createCarCategoryModal} toggle={setCreateCarCategoryModal} categoryCreated={categoryCreated} />
        {selectedCarCategory && 
        <EditCarCategoryModal display={editCarCategoryModal} toggle={setEditCarCategoryModal} category={selectedCarCategory} categoryUpdated={categoryUpdated} />
        }
        <ConfirmationModal
            title="Remove Car Category"
            message="Are you sure you want to delete this category?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteCarCategory(selectedCarCategoryId)} />

        <section>
            <Col>
                <Row className="mb-4">
                    <Col>
                        <Button onClick={() => setCreateCarCategoryModal(true)}>Create Category</Button>
                    </Col>
                </Row>
                <Row>
                    {categories.length > 0 && categories.map((category, index) => (
                        <Col key={`carcategory-${index}`} lg="4">
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title className="mb-3">
                                        {category.name}
                                    </Card.Title>
                                    <Row className="mb-2">
                                        <Col lg="6">
                                            <p className="mb-0"><strong>Seats:</strong> {category.seats}</p>
                                        </Col>
                                    </Row>
                                    <p className="mb-1"><strong>Drivers:</strong></p>
                                    {category.taxi_drivers.length > 0 ?
                                        <>
                                        {category.taxi_drivers.map((driver, index) => (
                                            <Button key={`taxidriver-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                               {`${driver.first_name} ${driver.last_name}`}
                                            </Button>
                                        ))} 
                                        </>
                                        :
                                        <p className="mb-0"><small>Nothing set.</small></p>
                                    }

                                    <p className="mb-1"><strong>Destinations:</strong></p>
                                    {category.taxi_destinations && category.taxi_destinations.length > 0 ?
                                        <>
                                        {category.taxi_destinations.map((destination, index) => (
                                            <Button key={`destination-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                                {destination.establishment_origin_name} <i className=" fas fa-arrow-circle-right"></i> {destination.destination_name} - {destination.price} CHF
                                            </Button>
                                        ))} 
                                        </>
                                        :
                                        <p className="mb-0"><small>Nothing set.</small></p>
                                    }
                                </Card.Body>
                                <Card.Footer className="text-right">
                                    <Button size="sm" className="mr-2" onClick={() => handleEditCarCategoryModal(category)}>Edit</Button>
                                    <Button size="sm" variant="outline-primary" onClick={() => confirmDeleteCarCategory(category._id)}>Delete</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
        </section>
    </>
}

export default CarCategoriesPage