import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './assets/css/style.css'
import './assets/css/vstyle.css'
import './assets/css/animation.css'
import './index.css'
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from './serviceWorker'
import Favicon from 'react-favicon';
import favicon from './assets/images/favicon.ico';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <BrowserRouter>
       <Favicon url={favicon} />
      <App />
    </BrowserRouter>,
  document.getElementById("root")
)
serviceWorker.unregister()
