import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import ReserverPayableCommissionList from '../../components/admin/reserverPayableCommissionList';
import PreviousEarnings from '../../components/common/previousEarnings';
import { getAllHotelsPreviousEarnings } from '../../services/reserverCommissions';

const SystemAdminCommissionsPage = () => {

    // const [earnings, setEarnings] = useState([])

    // useEffect(() => {
    //     fetch()
    // }, [])

    // const fetch = async () => {
    //     const { data: { code, message } } = await getAllHotelsPreviousEarnings()
    //     if (code == 200) {
    //         setEarnings(message)
    //     }
    // }

    return (
        <section>
            <Col>
                <Card className="mb-4">
                    <Card.Header className='text-center bg-primary text-white'>Reserver Commissions</Card.Header>
                    <Card.Body>
                        <ReserverPayableCommissionList />
                    </Card.Body>
                </Card>
            </Col>
        </section>
    );
};

export default SystemAdminCommissionsPage;