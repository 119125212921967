import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Card, Table } from 'react-bootstrap';
import logo from '../../../assets/images/logo.svg';
import { getAirCrewInvoiceById } from '../../../services/airlineCrewInvoices';
import { displayAddress, toLocalDateString } from '../../../utils/utils';
import PageLoader from '../../common/pageLoader';
import LinkTD from '../../common/linkTd';

const AirCrewInvoicePage = (props) => {

    const { _id } = props.match.params

    const [isLoading, setIsLoading] = useState(false)

    const [invoice, setInvoice] = useState({})

    const invoiceRef = useRef()

    useEffect(() => {
        fetchInvoice()
    }, [])

    const fetchInvoice = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getAirCrewInvoiceById(_id)
        setIsLoading(false)
        if (code == 200) {
            setInvoice(message)
        } else {

        }
    }

    return (
        <>
            <Card className="mb-4" ref={invoiceRef}>
                <Card.Body>
                    {isLoading ?
                        <div className="position-relative" style={{ height: '100px' }}>
                            <PageLoader />
                        </div>
                        :
                        <>
                            <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex">
                                    <img style={{ width: '150px' }} src={logo} />
                                    <div className="pl-4">
                                        <p className="mb-0 color-primary"><strong>BILLED TO:</strong></p>
                                        <p className="text-bold mb-0"><strong>{invoice?.recipient_hotel?.hotel_name ?? invoice?.recipient_airline?.airline_name}</strong></p>
                                        {/* <p>{invoice?.recipient_hotel && displayAddress(invoice?.recipient_hotel?.address)}</p> */}

                                        <p className="mb-0"><strong>Invoice No:</strong> {invoice.invoice_no}</p>
                                        <p className="mb-0"><strong>Date Covered:</strong> {Moment.utc(invoice.period_from).format('DD.MM.YYYY') + " - " + Moment.utc(invoice.period_to).format('DD.MM.YYYY')}</p>
                                        <p className="mb-0"><strong>Total Bookings:</strong> {invoice.number_of_sales}</p>
                                    </div>
                                </div>
                                <div>
                                    <h1 className="text-end"><strong>INVOICE</strong></h1>
                                </div>
                            </div>
                            <Table hover responsive>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>Date</th>
                                        <th>Seat</th>
                                        <th>Origin</th>
                                        <th>Destination</th>
                                        <th>Crew Names</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice?.airlineCrewBookings?.length > 0 && invoice.airlineCrewBookings.map((booking, index) => (
                                        <tr key={`aircrew-booking-invoice-${index}`}>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>{Moment(booking.pickup_time).format('DD.MM.YYYY')} @ {Moment(booking.pickup_time).format('HH:mm')}</LinkTD>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.seat_name}</LinkTD>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.origin}</LinkTD>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.destination}</LinkTD>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>
                                                {
                                                    booking?.crew_names && booking?.crew_names.length > 0 &&
                                                    booking?.crew_names.map((crew_name, index) =>
                                                        <span key={`crew_name-${index}`}>
                                                            <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`${crew_name}-${index}`}>{crew_name}</Badge>
                                                        </span>
                                                    )
                                                }
                                            </LinkTD>
                                            <LinkTD to={`/aircrew-booking/${booking._id}`}>{booking.seat_price.toFixed(2)} CHF</LinkTD>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong style={{ fontSize: '20px' }}>{invoice?.amount?.toFixed(2)} CHF</strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    }
                </Card.Body>
            </Card>
        </>
    );
};

export default AirCrewInvoicePage;