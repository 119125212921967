import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const NavigationAirlineUser = ({airlineData, airCrews}) => {
    
    const allowedAirCrewBooking = airCrews && airCrews.some(x => x.allowed_crew_bookings_airlines &&
        x.allowed_crew_bookings_airlines.some(y => y === airlineData.id));

    return ( 
        <Nav className="topbar-nav mr-auto pt-1 pt-md-0">
            {airlineData?.allowed_features?.includes('shuttle booking') &&
                <LinkContainer to='/book-shuttle' className="mr-2">
                    <Nav.Link ><i className="far fa-calendar-alt mr-2"></i> Book A Shuttle</Nav.Link>
                </LinkContainer>
            }
            {allowedAirCrewBooking &&
                < LinkContainer to='/aircrew' className="mr-2">
                    <Nav.Link ><i className="fas fa-bus-alt mr-2"></i> Crew</Nav.Link>
                </LinkContainer>
            }
        </Nav>
    );
}
 
export default NavigationAirlineUser;