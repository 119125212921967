import React from 'react'
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import { updateCommissionAsPaid } from '../../services/reserverCommissions';

const CommissionPaymentModal = ({ display, toggle, commission, paymentUpdated }) => {

    const [paymentDate, setPaymentDate] = useState(commission.payment_date ? new Date(commission.payment_date) : Date.now())
    const [amount, setAmount] = useState()

    const handleUpdatAsPaid = async () => {
        
        const payload = {
            "payment_date": paymentDate,
            "paid_amount" : amount
        }

        const {data: {code, message}} = await updateCommissionAsPaid(commission._id, payload)
        if (code == 200) {
            paymentUpdated(message)
            toggle(false)
        } else {
           //TODO: toast
        }
    }

    return (
        <>
        <Modal
        show={display}
        onHide={() => toggle(false)}
        backdropClassName="level-2"
        centered
        >
            <Modal.Body>
                <h3 className="text-center">Payment</h3>
                <hr />
                <Row>
                    <Col>
                        <Form.Group className="simul-datepicker">
                            <p className="mb-2">Date</p>
                            <DatePicker
                                className="form-control"
                                selected={paymentDate}
                                onChange={date => setPaymentDate(date)}
                                placeholderText={'Payment Date'}
                                dateFormat="dd.MM.yyyy"
                                />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <p className="mb-2">Amount</p>
                            <Form.Control onChange={(e) => setAmount(e.target.value)} type="number" placeholder="Amount" />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleUpdatAsPaid()}>Submit</Button>
            </Modal.Footer>
        </Modal>
        </>
  )
}

export default CommissionPaymentModal

