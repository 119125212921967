import Moment from 'moment';
import { getDay, getWeek } from 'date-fns';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { getDailyBookingSales, getFilteredShuttleBookingSales, getMonthlyBookingSales, getWeeklyBookingSales } from '../../services/bookingSales';
import { getHotelById, getHotels } from '../../services/hotels';
import LinkTD from '../../components/common/linkTd';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getHotelAccounts } from '../../services/hotelAccounts';
import PageLoader from '../../components/common/pageLoader';
import { getSimulAccounts } from '../../services/accounts';

const ShuttleSalesPage = () => {

    const [isTableLoading, setIsTableLoading] = useState(false)

    const [monthlyBookingSales, setMonthlyBookingSales] = useState()
    const [weeklyBookingSales, setWeeklyBookingSales] = useState()
    const [dailyBookingSales, setDailyBookingSales] = useState()
    const [filteredBookingSales, setFilteredBookingSales] = useState()

    const [hotels, setHotels] = useState()
    const [shuttleDrivers, setShuttleDrivers] = useState()
    const [hotelPersonnels, setHotelPersonnels] = useState()
    const [filteredHotelPersonnels, setFilteredHotelPersonnels] = useState()

    const [hotelIdFilter, setHotelIdFilter] = useState('')
    const [monthFilter, setMonthFilter] = useState(("0" + (new Date().getMonth() + 1)).slice(-2))
    const [yearFilter, setYearFilter] = useState(new Date().getFullYear())
    const [hotelPersonnelIdFilter, setHotelPersonnelIdFilter] = useState('')
    const [shuttleDriverIdFilter, setShuttleDriverIdFilter] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const componentRef = useRef();

    let history = useHistory()

    const months = [
        {"value":"01","label":"January"},
        {"value":"02","label":"February"},
        {"value":"03","label":"March"},
        {"value":"04","label":"April"},
        {"value":"05","label":"May"},
        {"value":"06","label":"June"},
        {"value":"07","label":"July"},
        {"value":"08","label":"August"},
        {"value":"09","label":"September"},
        {"value":"10","label":"October"},
        {"value":"11","label":"November"},
        {"value":"12","label":"December"}
    ]
    const years = []
    for(var i = 2019; i<= new Date().getFullYear(); i++) 
        years.push(i.toString())

    useEffect(() => {
        fetchMonthlyBookingSales()
        fetchWeeklyBookingSales()
        fetchDailyBookingSales()
        filterSales()
        fetchHotels()
        fetchHotelAccounts()
        fetchShuttleDrivers()
    }, [])

    const fetchMonthlyBookingSales = async () => {
        const { data: { code, message } } = await getMonthlyBookingSales()
        if (code == 200) {
            setMonthlyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        }
    }

    const fetchWeeklyBookingSales = async () => {
        const { data: { code, message } } = await getWeeklyBookingSales()
        if (code == 200) {
            setWeeklyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        } 
    }

    const fetchDailyBookingSales = async () => {
        const { data: { code, message } } = await getDailyBookingSales()
        if (code == 200) {
            setDailyBookingSales(message.toFixed(2).replace(/[.,]00$/, "") + " CHF");
        }
    }

    const fetchShuttleDrivers = async () => {
        const { data: { code, message } } = await getSimulAccounts()
        if (code == 200) {
            setShuttleDrivers(message);
        }
    }

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()
        
        if(Array.isArray(message)) {
            setHotels(message)  
        }
        else {
            setHotels([])
        }
    }

    const fetchHotelAccounts = async () => {
        const { data: { code, message } } = await getHotelAccounts()
        if (code == 200) {
            setHotelPersonnels(message);
        }
    }

    const handleChangeHotel = (hotelId) => {
        setHotelIdFilter(hotelId)
        var _hotelPersonnels = hotelPersonnels

        const _filteredHotelPersonnels = _hotelPersonnels.filter(x => x.hotel_id == hotelId)
        setFilteredHotelPersonnels(_filteredHotelPersonnels)
    }

    const filterSales = async (_hotelIdFilter = hotelIdFilter) => {
        setIsLoading(true)
        var payload = {
            'month': monthFilter,
            'year': yearFilter,
        }
        if (hotelIdFilter) { payload['hotel_id'] = hotelIdFilter }
        if (hotelPersonnelIdFilter) { payload['hotel_personnel_id'] = hotelPersonnelIdFilter }
        if (shuttleDriverIdFilter) { payload['shuttle_driver_id'] = shuttleDriverIdFilter }

        setIsTableLoading(true)
        const { data: { code, message } } = await getFilteredShuttleBookingSales(payload)
    
        setIsTableLoading(false)
        if(code==200){
            let sortedItems = message.sort((a, b) => new Date(b.sale_date) - new Date(a.sale_date))
            setFilteredBookingSales(sortedItems.filter(sale => !sale.isCancelled))
        }
        else {
            //TODO: toast
        }
        setIsLoading(false)
    }
    
    const getCurrentMonth = () => {
        return new Date().toLocaleString('default', { month: 'long' })
    }

    const getDayName = (dayNum) => {
        let days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
        return dayNum === 0 ? 'Sunday' : days[dayNum-1]
    }

    const getTotalSales = () => {

        var total = 0
        filteredBookingSales.forEach((sale) => {
            total += sale?.sale_info?.amount
        })
        return total.toFixed(2).replace(/[.,]00$/, "")
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getHotelName = () => {
        if(hotelIdFilter)
            return hotels.find(x => x._id === hotelIdFilter).hotel_name
        else
            return 'All'
    }

    const getMonthName = () => {
        return months.find(x => x.value === monthFilter).label
    }

    const getHotelPeronnelName = () => {
        if (hotelPersonnelIdFilter) {
            var hotelpersonnel = filteredHotelPersonnels.find(x => x._id === hotelPersonnelIdFilter)
            return hotelpersonnel.first_name + ' ' + hotelpersonnel.last_name
        } else {
            return 'All'
        }
    }

    const getShuttleDriverName = () => {
        if (shuttleDriverIdFilter) {
            var driver = shuttleDrivers.find(x => x._id === shuttleDriverIdFilter)
            return driver.first_name + ' ' + driver.last_name
        } else {
            return 'All'
        }
    }

    return (
        <section>
            <Col>
                <Row className="mb-4">
                    <Col className="text-left">
                        <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/sales')}>{'<'} Back</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Shuttle Sales</h1>
                        <hr className="mt-2 mb-4" />
                    </Col>
                </Row>
                <Row className="mb-4 row-eq-height">
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>THIS MONTH</strong></small></p>
                                <h3>{getCurrentMonth()}</h3>
                                <hr />
                                <h1 className="color-primary">{monthlyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>THIS WEEK</strong></small></p>
                                <h3>Week {getWeek(new Date())}</h3>
                                <hr />
                                <h1 className="color-primary">{weeklyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card>
                            <Card.Body>
                                <p className="mb-0 text-muted"><small><strong>TODAY</strong></small></p>
                                <h3>{getDayName(getDay(new Date()))}</h3>
                                <hr />
                                <h1 className="color-primary">{dailyBookingSales}</h1>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <Card className='w-100 filter-panel'>
                            <Card.Body>
                                {isLoading ?
                                <PageLoader position="relative" backgroundColor="#f2ecec" />
                                :
                                <>
                                    <Row className="mt-4">
                                        <Col lg="9">
                                            <Row>
                                                <Col lg="3">
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Control as="select" value={hotelIdFilter} onChange={(e) => handleChangeHotel(e.target.value)}>
                                                                    <option value=''>All Hotels</option>
                                                                    {hotels && hotels.map((hotel, index) => (
                                                                        <option key={`hotel-${index}`} value={hotel._id} >
                                                                            {hotel.hotel_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {hotelIdFilter && 
                                                    <Row>
                                                        <Col>
                                                            {filteredHotelPersonnels.length > 0 ?
                                                            <Form.Group>
                                                                <Form.Control as="select" defaultValue={hotelPersonnelIdFilter} onChange={(e) => setHotelPersonnelIdFilter(e.target.value)}>
                                                                    <option value=''>All Hotel Personnels</option>
                                                                    {filteredHotelPersonnels && filteredHotelPersonnels.map((personnel, index) => (
                                                                        <option key={`hotelpersonnel-${index}`} value={personnel._id}>
                                                                            {personnel.first_name || personnel.last_name ? `${personnel.first_name} ${personnel.last_name}` : personnel.username}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                            :
                                                            <p className="mt-n2"><small>No hotel personnels to select</small></p>
                                                            }
                                                            
                                                        </Col>
                                                    </Row>
                                                    }
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" defaultValue={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                                                            {months && months.map((month, index) => (
                                                                <option key={`month-${index}`} value={month.value}>
                                                                    {month.label}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" defaultValue={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                                                            {years && years.map((year) => (
                                                                <option key={`year-${year}`} value={year}>
                                                                    {year}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group>
                                                        <Form.Control as="select" onChange={(e) => setShuttleDriverIdFilter(e.target.value)}>
                                                            <option value="">All Shuttle Drivers</option>
                                                            {shuttleDrivers && shuttleDrivers.map((driver, index) => (
                                                                <option key={`driver-${index}`} value={driver._id}>{`${driver.first_name} ${driver.last_name}`}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="3">
                                            <Button variant="primary px-5" onClick={() => filterSales()}>Filter</Button>
                                        </Col>
                                    </Row>

                                    <div className="mx-n4">
                                        <div className="to-print px-4" ref={componentRef}>
                                            <div className="hide-on-screen mb-4">
                                                <p className="mb-0">
                                                    Hotel: <strong>{hotels && getHotelName()}</strong> &emsp;
                                                    {hotelIdFilter && <span>Hotel Personnel: <strong>{filteredHotelPersonnels && getHotelPeronnelName()}</strong> &emsp;</span>}
                                                    Month: <strong>{getMonthName(monthFilter)}</strong> &emsp;
                                                    Year: <strong>{yearFilter}</strong> &emsp;
                                                    Driver: <strong>{shuttleDrivers && getShuttleDriverName(shuttleDriverIdFilter)}</strong>
                                                </p>
                                            </div>

                                            {!isTableLoading ?
                                            <>
                                            <Table responsive hover>
                                                <thead className="border-top-0">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Sale Date</th>
                                                        <th>Guest</th>
                                                        <th>Seats</th>
                                                        <th>Paid By Guest</th>
                                                        <th>Commission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {filteredBookingSales && filteredBookingSales.length > 0 ?
                                                    <>
                                                        {filteredBookingSales && filteredBookingSales.map((sale, index) => (
                                                        <tr key={`bookingsale-${index}`}>
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{index+1}</LinkTD>
                                                            {/* We will use UTC for this shuttle booking date 
                                                            because it was not converted into UTC when saved in mongo db
                                                            compared to 'created_date' */}
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{Moment.utc(sale?.sale_info?.sale_date).format('DD.MM.YYYY @ HH:mm')}</LinkTD>
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{sale?.booking_info?.customer.first_name} {sale?.booking_info?.customer.last_name}</LinkTD>
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{sale?.booking_info?.number_of_persons}</LinkTD>
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{sale?.sale_info?.amount.toFixed(2).replace(/[.,]00$/, "")} CHF</LinkTD>
                                                            <LinkTD to={`/booking/${sale?.sale_info?.booking_id}`}>{sale?.commission_amount.toFixed(2)} CHF</LinkTD>
                                                        </tr>
                                                        ))}
                                                         <tr>
                                                            <td><strong>Total</strong></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>{filteredBookingSales.reduce((n, s) => n + (s?.sale_info?.amount ?? 0), 0).toFixed(2)} CHF</strong></td>
                                                            <td><strong>{filteredBookingSales.reduce((n, s) => n + s.commission_amount, 0).toFixed(2)} CHF</strong></td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan="3" className="pt-4">No records</td>
                                                        </tr>
                                                    </>
                                                    }      
                                                
                                                </tbody>
                                            </Table>
                                            </>
                                            :
                                            <PageLoader position="relative" backgroundColor="#f2ecec" />
                                            }

                                        </div>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className="pt-2 text-right">
                                                <Button size="sm" variant="outline-secondary" onClick={handlePrint}><i className="fas fa-print"></i> Print Table Data</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                }
                            </Card.Body>
                        </Card>          
                    </Col>
                </Row>
            </Col>
        </section>
    );
};

export default ShuttleSalesPage;