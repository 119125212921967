import React from 'react';
import simulLogo from '../../assets/images/logo_hor.svg'

const AirlineQRPrint = React.forwardRef(({ qr }, ref) => (

    <div className="d-flex justify-content-center position-relative">
        <div ref={ref} className="p-4 text-center">
            <h1 className="mb-4" style={{marginTop:'100px'}}><strong>Shuttle and Taxi Service for the Crews</strong></h1>
            <img className="mb-2" src={qr} style={{ width: '80%' }} alt=" preview..." />
            <img style={{width:'75%'}} src={simulLogo} alt="simul logo" />
        </div>
    </div>

));

export default AirlineQRPrint;