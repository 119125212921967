import React from 'react';
import TimetableCalender from '../../components/common/timetableCalendar';

const HotelStaffTimetablePage = () => {

    return <>
        <TimetableCalender />
    </>
}

export default HotelStaffTimetablePage