import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import GenerateTaxiDriverInvoice from '../components/admin/generateTaxiDriverInvoice';
import LinkTD from '../components/common/linkTd';
import ConfirmationModal from '../components/modals/confirmation';
import GenerateTaxiDriverInvoiceModal from '../components/modals/generateTaxiDriverInvoice';
import { cancelInvoice, getTaxiInvoices } from '../services/invoicesTaxi';
import { isSimulAccount } from '../utils/utils';

const TaxiDriverInvoicesPage = () => {

    const [generateTaxiDriverInvoiceModal, setGenerateTaxiDriverInvoiceModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false) 
    const [invoices, setInvoices] = useState()
    const [selectedInvoice, setSelectedInvoice] = useState()

    useEffect(() => {
        fetch()
    }, [])
    
    const fetch = async () => {
        const { data: { code, message } } = await getTaxiInvoices()
     
        if(code === 200) {
            const _invoices = message.filter(x => x.recipient_type === 'taxi-driver')
            setInvoices(_invoices)
        }
        else {
            //TODO: toast
        } 
    }

    const handleCancelInvoice = async () => {
        const { data: { code, message } } =  await cancelInvoice(selectedInvoice._id)
        if (code == 200) {
            let _invoices = invoices
            const index = _invoices.findIndex(x => x._id === selectedInvoice._id)
            _invoices.splice(index, 1)
            setInvoices([..._invoices])
         } else {
             //TODO: toast
         }
    }

    const handleConfirmationModal = (invoice) => {
        setSelectedInvoice(invoice)
        setConfirmationModal(true)
    }

    return <>

        <GenerateTaxiDriverInvoiceModal display={generateTaxiDriverInvoiceModal} toggle={setGenerateTaxiDriverInvoiceModal} />

        <ConfirmationModal
            title="Cancel Invoice"
            message="Are you sure you want to cancel this invoice?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleCancelInvoice()} />

        <section>
            <Row>
                <Col>
                    {isSimulAccount() &&
                    <Row className="mb-3">
                        <Col>
                            <Button onClick={() => setGenerateTaxiDriverInvoiceModal(true)}>Generate Invoice</Button>
                        </Col>
                    </Row>
                    }
                    <Card>
                        <Card.Header className='text-center bg-primary text-white'>Taxi Invoices</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th></th>
                                        <th>Invoice Number</th>
                                        <th>Recipient Name</th>
                                        <th>Period</th>
                                        <th>Number Of Bookings</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices && invoices.length > 0 ? 
                                    <>
                                    {invoices.map((invoice, index) => (
                                        <tr key={`invoice-${index}`}>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`} className="text-center">{invoice.is_paid ? <i style={{color:"Tomato"}} className="fas fa-money-bill-alt" title="Paid"></i> : <i style={{color:"LightGray"}} className="fas fa-money-bill-alt" title="Not Paid"></i>}</LinkTD>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`}>{invoice.invoice_human_readable_number}</LinkTD>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`}>{invoice.recipient_person_name}</LinkTD>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`}>{Moment(invoice.period_from).format('DD.MM.YYYY') + " - " + Moment(invoice.period_to).format('DD.MM.YYYY')}</LinkTD>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`}>{invoice.number_of_sales}</LinkTD>
                                            <LinkTD to={`/taxi-invoice/${invoice._id}`}>{invoice.amount.toFixed(2)}</LinkTD>
                                            <td>
                                                {!invoice.is_paid && isSimulAccount() && <Button size="sm" onClick={() => handleConfirmationModal(invoice)}>Cancel</Button> }
                                            </td>
                                        </tr>
                                    ))}
                                    </>
                                    :
                                    <tr>
                                        <td colSpan="7">No invoices found.</td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default TaxiDriverInvoicesPage