import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import EditOperatingCitiesModal from '../modals/editOperatingCities'
import { getTaxiDriverInvoiceOptions } from '../../utils/invoiceUtils'
import CarCategoriesModal from '../modals/carCategoriesModal'

const TaxiDriverCreator = ({ createTaxiDriver, carCategories }) => {

    const invoiceOptions = getTaxiDriverInvoiceOptions();

    const [editOperatingCitiesModal, setEditOperatingCitiesModal] = useState(false)
    const [carCategoriesModal, setCarCategoriesModal] = useState(false)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [invoicingOption, setInvoicingOption] = useState(invoiceOptions[0].value)
    const [fixedChargeAmount, setFixedChargeAmount] = useState(0)
    const [operatingCities, setOperatingCities] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('')
    const [carCategoryId, setCarCategoryId] = useState('')
    const [carCategoriesToAdd, setCarCategoriesToAdd] = useState([])
    const [creditsDeduction, setCreditsDeduction] = useState('')

    const handleAccountCreationSubmit = async (e) => {
        e.preventDefault()
        createTaxiDriver({
            username,
            password,
            first_name: firstname,
            last_name: lastname,
            operating_cities: operatingCities,
            phone: phoneNumber,
            license_number: licenseNumber,
            //simul_invoicing_option: invoicingOption,
            //simul_invoicing_option_fixed_charge_amount: fixedChargeAmount,
            car_categories: carCategoriesToAdd,
            credits_deduction_option: creditsDeduction
        })

        document.getElementById('taxi-driver-creator').reset();
        setOperatingCities([])
        setCarCategoriesToAdd([])
        setInvoicingOption(invoiceOptions[0].value);
    }

    const getCarCategoryName = (id) => {
        return carCategories.find(x => x._id === id).name ?? ''
    }

    return <>
        <EditOperatingCitiesModal display={editOperatingCitiesModal} toggle={setEditOperatingCitiesModal} operatingCities={operatingCities} setOperatingCities={setOperatingCities} />
        <CarCategoriesModal display={carCategoriesModal} toggle={setCarCategoriesModal} carCategories={carCategories} setCarCategoriesToAdd={setCarCategoriesToAdd} />

        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Taxi Driver</Card.Header>
            <Card.Body>
                <Form id="taxi-driver-creator" onSubmit={(e) => handleAccountCreationSubmit(e)}>
                    <Row>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control required type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control required type='text' placeholder='Enter firstname' onChange={e => setFirstname(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control required type='text' placeholder='Enter lastname' onChange={e => setLastname(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg="6">
                            <Row>
                                <Col lg="6">
                                    <Form.Group>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control required type='text' placeholder='Enter phone number' onChange={e => setPhoneNumber(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group>
                                        <Form.Label>License Number</Form.Label>
                                        <Form.Control required type='text' placeholder='Enter license number' onChange={e => setLicenseNumber(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col lg="6">
                                    <Form.Group>
                                        <Form.Label>Simul Invoicing Option</Form.Label>
                                        <Form.Control as="select" defaultValue={invoicingOption} onChange={(e) => setInvoicingOption(e.target.value)}>
                                            <option value="" disabled>Select Invoicing Option</option>
                                            {invoiceOptions.map((invoicOption, index) => (
                                                <option key={`invoiceoption-${index}`} value={invoicOption.value} >
                                                    {invoicOption.label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col> */}
                                {/* {invoicingOption === 'FIXED-CHARGE' &&   
                                <Col lg="6">
                                <Form.Group>
                                    <Form.Label>Fixed Charge Amount</Form.Label>
                                        <Form.Control required type='text' placeholder='Enter amount' onChange={e => setFixedChargeAmount(e.target.value)} />
                                    </Form.Group>  
                                </Col>
                                } */}
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <Form.Group>
                                        <Form.Label>Credits Deduction</Form.Label>
                                        <Form.Control as="select" defaultValue={creditsDeduction} onChange={(e) => setCreditsDeduction(e.target.value)}>
                                            <option value="" disabled>Select Deduction Option</option>
                                            <option>FIXED-4-CHF</option>
                                            <option>15%</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>

                                    <p className="mb-0"><Form.Label>Car Categories</Form.Label></p>
                                    {carCategoriesToAdd && carCategoriesToAdd.map((carCategory, index) => (
                                        <Button key={`operatingcity-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2">
                                            {`${getCarCategoryName(carCategory.car_category_id)} - ${carCategory.make} ${carCategory.model} ${carCategory.model_year}`}
                                        </Button>
                                    ))}

                                    <div className="mb-2">
                                        <Button size="sm" variant="link" onClick={() => setCarCategoriesModal(true)}>Add car categories</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>

                                    <p className="mb-0"><Form.Label>Operating Cities</Form.Label></p>
                                    {operatingCities && operatingCities.map((operatingCity, index) => (
                                        <>
                                            {operatingCity &&
                                                <Button key={`operatingcity-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2">
                                                    {operatingCity}
                                                </Button>
                                            }
                                        </>
                                    ))}

                                    <div className="mb-2">
                                        <Button size="sm" variant="link" onClick={() => setEditOperatingCitiesModal(true)}>Add operating cities</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button type="submit" variant='primary'>Create</Button>
                    </div>
                </Form>

            </Card.Body>
        </Card>
    </>
}

export default TaxiDriverCreator