import Moment from 'moment';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import simulLogo from '../../assets/images/logo_white.svg'

const BookingTaxiPrint = React.forwardRef(({booking, getDestination}, ref) => (

        <div ref={ref}>
            <Row className="border-bottom">
                <Col xs="8" className="border-right pr-0">

                    <Card>
                        <Card.Header className="bg-primary text-white py-2 mb-0">
                            <Row>
                                <Col xs="6">
                                    <img src={simulLogo} className="mr-3" style={{height:'40px'}} />
                                </Col>
                                <Col xs="6" className="text-right">
                                    <h5><i>TAXI PASS</i></h5>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs="6">
                                    <p className="mb-0"><small><strong>Name of passenger</strong></small></p>
                                    <p className="text-uppercase mb-2">{`${booking.customer.first_name} ${booking.customer.last_name}`}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <p className="mb-0"><small><strong>Origin</strong></small></p>
                                    <p className="text-uppercase mb-2">{booking.origin}</p>
                                </Col>
                                <Col xs="6">
                                    <Row>
                                        <Col xs="6">
                                            <p className="mb-0"><small><strong>Pickup Date</strong></small></p>
                                            <p className="text-uppercase mb-2">{Moment(booking.booking_date).format('DD.MM.YYYY')}</p>
                                        </Col>
                                        <Col>
                                            <p className="mb-0"><small><strong>Pickup Time</strong></small></p>
                                            <p className="text-uppercase mb-2">{Moment(booking.booking_date).format('HH:mm')}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <p className="mb-0"><small><strong>Destination</strong></small></p>
                                    <p className="text-uppercase mb-2">{getDestination(booking)}</p>
                                </Col>
                                <Col xs="6">
                                    <Row>
                                        <Col xs="6">
                                            <p className="mb-0"><small><strong>Created on</strong></small></p>
                                            <p className="text-uppercase mb-2">{Moment(booking.created_date).format('DD.MM.YYYY')} @ {Moment(booking.created_date).format('HH:mm')}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="9">
                                    <Row>
                                        <Col xs="4">
                                            <p className="mb-0"><small><strong>Walk-In</strong></small></p>
                                            <p className="text-uppercase mb-2">{booking.is_walk_in ? 'Yes' : 'No'}</p>
                                        </Col>
                                        <Col xs="4">
                                            <p className="mb-0"><small><strong>Paid</strong></small></p>
                                            <p className="text-uppercase mb-2">{booking.is_paid ? 
                                                <><i className="fas fa-check-circle text-success"></i> Yes </> 
                                                : 
                                                <><i className="fas fa-times-circle text-danger"></i> No </>
                                            }</p>
                                        </Col>
                                        {booking.number_of_persons && 
                                        <Col xs="4">
                                            <p className="mb-0"><small><strong>Seats</strong></small></p>
                                            <p className="text-uppercase mb-2">{booking.number_of_persons}</p>
                                        </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col xs="4">
                                            <p className="mb-0"><small><strong>Type</strong></small></p>
                                            <p className="text-uppercase mb-2">Taxi</p>
                                        </Col>
                                        <Col xs="4">
                                            <p className="mb-0"><small><strong>Source Type</strong></small></p>
                                            <p className="text-uppercase mb-2">{booking.booking_source_type}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <img src={booking.qrcode} className="w-100" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>

                {/* Right Column */}
                <Col className="pl-0">
                    <Card>
                        <Card.Header className="bg-primary text-white py-2 mb-0">
                            <Row>
                                <Col xs="6">    
                                    <img src={simulLogo} className="mr-3" style={{height:'40px'}} />    
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <p className="mb-0"><small><strong>Name of passenger</strong></small></p>
                            <p className="text-uppercase mb-2">{`${booking.customer.first_name} ${booking.customer.last_name}`}</p>

                            <p className="mb-0"><small><strong>Origin</strong></small></p>
                            <p className="text-uppercase mb-2">{booking.origin}</p>

                            <p className="mb-0"><small><strong>Destination</strong></small></p>
                            <p className="text-uppercase mb-2">{getDestination(booking)}</p>

                            <Row>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Walk-In</strong></small></p>
                                    <p className="text-uppercase mb-2">{booking.is_walk_in ? 'Yes' : 'No'}</p>
                                </Col>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Paid</strong></small></p>
                                    <p className="text-uppercase mb-2">{booking.is_paid ? 
                                        <><i className="fas fa-check-circle text-success"></i> Yes </> 
                                        : 
                                        <><i className="fas fa-times-circle text-danger"></i> No </>
                                    }</p>
                                </Col>
                                {booking.number_of_persons && 
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Seats</strong></small></p>
                                    <p className="text-uppercase mb-2">{booking.number_of_persons}</p>
                                </Col>
                                }
                            </Row>
                            

                            <Row>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Pickup Date</strong></small></p>
                                    <p className="text-uppercase mb-2">{Moment(booking.booking_date).format('DD.MM.YYYY')}</p>
                                </Col>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Pickup Time</strong></small></p>
                                    <p className="text-uppercase mb-2">{Moment(booking.booking_date).format('HH:mm')}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Type</strong></small></p>
                                    <p className="text-uppercase mb-2">Taxi</p>
                                </Col>
                                <Col xs="4">
                                    <p className="mb-0"><small><strong>Source Type</strong></small></p>
                                    <p className="text-uppercase mb-2">{booking.booking_source_type}</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
));
 
export default BookingTaxiPrint;