import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCurrentUser } from '../../services/auth'
import { updateUser } from '../../services/users'

const ChangePasswordModal = ({ display, toggle, user, passwordChanged, setIsLoading }) => {

    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [error, setError] = useState('')

    let _currentUser = getCurrentUser()

    const handleChangePassword = async () => {

        setError('')

        if(newPassword !== confirmPassword) {
            setError('passwords do not match')
            return
        }

        const payload = {
            "old_password": user.password,
            "new_password": newPassword
        }

        setIsLoading(true)
        const {data: {code, message}} = await updateUser(user._id, payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            passwordChanged(payload.new_password)
            toggle(false)
        }
    }
    
    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body className="change-password-modal">
                <Card.Title className="mb-3">Change Password</Card.Title>
                <div className="change-password">
                    <Form.Group>
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control disabled={_currentUser.role_name === 'system-admin'} type='text' value={user.password} placeholder='Enter old username' onChange={e => {}} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter new password' onChange={e => setNewPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control type='password' placeholder='Conform new password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Group>
                    {error && 
                    <p className="text-danger text-center"><small>{error}</small></p>
                    }
                    <Button className="w-100" onClick={() => handleChangePassword()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default ChangePasswordModal

