import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormControl, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCountries } from '../../services/address'
import { getCarCategories } from '../../services/carCategories'
import { getTaxiDrivers } from '../../services/taxiAccounts'
import { addTaxiDestination } from '../../services/taxiDestinations'
import { removeFromArray } from '../../utils/utils'

const CreateTaxiDestinationModal = ({ display, toggle, hotels, destinationAdded }) => {

    const [destination, setDestination] = useState()
    const [origin, setOrigin] = useState()
    const [city, setCity] = useState()
    const [street, setStreet] = useState()
    const [zip, setZip] = useState()
    const [country, setCountry] = useState()
    const [carCategoryIdsToAdd, setCarCategoryIdsToAdd] = useState([])
    const [carCategoriesToAdd, setCarCategoriesToAdd] = useState([])
    const [carCategoriesToAddDisplay, setCarCategoriesToAddDisplay] = useState([])

    const [countries, setCountries] = useState()
    const [modalCarCategories, setModalCarCategories] = useState()
    const [taxiDrivers, setTaxiDrivers] = useState()
    
    const [vehicles, setVehicles] = useState([
        {'taxi_driver_id':'', 'seats':'', 'price':''}
    ])

    useEffect(() => {
        fetchCountries()
        fetchTaxiDrivers()
        fetchCarCategories()
    },[])

    const fetchCountries = async () => {
       const {data: {code, message}} = await getCountries();
       if(code == 200) {
        setCountries(message)
       }
    }

    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if(code === 200){
            setTaxiDrivers(message)
        } else {
            //TODO: toast
        }  
    }

    const fetchCarCategories = async () => {
        const {data: {code, message}} = await getCarCategories()
        if (code == 200) {
            setModalCarCategories(message)
        } else {
            //TODO: toast
        }
    }

    const handleCreateTaxiDestination = async () => {

        const payload = {
            "destination_name": destination,
            "establishment_origin_id": origin,
            "address": {
                "street": street,
                "zip": zip,
                "city": city,
                "country": country
            },
            "car_categories" : carCategoriesToAdd
        }

        const {data: {code, message}} = await addTaxiDestination(payload)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            payload["_id"] = message._id
            destinationAdded(message)
            setCarCategoriesToAddDisplay([])
            fetchCarCategories()
            toggle(false)
        }
    }

    const handleModalCarCategoriesStatusClass = (statusClass, index) => {
        let _modalCarCategories = modalCarCategories
        _modalCarCategories[index]['statusClass'] = statusClass
        setModalCarCategories([..._modalCarCategories])
    }

    const handleSetCarCategoryPrice = (price, index) => {
        handleModalCarCategoriesStatusClass('', index)
        
        let _carCategories = modalCarCategories
        _carCategories[index]['price'] = price;
        setModalCarCategories([..._carCategories])
    }

    const handleSetCarCategoriesToAdd = (carCategory, index) => {

        if (!carCategory.price) {
            handleModalCarCategoriesStatusClass('is-invalid', index)
            return
        } 

        let _carCategoriesToAdd = carCategoriesToAdd
        let _carCategoriesToAddDisplay = carCategoriesToAddDisplay
        _carCategoriesToAdd.push({
            "car_category_id": carCategory._id,
            "price": carCategory.price,
        })
        _carCategoriesToAddDisplay.push({
            "car_category_id": carCategory._id,
            "price": carCategory.price,
            "name": carCategory.name
        })
        setCarCategoriesToAdd([..._carCategoriesToAdd])
        setCarCategoriesToAddDisplay([..._carCategoriesToAddDisplay])

        let _modalCarCategories = modalCarCategories
        _modalCarCategories.splice(index, 1)
        setModalCarCategories([..._modalCarCategories])
    }

    const handleSetCarCategoriesToRemove = (carCategory, index) => {
        let _carCategoriesToAdd = carCategoriesToAdd
        let _carCategoriesToAddDisplay = carCategoriesToAddDisplay

        _carCategoriesToAdd.splice(index, 1)
        setCarCategoriesToAdd([..._carCategoriesToAdd])
        _carCategoriesToAddDisplay.splice(index, 1)
        setCarCategoriesToAddDisplay([..._carCategoriesToAddDisplay])

        let _modalCarCategories = modalCarCategories
        _modalCarCategories.push({
            "_id": carCategory.car_category_id,
            "price": carCategory.price,
            "name": carCategory.name
        })
        setModalCarCategories([..._modalCarCategories])
    }

    return (
        <>
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="lg"
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-2">
                    Create Taxi Destination
                </Card.Title>    
                <Form.Group>
                    <Form.Label>Origin</Form.Label>
                    <Form.Control as="select" onChange={(e) => setOrigin(e.target.value)}>
                        <option value="">Choose Origin</option>
                        {hotels && hotels.map((hotel, index) => (
                            <option key={`hotel-${index}`} value={hotel._id}>{hotel.hotel_name}</option>
                        ))}
                    </Form.Control>
                </Form.Group> 
                <Form.Group>
                    <Form.Label>Destination Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => setDestination(e.target.value)} placeholder="Enter a destination name" />
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" onChange={(e) => setCity(e.target.value)} placeholder="Enter city" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Street</Form.Label>
                            <Form.Control type="text" onChange={(e) => setStreet(e.target.value)} placeholder="Enter street" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Zip</Form.Label>
                            <Form.Control type="text" onChange={(e) => setZip(e.target.value)} placeholder="Enter zip cone" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Country</Form.Label>
                            <Form.Control as="select" defaultValue="" onChange={(e) => setCountry(e.target.value)}>
                                <option value="" disabled>Select Country</option>
                                {countries && countries.map((_country, index) => (
                                    <option key={`country-${index}`} value={_country}>{_country}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6">
                        <Form.Label className="mb-0">Car Categories</Form.Label>
                        <Form.Text className="mb-2">Select car categories</Form.Text>
                            
                        <div className="p-2 border rounded" style={{minHeight:'53px'}}>
                        {modalCarCategories && modalCarCategories.map((category, index) => (
                            <Row key={`category-${index}`} className="mb-1">
                                <Col xs="7">
                                    <FormControl value={category.name} size="sm" disabled />
                                </Col>
                                <Col xs="3" className="px-0">
                                    <FormControl className={`${category.statusClass}`} value={category.price} onChange={(e) => handleSetCarCategoryPrice(e.target.value, index)} placeholder="price" size="sm" type="number" />
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => handleSetCarCategoriesToAdd(category, index)} size="sm" title="add category"><i className="fas fa-plus"></i></Button>
                                </Col>
                            </Row>
                        ))}
                        </div>
                           
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">&nbsp;</Form.Label>
                        <Form.Text className="mb-2">Car categories to add</Form.Text>
                        <div className="p-2 border rounded" style={{minHeight:'53px'}} >
                        {carCategoriesToAddDisplay.length > 0 && carCategoriesToAddDisplay.map((category, index) => (
                            <Row key={`category-${index}`} className="mb-1">
                                <Col xs="7">
                                    <FormControl value={category.name} size="sm" disabled />
                                </Col>
                                <Col xs="3" className="px-0">
                                    <FormControl value={`${category.price} CHF`} size="sm" disabled />
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => handleSetCarCategoriesToRemove(category, index)} size="sm" title="remove category"><i className="fas fa-minus"></i></Button>
                                </Col>
                            </Row>
                        ))}
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleCreateTaxiDestination()}>Save</Button>
            </Modal.Footer>
        </Modal>
        </>
  )
}

export default CreateTaxiDestinationModal

