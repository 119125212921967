import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import EnterSeparatedTextbox from '../../components/common/enterSeparatedTextbox';
import { getAllAirlineCrewRoutePlaces } from '../../services/airlineCrewRoutePlaces';
import { getSeatOptions } from '../../services/airlineCrewSeatOptions';
import { combineDateAndTimeV2, isNullOrEmpty } from '../../utils/utils';
import { bookAirlineCrewPublic } from '../../services/airlineCrewBookings';
import { isToday, setHours, setMinutes } from 'date-fns';
import { debugText } from '../../services/logs';

const PublicBookAirlineCrewForm = ({ airCrew, setHasSelectedAirCrew, isVerifiedKey }) => {

    const [seatOptions, setSeatOptions] = useState([])
    const [filteredSeatOptions, setFilteredSeatOptions] = useState([])
    const [routePlaces, setRoutePlaces] = useState([])

    const [isBookingAirCrew, setIsBookingAirCrew] = useState(false)

    const [booking, setBooking] = useState({})

    useEffect(() => {
        fetchSeatOptions()
        fetchRoutePlaces()
        // fetchHotels()
    }, [])

    const fetchSeatOptions = async () => {
        const { data: { message } } = await getSeatOptions()

        let _seatOptions = [...message]
        _seatOptions = _seatOptions.filter(x => x.airline_crew_id === airCrew.id)
        setSeatOptions(_seatOptions)
    }

    const fetchRoutePlaces = async () => {
        const { data: { message } } = await getAllAirlineCrewRoutePlaces()
        if (Array.isArray(message)) {
            setRoutePlaces(message)
        }
        else {
            setRoutePlaces([])
        }
    }

    const handleChange = (objVal) => {
        let _booking = { ...booking, ...objVal }
        setBooking(_booking)
    }

    const getTime = (dateTime) => {
        return dateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!isVerifiedKey) {
            toast.error(`Error! unauthorized access.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }

        const originPlace = routePlaces.find(x => x.id === booking.origin_id) ?? null
        const destinationPlace = routePlaces.find(x => x.id === booking.destination_id) ?? null
        const seatOption = seatOptions.find(x => x.id === booking.seatId) ?? null

        if (seatOption === null) {
            toast.error(` No seat price matched.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }

        let _booking = {
            seat_option_id: seatOption.id,
            seat_name: seatOption.name,
            origin: originPlace.name,
            origin_place_type: originPlace.type,
            origin_place_id: originPlace.id,
            destination: destinationPlace.name,
            destination_place_type: destinationPlace.type,
            destination_place_id: destinationPlace.id,
            airline_crew_id: airCrew.id,
            pickup_time: combineDateAndTimeV2(booking.pickup_date, getTime(booking.pickup_time)),
            crew_names: booking.crew_names,
            number_of_persons: booking.number_of_persons,
            seat_price: booking.usePricePerHead ? booking.number_of_persons * seatOption.per_person_price : seatOption.price
        }

        if (booking.flight_arrival_time) {
            _booking['flight_arrival_date'] = combineDateAndTimeV2(booking.pickup_date, getTime(booking.flight_arrival_time))
        }
        if (booking.flight_arrival_number) {
            _booking['flight_arrival_number'] = booking.flight_arrival_number
        }
        if (originPlace.type === "hotel") {
            _booking['origin_hotel_id'] = originPlace.hotelId
        }
        if (destinationPlace.type === "hotel") {
            _booking['destination_hotel_id'] = destinationPlace.hotelId
        }

        setIsBookingAirCrew(true)

        // logs data to backend
        const { data: pickupTimeData } = await debugText(booking.pickup_time)
        const { data: pickupDateData } = await debugText(booking.pickup_date)
        const { data: getTimeFuncData } = await debugText(getTime(booking.pickup_time))
        console.log(pickupTimeData, pickupDateData, getTimeFuncData)

        const { data: { code, message } } = await bookAirlineCrewPublic(_booking, localStorage.getItem('simul-public-key'))
        setIsBookingAirCrew(false)

        if (code !== 200) {
            toast.error(`Error! ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            setBooking({})
            toast.success(`Booking successful!`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const handleChangeOrigin = (value) => {

        let _selectedOriginType = routePlaces.find(x => x.id === value).type

        let _seatOptions = seatOptions
        if (_selectedOriginType === "airport") {
            _seatOptions = _seatOptions.filter(x => x.applicable_route === 'airport-hotel' || isNullOrEmpty(x.applicable_route))
        }
        else if (_selectedOriginType === "hotel") {
            _seatOptions = _seatOptions.filter(x => x.applicable_route === 'hotel-airport' || isNullOrEmpty(x.applicable_route))
        }

        setFilteredSeatOptions([..._seatOptions])

        let _change = {
            origin_id: value,
            number_of_persons: ''
        }
        if (_seatOptions.length === 0)
            _change.seatId = ''

        handleChange(_change)
    }

    const handleSetNumberOfPersons = (value) => {
        const taxiSeatOptionId = filteredSeatOptions.find(x => x.vehicle_type === "taxi")?.id ?? ''
        const shuttleSeatOptionId = filteredSeatOptions.find(x => x.vehicle_type === "shuttle")?.id ?? ''
        let _change = {
            number_of_persons: value,
            seatId: filteredSeatOptions.length > 0 ? (value < 5 && taxiSeatOptionId ? taxiSeatOptionId : shuttleSeatOptionId) : ''
        }
        handleChange(_change)
    }

    const handleChangePickupDate = (date) => {
        handleChange({ pickup_date: date, pickup_time: '', flight_arrival_time: ''})
    }

    const handleChangePickupTime = (date) => {
        handleChange({ pickup_time: date, flight_arrival_time: ''})
    }

    const getMinTime = () => {
        if (booking.pickup_date <= new Date())
            return setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours())
        return new Date(0, 0, 0, 0, 0)
    }

    return (
        <div>

            <Row className="justify-content-center mt-3">
                <Col lg="5">

                    <h3 className="text-center"><strong>Book Airline Crew</strong></h3>

                    <hr />

                    <Form onSubmit={handleSubmit} >

                        <div className="mb-4">
                            <p><strong>Route</strong></p>
                            <Form.Group>
                                <Form.Label>Origin</Form.Label>
                                <FormControl required value={booking?.origin_id ?? ''} as="select" onChange={(e) => handleChangeOrigin(e.target.value)}>
                                    <option value="" disabled>Select Origin</option>
                                    {routePlaces.length > 0 && routePlaces.map((routePlace) => (
                                        <React.Fragment key={`routeplace-origin-${routePlace.id}`}>
                                            {routePlace.id !== booking?.destination_id &&
                                                <option value={routePlace.id}>{routePlace.name}</option>
                                            }
                                        </React.Fragment>
                                    ))}
                                </FormControl>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Destination</Form.Label>
                                <FormControl required value={booking?.destination_id ?? ''} as="select" onChange={(e) => handleChange({ destination_id: e.target.value })}>
                                    <option value="" disabled>Select Destination</option>
                                    {routePlaces.length > 0 && routePlaces.map((routePlace) => (
                                        <React.Fragment key={`routeplace-destination-${routePlace.id}`}>
                                            {routePlace.id !== booking?.origin_id &&
                                                <option value={routePlace.id}>{routePlace.name}</option>
                                            }
                                        </React.Fragment>
                                    ))}
                                </FormControl>
                            </Form.Group>
                        </div>

                        <table className="w-100 mb-3">
                            <tbody>
                                <tr>
                                    <td className="p-2" style={{ width: '150px' }}>Pickup Time</td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2 react-datepicker-flex1">
                                                <DatePicker required
                                                    className="form-control"
                                                    selected={booking.pickup_date}
                                                    onChange={date => handleChangePickupDate(date)}
                                                    dateFormat="dd.MM.yyyy"
                                                    minDate={new Date()}
                                                />
                                            </div>
                                            <div className="react-datepicker-flex1">
                                                <DatePicker required
                                                    className="form-control"
                                                    selected={booking.pickup_time}
                                                    onChange={date => handleChangePickupTime(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    timeFormat="HH:mm"
                                                    minTime={getMinTime()}
                                                    maxTime={new Date(0, 0, 0, 23, 59)} // End of the day
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="p-2" style={{ width: '160px' }}>
                                        Flug-Nr/Arrival Time&nbsp;
                                        {routePlaces.find(x => x.id === booking.origin_id)?.type === "airport" && <span className="text-danger">*</span>}
                                    </td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2 react-datepicker-flex1">
                                                <FormControl
                                                    value={booking?.flight_arrival_number ?? ''}
                                                    onChange={(e) => handleChange({ flight_arrival_number: e.target.value })}
                                                    required={routePlaces.find(x => x.id === booking.origin_id)?.type === "airport"}
                                                />
                                            </div>
                                            <div className="react-datepicker-flex1">
                                                <DatePicker required={routePlaces.find(x => x.id === booking.origin_id)?.type === "airport"}
                                                    className="form-control"
                                                    selected={booking.flight_arrival_time}
                                                    onChange={date => handleChange({ flight_arrival_time: date })}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    timeFormat="HH:mm"
                                                    // minTime={Moment(booking.pickup_time).add(1, 'minutes').toDate()}
                                                    // maxTime={new Date(0, 0, 0, 23, 59)} // End of the day
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2">Number of Seats</td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2">
                                                <FormControl
                                                    value={booking?.number_of_persons ?? ''}
                                                    onChange={(e) => handleSetNumberOfPersons(e.target.value)}
                                                    type="number" style={{ maxWidth: '70px' }}
                                                />
                                            </div>
                                            <div className="mr-2 react-datepicker-flex1">
                                                <FormControl disabled required value={booking?.seatId ?? ''} as="select" onChange={(e) => handleChange({ seatId: e.target.value })}>
                                                    <option value="" disabled>Select Seat</option>
                                                    {filteredSeatOptions.length > 0 && filteredSeatOptions.map((option) => (
                                                        <option key={`aircrew-seatoption-${option.id}`} value={option.id}>{option.name}</option>
                                                    ))}
                                                </FormControl>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2"></td>
                                    <td className="p-2">
                                        <Form.Check
                                            inline
                                            name="use-price-per-head"
                                            id="use-price-per-head"
                                            label="Use per head pricing"
                                            checked={booking?.usePricePerHead ?? false}
                                            onChange={(e) => handleChange({ usePricePerHead: e.target.checked })}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2">Personnel Details</td>
                                    <td className="p-2">
                                        <div>
                                            <EnterSeparatedTextbox value={booking?.crew_names ?? []} onChange={(arr) => handleChange({ crew_names: arr })} />
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <Button variant="outline-dark mr-2" onClick={() => setHasSelectedAirCrew(false)}>Back</Button>
                        <Button type="submit" disabled={isBookingAirCrew}>{isBookingAirCrew ? 'Submitting...' : 'Submit'}</Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default PublicBookAirlineCrewForm;