import React from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import { useState } from 'react';
import { getUser } from '../services/users';
import { getCurrentUser } from '../services/auth';
import { useEffect } from 'react';
import { getCountries } from '../services/address';
import { updateUser } from '../services/accounts';
import { toast } from 'react-toastify'
import { isAirlineAccount } from '../utils/utils';

const ProfilePage = () => {

    const [user, setUser] = useState()
    const [countries, setCountries] = useState([])

    const currentUser = getCurrentUser()

    useEffect(() => {
        fetchUser()
        fetchCountries()
    }, [])

    const fetchCountries = async () => {
        const { data: { code, message } } = await getCountries();
        if (code == 200) {
            setCountries(message)
        }
    }

    const fetchUser = async () => {
        const { data: { code, message } } = await getUser(currentUser._id)

        if (code == 200) {
            setUser(message)
        } else {
            //TODO: toast
        }
    }

    const handleSaveUser = async (e) => {
        e.preventDefault()

        if (!user.address) user.address = {}

        const payload = {
            "username": currentUser.username,
            "role_name": currentUser.role_name,
            "first_name": user.firstname,
            "last_name": user.lastname,
            "email": user.email,
            "phone": user.phone,
            "bank_account": user.bank_account,
            "iban": user.iban,
            "address": {
                "street": user.address.street,
                "zip": user.address.zip,
                "city": user.address.city,
                "country": user.address.country
            },
            "hotel_id": currentUser.hotel_id
        }

        if (isAirlineAccount()) {
            payload.airline_id = user.airline_id
            delete payload.phone
            delete payload.bank_account
            delete payload.iban
            delete payload.address
            delete payload.hotel_id
        }

        const { data: { code, message } } = await updateUser(payload, currentUser._id)
        if (code !== 200) {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            toast.success(` Update successful!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            fetchUser()
        }
    }

    const handleUserChange = (field, value) => {

        let newUser = user
        newUser[field] = value
        setUser(newUser)
    }

    const handleAddressChange = (field, value) => {

        let newUser = user

        if (!newUser.hasOwnProperty('address')) {
            var address = {}
            address[field] = value
            newUser['address'] = address
        } else {
            newUser['address'][field] = value
        }

        setUser(newUser)
    }

    return (
        <section>
            {user &&
                <Col>
                    <Row>
                        <Col lg="10">
                            <Card className="mb-5">
                                <Card.Body>
                                    <Form onSubmit={(e) => handleSaveUser(e)}>
                                        <div className="personal-info mb-4">
                                            <p><strong>PERSONAL INFO</strong></p>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Firstname</Form.Label>
                                                        <Form.Control defaultValue={user.first_name} placeholder="Firstname" onChange={(e) => handleUserChange('firstname', e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Lastname</Form.Label>
                                                        <Form.Control defaultValue={user.last_name} placeholder="Lastname" onChange={(e) => handleUserChange('lastname', e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="contact-details mb-4">
                                            <p><strong>CONTACT DETAILS</strong></p>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" defaultValue={user.email} placeholder="Email" onChange={(e) => handleUserChange('email', e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    {
                                                        !isAirlineAccount() &&
                                                        <Form.Group>
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Form.Control defaultValue={user.phone} placeholder="Phone Number" onChange={(e) => handleUserChange('phone', e.target.value)} />
                                                        </Form.Group>
                                                    }

                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            !isAirlineAccount() &&
                                            <>
                                                <div className="address mb-4">
                                                    <p><strong>ADDRESS</strong></p>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Street</Form.Label>
                                                                <Form.Control defaultValue={user?.address?.street} placeholder="Street" onChange={(e) => handleAddressChange('street', e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>City</Form.Label>
                                                                <Form.Control defaultValue={user?.address?.city} placeholder="City" onChange={(e) => handleAddressChange('city', e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Country</Form.Label>
                                                                <Form.Control as="select" defaultValue={user?.address?.country} onChange={(e) => handleAddressChange('country', e.target.value)}>
                                                                    {countries && countries.map((_country, index) => (
                                                                        <option key={`country-${index}`} value={_country} >{_country}</option>
                                                                    ))}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Zip</Form.Label>
                                                                <Form.Control defaultValue={user?.address?.zip} placeholder="Zip" onChange={(e) => handleAddressChange('zip', e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="bank-details mb-4">
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Account Number</Form.Label>
                                                                <Form.Control defaultValue={user.bank_account} placeholder="Account Number" onChange={(e) => handleUserChange('bank_account', e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>IBAN</Form.Label>
                                                                <Form.Control defaultValue={user.iban} placeholder="IBAN" onChange={(e) => handleUserChange('iban', e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                        }
                                        <div>
                                            <Button type="submit">Save</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Col>
            }
        </section>
    );
};

export default ProfilePage;