
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Moment from 'moment';
import React from 'react';

const ShuttleInvoicePDF = ({invoice, hotelName, recepientAddress}) => {

    const styles = StyleSheet.create({
        page: { 
          backgroundColor: '#fff',
          padding:50
        },
        footerText: {
            fontSize: 10,
            marginBottom: 5,
        },
        headerText: {
            fontSize: 10,
            marginBottom: 3,
        },
        row:{
            display: 'flex',
            flexDirection: 'row',
        },
        tableCell: {
            padding: 10,
            fontSize: 9
        },
        marginTop:{
            marginTop: 10
        },
        marginTopMD:{
            marginTop: 30
        },
        marginBottom:{
            marginBottom: 10
        },
        marginBottomMD:{
            marginBottom: 30
        }
    });
 
    return (
        <Document
        >
            <Page size="A4" style={styles.page}>
                <View wrap={true}>
                    <View wrap={false} style={styles.marginBottom}>
                        <Text style={{fontSize:15,color:'#333'}}>Invoice</Text>
                    </View>

                    <View wrap={false} style={styles.marginBottomMD}>
                        <Text style={{fontSize:10}}>{hotelName}</Text>
                        <Text style={[styles.marginBottom, {fontSize:10}]}>{recepientAddress}</Text>

                        <Text style={[styles.headerText]}>Invoice No.: {invoice.invoice_human_readable_number}</Text>
                        <Text style={[styles.headerText]}>Date Covered: {Moment.utc(invoice.period_from).format('DD.MM.YYYY')} - {Moment.utc(invoice.period_to).format('DD.MM.YYYY')}</Text>
                        <Text style={[styles.headerText]}>Number of Bookings: {invoice.number_of_sales}</Text>
                        <Text style={[styles.headerText]}>Number of Seats: {invoice.seats}</Text>
                        <Text style={[styles.headerText, {textTransform:'capitalize'}]}>Recipient: {invoice.establishment_type}</Text>
                    </View>

                    <Table
                        data={invoice.sales}
                    >
                        <TableHeader>
                            <TableCell style={styles.tableCell}>
                                Date
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Guest Name
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Paid At Hotel
                            </TableCell>
                            {/* <TableCell style={styles.tableCell}>
                                Commission
                            </TableCell> */}
                            <TableCell style={styles.tableCell}>
                                Invoice Amount
                            </TableCell>
                        </TableHeader>
                        <TableBody>
                            <DataTableCell style={styles.tableCell} getContent={(sale) => Moment.utc(sale.sale_date).format('DD.MM.YYYY @ HH:mm')}/>
                            <DataTableCell style={styles.tableCell} getContent={(sale) => sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}/>
                            <DataTableCell style={styles.tableCell} getContent={(sale) => `${sale?.amount.toFixed(2)} CHF` }/>
                            {/* <DataTableCell style={styles.tableCell} getContent={(sale) => `${sale?.commission_amount?.toFixed(2)} CHF`}/> */}
                            <DataTableCell style={styles.tableCell} getContent={(sale) => `${sale?.amount.toFixed(2)} CHF`}/>
                        </TableBody>
                    </Table>
                    <View style={[styles.marginTopMD]}>
                        {/* <Text style={[styles.footerText]}>Total Commission: {invoice.commission_amount ? invoice.commission_amount.toFixed(2) + ' CHF' : 'N/A'}</Text> */}
                        <Text style={[styles.footerText]}>SubTotal: {invoice.sub_amount ? invoice.sub_amount.toFixed(2) + ' CHF' : 'N/A'}</Text>
                        {
                            invoice.fees && invoice.fees.monthly_service_fee &&
                            <Text style={[styles.footerText]}>Monthly Service Fee: {invoice.fees.monthly_service_fee.toFixed(2) + ' CHF'}</Text>
                        }
                        <Text style={[styles.footerText]}>Total: {invoice.amount.toFixed(2)} CHF</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default ShuttleInvoicePDF
