import React, { useEffect, useState } from 'react'
import simulLogo from '../../assets/images/logo_hor.svg'
import '../sidebar/sidebar.css'
import { getCurrentUser } from './../../services/auth'
import { getHotelById } from './../../services/hotels';
import SidebarAdmin from './sidebarAdmin'
import SidebarHotelAdmin from './sidebarHotelAdmin'
import SidebarHotelStaff from './sidebarHotelStaff'
import SidebarShuttleCompany from './sidebarShuttleCompany'
import SidebarShuttleDriver from './sidebarShuttleDriver'
import SidebarAirlineAdmin from './sidebarAirlineAdmin'

const Sidebar = ({ drawerOpen, setDrawerOpen, hotelData, setHotelData, setAirlineData }) => {

    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
        if(_user && (_user.role_name === 'hotel-admin' || _user.role_name === 'hotel-staff')){
            fetchHotel()
        }
    }, [_user && _user.username])

    const fetchHotel = async () => {
        const { data: { message } } = await getHotelById(_user.hotel_id);
        setHotelData(message)
    }

    return (
        <div bg='light' className={`col-md-3 col-lg-2 d-md-block sidebar ${drawerOpen && 'drawer-open'}`}>
            <div className='sidebar-sticky'>

                <i onClick={() => setDrawerOpen(false)} className="fas fa-times d-md-none d-block cursor-pointer sidebar-close"></i>

                <section>

                    {
                        user && user.role_name === 'system-admin' &&
                        <>
                            <div className="upper-logo-container">
                                <img className="logo" src={simulLogo} alt="logo" />
                            </div>
                            <SidebarAdmin />
                        </>
                    }

                    {
                        user && user.role_name === 'hotel-admin' &&
                        <>
                            <SidebarHotelAdmin hotel={hotelData} />
                            <div className="bottom-logo-container">
                                <img className="logo" src={simulLogo} alt="logo" />
                            </div>
                        </>
                    }

                    {
                        user && user.role_name === 'airline-admin' &&
                        <>
                            <SidebarAirlineAdmin setAirlineData={setAirlineData}/>
                            <div className="bottom-logo-container">
                                <img className="logo" src={simulLogo} alt="logo" />
                            </div>
                        </>
                    }

                    {
                        user && user.role_name === 'hotel-staff' &&
                        <>
                            <SidebarHotelStaff hotel={hotelData}/>
                            <div className="bottom-logo-container">
                                <img className="logo" src={simulLogo} alt="logo" />
                            </div>
                        </>
                    }

                    {
                        user && user.role_name === 'shuttle-admin' &&
                        <>
                            <div className="upper-logo-container">
                                <img className="logo" src={simulLogo} alt="logo" />
                            </div>
                            <SidebarShuttleCompany />
                        </>
                    }

                    {
                        user && user.role_name === 'shuttle-driver' &&
                        <SidebarShuttleDriver />
                    }

                </section>
            </div>
        </div>
    )
}

export default Sidebar