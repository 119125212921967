import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createShuttleDriver } from '../../services/accounts'
import { getShuttleDrivers } from '../../services/users'
import ShuttleDriverCreator from './shuttleDriverCreator'
import ShuttleDriverList from './shuttleDriverList'

const ShuttleDriverCreation = () => {

    let history = useHistory()

    const [shuttleDrivers, setShuttleDrivers] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsTableLoading(true)
        const { data: { message } } = await getShuttleDrivers()
        setIsTableLoading(false)
        if(Array.isArray(message)) {
            setShuttleDrivers(message)
        }
        else {
            setShuttleDrivers([])
            //TODO: toast when getting hotel admins error
        }
    }

    const handleCreateShuttleDriver = async (payload, password) => {

        setIsLoading(true)
        const {data :{code,message}} = await createShuttleDriver(payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newShuttleDriver = {
                '_id': message['shuttle-account-info']._id,
                'username': message['user-info'].username,
                'password': password,
                'info' :{
                    first_name: message['shuttle-account-info'].first_name,
                    last_name: message['shuttle-account-info'].last_name,
                },
               
            }

            let _shuttleDrivers = shuttleDrivers
            _shuttleDrivers.push(_newShuttleDriver)
            setShuttleDrivers([..._shuttleDrivers])
        }
    }

    return (
        <>
            <Row className="mb-4">
                <Col className="text-left">
                    <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/accounts')}>{'<'} Back</Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="8" >
                    <ShuttleDriverCreator createShuttleDriver={handleCreateShuttleDriver} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <ShuttleDriverList shuttleDrivers={shuttleDrivers} setShuttleDrivers={setShuttleDrivers} 
                        isTableLoading={isTableLoading} 
                        isLoading={isLoading}
                        setIsLoading={setIsLoading} />
                </Col>
            </Row>
        </>
    )
}

export default ShuttleDriverCreation