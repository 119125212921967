import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PageLoader from '../components/common/pageLoader';
import ConfirmationModal from '../components/modals/confirmation';
import CreateTaxiDestinationModal from '../components/modals/createTaxiDestination';
import EditDestinationTaxiModal from '../components/modals/editDestinationTaxi';
import { getCarCategories } from '../services/carCategories';
import { getHotels } from '../services/hotels';
import { deleteTaxiDestination, editTaxiDestination, getAllTaxiDestinations } from '../services/taxiDestinations';
import { removeFromArray } from '../utils/utils';

const TaxiDestinations = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [createTaxiDestinationModal, setCreateTaxiDestinationModal] = useState(false)
    const [destinationTaxiModal, setDestinationTaxiModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)

    const [destinations, setDestinations] = useState([])
    const [hotels, setHotels] = useState()
    const [carCategories, setCarCategories] = useState()
    const [modalCarCategories, setModalCarCategories] = useState()
    const [selectedDestination, setSelectedDestination] = useState()

    const [carCategoryIdsToAdd, setCarCategoryIdsToAdd] = useState([])
    const [carCategoriesToAdd, setCarCategoriesToAdd] = useState([])
    const [carCategoriesToAddDisplay, setCarCategoriesToAddDisplay] = useState([])

    const [filteredDestinations , setFilteredDestinations] = useState([])

    useEffect(() => {
        fetch()
        fetchHotels()
    }, [])

    const fetch = async () => {
        setIsLoading(true)

        const { data: { code, message } } =  await getAllTaxiDestinations()
        if (code == 200) {
            setDestinations(message)
            setFilteredDestinations(message)
            setIsLoading(false)
        }
    }

    const fetchHotels = async () => {
        setIsLoading(true)

        const {data: {code, message}} = await getHotels()
        if (code === 200) {
            setHotels(message)
            setIsLoading(false)
        }

    }

    const handleDeleteDestination = async () => {
        const { data: { code, message } } = await deleteTaxiDestination(selectedDestination._id)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _destinations = destinations
            const index = _destinations.indexOf(selectedDestination)
            _destinations.splice(index, 1)
            setDestinations([..._destinations])
            filterDestinations()
        }
    }

    const handleSaveDestination = async () => {
        const payload = {
            "car_categories": reduceCarCategoriesDocument(carCategoriesToAdd)
        }

        const {data: {code, message}} = await editTaxiDestination(selectedDestination._id, payload)
    
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _destinations = destinations
            const index = _destinations.findIndex(x => x._id === selectedDestination._id)
            _destinations[index] = message
            _destinations[index]['establishment_origin_name'] = hotels.find(x => x._id === message.establishment_origin_id).hotel_name
            setDestinations([..._destinations])
            filterDestinations()
            setDestinationTaxiModal(false)
        }
    }

    const reduceCarCategoriesDocument = (categories) => {
        var _carCategories = []
        categories.forEach(x => {
            if(x.hasOwnProperty('_id')) {
                _carCategories.push({
                    "car_category_id": x.car_category_id,
                    "price": x.price
                })
            } else {
                _carCategories.push(x)
            }
        })
        return _carCategories
    }

    const getCarCategoriesToAddByIds = () => {
        const _carCategoriesToAdd = carCategories.filter(category => carCategoryIdsToAdd.includes(category._id))
        var _reducedCarCategoriesToAdd = []
        _carCategoriesToAdd.forEach(x => {
            _reducedCarCategoriesToAdd.push({
                "car_category_id" : x._id,
                "price": x.price
            })
        })
        return _reducedCarCategoriesToAdd
    }

    const destinationAdded = (destination) => {
        let _destinations = destinations
        let hotel = hotels.find(x => x._id === destination.establishment_origin_id)
        destination['establishment_origin_name'] = hotel.hotel_name
        _destinations.push(destination)
        setDestinations([..._destinations])
        filterDestinations()
    }

    const filterDestinations = (hotelId) => {
        let _destinations = destinations
        const filteredDestinations = hotelId ? _destinations.filter(x => x.establishment_origin_id === hotelId) : _destinations
        setFilteredDestinations([...filteredDestinations])
    }

    const handleConfirmationModal = (destination) => {
        setSelectedDestination(destination)
        setConfirmationModal(true)
    }

    const handleEditDestinationTaxiModal = async (destination) => {
        setSelectedDestination(destination)
        setCarCategoriesToAdd([...destination.car_categories])
        setCarCategoriesToAddDisplay([...destination.car_categories])
        // setCarCategoryIdsToAdd([...getCarCategoryIds(destination.car_categories)])
        setDestinationTaxiModal(true)
    }

    const handleCarCategoryIdsToAdd = (id) => {
        if (carCategoryIdsToAdd.some(ids => ids === id)) {
            let _carCategoryIdsToAdd = carCategoryIdsToAdd
            _carCategoryIdsToAdd = removeFromArray(_carCategoryIdsToAdd, id);
            setCarCategoryIdsToAdd([..._carCategoryIdsToAdd]);
        } else {
            let _carCategoryIdsToAdd = carCategoryIdsToAdd
            setCarCategoryIdsToAdd([..._carCategoryIdsToAdd, id])
        }
    }

    const getCarCategoryIds = (carCategories) => {
        var _carCategoryIds = []
        carCategories.forEach(x => {
            _carCategoryIds.push(x.car_category_id)
        })
        return _carCategoryIds
    }

    return ( 
        <>  
        {isLoading && <PageLoader />}

        <CreateTaxiDestinationModal display={createTaxiDestinationModal} toggle={setCreateTaxiDestinationModal} 
            hotels={hotels} 
            destinationAdded={destinationAdded} />

        {selectedDestination && 
        <EditDestinationTaxiModal display={destinationTaxiModal} toggle={setDestinationTaxiModal}
            modalCarCategories={modalCarCategories} setModalCarCategories={setModalCarCategories}
            carCategoriesToAdd={carCategoriesToAdd} carCategoriesToAddDisplay={carCategoriesToAddDisplay}
            setCarCategoriesToAdd={setCarCategoriesToAdd} setCarCategoriesToAddDisplay={setCarCategoriesToAddDisplay}
            saveDestination={handleSaveDestination} />
        }
        <ConfirmationModal
            title="Delete Taxi Destination"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteDestination()} />

        <section>
            <Col>
                <Row className="mb-5">
                    <Col>
                        <Button onClick={() => setCreateTaxiDestinationModal(true)}>Add Destination</Button>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs="auto" className="pr-0">
                        <Form.Label><strong>Filter by Hotel</strong></Form.Label>
                        <Form.Control as="select" style={{minWidth:'200px'}} onChange={(e) => filterDestinations(e.target.value)}>
                            <option value="">All Hotels</option>
                            {hotels && hotels.map((hotel, index) => (
                                <option key={`hotel-${index}`} value={hotel._id}>{hotel.hotel_name}</option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="row-eq-height">
                    {filteredDestinations.length > 0 &&
                    filteredDestinations.map((destination, index) => (
                        <Col key={`destination-${index}`} md="6" lg="4" className="mb-4">
                            <Card className="h-100">
                                <Card.Body>
                                    
                                    <h4 className="mb-0">{destination.establishment_origin_name} <i className=" fas fa-arrow-circle-right"></i> {destination.destination_name}</h4>
                                    <hr />
                                    <Row>
                                        <Col><strong>Destination Address</strong></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p className="mb-0">{destination.address.street} {destination.address.city} {destination.address.zip} {destination.address.country}</p>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3 mb-1">
                                        <Col><strong>Car Categories</strong></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        {destination.car_categories && destination.car_categories.length > 0 ?
                                        <>
                                        {destination.car_categories.map((category, index) => (
                                            <Button key={`othercategory-${index}`} size="sm" style={{fontSize:'.75rem'}} variant="outline-primary"
                                                className="btn-rounded mr-2 mb-2 no-pointer-events" >
                                                {`${category?.category_info?.name} -  ${category?.price} CHF`}
                                            </Button>
                                        ))}
                                        </>
                                        :
                                        <p className="mb-0">No car categories</p>
                                        }
                                        
                                        </Col>
                                    </Row>
                                    
                                </Card.Body>
                                <Card.Footer className="text-right">
                                    <Button size="sm" className="mr-2" onClick={() => handleEditDestinationTaxiModal(destination)}>Edit</Button>
                                    <Button size="sm" variant="outline-primary" onClick={() => handleConfirmationModal(destination)}>Delete</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
                
            </Col>
        </section>
        </>
     );
}
 
export default TaxiDestinations;