
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';
import { getTaxiDrivers } from '../../services/taxiAccounts';
import { cancelTaxiBooking, getTaxiBookingsToday } from '../../services/taxiBookings';
import { isDateTimePassedCurrentTime, isDateToday, isSimulAccount } from '../../utils/utils';
import AssignTaxiDriverModal from '../modals/assignTaxiDriverModal';
import ChangeTaxiBookingDateModal from '../modals/changeTaxiBookingDate';
import ConfirmationModal from '../modals/confirmation';
import LinkTD from '../../components/common/linkTd';
import { toast } from 'react-toastify';
import ElementLoader from './elementLoader';

const LatestTaxiBookings = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [assignDriverModal, setAssignDriverModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)

    const [bookings, setBookings] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState()
    const [selectedBooking, setSelectedBooking] = useState()

    const _currentUser = getCurrentUser()

    Moment.locale('en');

    useEffect(() => {
        fetch()
        fetchTaxiDrivers()
    }, [])

    const fetch = async () => {
        
        setIsLoading(true)

        const { data: { code, message } } =  await getTaxiBookingsToday()

        setIsLoading(false)
        
        if(code === 200)
        {
            let sortedItems = message.sort((a, b) => new Date(a.booking_date) - new Date(b.booking_date))
            setBookings(sortedItems)
        }
    }

    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if(code === 200){
            setTaxiDrivers(message)
        } else {
            //TODO: toast
        }  
    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const handleAssignDriverModal = (booking) => {
        setSelectedBooking(booking)
        setAssignDriverModal(true)
    }

    const handleCancelBooking = async () => {
        const {data: {code, message}} = await cancelTaxiBooking(selectedBooking._id)
        if (code == 200) {
            var _bookings = bookings
            var index = _bookings.indexOf(selectedBooking);
            _bookings.splice(index, 1);
            setBookings([..._bookings])
            toast.success('Successfully cancelled booking.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toastError(`An error occured on cancelling taxi bookings. ${message}.`)
         }
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    const canChangeTime = (booking) => {
        return !Moment(Moment().add(30, 'minutes').utc().format()).isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canCancel = (booking) => {

        const isSuperAdmin =  _currentUser.role_name === 'system-admin'
        if(isSuperAdmin) return true

        const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(booking.booking_date));
        return !currentTimeHasPassedBookingTime && !booking.fulfilled
    }

    const bookingDateUpdated = (newBookingDate) => {
        const bookingIndex = bookings.indexOf(selectedBooking)
        let _bookings = bookings
        _bookings[bookingIndex].booking_date = newBookingDate
        setBookings(_bookings)
    }

    const driverUpdated = (driverId) => {
        let _bookings = bookings
        const index = _bookings.indexOf(selectedBooking)
        _bookings[index].admin_assigned_candidate_driver_id = driverId;
        if(_bookings[index].driver_assignment){
            _bookings[index].driver_assignment.notified_candidate_driver_latest = driverId;
        }
        else{
            _bookings[index].driver_assignment = { 
                notified_candidate_driver_latest: driverId
            }
        }
        
        setBookings(_bookings)
    }

    const renderAssignDriverButton = (booking) => {

        const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(booking.booking_date));
        const assignedDriverName = getAssignedDriverName(booking)

        //if(!booking.admin_assigned_candidate_driver_id && !booking.driver_assignment?.assigned_driver_id)
        if(!currentTimeHasPassedBookingTime && !booking.driver_assignment?.notified_candidate_driver_latest) {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                    <i className="fas fa-plus"></i> Assign
                </Button>)
        } else if(currentTimeHasPassedBookingTime) {
            return (<>{assignedDriverName}</>)
        } else {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                   <i className="fas fa-pencil-alt"></i> {assignedDriverName}
                </Button>)
        }  
    }
  
    const getAssignedDriverName = (booking) => {

        if(!taxiDrivers || taxiDrivers.length === 0){
            return "No driver";
        }
        if(booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest) {
            const driverId = booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest;
            var _taxiDriver = taxiDrivers.find(driver => driver._id === driverId)
            return  _taxiDriver ? _taxiDriver.first_name + " " + _taxiDriver.last_name : '';
        }
        return "No driver";
    }

    return <>
        {selectedBooking && taxiDrivers && 
        <>
            <ChangeTaxiBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} bookingDateUpdated={bookingDateUpdated} />
            <AssignTaxiDriverModal display={assignDriverModal} toggle={setAssignDriverModal} booking={selectedBooking} taxiDrivers={taxiDrivers} driverUpdated={driverUpdated} />
        </>
        }
        <ConfirmationModal 
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />
        <Card className='bookings-list'>
            <Card.Body>
                <Card.Title>Upcoming Taxi Bookings </Card.Title>
                <Table responsive>
                    <thead className="border-top-0">
                        <tr>
                            <th>Status</th>
                            <th style={{minWidth:'120px'}}>Booked Date</th>
                            <th style={{minWidth:'80px'}}>Room #</th>
                            <th style={{minWidth:'130px'}}>Guest Name</th>
                            <th>Seats</th>
                            <th>Driver</th>
                            <th style={{minWidth:'230px'}}></th>
                        </tr>
                    </thead>
                    <tbody style={{position:'relative'}}>
                        {isLoading && <tr><td><ElementLoader /></td></tr>}
                        {
                        bookings.length > 0 &&
                        bookings.map((booking, index) =>
                            <React.Fragment key={`bookingstaxi-${index}`}>
                            <tr className={isDateTimePassedCurrentTime(booking.booking_date) ? 'booking-past' : ''}>
                                <LinkTD to={`/taxi-booking/${booking._id}`}>
                                    {   
                                        <i style={{color:(booking.is_confirmed || booking.fulfilled ? 'Tomato': 'LightGray')}} 
                                            className={(booking.fulfilled ? 'fas fa-check-circle': 'fas fa-check')} 
                                            title={(booking.fulfilled ? 'Completed': (booking.is_confirmed ? 'Confirmed' : 'Not Confirmed'))}></i>
                                    }
                                </LinkTD>
                                <LinkTD to={`/taxi-booking/${booking._id}`}>{Moment(booking.booking_date).format('DD.MM.YYYY HH:mm')}</LinkTD>
                                <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                {isSimulAccount() ?
                                <td>
                                    {booking.fulfilled && getAssignedDriverName(booking)}
                                    {!booking.fulfilled && renderAssignDriverButton(booking)}
                                </td>
                                :
                                <td>
                                    {getAssignedDriverName(booking)} 
                                </td>
                                }
                                <td>
                                    {canChangeTime(booking) && 
                                        <Button className="mr-2" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>
                                            <i className="fas fa-clock"></i> Change Time
                                        </Button>
                                    }
                                    {canCancel(booking) &&
                                        <Button size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                    }
                                </td>
                            </tr>
                            </React.Fragment>
                        )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
                
    </>
}

export default LatestTaxiBookings