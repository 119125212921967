import http from './http'
import { getToken } from './auth'

const base = 'bookingSales'

export async function getMonthlyBookingSales() {

    return await http.get(`${base}/monthly`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function getWeeklyBookingSales() {

    return await http.get(`${base}/weekly`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function getDailyBookingSales() {

    return await http.get(`${base}/daily`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function getAllBookingSales() {

    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

//#region not in use
// export async function getBookingSalesByHotelId(id, month, year) {

//     return await http.get(`${base}/by-hotel-id/${id}/${month}-${year}`, {
//         headers: {
//             'x-auth-token': getToken()
//         }
//     })
// }
//#endregion

export async function getFilteredShuttleBookingSales(payload) {

    var uri = `${base}/filter?&month_year=${payload?.month}-${payload?.year}`
    if(payload.hotel_id) { uri += `&hotel_id=${payload?.hotel_id}` }
    if(payload.hotel_id && payload.hotel_personnel_id) { uri += `&hotel_personnel_id=${payload?.hotel_personnel_id}` }
    if(payload.shuttle_driver_id) { uri += `&shuttle_driver_id=${payload?.shuttle_driver_id}` }

    return await http.get(uri, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}