import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCountries } from '../../services/address'
import { updateHotel } from '../../services/hotels'
import Select from 'react-select'

const UpdateHotelModal = ({ display, toggle, hotel, hotelUpdated }) => {

    const [name, setName] = useState()
    const [street, setStreet] = useState()
    const [zip, setZip] = useState()
    const [city, setCity] = useState()
    const [country, setCountry] = useState()
    const [countries, setCountries] = useState([])
    const [numberOfRooms, setNumberOfRooms] = useState()
    const [maxGuests, setMaxGuests] = useState()
    const [monthlyServiceFee, setMonthlyServiceFee] = useState()
    const [guestDestinationRoutes, setGuestDestinationRoutes] = useState([])
    const [allowedFeatures, setAllowedFeatures] = useState([])

    const destinationRoutesOptions = [
        { value: 'hotel-airport', label: 'Hotel to Airport' },
        { value: 'airport-hotel', label: 'Airport to Hotel' },
    ]

    const allowedFeaturesOptions = [
        { value: 'swiss layover', label: 'Swiss Layover' },
        { value: 'shuttle booking', label: 'Shuttle Booking' }
    ]


    useEffect(() => {
        fetchCountries()
    }, [])

    const fetchCountries = async () => {
        const { data: { code, message } } = await getCountries();
        if (code == 200) {
            setCountries(message)
        }
    }

    const handleUpdateHotelInfo = async () => {

        var payload = {}
        var address = hotel.address

        if (street) address['street'] = street
        if (zip) address['zip'] = zip
        if (city) address['city'] = city
        if (country) address['country'] = country

        payload['_id'] = hotel._id
        if (name) payload['hotel_name'] = name
        if (!(address && Object.keys(address).length === 0 && address.constructor === Object)) payload['address'] = address
        if (numberOfRooms) payload['number_of_rooms'] = numberOfRooms
        if (maxGuests) payload['max_number_of_guests'] = maxGuests
        payload['routes_require_guest_payment'] = guestDestinationRoutes && guestDestinationRoutes.map(x => x.value)
        payload['allowed_features'] = allowedFeatures && allowedFeatures.map(x => x.value)

        if (monthlyServiceFee) {
            if (!payload.fees) payload.fees = {}

            payload.fees.monthly_service_fee = monthlyServiceFee
        }

        const { data: { code, message } } = await updateHotel(hotel._id, payload)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            hotelUpdated(payload)
            toggle(false)
        }
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            centered
        >
            <Modal.Body className="update-hotel-modal">
                <Card.Title className="mb-3">Update Hotel</Card.Title>
                <div className="update-hotel">

                    <Form.Group>
                        <Form.Label>Hotel Name</Form.Label>
                        <Form.Control type='text' placeholder='Hotel name' defaultValue={hotel?.hotel_name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Street</Form.Label>
                                <Form.Control type='text' placeholder='Street' defaultValue={hotel?.address?.street} onChange={e => setStreet(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Zip</Form.Label>
                                <Form.Control type='text' placeholder='Zip' defaultValue={hotel?.address?.zip} onChange={e => setZip(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control type='text' placeholder='City' defaultValue={hotel?.address?.city} onChange={e => setCity(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control size="sm" as="select" onChange={(e) => setCountry(e.target.value)}>
                                    <option disabled={true} key={`country-0`} selected={!hotel.address.country} value=''>Select Country</option>
                                    {countries && countries.map((_country, index) => (
                                        <option key={`country-${index}`} selected={hotel.address.country === _country} value={_country}>{_country}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Number of rooms</Form.Label>
                                <Form.Control type='number' placeholder='Number of rooms' defaultValue={hotel?.number_of_rooms} onChange={e => setNumberOfRooms(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Max guests</Form.Label>
                                <Form.Control type='number' placeholder='Max guests' defaultValue={hotel?.max_number_of_guests} onChange={e => setMaxGuests(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Shuttle routes that require guest payment</Form.Label>
                                <Select defaultValue={hotel?.routes_require_guest_payment?.map(x => { return { value: x, label: x } })} isMulti={true} onChange={(value) => setGuestDestinationRoutes(value)} placeholder={""}
                                    options={destinationRoutesOptions} />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Allowed Features</Form.Label>
                                <Select defaultValue={hotel?.allowed_features?.map(x => { return { value: x, label: x } })} isMulti={true} onChange={(value) => setAllowedFeatures(value)} placeholder={""}
                                    options={allowedFeaturesOptions} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Monthly Service Fee</Form.Label>
                                <Form.Control type='number' placeholder='Monthly Service Fee' defaultValue={hotel?.fees?.monthly_service_fee} onChange={e => setMonthlyServiceFee(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Button className="w-100 mt-3" onClick={() => handleUpdateHotelInfo()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateHotelModal

