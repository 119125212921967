
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import PageLoader from '../../components/common/pageLoader'
import TimetableCalender from '../../components/common/timetableCalendar';
import { getCurrentUser } from '../../services/auth';
import { getHotelById } from '../../services/hotels';
import { getVehicles } from '../../services/vehicles';
import BookShuttleDestinations from './bookShuttleDestinations';
import HotelDestinations from '../airlineAdmin/hotelDestinations';
import { getAirlineById } from '../../services/airlines';
import { isAirlineAccount } from '../../utils/utils';

const BookShuttlePage = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [hasSelectedDestination, setHasSelectedDestination] = useState(false)
    const [destination, setDestination] = useState('')

    const [hotel, setHotel] = useState()
    const [airline, setAirline] = useState()
    // const [availableVehicles, setAvailableVehicles] = useState()

    const _currentUser = getCurrentUser();

    useEffect(() => {
        fetchHotel()
        isAirlineAccount() && fetchAirline()
    }, [])

    const fetchHotel = async () => {
        setIsLoading(true)
        const { data: { message } } = await getHotelById(_currentUser.hotel_id);
        setHotel(message);
        setIsLoading(false)
    }

    const fetchAirline = async () => {
        const { data: { message } } = await getAirlineById(_currentUser.airline_id);
        setAirline(message);
    }

    const selectDestination = (destination) => {
        setHasSelectedDestination(true)
        setDestination(destination)
    }

    return <>
        {isLoading && <PageLoader />}
        {!isLoading &&
            <section>
                <Col>
                    <>
                        {!hasSelectedDestination ?
                            <>
                                {(_currentUser.role_name === "airline-admin" || _currentUser.role_name === "airline-staff") &&
                                    <>
                                        <HotelDestinations setHotel={setHotel} selectDestination={selectDestination} />
                                    </>
                                }

                                {(_currentUser.role_name === "hotel-admin" || _currentUser.role_name === "hotel-staff") &&
                                    <>
                                        <BookShuttleDestinations selectDestination={selectDestination} hotel={hotel} />
                                    </>
                                }
                            </>
                            :
                            <>
                                {/* <TimetableCalender destination={destination} hotel={hotel} seatsPerVehicle={availableVehicles.map(x => { return { id: x._id, seats: x.seats } })} /> */}
                                <TimetableCalender destination={destination} hotel={hotel} airline={airline} />
                            </>
                        }
                    </>

                </Col>
            </section>
        }
    </>
}

export default BookShuttlePage
