import http from './http'
import { getToken } from './auth'

const base = 'taxiBookingFares'

export async function getMonthlyTaxiBookingSales() {

    return await http.get(`${base}/monthly`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function getWeeklyTaxiBookingSales() {

    return await http.get(`${base}/weekly`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function getDailyTaxiBookingSales() {

    return await http.get(`${base}/daily`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getFilteredTaxiBookingSales(payload) {

    var uri = `${base}/filter?&month_year=${payload?.month}-${payload?.year}`
    if(payload.hotel_id) { uri += `&hotel_id=${payload?.hotel_id}` }
    if(payload.hotel_id && payload.hotel_personnel_id) { uri += `&hotel_personnel_id=${payload?.hotel_personnel_id}` }
    if(payload.taxi_driver_id) { uri += `&taxi_driver_id=${payload?.taxi_driver_id}` }

    return await http.get(uri, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}