import React from 'react';
import { Form, Modal, Card, Button, Row, Col, FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { useEffect } from 'react';
import { getVehicles } from '../../services/vehicles';
import { removeFromArray } from '../../utils/utils';
import { addTime } from '../../services/shuttleDestinations';
import DialogBoxModal from './dialogBox';
import { toast } from 'react-toastify'
import { getHotels } from '../../services/hotels';

const AddTimeTimetableModal = ({hotels, timeAdded, display, toggle, timetableTimes, timetable, vehicles}) => {

    const [duplicateTimeModal, setDuplicateTimeModal] = useState(false)
    const [seatPriceMsgModal, setSeatPriceMsgModal] = useState(false)

    const [time, setTime] = useState(new Date())
    const [seatPrice, setSeatPrice] = useState(0);
    const [seatPriceCurrency, setSeatPriceCurrency] = useState('CHF');
    const [vehiclesToAdd, setVehiclesToAdd] = useState([])

    const [establishmentsPrices, setEstablishmentsPrices] = useState([])
    const [establishmentsPricesToAdd, setEstablishmentsPricesToAdd] = useState([])
    const [establishmentsPricesToAddDisplay, setEstablishmentsPricesToAddDisplay] = useState([])
    const [hotel, setHotel] = useState({})

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        setEstablishmentsPrices(hotels)
            
        if(timetable.origin_type === 'hotel') {
            setHotel(hotels.find(x => x._id === timetable.origin_id))
        } else {
            setHotel(hotels.find(x => x._id === timetable.destination_id))
        }
    }

    const hasMonthlyServiceFee = (_hotel) => {
        if(!_hotel) return false;
        if(!_hotel.fees) return false;
        return _hotel.fees.monthly_service_fee && _hotel.fees.monthly_service_fee > 0;
    }

    const handleVehiclesToAdd = (id) => {
        if (vehiclesToAdd.some(ids => ids === id)) {
            let _vehicles = vehiclesToAdd
            _vehicles = removeFromArray(_vehicles, id);
            setVehiclesToAdd([..._vehicles]);
        } else {
            let _vehicles = vehiclesToAdd
            setVehiclesToAdd([..._vehicles, id])
        }
    }

    const handleSave = async () => {

        const success = await handleAddTime()
        if (success) {
            toggle(false)
        }
    }

    const handleAddTime = async () => {

        let success = false
        
        let _time = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })
        _time = _time.replace('24:','00:')

        if(!timetable.is_common_origin) {
            
            if(!hasMonthlyServiceFee(hotel) && seatPrice <= 0)
                return setSeatPriceMsgModal(true)
        }

        if(!timetableTimes.some(x => x.time == _time)) {
            
            const payload = {
                time: _time,
                vehicle_ids: vehiclesToAdd,
                seat_price: seatPrice,
                seat_price_currency: seatPriceCurrency,
                seat_price_list: establishmentsPricesToAdd
            }

            const {data:{code, message}} = await addTime(timetable._id, payload)
            if (code !== 200) {
                toast.error(`An error occured. Unable to add time. '${message}'`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else{
                timeAdded(timetable, message)
                success = true
            }
        }
        else {
            setDuplicateTimeModal(true)
        }

        return success;
    }

    const handleEstablishmentsPricesStatusClass = (statusClass, index) => {
        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices[index]['statusClass'] = statusClass
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleSetEstablishmentPrice = (price, index) => {
        handleEstablishmentsPricesStatusClass('', index)
        
        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices[index]['amount'] = price;
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleEstablishmentPricesToAdd = (establishmentPrice, index) => {

        if (!establishmentPrice.amount || establishmentPrice.amount < 0) {
            handleEstablishmentsPricesStatusClass('is-invalid', index)
            return
        }

        if( !hasMonthlyServiceFee(establishmentPrice) && establishmentPrice.amount <= 0) {
            handleEstablishmentsPricesStatusClass('is-invalid', index)
            return
        }

        let _establishmentsPricesToAdd = establishmentsPricesToAdd
        _establishmentsPricesToAdd.push({
            "establishment_id": establishmentPrice._id,
            "amount": establishmentPrice.amount,
        })
        setEstablishmentsPricesToAdd([..._establishmentsPricesToAdd])

        let _establishmentsPricesToAddDisplay = establishmentsPricesToAddDisplay
        _establishmentsPricesToAddDisplay.push({
            "establishment_id": establishmentPrice._id,
            "amount": establishmentPrice.amount,
            "hotel_name": establishmentPrice.hotel_name
        })
        setEstablishmentsPricesToAddDisplay([..._establishmentsPricesToAddDisplay])

        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices.splice(index, 1)
        setEstablishmentsPrices([..._establishmentsPrices])
    }

    const handleEstablishmentPricesToRemove = (establishmentPrice, index) => {

        let _establishmentsPrices = establishmentsPrices
        _establishmentsPrices.push({
            "establishment_id": establishmentPrice._id,
            "amount": establishmentPrice.amount,
            "hotel_name": establishmentPrice.hotel_name
        })
        setEstablishmentsPrices([..._establishmentsPrices])

        let _establishmentsPricesToAdd = establishmentsPricesToAdd
        _establishmentsPricesToAdd.splice(index, 1)
        setEstablishmentsPricesToAdd([..._establishmentsPricesToAdd])

        let _establishmentsPricesToAddDisplay = establishmentsPricesToAddDisplay
        _establishmentsPricesToAddDisplay.splice(index, 1)
        setEstablishmentsPricesToAddDisplay([..._establishmentsPricesToAddDisplay])
    }

    return ( 
        <>
        <Modal
        className="timetable-addtime"
        show={display}
        onHide={() => toggle(false)}
        size={timetable.is_common_origin ? 'lg' : 'md'}
        centered
        >
            
            <Modal.Body>
                <Card.Title className="mb-2">
                    Add Time
                </Card.Title>     
                <div className="form-inline mb-2 simul-datepicker">
                    <strong className="mr-3">Time:</strong> 
                    <DatePicker
                    className="form-control form-control-sm"
                    selected={time}
                    onChange={date => setTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    />
                </div>
                
                {timetable.is_common_origin ?
                <Row className="mb-3">
                    <Col lg="6">
                        <strong>Establishments Price:</strong>
                        <Form.Text className="mb-2">Select establishments and set prices</Form.Text>
                        <div className="p-2 border rounded" style={{minHeight:'53px'}}>
                            {establishmentsPrices && establishmentsPrices.map((establishmentPrice, index) => (
                                <Row key={`establishment-price-${index}`} className="mb-1">
                                    <Col xs="7">
                                        <FormControl value={establishmentPrice.hotel_name} size="sm" disabled />
                                    </Col>
                                    <Col xs="3" className="px-0">
                                       <FormControl className={`${establishmentPrice.statusClass}`} value={establishmentPrice.amount ?? ''} onChange={(e) => handleSetEstablishmentPrice(e.target.value, index)} placeholder="price" size="sm" type="number" />
                                    </Col>
                                    <Col xs="2">
                                        <Button onClick={() => handleEstablishmentPricesToAdd(establishmentPrice, index)} size="sm" title="add category"><i className="fas fa-plus"></i></Button>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">&nbsp;</Form.Label>
                        <Form.Text className="mb-2">Establishments prices to add</Form.Text>
                        <div className="p-2 border rounded" style={{minHeight:'53px'}} >
                        {establishmentsPricesToAddDisplay.length > 0 && establishmentsPricesToAddDisplay.map((establishmentPrice, index) => (
                            <Row key={`establishment-price-to-add-${index}`} className="mb-1">
                                <Col xs="7">
                                    <FormControl value={establishmentPrice.hotel_name} size="sm" disabled />
                                </Col>
                                <Col xs="3" className="px-0">
                                    <FormControl value={`${establishmentPrice.amount} CHF`} size="sm" disabled />
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => handleEstablishmentPricesToRemove(establishmentPrice, index)} size="sm" title="remove price"><i className="fas fa-minus"></i></Button>
                                </Col>
                            </Row>
                        ))}
                        </div>
                    </Col>
                </Row>
                :
                <div className="form-inline mb-2">
                    <strong className="mr-3">Seat Price: </strong>
                    <Form.Control type="number" required size="sm" onChange={(e) => setSeatPrice(e.target.value)} placeholder="Enter Seat Price" />
                </div>
                }

                <div className="form-inline mb-2">
                    <strong className="mr-3">Seat Price Currency: </strong>
                    <Form.Control required size="sm" value={seatPriceCurrency} onChange={(e) => setSeatPriceCurrency(e.target.value)} placeholder="Enter Currency" />
                </div>

                <p className="mb-0 mb-2"><strong>Vehicles: {vehicles.length}</strong> </p>
                <p className="mb-1"><small>Select Vehicles</small></p>
                <div className="timetable-addtime-modal-vehicles">
                    
                    {vehicles && vehicles.map((vehicle, index) => (
                        <React.Fragment key={`addtime-vehicle-${index}`}>
                            <Button size="sm" variant={vehiclesToAdd.some(ids => ids == vehicle._id) ? "outline-primary" : "outline-secondary"} 
                                className="btn-rounded mr-2 mb-2" 
                                onClick={() => handleVehiclesToAdd(vehicle._id)}>
                                {vehicle.name}
                            </Button>
                        </React.Fragment>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSave()}>Save</Button>
                <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>

        <DialogBoxModal display={duplicateTimeModal} toggle={setDuplicateTimeModal} title='Duplicate Time' message='The time you wish to add already exists. Please add another time.' />
        <DialogBoxModal display={seatPriceMsgModal} toggle={setSeatPriceMsgModal} title='Seat Price Required' message='The seat price you entered is invalid. Please enter a valid seat price.' />
        </>
    );
}
 
export default AddTimeTimetableModal;