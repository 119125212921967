import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker';
import { toast } from 'react-toastify'
import { updateTaxiBookingDateTime } from '../../services/taxiBookings';
import { toLocaleISOString } from '../../utils/utils';

const ChangeTaxiBookingDateModal = ({ display, toggle, booking, bookingDateUpdated }) => {

    const [selectedDateTime, setSelectedDateTime] = useState(new Date(booking.booking_date))

    const saveBookingDate = async () => {

        const {data: {code, message}} = await updateTaxiBookingDateTime(booking._id, toLocaleISOString(selectedDateTime))
        if (code == 200) {
            bookingDateUpdated(toLocaleISOString(selectedDateTime))
            toggle(false)
        } else {
            toast.error(` Unable to change booking time! ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }
    }
    
    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body className="change-booking-time-modal">
                <Card.Title className="mb-3">Change Booking Time</Card.Title>
                <div className="change-booking-time">
                    <Form.Group>
                        <Form.Label>Booking Date</Form.Label>
                        <div className="">
                            <DatePicker
                                locale="en-gb"
                                showTimeSelect
                                selected={selectedDateTime}
                                wrapperClassName="modal-datepicker"
                                onChange={(e) => setSelectedDateTime(e)}
                                dateFormat="MMM d, yyyy h:mm aa"
                                />
                        </div>
                    </Form.Group>
                    <Button className="w-100" onClick={() => saveBookingDate()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default ChangeTaxiBookingDateModal

