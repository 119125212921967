import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createVehicle } from '../../services/vehicles'
import { getVehicles } from '../../services/vehicles'
import { getShuttleAccounts } from '../../services/accounts'
import PageLoader from '../common/pageLoader'
import VehicleCreator from './vehicleCreator'
import VehicleList from './vehicleList'

const VehicleCreation = () => {

    const [vehicles, setVehicles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [shuttleAccounts, setShuttleAccounts] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        setIsLoading(true)
        const { data: { message } } = await getVehicles()
        if(Array.isArray(message))
            setVehicles(message)
        else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
              })
        }

        const simulAccountsResp = await getShuttleAccounts()
        if(simulAccountsResp.data.code !== 200){
            toast.error(` ${simulAccountsResp.data.message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
              })
        }
        else setShuttleAccounts(simulAccountsResp.data.message)

        setIsLoading(false)
    }

    const handleCreateVehicle = async (payload) => {

        const {data :{code,message}} = await createVehicle(payload)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newVehicle = {
                '_id' : message._id,
                'name': message.name,
                'model': message.model,
                'year': message.year,
                'color': message.color,
                'seats': message.seats,
                'driver_id': message.driver_id,
                'available': message.available,
                'auto_assignment_priority_no': message.auto_assignment_priority_no
            }

            let _vehicles = vehicles
            _vehicles.push(_newVehicle)
            setVehicles([..._vehicles])

            toast.success(`Successfully created vehicle.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }

        return code
    }

    return (
        <>
        {isLoading && <PageLoader />}
        <Row className="mb-4">
            <Col>
                <VehicleCreator createVehicle={handleCreateVehicle} shuttleAccounts={shuttleAccounts}/>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <VehicleList vehicles={vehicles} setVehicles={setVehicles} simulAccounts={shuttleAccounts}/>
            </Col>
        </Row>
        </>
    )
}

export default VehicleCreation