import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'

const AirlineCreator = ({ createAirline, isCreating }) => {

    const [name, setName] = useState('')
    const [allowedFeatures, setAllowedFeatures] = useState([])

    const allowedFeaturesOptions = [
        { value: 'shuttle booking', label: 'Shuttle Booking' }
    ]

    const handleCreateAirline = async () => {

        const airline = {
            name,
            allowed_features: allowedFeatures && allowedFeatures.map(x => x.value)
        }

        createAirline(airline)
        setName('')
        setAllowedFeatures([])
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center text-white'>Create Airline</Card.Header>
            <Card.Body>
                <Form id="airline-creator">
                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Airline Name</Form.Label>
                                <Form.Control type='text' value={name} placeholder='Enter Airline Name' onChange={e => setName(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Allowed Features</Form.Label>
                                <Select value={allowedFeatures} isMulti={true} onChange={(value) => setAllowedFeatures(value)} placeholder={"Allowed features"}
                                    options={allowedFeaturesOptions} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center mt-4">
                    <Button disabled={isCreating} variant='primary' onClick={() => handleCreateAirline()}>
                        {isCreating ? 'Creating...' : 'Create'}
                    </Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default AirlineCreator