import React from 'react';
import HotelCreation from '../components/admin/hotelCreation';

const HotelsPage = () => {
    return <>
        <section>
            <HotelCreation />
        </section>
    </>
}

export default HotelsPage