import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import GenerateShuttleInvoiceModal from '../components/modals/generateShuttleInvoice';
import LinkTD from '../components/common/linkTd';
import ConfirmationModal from '../components/modals/confirmation';
import { cancelInvoice, getShuttleInvoices } from '../services/invoicesShuttle';
import { isSimulAccount } from '../utils/utils';

const ShuttleInvoicesPage = () => {

    const [generateShuttleInvoiceModal, setGenerateShuttleInvoiceModal] = useState(false)
    const [invoices, setInvoices] = useState()

    const [selectedInvoice, setSelectedInvoice] = useState()
    const [confirmationModal, setConfirmationModal] = useState(false) 

    useEffect(() => {
        fetch()
    }, [])
    
    const fetch = async () => {
        const { data: { code, message } } = await getShuttleInvoices()
        if(code === 200)
            setInvoices(message)
        else {
            toast.error(`${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } 
    }

    const handleCancelInvoice = async () => {
        const { data: { code, message } } =  await cancelInvoice(selectedInvoice._id)
        if (code == 200) {
            let _invoices = invoices
            const index = _invoices.findIndex(x => x._id === selectedInvoice._id)
            _invoices.splice(index, 1)
            setInvoices([..._invoices])
         } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
         }
    }

    const handleConfirmationModal = (invoice) => {
        setSelectedInvoice(invoice)
        setConfirmationModal(true)
    }

    return <>

        <GenerateShuttleInvoiceModal display={generateShuttleInvoiceModal} toggle={setGenerateShuttleInvoiceModal} />

        <ConfirmationModal
            title="Cancel Invoice"
            message="Are you sure you want to cancel this invoice?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleCancelInvoice()} />

        <section>
            <Row>
                <Col>
                    {
                        isSimulAccount() && 
                        <Row className="mb-3">
                            <Col>
                                <Button onClick={() => setGenerateShuttleInvoiceModal(true)}>Generate Invoice</Button>
                            </Col>
                        </Row>
                    }
                    <Card>
                        <Card.Header className='text-center bg-primary text-white'>Shuttle Invoices</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>Invoice Number</th>
                                        { isSimulAccount() && <th>Establishment</th> }
                                        <th>Period</th>
                                        <th>Number Of Bookings</th>
                                        <th>Amount</th>
                                        { isSimulAccount() && <th></th> }
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices ? 
                                    <>
                                    {invoices.map((invoice, index) => (
                                        <tr key={`invoice-${index}`}>
                                            <LinkTD to={`/shuttle-invoices/${invoice._id}`}>{invoice.invoice_human_readable_number}</LinkTD>
                                            { isSimulAccount() && <LinkTD to={`/shuttle-invoices/${invoice._id}`}>
                                                {invoice.establishment_type === "hotel" ? <i className="fas fa-building mr-2 color-primary"></i> : <i class="fas fa-plane-departure mr-2 color-primary"></i>} 
                                                {invoice.establishment_name}
                                            </LinkTD> }
                                            <LinkTD to={`/shuttle-invoices/${invoice._id}`}>{Moment.utc(invoice.period_from).format('DD.MM.YYYY') + " - " + Moment.utc(invoice.period_to).format('DD.MM.YYYY')}</LinkTD>
                                            <LinkTD to={`/shuttle-invoices/${invoice._id}`}>{invoice.number_of_sales}</LinkTD>
                                            <LinkTD to={`/shuttle-invoices/${invoice._id}`}>{invoice.amount}</LinkTD>
                                            { isSimulAccount() && <td>
                                                {!invoice.is_paid && <Button size="sm" onClick={() => handleConfirmationModal(invoice)}>Cancel</Button> }
                                            </td>}
                                        </tr>
                                    ))}
                                    </>
                                    :
                                    <tr>
                                        <td colSpan="5">No invoices found.</td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default ShuttleInvoicesPage