import http from './http'
import { getToken } from './auth';

export async function createAccount(payload, creator) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    return await http.post(`${creator}/create-user`, payload, config)
}

// Simul Accounts
export async function getSimulAccounts() {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.get(`simulAccounts/`, config)
}

// Shuttle Accounts
export async function getShuttleAccounts() {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.get(`shuttleAccounts/`, config)
}

export async function shuttleAccountsCrewBookingsAllowed(id, payload) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`shuttleAccounts/crew-bookings/allow/${id}`, payload, config)
}

// Hotel Accounts
export async function getAllHotelAccounts() {
    return await http.get(`hotelAccounts/all`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getHotelAccountsByHotelId(id) {
    return await http.get(`hotelAccounts/hotel-accounts/by-hotel-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getHotelStaffs(id) {
    return await http.get(`hotelAccounts/hotel-staff/by-hotel-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
export async function createHotelStaff(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`hotelAccounts/create-hotel-staff`, payload, config)
}
export async function deleteHotelStaff(id) {
    return await http.delete(`hotelAccounts/hotel-staff/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

// Shuttle Accounts
export async function createShuttleDriver(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`shuttleAccounts/create-shuttle-driver`, payload, config)
}

// Conditional
export async function updateUser(payload, id) {

    var userRole = ''
    if (payload.role_name == 'hotel-admin' || payload.role_name == 'hotel-staff')
        userRole = 'hotelAccounts'
    else if (payload.role_name == 'airline-admin' || payload.role_name == 'airline-staff')
        userRole = 'airlineAccounts'
    else if (payload.role_name == 'shuttle-admin' || payload.role_name == 'shuttle-driver')
        userRole = 'shuttleAccounts'
    else
        userRole = 'systemAdmin'

    return await http.patch(`${userRole}/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}