import React from 'react'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import ConfirmationModal from '../modals/confirmation'
import UpdateAccountModal from '../modals/updateAccount'

const HotelStaffList = ({ hotelStaffs, setHotelStaffs, deleteHotelStaff }) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [hotelStaffId, setHotelStaffId] = useState('')
    const [hotelStaffIndex, setHotelStaffIndex] = useState('')
    const [user, setUser] = useState()

    const accountUpdated = (account) => {
        const userIndex = hotelStaffs.indexOf(user)
        let _hotelStaffs = hotelStaffs
        if(account.new_password) _hotelStaffs[userIndex].password = account.new_password
        if(account.first_name) _hotelStaffs[userIndex].first_name = account.first_name
        if(account.last_name) _hotelStaffs[userIndex].last_name = account.last_name
        setHotelStaffs(_hotelStaffs)
    }

    const handleChangePasswordModal = async (user) => {
        setUser(user)
        setChangePasswordModal(true)
    }

    const handleSetConfirmationModal = (id, index) => {
        setConfirmationModal(true)
        setHotelStaffId(id)
        setHotelStaffIndex(index)
    }
    
    return <>

        {user && 
        <UpdateAccountModal display={changePasswordModal} toggle={setChangePasswordModal} user={user} accountUpdated={accountUpdated} />
        }

        <ConfirmationModal 
            title="Delete Staff"
            message="Are you sure you want to delete this staff?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => deleteHotelStaff(hotelStaffId, hotelStaffIndex)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary' style={{ color: 'white' }}>Hotel Staffs</Card.Header>
            <Card.Body>
                <Table striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Password</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            hotelStaffs.length > 0 &&
                            hotelStaffs.map((hotelStaff, index) =>
                                <tr key={`hotelstaff-${index}`}>
                                    <td>{hotelStaff.username}</td>
                                    <td>{hotelStaff.password}</td>
                                    <td>{hotelStaff.first_name}</td>
                                    <td>{hotelStaff.last_name}</td>
                                    <td className="actions text-center">
                                        <i title="update account" className="fas fa-user-edit mr-2" onClick={() => handleChangePasswordModal(hotelStaff)}></i>
                                        <i onClick={() => handleSetConfirmationModal(hotelStaff._id, index)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default HotelStaffList
