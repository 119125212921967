import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactHowler from 'react-howler';
import bellRing from '../../assets/sounds/bell-ring.wav';

const GuestShuttleOrderNoticeModal = ({ 
    display, 
    toggle, 
    title, 
    message, 
    confirmText,
    // cancelText,
    size = 'sm',
    bellIcon = false
    }) => {

    const [playSound, setPlaySound] = useState(false)

    const handleModalHide = () => {
        setPlaySound(false)
        toggle(false)
    }

    return (
        <>
        <ReactHowler
            src={bellRing}
            loop={true}
            html5={true}
            playing={playSound}
        />
        <Modal
        show={display}
        onHide={() => handleModalHide()}
        size={size}
        keyboard={false}
        backdropClassName="level-2"
        centered
        >
            <Modal.Body>
                {bellIcon &&
                <div className="text-center my-2">
                    <i className="far fa-bell faa-ring animated fa-4x"></i>
                </div>
                }
                <h3 className="text-center">{title}</h3>
                <p className="text-center mb-0">{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleModalHide()}>{confirmText}</Button>
                {/* <Button variant="secondary" onClick={() => handleModalHide()}>{cancelText}</Button> */}
            </Modal.Footer>
        </Modal>
        </>
        
  )
}

export default GuestShuttleOrderNoticeModal

