import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { getCurrentUser } from '../../services/auth';

const AirlineInfo = (props) => {
    
    const [airline, _] = useState(props.airline)

    const _currentuser = getCurrentUser()

    return ( 
        <>
        {airline && 
        <>
        <Card>
            <Card.Body>
                <p><strong>INFORMATION</strong></p>
                <hr></hr>
                <Row>
                    <Col className="mb-3">
                        <h4>{airline?.name}</h4>
                        {/* {
                            airline?.address &&
                            <>
                                <p>{airline.address.street} {airline.address.city} {airline.address.zip} {airline.address.country}</p>
                            </>
                        } */}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
        }
        </>
     );
}
 
export default AirlineInfo;