import React, { useEffect, useState } from 'react';
import { deleteAirlineCrewRoutePlace, getAllAirlineCrewRoutePlaces } from '../../../services/airlineCrewRoutePlaces';
import { Button, Card, Table } from 'react-bootstrap';
import AirlineCrewRoutePlaceSetModal from '../../modals/airline-crew/airlineCrewRoutePlaceSet';
import { toast } from 'react-toastify'
import ConfirmationModal from '../../modals/confirmation';

const AirlineCrewRoutePlaces = () => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [airlineCrewRoutePlaceSetModal, setAirlineCrewRoutePlaceSetModal] = useState(false)

    const [routePlaces, setRoutePlaces] = useState([])
    const [selectedRoutePlace, setSelectedRoutePlace] = useState()

    useEffect(() => {
        fetchAirlineCrewRoutePlaces()
    }, [])

    const fetchAirlineCrewRoutePlaces = async () => {

        const { data: { message } } = await getAllAirlineCrewRoutePlaces()
        if (Array.isArray(message))
            setRoutePlaces(message)
        else {
            setRoutePlaces([])
        }
    }

    const handleSetRoutePlaceModal = (routePlace) => {
        setSelectedRoutePlace(routePlace)
        setAirlineCrewRoutePlaceSetModal(true)
    }

    const handleConfirmationModal = (routePlace) => {
        setSelectedRoutePlace(routePlace)
        setConfirmationModal(true)
    }

    const handleDeleteAirlineRoutePlace = async (id) => {
        const { data: { code, message } } = await deleteAirlineCrewRoutePlace(id)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this route. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else {
            let _routePlaces = routePlaces
            let selectedRoutePlaceIndex = _routePlaces.indexOf(selectedRoutePlace)
            _routePlaces.splice(selectedRoutePlaceIndex, 1)
            setRoutePlaces([..._routePlaces])
        }
    }

    return (
        <>
            <ConfirmationModal
                title="Remove Route Place"
                message="Are you sure you want to delete this route place?"
                display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteAirlineRoutePlace(selectedRoutePlace.id)} />
            <AirlineCrewRoutePlaceSetModal display={airlineCrewRoutePlaceSetModal} toggle={setAirlineCrewRoutePlaceSetModal} selectedRoutePlace={selectedRoutePlace} routePlaces={routePlaces} setRoutePlaces={setRoutePlaces} />

            <div className="mb-2">
                <Button variant='primary' onClick={() => handleSetRoutePlaceModal()}>Add Route Place</Button>
            </div>

            <Card className='w-100'>
                <Card.Header className='text-center bg-primary text-white'>Route Places</Card.Header>
                <Card.Body>
                    <Table responsive striped bordered hover variant='light'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th style={{ width: "65px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                routePlaces.length > 0 &&
                                routePlaces.map((routePlace, index) =>
                                    <tr key={`routePlace-${index}`}>
                                        <td>{routePlace?.name}</td>
                                        <td>{routePlace?.type}</td>
                                        <th className="actions text-center">
                                            <i title="update route place" className="fas fa-edit mr-2" onClick={() => handleSetRoutePlaceModal(routePlace)}></i>
                                            <i onClick={() => handleConfirmationModal(routePlace)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                        </th>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default AirlineCrewRoutePlaces;