import http from './http'
import { getToken } from './auth'

const base = 'shuttleDestinations'

export async function getDestination(id) {

    return await http.get(`${base}/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAllDestinations() {

    return await http.get(`${base}/`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getDestinationsByHotelUser() {

    return await http.get(`${base}/by-hotel-user`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function addDestination(payload){
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    return await http.post(`${base}`, payload, config)
}

export async function updateSeatPrice(id, payload) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    return await http.patch(`${base}/update-seat-price/${id}`, payload, config)
}

export async function updateSeatPriceList(id, payload) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    return await http.patch(`${base}/overwrite-seat-price-list/${id}`, payload, config)
}

export async function addTime(id, payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    return await http.patch(`${base}/add-time/${id}`, payload, config)
}

export async function deleteShuttleDestinations(id) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    return await http.delete(`${base}/remove/${id}/`, config)
}

export async function deleteTime(id, timetableId) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    return await http.delete(`${base}/remove-time/${id}/${timetableId}`, config)
}

export async function deleteAllTime(id) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    return await http.delete(`${base}/remove-all-time/${id}`, config)
}


export async function addVehicle(id, timetableId, vehicleIds) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    const payload = {
        "timetable_id": timetableId,
        "vehicle_ids": vehicleIds
    }

    return await http.patch(`${base}/add-vehicles/${id}`, payload, config)
}

export async function updateTime(id, timetableId, time) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    const payload = {
        "timetable_id": timetableId,
        "time": time
    }

    return await http.patch(`${base}/update-time/${id}`, payload, config)
}

export async function removeVehicle(id, timetableId, vehicleId) {

    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }

    const payload = {
        "timetable_id": timetableId,
        "vehicle_id": vehicleId
    }

    return await http.patch(`${base}/remove-vehicle/${id}`, payload, config)
}
