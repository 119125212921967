import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

const EnterSeparatedTextbox = ({ value = [], onChange }) => {

    const [inputValue, setInputValue] = useState('');
    const [stringArray, setStringArray] = useState([]);

    useEffect(() => {
        setStringArray([...value])
    }, [value])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addString()
        }
    };

    const addString = () => {
        // Split the input value by "Enter" and add to the array
        const newStrings = inputValue.split('\n');
        const updatedStringArray = [...stringArray, ...newStrings];
        setStringArray(updatedStringArray);

        // Update the parent component with the new stringArray
        onChange(updatedStringArray);

        // Clear the input value
        setInputValue('');
    }

    const removeString = (index) => {
        let _stringArray = stringArray
        _stringArray.splice(index, 1)

        onChange(_stringArray);
        setStringArray([..._stringArray])
    }

    return (
        <div>
            {stringArray.length > 0 &&
                <div>
                    <ul className="book-aircrew-crew-names" style={{ listStyle: 'none', paddingLeft: '0px' }}>
                        {stringArray.map((str, index) => (
                            <li key={index}>{str} <small className="ml-1 cursor-pointer" onClick={() => removeString(index)}><i className="fa fa-times text-muted " aria-hidden="true"></i></small></li>
                        ))}
                    </ul>
                </div>
            }
            <InputGroup className="mb-3">
                <Form.Control
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type and press Enter"
                />
                <Button id="basic-addon2" onClick={addString}>Add+</Button>
            </InputGroup>

        </div>
    );
};

export default EnterSeparatedTextbox;