import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../../services/auth';
import { getUser } from '../../services/users';
import { getHotelById } from '../../services/hotels';

const NameWithRole = () => {

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState()
    const [hideNameRole, setHideNameRole] = useState(false)

    const _currentUser = getCurrentUser()

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = async () => {
        setLoading(true)
        const { data: { code, message } } = await getUser(_currentUser._id)
        if (code == 200) {
            setUser(message)
            if (message.hotel_id) {
                const userHotel = await getHotelById(message.hotel_id);
                if (userHotel.data.message.hotel_name.toLowerCase().includes('intercity')) {
                    setHideNameRole(true)
                }
            }
        } else {
            //TODO: toast
        }
        setLoading(false)
    }

    return <>
        {user && !loading &&
            <div className='pt-3 py-2 px-3 text-center'>
                {
                    hideNameRole ?
                        <>
                            <h4 className="mb-0">Rezeption InterCity</h4>
                        </>
                        :
                        <>
                            <h4 className="mb-0">{user.first_name && user.last_name ? `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)}` : _currentUser.username} </h4>
                            <p>{_currentUser?.role_name.toUpperCase().replace("-", " ")}</p>
                        </>
                }
            </div>
        }
    </>
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default NameWithRole