import React from 'react';
import { Col, Row } from 'react-bootstrap';
import HotelCreator from '../components/admin/hotelCreator';
import VehicleCreation from '../components/admin/vehicleCreation';
import VehicleCreator from '../components/admin/vehicleCreator';
import VehicleList from '../components/admin/vehicleList';

const VehiclesPage = () => {
    return <>
        <section>
            <VehicleCreation />
        </section>
    </>
}

export default VehiclesPage