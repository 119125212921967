import React from 'react';
import HotelAdminDashboardPage from './hotelAdmin/dashboard';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCurrentUser } from '../services/auth';
import SystemAdminDashboardPage from './admin/dashboard';
import ShuttleAdminDashboardPage from './shuttleCompany/dashboard';
import AirlineAdminDashboardPage from './airlineAdmin/dashboard';

const DashboardPage = () => {
    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && user.role_name === 'hotel-admin' &&
            <HotelAdminDashboardPage />
        }

        {
            user && user.role_name === 'hotel-staff' &&
            <HotelAdminDashboardPage />
        }

        {
            user && user.role_name === 'airline-admin' &&
            <AirlineAdminDashboardPage />
        }

        {
            user && user.role_name === 'system-admin' &&
            <SystemAdminDashboardPage />
        }

        {
            user && user.role_name === 'shuttle-admin' &&
            <ShuttleAdminDashboardPage />
        }

    </>
};

export default DashboardPage;