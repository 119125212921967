import React from 'react';
import Spinner from '../../assets/images/spinner.svg';

const ElementLoader = () => {

    const styles = {
        container: {
            position: 'absolute',
            backgroundColor: '#eeeeee80',
            width: '100%',
            height: '100%',
            zIndex: 1030,
            left: 0,
            top: 0 
        }
    }

    return (
        <div style={styles.container} className="center-xy">
            <img src={Spinner} alt='loading..' />
        </div>
    )
}

export default ElementLoader
