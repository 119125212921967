import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const SalesPage = () => {

    let history = useHistory()

    return (
        <section>
            <Col>
                <Row className="mb-4 row-eq-height">
                    <Col md="6" lg="4">
                        <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/sales/shuttle')}>
                            <Card.Body className="text-center p-4">
                                <span className="fa-stack fa-3x">
                                    <i className="fas fa-bus-alt fa-stack-2x"></i>
                                    <i className="fas fa-coins fa-stack-1x custom-stack color-primary"></i>
                                </span>
                                <hr />
                                <h4>Shuttle Sales</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="shadow-sm cursor-pointer" onClick={() => history.push('/sales/taxi')}>
                            <Card.Body className="text-center p-4">
                                <span className="fa-stack fa-3x">
                                    <i className="fas fa-car-alt fa-stack-2x"></i>
                                    <i className="fas fa-coins fa-stack-1x custom-stack color-primary"></i>
                                </span>
                                <hr />
                                <h4>Taxi Sales</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </section>
    );
};

export default SalesPage;