import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const NavigationAdmin = () => {
    return ( 
        <Nav className="topbar-nav mr-auto">
            <LinkContainer to='/admin-book-taxi'>
                <Nav.Link className="nav-link" ><i className="fas fa-car-alt mr-2"></i> Book A Taxi</Nav.Link>
            </LinkContainer>
        </Nav>
    );
}
 
export default NavigationAdmin;