import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { get } from '../../../services/airlineCrews'
import { getAirlines } from '../../../services/airlines'
import { getHotels } from '../../../services/hotels'
import PageLoader from '../../common/pageLoader'
import AddAirlineCrewModal from '../../modals/airline-crew/addAirlineCrewModal'
import AirlineCrewList from './airlineCrewList'

import { getSeatOptions } from '../../../services/airlineCrewSeatOptions'
import AddAirlineCrewSeatOptionModal from '../../modals/airline-crew/addAirlineCrewSeatOptionModal'
import AirlineCrewRoutePlaces from './airlineCrewRoutePlaces'
import AirlineCrewSeatOptionList from './airlineCrewSeatOptionList'
import AirlineQRPrint from '../../print/airlineQR'
import { useReactToPrint } from 'react-to-print'

const AirlineCrewPage = () => {

    const [airlines, setAirlines] = useState([])
    const [hotels, setHotels] = useState([])

    const [addModal, setAddModal] = useState(false)
    const [airCrews, setAirCrews] = useState([])

    const [addSeatOptionModal, setAddSeatOptionModal] = useState(false)
    const [seatOptions, setSeatOptions] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)

    const [airCrewQrCode, setAirCrewQrCode] = useState()

    const componentRef = useRef();

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        setIsLoading(true)

        const { data: { message: _airlines } } = await getAirlines()
        const { data: { message: _hotels } } = await getHotels()
        setAirlines(_airlines.map(x => { return { label: x.name, value: x._id } }))
        setHotels(_hotels.map(x => { return { label: x.hotel_name, value: x._id } }))

        const { data: { message } } = await get()

        if (Array.isArray(message)) {
            const _airCrews = message.map(a => ({
                ...a,
                ['allowed_crew_bookings_airlines_temp']: a.allowed_crew_bookings_airlines.map(x => _airlines.find(y => y._id === x).name),
                ['allowed_crew_bookings_hotels_temp']: a.allowed_crew_bookings_hotels.map(x => _hotels.find(y => y._id === x).hotel_name)
            }));

            let _airCrewQrCode = _airCrews.find(x => x.hasOwnProperty('public_booking_qrcode'))?.public_booking_qrcode
            setAirCrewQrCode(_airCrewQrCode)
            setAirCrews(_airCrews)
        }
        else {
            setAirCrews([])
        }
        await fetchSeatOptions()

        setIsLoading(false)
    }

    const fetchSeatOptions = async () => {

        const { data: { message } } = await getSeatOptions()

        if (Array.isArray(message))
            setSeatOptions(message)
        else {
            setSeatOptions([])
        }
    }

    const handleAddModal = async () => {
        setAddModal(true)
    }

    const handleAddSeatOptionModal = async () => {
        setAddSeatOptionModal(true)
    }

    const print = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <>
            <AddAirlineCrewModal display={addModal} toggle={setAddModal} airlines={airlines} hotels={hotels} airCrews={airCrews} setAirCrews={setAirCrews} />
            <AddAirlineCrewSeatOptionModal display={addSeatOptionModal} toggle={setAddSeatOptionModal} seatOptions={seatOptions} setSeatOptions={setSeatOptions} />

            {airCrewQrCode &&
                <>
                    <div className="hide-on-screen">
                        <AirlineQRPrint ref={componentRef} qr={airCrewQrCode} />
                    </div>
                    <div className="mb-4 d-flex justify-content-center">
                        <div style={{ width: '150px' }}>
                            <Row className="mb-2">
                                <Col>
                                    <img src={airCrewQrCode} style={{ width: '100%' }} alt="preview..." />
                                </Col>
                            </Row>
                            <Button variant="outline-dark" className="w-100" onClick={() => print()}>Print QR</Button>
                        </div>
                    </div>
                </>
            }

            {isLoading && <PageLoader />}
            <Row className="mb-2">
                <Col>
                    <Button disabled={isCreating} variant='primary' onClick={() => handleAddModal()}>Add Air Crew</Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <AirlineCrewList airCrews={airCrews} airlines={airlines} hotels={hotels} setAirCrews={setAirCrews} />
                </Col>
            </Row>

            <Row className="mb-2">
                <Col>
                    <Button disabled={isCreating} variant='primary' onClick={() => handleAddSeatOptionModal()}>Add Seat Option</Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <AirlineCrewSeatOptionList seatOptions={seatOptions} setSeatOptions={setSeatOptions} airCrews={airCrews} />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <AirlineCrewRoutePlaces />
                </Col>
            </Row>
        </>
    )
}

export default AirlineCrewPage