import http from './http'
import { getToken } from './auth'

const base = 'address'

export async function getCountries() {
    return await http.get(`${base}/countries`)
}

export async function getCities(country) {
    return await http.get(`${base}/cities?country=${country}`)
}