import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { getHotels } from '../../services/hotels';
import { generateTaxiInvoiceByMonth } from '../../services/invoicesTaxi';
import { toLocaleISOString } from '../../utils/utils';
import TaxiInvoicePreviewModal from '../modals/taxiInvoicePreview';

const GenerateEstablishmentTaxiInvoiceModal = ({display, toggle}) => {

  const [invoiceDataPreviewModal, setInvoiceDataPreviewModal] = useState(false)

  const [hotels, setHotels] = useState([])
  const [hotelId, setHotelId] = useState('')
  const [monthFilter, setMonthFilter] = useState(("0" + (new Date().getMonth() + 1)).slice(-2))
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear())
  const [establishmentDateType, setEstablishmentDateType] = useState('single')
  const [estDateRange, setEstDateRange] = useState([null, null]);
  const [estStartDate, estEndDate] = estDateRange;

  const [invoiceDataPreview, setInvoiceDataPreview] = useState()

  const months = [
    { "value": "", "label": "All" },
    { "value": "01", "label": "January" },
    { "value": "02", "label": "February" },
    { "value": "03", "label": "March" },
    { "value": "04", "label": "April" },
    { "value": "05", "label": "May" },
    { "value": "06", "label": "June" },
    { "value": "07", "label": "July" },
    { "value": "08", "label": "August" },
    { "value": "09", "label": "September" },
    { "value": "10", "label": "October" },
    { "value": "11", "label": "November" },
    { "value": "12", "label": "December" }
  ]
  const years = []
  for (var i = 2019; i <= new Date().getFullYear(); i++)
    years.push(i.toString())

  useEffect(() => {
    fetchHotels()
  }, [])

  const fetchHotels = async () => {
    const { data: { message } } = await getHotels()
    if (Array.isArray(message))
      setHotels(message)
    else {
      setHotels([])
    }
  }

  const handleGenerateInvoiceByDate = async () => {

    var payload = {
        "recipient_type": "establishment",
        "establishment_id": hotelId,
        "establishment_type": "hotel",
    }
    if(establishmentDateType === 'single') {
        payload['month'] = monthFilter
        payload['year'] = yearFilter.toString()
    } else {
        payload['start_date'] = toLocaleISOString(new Date(estStartDate.setHours(0,0,0,0)))
        payload['end_date'] = toLocaleISOString(new Date(estEndDate.setHours(23,59,59,999)))
    }
    
    return await generateTaxiInvoiceByMonth(payload)
  }

  const handleInvoiceDataPreview = async () => {

    const { data: { code, message } } = await handleGenerateInvoiceByDate()

    if(code === 200) {
      let _invoiceData = message
      _invoiceData['title'] = getHotelNameById(hotelId)
    
      setInvoiceDataPreview(_invoiceData)
      setInvoiceDataPreviewModal(true)
    }
    else {
      toast.error(` ${message}.`, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
    
  }

  const getHotelNameById = (hotelId) => {
    return hotels.find(x => x._id === hotelId).hotel_name
  } 

  const toggleEstablishmentDateType = (type) => {
    if(type === 'single')
      setEstDateRange([null, null])
    else {
      setMonthFilter(("0" + (new Date().getMonth() + 1)).slice(-2))
      setYearFilter(new Date().getFullYear())
    }

    setEstablishmentDateType(type)
  }

  return (
    <>
      
        {invoiceDataPreview && <TaxiInvoicePreviewModal display={invoiceDataPreviewModal} toggle={setInvoiceDataPreviewModal} invoice={invoiceDataPreview} /> }

        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
              
                <h4>Invoice to Establishment</h4>

                <Form.Group>
                    <Form.Label>Select Hotel</Form.Label>
                    <Form.Control as="select" defaultValue={hotelId} onChange={(e) => setHotelId(e.target.value)}>
                    <option value="" disabled>Select Hotel</option>
                    {hotels && hotels.map((hotel, index) => (
                        <option key={`hotel-${index}`} value={hotel._id} >
                        {hotel.hotel_name}
                        </option>
                    ))}
                    </Form.Control>
                </Form.Group>

                <Row className="mb-3">
                    <Col>
                        <Button variant={establishmentDateType === 'single' ? 'primary' : 'outline-primary'} size="sm" className="mr-2" onClick={() => toggleEstablishmentDateType('single')}>By Month</Button>
                        <Button variant={establishmentDateType === 'dateRange' ? 'primary' : 'outline-primary'} size="sm" onClick={() => toggleEstablishmentDateType('dateRange')}>By Date Range</Button>
                    </Col>
                </Row>

                {establishmentDateType === 'single' ?
                <>
                    <Form.Group>
                    <Form.Label>Select Month</Form.Label>
                    <Form.Control as="select" defaultValue={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                        {months && months.map((month, index) => (
                        <option key={`month-${index}`} value={month.value}>
                            {month.label}
                        </option>
                        ))}
                    </Form.Control>
                    </Form.Group>

                    <>
                    {monthFilter &&
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <Form.Control as="select" defaultValue={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                        {years && years.map((year) => (
                            <option key={`year-${year}`} value={year}>
                            {year}
                            </option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    </>
                </>
                :
                <Form.Group>
                    <Form.Label>Select Date Range</Form.Label>
                    <div className="react-datepicker-w100">
                    <DatePicker
                        selectsRange={true}
                        startDate={estStartDate}
                        endDate={estEndDate}
                        onChange={(update) => {
                            setEstDateRange(update);
                        }}
                        isClearable={true}
                        className="form-control"
                        style={{width:'400px'}}
                        dateFormat="MMM d, yyyy"
                        />
                    </div>
                </Form.Group>
                }
                  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
                <Button variant="primary" onClick={() => handleInvoiceDataPreview()}>Generate</Button>
            </Modal.Footer>
        </Modal>
    </>
  );
};

export default GenerateEstablishmentTaxiInvoiceModal;