import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { updateVehicle } from '../../services/vehicles'

const UpdateVehicleModal = ({ display, toggle, vehicle, vehicleUpdated, simulAccounts }) => {

    const [seats, setSeats] = useState(vehicle.seats)
    const [available, setAvailable] = useState(vehicle.available)
    const [driver_id, setDriverId] = useState(vehicle.driver_id)
    const [priorityNumber, setPriorityNumber] = useState(vehicle.auto_assignment_priority_no)

    const handleUpdateVehicle = async (e) => {
        
        e.preventDefault()

        const {data: {code, message}} = await updateVehicle(vehicle._id, seats, available, driver_id, priorityNumber)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            vehicleUpdated(message)
            document.getElementById('update-vehicle-form').reset()
            toggle(false)
        }
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body>
                <h4 className="mb-4"><i className="fas fa-bus"></i> Edit Vehicle</h4>
                <hr className="mb-2" />
                {vehicle &&
                    <Form id="update-vehicle-form" onSubmit={(e) => handleUpdateVehicle(e)}>
                        <Row>
                            <Col xs='4'>
                                Name:
                            </Col>
                            <Col>
                                <strong>
                                    {vehicle.name}
                                </strong>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Model:
                            </Col>
                            <Col>
                                <strong>
                                    {vehicle.model}
                                </strong>
                            </Col>
                        </Row>
                        <hr className="my-2"/>
                        <Row>
                            <Col xs='4'>
                                Year:
                            </Col>
                            <Col>
                                <strong>{vehicle.year}</strong>
                            </Col>
                        </Row>
                        <hr className="my-2"/>
                        <Row>
                            <Col xs='4'>
                                Color:
                            </Col>
                            <Col>
                                <strong>{vehicle.color}</strong>
                            </Col>
                        </Row>
                        <hr className="my-2"/>
                        <Row>
                            <Col xs='4'>
                                Seats:
                            </Col>
                            <Col>
                                <Form.Control required size="sm" type="text" defaultValue={vehicle.seats} onChange={(e) => setSeats(e.target.value)} />
                            </Col>
                        </Row>
                        <hr className="my-2"/>
                        <Row>
                            <Col xs='4'>
                                Available:
                            </Col>
                            <Col>
                                <select className="form-control form-control-sm" defaultValue={vehicle.available} onChange={(e) => setAvailable(e.target.value)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Driver:
                            </Col>
                            <Col>
                                <Form.Control as="select" defaultValue={vehicle.driver_id} onChange={(e) => setDriverId(e.target.value)}>
                                        {simulAccounts.map((account, index) => (
                                    <option key={`driver-${index}`} value={account._id}>{account.first_name || account.last_name ? `${account.first_name} ${account.last_name}` : account.username}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row>
                            <Col xs='4'>
                                Priority:
                            </Col>
                            <Col>
                                <Form.Control required size="sm" type="text" defaultValue={vehicle.auto_assignment_priority_no} onChange={(e) => setPriorityNumber(e.target.value)} />
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <div className="px-3 py-2 text-center">
                            <Button type="submit" variant="outline-primary">Update</Button>
                        </div>
                    </Form>
                }
            </Modal.Body>
        </Modal>
  )
}

export default UpdateVehicleModal

