import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { getCountries } from '../../services/address'
import Select from 'react-select'

const HotelCreator = ({ createHotel }) => {

    const [name, setName] = useState('')
    const [street, setStreet] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('Switzerland')
    const [countries, setCountries] = useState([])
    const [numberOfRooms, setNumberOfRooms] = useState()
    const [maxGuests, setMaxGuests] = useState()
    const [guestDestinationRoutes, setGuestDestinationRoutes] = useState([])
    const [allowedFeatures, setAllowedFeatures] = useState([{ value: 'shuttle booking', label: 'Shuttle Booking' }])

    const destinationRoutesOptions = [
        { value: 'hotel-airport', label: 'Hotel to Airport' },
        { value: 'airport-hotel', label: 'Airport to Hotel' },
    ]

    const allowedFeaturesOptions = [
        { value: 'swiss layover', label: 'Swiss Layover' },
        { value: 'shuttle booking', label: 'Shuttle Booking' }
    ]

    useEffect(() => {
        fetchCountries()
    }, [])

    const fetchCountries = async () => {
        const { data: { code, message } } = await getCountries();
        if (code == 200) {
            setCountries(message)
        }
    }

    const handleCreateHotel = async () => {

        const hotel = {
            "hotel_name": name,
            "number_of_rooms": numberOfRooms ?? numberOfRooms,
            "max_number_of_guests": maxGuests ?? maxGuests,
            "address": {
                "street": street,
                "zip": zip,
                "city": city,
                "country": country
            },
            "routes_require_guest_payment": guestDestinationRoutes && guestDestinationRoutes.map(x => x.value),
            "allowed_features": allowedFeatures && allowedFeatures.map(x => x.value)
        }

        createHotel(hotel)
        document.getElementById('hotel-creator').reset()
        setGuestDestinationRoutes([])
        setAllowedFeatures([])
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center text-white'>Create Hotel</Card.Header>
            <Card.Body>
                <Form id="hotel-creator">
                    <Row>
                        <Col className="mb-3">
                            <Alert variant="warning">
                                <span className="badge badge-warning">Important!</span>
                                <small> It is very important to enter exact hotel address as it will be use for distance calculation.</small>
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Hotel Name</Form.Label>
                                <Form.Control type='text' placeholder='Hotel name' onChange={e => setName(e.target.value)} />
                            </Form.Group>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Street</Form.Label>
                                        <Form.Control type='text' placeholder='Street' onChange={e => setStreet(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control type='text' placeholder='Zip' onChange={e => setZip(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type='text' placeholder='City' onChange={e => setCity(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control size="sm" as="select" onChange={(e) => setCountry(e.target.value)} value={country}>
                                            {countries && countries.map((_country, index) => (
                                                <option key={`country-${index}`} value={_country}>{_country}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Number of rooms</Form.Label>
                                        <Form.Control type='number' placeholder='Number of rooms' onChange={e => setNumberOfRooms(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Max guests</Form.Label>
                                        <Form.Control type='number' placeholder='Max guests' onChange={e => setMaxGuests(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Shuttle routes that require guest payment</Form.Label>
                                {/* <Form.Control as='select' placeholder='Guest Payment' onChange={e => setGuestDestinationRoutes(e.target.value)} >
                                    <option value="airport-hotel">Airport to Hotel</option>
                                    <option valye="hotel-airport">Hotel to Airport</option>
                                </Form.Control> */}
                                <Select value={guestDestinationRoutes} isMulti={true} onChange={(value) => setGuestDestinationRoutes(value)} placeholder={"Shuttle routes that require guest payment"}
                                    options={destinationRoutesOptions} />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Allowed Features</Form.Label>
                                <Select value={allowedFeatures} isMulti={true} onChange={(value) => setAllowedFeatures(value)} placeholder={"Allowed features"}
                                    options={allowedFeaturesOptions} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center mt-4">
                    <Button variant='primary' onClick={() => handleCreateHotel()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default HotelCreator