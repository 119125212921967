import { isSameDay, subMinutes } from 'date-fns';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table, Pagination, InputGroup, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LinkTD from '../../components/common/linkTd';
import PageLoader from '../../components/common/pageLoader';
import AssignTaxiDriverModal from '../../components/modals/assignTaxiDriverModal';
import ChangeTaxiBookingDateModal from '../../components/modals/changeTaxiBookingDate';
import ConfirmationModal from '../../components/modals/confirmation';
import { getTaxiDrivers } from '../../services/taxiAccounts';
import { cancelTaxiBooking, getAllTaxiBookings, getNextTaxiBookings, getInitialTaxiBookings } from '../../services/taxiBookings';
import { isDateTimePassedCurrentTime, isSimulAccount } from '../../utils/utils';
import CancelledTaxiBookings from '../../components/common/cancelledTaxiBookings';
import { getCancelledTaxiBookings } from '../../services/taxiBookings';
import { useStateManager } from 'react-select';

const SystemAdminTaxiBookings = () => {

    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [assignDriverModal, setAssignDriverModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)
    const [pageSize, setPageSize] = useState(20)

    const [bookings, setBookings] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState()
    const [selectedBooking, setSelectedBooking] = useState()

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [previouses, setPreviouses] = useState([])
    const [disableNext, setDisableNext] = useState(true)

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        fetch()
        fetchTaxiDrivers()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentPageNumber === 1) {
                setPreviouses([]);
                setBookings([]);
                fetch();
                fetchTaxiDrivers()
            }

        }, 30000);
        return () => clearInterval(interval);
    });


    const fetch = async () => {

        setIsLoading(true)
        const { data: { code, message } } = await getInitialTaxiBookings(pageSize + 1, searchText)

        setIsFirstLoad(false)
        if (code == 200) {

            if (Array.isArray(message)) {
                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date)).filter(booking => !booking.isCancelled)

                setBookings(sortedItems.slice(0, pageSize))
                setDisableNext(sortedItems.length <= pageSize)

                //checkNextPage(sortedItems)
            }
        }
        else {
            toastError(`An error occured getting taxi bookings. ${message}.`)
        }

        renderPaginationButtons()

        setIsLoading(false)
    }

    // const checkNextPage = async (sortedItems) =>{

    //     let tempBookings = sortedItems.filter(booking => !booking.isCancelled)
    //     const tempCurrentBooking = tempBookings[tempBookings.length-1] 

    //     const { data: {  message } } =  await getNextTaxiBookings('20', tempCurrentBooking.booking_date, searchText)

    //     if(Array.isArray(message) && message.length >0){

    //         setDisableNext(false)
    //     }
    // }


    const renderPaginationButtons = () => {
        let items = []

        items.push(<Pagination.First className="pagination" disabled={currentPageNumber === 1} key={`page-first`} onClick={() => changePage('first')} title="first page" style={{ fontSize: 20 }} />)
        items.push(<Pagination.Prev className="pagination" disabled={currentPageNumber === 1} key={`page-previous`} onClick={() => changePage('previous')} title="previous page" style={{ fontSize: 20 }} />)

        items.push(<Pagination.Next className="pagination" disabled={disableNext} key={`page-next`} onClick={() => changePage('next')} title="next page" style={{ fontSize: 20 }} />)
        // items.push(<Pagination.Last className="pagination" disabled={!currentBooking} key={`page-last`}  title="last page" />)
        return items
    }


    const changePage = (page) => {


        if (page === 'next') {
            renderNext()
            setCurrentPageNumber(currentPageNumber + 1)
            return
        }

        if (page === 'previous') {
            renderPrevious()
            setCurrentPageNumber(currentPageNumber - 1)
            return
        }

        if (page === 'first') {
            setPreviouses([]);
            setBookings([]);
            fetch();
            fetchTaxiDrivers()
            setCurrentPageNumber(1)
            return
        }

    }

    const renderPrevious = () => {

        const previousItem = previouses[currentPageNumber - 2]
        setBookings(previousItem['items'])
        previouses.splice(currentPageNumber - 2, 1)
        setCurrentPageNumber(currentPageNumber - 1)
        setDisableNext(false)

    }

    const renderNext = async () => {

        setIsLoading(true)

        const currentBooking = bookings.slice(-1)[0]

        const previous = {
            'pageNumber': currentPageNumber,
            'items': bookings
        }

        const tempPreviouses = previouses
        tempPreviouses.push(previous)
        setPreviouses(tempPreviouses)

        const { data: { code, message } } = await getNextTaxiBookings(pageSize + 1, currentBooking.booking_date, searchText)
        setDisableNext(true)
        if (code === 200) {
            if (Array.isArray(message)) {

                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date)).filter(booking => !booking.isCancelled)

                setBookings(sortedItems.slice(0, pageSize))
                setDisableNext(sortedItems.length <= pageSize)

                //checkNextPage(sortedItems)
            }
        }

        setIsLoading(false)
    }


    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if (code === 200) {
            setTaxiDrivers(message)
        } else {
            //TODO: toast
        }
    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const handleAssignDriverModal = (booking) => {
        setSelectedBooking(booking)
        setAssignDriverModal(true)
    }

    const handleCancelBooking = async () => {
        const { data: { code, message } } = await cancelTaxiBooking(selectedBooking._id)
        if (code == 200) {
            var _bookings = bookings
            var index = _bookings.indexOf(selectedBooking);
            _bookings.splice(index, 1);
            setBookings([..._bookings])
            toast.success('Successfully cancelled booking.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toastError(`An error occured on cancelling taxi bookings. ${message}.`)
        }
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    const canChangeTime = (booking) => {
        return !Moment(Moment().add(30, 'minutes').utc().format()).isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canCancel = (booking) => {

        //const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(booking.booking_date));
        //return !currentTimeHasPassedBookingTime && (!booking.hasOwnProperty('sale_info'))

        //can cancel even booking time has passed because this is SUPER ADMIN
        return true
    }

    const bookingDateUpdated = (newBookingDate) => {
        const bookingIndex = bookings.indexOf(selectedBooking)
        let _bookings = bookings
        _bookings[bookingIndex].booking_date = newBookingDate
        setBookings(_bookings)
    }

    const driverUpdated = (driverId) => {
        let _bookings = bookings
        const index = _bookings.indexOf(selectedBooking)
        _bookings[index].admin_assigned_candidate_driver_id = driverId;
        if (_bookings[index].driver_assignment) {
            _bookings[index].driver_assignment.notified_candidate_driver_latest = driverId;
        }
        else {
            _bookings[index].driver_assignment = {
                notified_candidate_driver_latest: driverId
            }
        }

        setBookings(_bookings)
    }

    const renderAssignDriverButton = (booking) => {

        const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(booking.booking_date));
        const assignedDriverName = getAssignedDriverName(booking)

        //if(!booking.admin_assigned_candidate_driver_id && !booking.driver_assignment?.assigned_driver_id)
        if (!currentTimeHasPassedBookingTime && !booking.driver_assignment?.notified_candidate_driver_latest) {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                    <i className="fas fa-plus"></i> Assign
                </Button>)
        } else if (currentTimeHasPassedBookingTime) {

            if (!hasAssignedDriver(booking))
                return (
                    <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                        <i className="fas fa-plus"></i> Assign
                    </Button>)
            else
                return (<>{assignedDriverName}</>)
        } else {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                    <i className="fas fa-pencil-alt"></i> {assignedDriverName}
                </Button>)
        }
    }

    const getAssignedDriverName = (booking) => {

        if (!taxiDrivers || taxiDrivers.length === 0) {
            return "";
        }
        if (booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest) {
            const driverId = booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest;
            var _taxiDriver = taxiDrivers.find(driver => driver._id === driverId)
            return _taxiDriver ? _taxiDriver.first_name + " " + _taxiDriver.last_name : '';
        }
        return "";
    }

    const hasAssignedDriver = (booking) => {
        return (booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest) ? true : false
    }

    const getRowClassName = (booking, index) => {

        var rowClassName = ''
        if (isDateTimePassedCurrentTime(booking.booking_date))
            rowClassName += 'booking-past '

        if (bookings[index + 1] && (!isSameDay(new Date(bookings[index].booking_date), new Date(bookings[index + 1].booking_date))))
            rowClassName += 'row-breakpoint '

        return rowClassName
    }

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            fetch()
        }
    }

    useEffect(() => {
        fetch()
    }, [pageSize])

    return <>
        {isLoading && <PageLoader />}
        {selectedBooking && taxiDrivers &&
            <>
                <ChangeTaxiBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} bookingDateUpdated={bookingDateUpdated} />
                <AssignTaxiDriverModal display={assignDriverModal} toggle={setAssignDriverModal} booking={selectedBooking} taxiDrivers={taxiDrivers} driverUpdated={driverUpdated} />
            </>
        }
        <ConfirmationModal
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />
        <section>
            <Row className="mb-4">
                <Col>
                    <Card className='w-100 bookings-list'>
                        <Card.Header className='text-center bg-primary text-white'>Taxi Bookings</Card.Header>
                        <Card.Body>

                            <Row className="justify-content-center">
                                <Col lg="6">
                                    <InputGroup className="mb-3 rounded-right">
                                        <InputGroup.Text ><i className="fa fa-search" aria-hidden="true"></i></InputGroup.Text>
                                        <Form.Control value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search"  onKeyPress={onKeyUp} placeholder="Search bookings" />
                                        <div className="input-group-append rounded-right">
                                            <Button className="rounded-right" onClick={() => fetch()}>search</Button>
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col lg="6">
                                    <div className="d-flex flex-row align-items-center justify-content-end">
                                        <span className='mr-3'>Page size:</span>
                                        <Form.Control style={{ width: '80px' }} value={pageSize} onChange={(e) => setPageSize(+e.target.value)} as="select">
                                            <option>20</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </Form.Control>
                                    </div>
                                </Col>
                            </Row>

                            <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th></th>
                                        <th>Booked Date</th>
                                        <th>Guest Name</th>
                                        <th>Booked By</th>
                                        <th>Origin</th>
                                        <th>Room #</th>
                                        <th>Seats</th>
                                        <th>Driver</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookings.length > 0 ?
                                        <>
                                            {
                                                bookings.map((booking, index) =>
                                                    <React.Fragment key={`bookingstaxi-${index}`}>
                                                        <tr className={getRowClassName(booking, index)}>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>
                                                                <div className="d-flex align-items-center">
                                                                    {
                                                                        <i style={{ color: (booking.is_confirmed || booking.fulfilled ? 'Tomato' : 'LightGray') }}
                                                                            className={(booking.fulfilled ? 'fas fa-check-circle' : 'fas fa-check')}
                                                                            title={(booking.fulfilled ? 'Completed' : (booking.is_confirmed ? 'Confirmed' : 'Not Confirmed'))}></i>
                                                                    }
                                                                    {booking.payment_info && booking.payment_info.paid_over_counter && <i style={{ color: 'Tomato' }} className="fas fa-hotel ml-2" title="Paid in the hotel"></i>}
                                                                </div>
                                                            </LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{Moment(booking.booking_date).format('DD.MM.YYYY HH:mm')}</LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.booking_source_username}</LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>
                                                                <span>{booking.origin}</span>
                                                            </LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                                            <td>
                                                                {booking.fulfilled ?
                                                                    getAssignedDriverName(booking)
                                                                    :
                                                                    renderAssignDriverButton(booking)
                                                                }
                                                                {/* {
                                                    booking.fulfilled && getAssignedDriverName(booking)
                                                }
                                                {
                                                    !booking.fulfilled && renderAssignDriverButton(booking)
                                                } */}
                                                            </td>
                                                            <td>
                                                                {canChangeTime(booking) && isSimulAccount() &&
                                                                    <Button className="mr-2 mb-1" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>
                                                                        <i className="fas fa-clock"></i> Change
                                                                    </Button>
                                                                }
                                                                {canCancel(booking) &&
                                                                    <Button className="mb-1" size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td>{!isLoading && <i>No bookings found.</i>}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>


                            <Pagination className="mb-0 justify-content-center override-pagination">

                                {renderPaginationButtons()}

                            </Pagination>



                        </Card.Body>
                    </Card>
                </Col>

            </Row>

        </section>
    </>
}

export default SystemAdminTaxiBookings