import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { updateAirline } from '../../services/airlines'
import Select from 'react-select'

const UpdateAirlineModal = ({ display, toggle, airline, airlineUpdated }) => {
    const [name, setName] = useState('')
    const [allowedFeatures, setAllowedFeatures] = useState([])

    const [isUpdating, setIsUpdating] = useState(false)

    const allowedFeaturesOptions = [
        { value: 'shuttle booking', label: 'Shuttle Booking' }
    ]

    const handleUpdateAirlineInfo = async () => {
        setIsUpdating(true)
        var payload = {}

        if (name) payload['name'] = name
        payload['allowed_features'] = allowedFeatures && allowedFeatures.map(x => x.value)

        const { data: { code, message } } = await updateAirline(airline._id, payload)
        setIsUpdating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            airlineUpdated(message)
            toggle(false)
        }
    }

    const onShow = () => {
        setName(airline.name )
        setAllowedFeatures(airline.allowed_features)
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            onShow={onShow}
            centered
        >
            <Modal.Body className="update-airline-modal">
                <Card.Title className="mb-3">Update Airline</Card.Title>
                <div className="update-airline">

                    <Form.Group>
                        <Form.Label>Airline Name</Form.Label>
                        <Form.Control type='text' placeholder='Enter Airline Name' value={airline?.name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Allowed Features</Form.Label>
                        <Select defaultValue={airline?.allowed_features?.map(x => { return { value: x, label: x } })} isMulti={true} onChange={(value) => setAllowedFeatures(value)} placeholder={""}
                            options={allowedFeaturesOptions} />
                    </Form.Group>

                    <Button disabled={isUpdating} className="w-100 mt-3" onClick={() => handleUpdateAirlineInfo()}>
                        {isUpdating ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateAirlineModal

