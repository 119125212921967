
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createRelease, getReleases } from '../../services/releases';

const ReleasesPage = () => {

    const [version, setVersion] = useState()
    const [type, setType] = useState()
    const [documentUrl, setDocumentUrl] = useState()

    const [releases, setReleases] = useState([])

    useEffect(() => {
        fetch()
    },[])

    const fetch = async () => {
        const { data: { code, message } } = await getReleases()
        if(code === 200){
            let sortedItems = message.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            setReleases(message)
        } else {
            //TODO: toast
        }
    }

    const handleCreateRelease = async () => {

        const payload = {
            "version": version,
            "type": type,
            "document_url": documentUrl
        }

        const { data: { code, message } } = await createRelease(payload)
        if(code === 200){
            let _releases = releases
            _releases.unshift(message)
            setReleases([..._releases])
        } else {
            toast.error( message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return <>
        <section>
            <Row className="mb-5">
                <Col lg="6">
                    <Card className="p-4">
                        <p className="mb-4"><strong>Create Release</strong></p>
                    
                        <Form id="create-release-form">
                            <Row>
                                <Col lg="6">
                                    <Form.Group>
                                        <Form.Label>Version</Form.Label>
                                        <FormControl placeholder="Enter version" onChange={(e) => setVersion(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as="select" defaultValue="" onChange={(e) => setType(e.target.value)}>
                                            <option value="" disabled>Select Type</option>
                                            <option value="WEB">Web</option>
                                            <option value="ANDROID">Android</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Document Url</Form.Label>
                                        <FormControl placeholder="Enter document url" onChange={(e) => setDocumentUrl(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        
                        <Row className="mt-3">
                            <Col className="text-center">
                                <Button className="px-4" onClick={() => handleCreateRelease()}>Submit</Button>
                            </Col>
                        </Row>
                        
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg="8">
                    <h5>Releases</h5>
                    {releases.length > 0 ?
                    <>
                    {releases.map((release, index) => (
                        <Row key={`release-${index}`} className="mb-3">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <p className="mb-0"><strong>{release.type}</strong></p>
                                        <p className="mb-0 mt-n1"><small>version {release.version}</small></p>
                                        <p className="mb-0">{release.document_url}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                    </>
                    :
                    <p><small>Nothing found</small></p>
                    }
                </Col>
            </Row>
        </section>
    </>
}

export default ReleasesPage
