import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const InfoModal = ({ 
    display, 
    toggle, 
    title, 
    message,
    messages
    }) => {

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="md"
        backdropClassName="level-2"
        centered
        >
            <Modal.Header><h3 className="text-center ml-4">{title}</h3></Modal.Header>
            <Modal.Body>
                { message && message !== '' && <p className="mb-0">{message}</p>}
                {
                    messages && messages.length > 0 &&
                    <ul>
                        {
                            messages.map(m => (<li>{m}</li>))
                        }
                    </ul>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => toggle(false)}>Ok</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default InfoModal

