import Moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { generateShuttleInvoiceFinal } from '../../services/invoicesShuttle';
import LinkTD from '../common/linkTd';
import ConfirmationModal from './confirmation';

const ShuttleInvoicePreviewModal = ({ 
    display, 
    toggle, 
    invoice,
    }) => {

    let history = useHistory()

    let invoiceData = invoice.invoice

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleGenerateInvoiceFinal = async () => {

        let _invoiceData = invoiceData

        _invoiceData['bookingsale_ids'] = extractIdsFromSales(invoice.sales)  
        setIsLoading(true)
        const { data: { code, message } } = await generateShuttleInvoiceFinal(_invoiceData)
        setIsLoading(false)
        if (code == 200) {
          history.push(`/shuttle-invoices/${message.invoice._id}`)
        } else {
          toast.error(` ${message}.`, {
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
    }

    const extractIdsFromSales = (sales) => {
        return sales.map(x => x._id);
    }

    const handleFinalInvoiceModal = () => {
        setConfirmationModal(true)
    }

    return (
        <>
            <ConfirmationModal
            title="Generate Invoice"
            message="You are about to generate invoice"
            confirmText="Proceed"
            cancelText="Cancel"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleGenerateInvoiceFinal} />

            <Modal
            show={display}
            onHide={() => toggle(false)}
            size="xl"
            backdropClassName="level-2"
            centered
            >
                <Modal.Body>
                    <h3 className="text-center">Shuttle Invoice Preview</h3>
                    <hr/>

                    <h4 className="mb-1">{invoice.title}</h4>

                    <div className="invoice-general-info mb-4">
                        <p className="mb-0">Invoice No.: <strong>{invoiceData.invoice_human_readable_number}</strong></p>
                        <p className="mb-0">Date Covered: <strong>{Moment.utc(invoiceData.period_from).format('DD.MM.YYYY')} - {Moment.utc(invoiceData.period_to).format('DD.MM.YYYY')}</strong></p>
                        <p className="mb-0">Number of Bookings: <strong>{invoiceData.number_of_sales}</strong></p>
                        <p className="mb-2">Recipient: <strong style={{textTransform:'capitalize'}}>{invoiceData.establishment_type}</strong></p>
                    </div>

                    <h4 className="mb-3">Sales</h4>
                    <Table className="mb-5">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Guest Name</th>
                            <th>Paid At Hotel</th>
                            {/* <th>Commission</th> */}
                            <th>Invoice Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoice.sales.length > 0 && invoice.sales.map((sale, index) => (
                            <tr key={`invoicesale-${index}`}>
                            <LinkTD to={`/booking/${sale.booking_id}`}>{Moment.utc(sale.sale_date).format('DD.MM.YYYY @ HH:mm')}</LinkTD>
                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale.booking_info.customer.first_name + " " + sale.booking_info.customer.last_name}</LinkTD>
                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale.amount.toFixed(2)} CHF</LinkTD>
                            {/* <LinkTD to={`/booking/${sale.booking_id}`}>{sale.commission_amount.toFixed(2)} CHF</LinkTD> */}
                            <LinkTD to={`/booking/${sale.booking_id}`}>{sale.amount.toFixed(2)} CHF</LinkTD>
                            </tr>
                        ))}
                        {/* <tr>
                            <td></td>
                            <td></td> 
                            <td></td>
                            <td>Total Commission</td>
                            <td>{invoiceData.commission_amount.toFixed(2)} CHF</td>
                        </tr> */}
                        <tr>
                            {/* <td className="border-top-0"></td>
                            <td className="border-top-0"></td> 
                            <td className="border-top-0"></td>  */}
                            <td></td>
                            <td></td> 
                            <td>Sub Total</td>
                            <td>{invoiceData.sub_amount.toFixed(2)} CHF</td>
                        </tr>
                        {
                            invoiceData.fees && invoiceData.fees.monthly_service_fee &&
                            <tr>
                                <td className="border-top-0"></td>
                                <td className="border-top-0"></td> 
                                {/* <td className="border-top-0"></td>  */}
                               <td>Monthly Service Fee</td>
                               <td>{invoiceData.fees.monthly_service_fee.toFixed(2)} CHF</td>
                            </tr>
                        }
                        <tr>
                            <td className="border-top-0"></td>
                            <td className="border-top-0"></td> 
                            {/* <td className="border-top-0"></td>  */}
                            <td>Total</td>
                            <td><strong style={{fontSize:'20px'}}>{invoiceData.amount.toFixed(2)} CHF</strong></td>
                        </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    {
                        isLoading ?
                        <Button variant="primary" disabled>Generating, please wait...</Button>:
                        <>
                            <Button onClick={() => handleFinalInvoiceModal()}>Generate</Button>
                            <Button variant="secondary" onClick={() => toggle(false)}>Cancel</Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShuttleInvoicePreviewModal

