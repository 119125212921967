import http from './http'
import { getToken } from './auth'

const base = 'reserverCommissionsPayments'

export async function getPreviousEarnings() {

    return await http.get(`${base}/by-hotel-user/`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getAllHotelsPreviousEarnings() {

    return await http.get(`${base}/all/`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getCommissionsPaymentsDetails(id) {

    return await http.get(`${base}/details/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateCommissionAsPaid(id, payload) {

    return await http.patch(`${base}/update-as-paid/${id}/`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}