import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';

const VehicleCreator = ({createVehicle, shuttleAccounts}) => {

    const [name, setName] = useState('')
    const [model, setModel] = useState('')
    const [year, setYear] = useState('')
    const [color, setColor] = useState('')
    const [seats, setSeats] = useState(1)
    const [driverId, setDriverId] = useState('')
    const [priorityNumber, setPriorityNumber] = useState(1)

    const handleCreateVehicle = async () => {

        const vehicle = {
            "name": name,
            "model": model,
            "year": year,
            "color": color,
            "seats": seats,
            "available": true,
            "driver_id": driverId,
            "auto_assignment_priority_no": priorityNumber
        }

        const errCode = await createVehicle(vehicle)
        if(errCode === 200) document.getElementById('vehicle-creator').reset()
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center text-white'>Create Vehicle</Card.Header>
            <Card.Body>
                <Form id="vehicle-creator">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='text' placeholder='Vehicle name' onChange={e => setName(e.target.value)} />
                            </Form.Group>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control type='text' placeholder='Vehicle model' onChange={e => setModel(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control type='text' placeholder='Vehicle year' onChange={e => setYear(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Color</Form.Label>
                                        <Form.Control type='text' placeholder='Vehicle color' onChange={e => setColor(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Seats</Form.Label>
                                        <Form.Control type="number" defaultValue={seats} onChange={(e) => setSeats(e.target.value)} min="1" max="50" />
                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Form.Label>Seats</Form.Label>
                                        <Form.Control as="select" onChange={(e) => setSeats(e.target.value)}>
                                            <option value="">Vehicle seats capacity</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>                    
                                        </Form.Control>
                                    </Form.Group> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label>Driver</Form.Label>
                                        <Form.Control as="select" onChange={(e) => setDriverId(e.target.value)}>
                                            <option value="">Choose Driver</option>
                                            {shuttleAccounts.map((account, index) => (
                                                <option key={`driver-${index}`} value={account._id}>{account.first_name || account.last_name ? `${account.first_name} ${account.last_name}` : account.username}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Priority</Form.Label>
                                        <Form.Control type="number" defaultValue={priorityNumber} onChange={(e) => setPriorityNumber(e.target.value)} min="0" />
                                        <Form.Text className="text-muted">
                                            Priority starts at 1. The lower the higher priority.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center">
                    <Button variant='primary' onClick={() => handleCreateVehicle()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default VehicleCreator