import React from 'react'
import { useState } from 'react'
import { Modal, Button, Row, Col, Form, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { updateCarCategory } from '../../services/carCategories'

const EditCarCategoryModal = ({ display, toggle, category, categoryUpdated }) => {

    const [categoryName, setCategoryName] = useState(category.name)
    const [seats, setSeats] = useState(category.seats)

    const handleUpdateCarCategory = async () => {

        const payload = {
            "name": categoryName,
            "seats": seats
        }

        const { data: { code, message } } =  await updateCarCategory(category._id, payload)
        if (code == 200) {
            categoryUpdated(payload)
            toggle(false)
        } else {
            toast.error(` Error: Unable to update.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-2">
                    Edit Car Category
                </Card.Title>    
                <Row>
                    <Col lg="8">
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control defaultValue={category.name} type="text" onChange={(e) => setCategoryName(e.target.value)} placeholder="Enter category name" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Seats</Form.Label>
                            <Form.Control defaultValue={category.seats} type="number" onChange={(e) => setSeats(e.target.value)} placeholder="seats" />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleUpdateCarCategory()}>Save</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default EditCarCategoryModal

