import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form} from 'react-bootstrap';
import LatestBookings from '../../components/common/latestBookings';
import LatestTaxiBookings from '../../components/common/latestBookingsTaxi';
import SalesOverview from '../../components/simulUserCommon/salesOverview';
import ShuttleBookingsPerTimeChart from '../../components/charts/shuttleBookingsPerTimeChart';
import { getTimeBooksToday, getTimeBooksWeek, getTimeBooksMonth } from '../../services/shuttleBookings';
import PageLoader from '../../components/common/pageLoader'

const SystemAdminDashboardPage = () => {

    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])

    const [dateType, setDataType] = useState('Today')

    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
        // Default chart 
        fetchDailyTimebooks()
    }, [])

    const fetchDailyTimebooks = async () => {
        setIsLoading(true) 
        const { data: { code, message } } =  await getTimeBooksToday()
        setIsLoading(false) 
        if( code == 200 ){
            setData(message.data)
            setLabels(message.labels)
        }  
    }

    const fetchWeeklyTimebooks = async () => {
        setIsLoading(true) 
        const { data: { code, message } } =  await getTimeBooksWeek()
        setIsLoading(false) 
        if( code == 200 ){
            setData(message.data)
            setLabels(message.labels)
        }  
    }

    const fetchMonthlyTimebooks = async () => {
        setIsLoading(true) 
        const { data: { code, message } } =  await getTimeBooksMonth()
        setIsLoading(false) 
        if( code == 200 ){
            setData(message.data)
            setLabels(message.labels)
        }  
    }

    const changeChartScopeHandler = (e) => {

        setData([])
        setLabels([])
        setDataType(e.target.value)

        if(e.target.value == 'Today'){
            fetchDailyTimebooks()
        }
        if(e.target.value == 'This Week'){
            fetchWeeklyTimebooks()
        }
        if(e.target.value == 'This Month'){
            fetchMonthlyTimebooks()
        }
    }

    return <>
        <section>
            <Row className="mb-3">
                <Col>
                    <SalesOverview />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="6">
                    <LatestBookings />
                </Col>
                <Col lg="6">
                    <LatestTaxiBookings />
                </Col>
            </Row>
            <Row>
                <Col lg="4">
                    <Form.Group>
                        <Form.Control as="select" onChange={changeChartScopeHandler} value={dateType} >
                            <option>Today</option>
                            <option>This Week</option>
                            <option>This Month</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-3">Shuttle Bookings Per Time </Card.Title>
                            <div className="center-xy">
                                {isLoading ?
                                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                                    :
                                    <ShuttleBookingsPerTimeChart data={data} labels={labels} /> 
                                }    
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default SystemAdminDashboardPage
