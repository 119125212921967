import React, { useState } from 'react'
import { Badge, Card, Table } from 'react-bootstrap'
import { deleteAirline } from '../../services/airlines'
import ConfirmationModal from '../modals/confirmation'
import { toast } from 'react-toastify'
import UpdateAirlineModal from '../modals/updateAirlineModal'
// import UpdateAirlineModal from '../modals/updateAirlineModal'

const AirlineList = ({airlines, setAirlines}) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [updateAirlineInfoModal, setUpdateAirlineInfoModal] = useState(false)
    const [airline, setAirline] = useState()

    const handleDeleteAirline = async (id) => {
        const {data: {code, message}} = await deleteAirline(id)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this airline. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else{
            airlineDeleted()
        }
    }

    const airlineUpdated = (airline) => {
        setAirlines(airlines.map( item => item._id === airline._id ? airline : item ))
    }

    const handleUpdateAirlineInfoModal = async (airline) => {
        setAirline(airline)
        setUpdateAirlineInfoModal(true)
    }

    const airlineDeleted = () => {
        const airlineIndex = airlines.indexOf(airline)
        let _airlines = airlines
        _airlines.splice(airlineIndex, 1)
        setAirlines([..._airlines])
    }

    const handleConfirmationModal = (airline) => {
        setAirline(airline)
        setConfirmationModal(true)
    }

    return <>

        {airline && 
        <UpdateAirlineModal display={updateAirlineInfoModal} toggle={setUpdateAirlineInfoModal} airline={airline} airlineUpdated={airlineUpdated} />
        }

        <ConfirmationModal 
            title="Delete Airline"
            message="Are you sure you want to delete this airline?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteAirline(airline._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Airlines</Card.Header>
            <Card.Body>
                <Table responsive striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Allowed Feature</th>
                            {/* <th>logo</th> */}
                            <th style={{width:"65px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            airlines.length > 0 &&
                            airlines.map((airline, index) =>
                                <tr key={`airline-${index}`}>
                                    <td>{airline?.name}</td>
                                    <td>{airline?.allowed_features?.map((x, index) => (
                                        <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`allowed-feature-${airline?.id}-${index}`}>{x}</Badge>
                                    ))}</td>
                                    {/* <td>{airline?.logo}</td> */}
                                    <th className="actions text-center">
                                        <i title="update airline" className="fas fa-edit mr-2" onClick={() => handleUpdateAirlineInfoModal(airline)}></i>
                                        <i onClick={() => handleConfirmationModal(airline)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default AirlineList