import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { getHotels } from '../../services/hotels';
import { addDestination } from '../../services/shuttleDestinations';
import PageLoader from '../common/pageLoader';

const TimetableAdderModal = ({display, toggle, timetableUpdated}) => {

    const [hotels, setHotels] = useState([])
    const [options, setOptions] = useState([]);

    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');

    const [inputsError, setInputsError] = useState();

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchHotels()
    },[])

    const fetchHotels = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getHotels()
        if(code !== 200){
            return;
        }
        setHotels(message)
        setOptions([{_id:'zurichairport', name:'Zurich Airport'}, ...message])
        setIsLoading(false)
    }

    const handleSave = async () => {
        
        setInputsError();

        if(!origin && !destination){
            setInputsError('Origin and destination is empty.')
            return;
        }

        if(origin == destination){
            setInputsError('Origin and destination must not be equal.')
            return;
        }

        setIsLoading(true)
        const originObj = options.find(x => x._id == origin)
        const destObj = options.find(x => x._id == destination)
        const origin_type = hotels.some(x => x._id == origin) ? 'hotel' : 'others';
        const payload = {
            origin: (originObj?.hotel_name || originObj?.name) ?? '',
            origin_type,
            origin_id: origin === 'zurichairport' ? null: origin,
            destination: (destObj?.hotel_name || destObj?.name) ?? '',
            destination_id: destination === 'zurichairport' ? null: destination
        }
        if(!(origin && destination)) payload['is_common_origin'] = true
        if(origin == 'zurichairport' && destination == '') payload['is_common_origin'] = true
        
        const {data:{code, message}} = await addDestination(payload);
        if(code !== 200){
            setInputsError(message)
            
        }
        else{
            toggle(false)
            timetableUpdated(message);
        }
        setIsLoading(false)
    }

    return ( 
        <>
        {isLoading && <PageLoader />}
        <Modal
        className="timetable-adder"
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            
            <Modal.Body>
                <Card.Title className="mb-2">
                    Add Timetable
                </Card.Title>     
                <Form.Group>
                    <Form.Label>Origin</Form.Label>
                    <Form.Control as="select" onChange={(e) => setOrigin(e.target.value)}>
                        <option value="">Choose Origin</option>
                        {options.map((o, index) => (
                            <option key={`option-${index}`} value={o._id}>{o.hotel_name || o.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Destination</Form.Label>
                    <Form.Control as="select" onChange={(e) => setDestination(e.target.value)}>
                        <option value="">Choose Destination</option>
                        {options.map((o, index) => (
                            <option key={`option-${index}`} value={o._id}>{o.hotel_name || o.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                {
                    inputsError &&
                    <p className="text-danger">{inputsError}</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSave()}>Save</Button>
                <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
 
export default TimetableAdderModal;