import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CancelledTaxiBookings from '../../components/common/cancelledTaxiBookings';
import { getCancelledTaxiBookings } from '../../services/taxiBookings';

const CancelledTaxiBookingsPage = () => {

    const [cancelledTaxiBookings, setCancelledTaxiBookings] = useState([])

    useEffect(() => {
        fetchCancelledTaxiBookings()
    }, [])

    const fetchCancelledTaxiBookings = async () => {
        
        const { data: { message } } =  await getCancelledTaxiBookings()

        if(Array.isArray(message))
        {
            setCancelledTaxiBookings(message)
        }
    }

    return <>
        <section>
            <Row>
                <Col>
                    <CancelledTaxiBookings bookings={cancelledTaxiBookings}/>
                </Col>
            </Row>
        </section>
    </>
}

export default CancelledTaxiBookingsPage