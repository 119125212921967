import React, { useState } from 'react'
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { updateSeatOption } from '../../../services/airlineCrewSeatOptions'
import AirlineCrewSelect from '../../common/airlineCrewSelect'

const UpdateAirlineCrewSeatOptionModal = ({ display, toggle, selectedSeatOption, seatOptionUpdated }) => {

    const [seatOption, setSeatOption] = useState(selectedSeatOption)
    const [isUpdating, setIsUpdating] = useState(false)

    const onShow = () => {
        setSeatOption(selectedSeatOption)
    }

    const handleChange = (objVal) => {
        let _seatOption = { ...seatOption, ...objVal }
        setSeatOption(_seatOption)
    }

    const handleUpdate = async (e) => {
        const {
            name,
            seats,
            price,
            per_person_price,
            applicable_route,
            vehicle_type = 'shuttle',
            airline_crew_id
        } = seatOption

        e.preventDefault()
        setIsUpdating(true)

        var payload = {
            name,
            seats,
            per_person_price,
            vehicle_type,
            applicable_route,
            price,
            airline_crew_id: airline_crew_id || undefined
        }

        const { data: { code, message } } = await updateSeatOption(seatOption._id, payload)
        setIsUpdating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            seatOptionUpdated(message)
            toggle(false)
        }
    }

    return (
        <Modal
            show={display}
            onShow={onShow}
            onHide={() => toggle(false)}
            centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">Update Seat Option</Card.Title>
                <Form onSubmit={handleUpdate} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='text' value={seatOption?.name ?? ''} onChange={e => handleChange({ name: e.target.value })} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <AirlineCrewSelect selected={seatOption?.airline_crew_id ?? ''} onSelect={(airlineCrew) => handleChange({airline_crew_id: airlineCrew?._id})} />
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Applicable Route</Form.Label>
                                <Form.Control as="select" value={seatOption?.applicable_route ?? ''} onChange={(e) => handleChange({ applicable_route: e.target.value })}>
                                    <option value=''>All Route</option>
                                    <option value='airport-hotel'>Airport to Hotel</option>
                                    <option value='hotel-airport'>Hotel to Airport</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Vehicle Type</Form.Label>
                                <Form.Control as="select" value={seatOption?.vehicle_type ?? 'shuttle'} onChange={(e) => handleChange({vehicle_type: e.target.value})} required>
                                    <option value='shuttle'>Shuttle</option>
                                    <option value='taxi'>Taxi</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Seats</Form.Label>
                                <Form.Control type='number' value={seatOption?.seats ?? ''} onChange={e => handleChange({ seats: e.target.value })} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Price</Form.Label>
                                <Form.Control type='number' value={seatOption?.price ?? ''} step='any' onChange={e => handleChange({price: e.target.value})} min={0} required />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Price per Person</Form.Label>
                                <Form.Control type='number' value={seatOption?.per_person_price ?? ''} step='any' onChange={e => handleChange({per_person_price: e.target.value})} min={0} required />
                            </Form.Group>
                        </Col>
                        <Col lg="12" className="text-right">
                            <Button type="submit" disabled={isUpdating} className="mt-2">
                                {isUpdating ? 'Saving...' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateAirlineCrewSeatOptionModal

