import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCurrentUser } from '../../services/auth'
import { getCarCategories } from '../../services/carCategories'
import { updateUser } from '../../services/users'
import EditOperatingCitiesModal from './editOperatingCities'
import { getTaxiDriverInvoiceOptions } from '../../utils/invoiceUtils'
import { getTaxiCompanies } from '../../services/taxiCompanies'
import CarCategoriesModal from './carCategoriesModal'

const UpdateAccountTaxiModal = ({ display, toggle, user, carCategories, taxiCompanies, accountUpdated, setIsLoading, citiesUpdated, setCitiesUpdated }) => {

    const invoiceOptions = getTaxiDriverInvoiceOptions()

    const [editOperatingCitiesModal, setEditOperatingCitiesModal] = useState(false)
    const [carCategoriesModal, setCarCategoriesModal] = useState(false)

    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [operatingCities, setOperatingCities] = useState(user?.operating_cities)
    const [phoneNumber, setPhoneNumber] = useState()
    const [licenseNumber, setLicenseNumber] = useState()
    const [invoicingOption, setInvoicingOption] = useState(user.simul_invoicing_option ?? 'FIXED-CHARGE')
    const [fixedChargeAmount, setFixedChargeAmount] = useState(user?.simul_invoicing_option_fixed_charge_amount ?? 0)
    //const [carCategoryId, setCarCategoryId] = useState(user?.car_category_id)
    const [selectedTaxiCompanyId, setSelectedTaxiCompanyId] = useState()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState()
    const [creditsDeduction, setCreditsDeduction] = useState(user?.credits_deduction_option)

    const [carCategoriesToAdd, setCarCategoriesToAdd] = useState([])
    
    const [error, setError] = useState('')

    const _currentUser = getCurrentUser()

    const handleSave = async () => {

        setError('')

        if(newPassword.length > 0 && (newPassword !== confirmPassword)) {
            setError('passwords do not match')
            return
        }

        const payload = {
            taxi_specific : {}
        }
            
        if(firstname) payload['first_name'] = firstname
        if(lastname) payload['last_name'] = lastname
        if(operatingCities) payload.taxi_specific['operating_cities'] = operatingCities
        if(phoneNumber) payload['phone'] = phoneNumber
        if(licenseNumber) payload.taxi_specific['license_number'] = licenseNumber
        if(newPassword) {
            payload['old_password'] = user.doc.password
            payload['new_password'] = newPassword
        }
        // if(invoicingOption) payload.taxi_specific['simul_invoicing_option'] = invoicingOption
        //if(carCategoryId) payload.taxi_specific['car_category_id'] = carCategoryId
        if(carCategoriesToAdd) payload.taxi_specific['car_categories'] = carCategoriesToAdd
        //payload.taxi_specific['simul_invoicing_option_fixed_charge_amount'] = fixedChargeAmount
        if(selectedTaxiCompanyId) payload.taxi_specific['taxi_company_id'] = selectedTaxiCompanyId
        if(creditsDeduction) payload.taxi_specific['credits_deduction_option'] = creditsDeduction

        setIsLoading(true)
        const {data: {code, message}} = await updateUser(user._id, payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            accountUpdated(payload)
            toggle(false)
        }
    }

    const handleSetEditOperatingCitiesModal = () => {
        let _cities = user?.operating_cities
        setOperatingCities(_cities)
        setEditOperatingCitiesModal(true)
    }

    const handleOnEnterModal = () => {
        let _carCategoriesToAdd = []
        user.car_categories.forEach(x => {
            _carCategoriesToAdd.push({
                "car_category_id": x.car_category_id,
                "make": x.make,
                "model": x.model,
                "model_year":  x.model_year
            })
        })
        setCarCategoriesToAdd(_carCategoriesToAdd)
    }

    const getCarCategoryName = (id) => {
        return carCategories.find(x => x._id === id)?.name ?? ''
    }
    
    return (
        <>
        {operatingCities &&
        <EditOperatingCitiesModal display={editOperatingCitiesModal} toggle={setEditOperatingCitiesModal} 
            operatingCities={operatingCities} setOperatingCities={setOperatingCities} setCitiesUpdated={setCitiesUpdated} />
        }

        <CarCategoriesModal display={carCategoriesModal} toggle={setCarCategoriesModal} carCategories={carCategories} setCarCategoriesToAdd={setCarCategoriesToAdd} carCategoriesToAdd={carCategoriesToAdd} />

        <Modal
        show={display}
        onHide={() => toggle(false)}
        onEnter={() => handleOnEnterModal()}
        centered
        >
            <Modal.Body className="update-account-modal">
                <Card.Title className="mb-3">Update Account</Card.Title>
                <div className="update-account">
                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type='text' defaultValue={user?.first_name} placeholder='Enter first name' onChange={e => setFirstname(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type='text' defaultValue={user?.last_name} placeholder='Enter last name' onChange={e => setLastname(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type='text' defaultValue={user?.phone} placeholder='Enter phone number' onChange={e => setPhoneNumber(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>License Number</Form.Label>
                                <Form.Control type='text' defaultValue={user?.license_number} placeholder='Enter license number' onChange={e => setLicenseNumber(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="mb-0"><Form.Label>Operating Cities</Form.Label></p>
                                {citiesUpdated ?
                                    <>
                                        {operatingCities && operatingCities.length > 0 ?
                                        <>
                                        {operatingCities.map((operatingCity, index) => (
                                        <Button key={`operatingcity-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2">
                                            {operatingCity}
                                        </Button>
                                        ))}
                                        </>
                                        :
                                        <p className="mb-0 text-muted">No assigned cities</p>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                        user?.operating_cities && user?.operating_cities.length > 0 ?
                                        <>
                                        {user?.operating_cities.map((operatingCity, index) => (
                                        <Button key={`operatingcity-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2">
                                            {operatingCity}
                                        </Button>
                                        ))}
                                        </>
                                        :
                                        <p className="mb-0 text-muted"><small>No assigned cities</small></p>
                                        }
                                    </>
                                }
                               
                            <Row className="mb-3">
                                <Col>
                                    <Button size="sm" variant="link" onClick={() => handleSetEditOperatingCitiesModal()}>Update Operating Cities</Button>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           
                            <p className="mb-0"><Form.Label>Car Categories</Form.Label></p>
                            {carCategoriesToAdd && carCategoriesToAdd.map((carCategory, index) => (
                                <Button key={`operatingcity-${index}`} size="sm" variant="outline-primary" className="btn-rounded mr-2 mb-2">
                                    {`${getCarCategoryName(carCategory.car_category_id)} - ${carCategory.make} ${carCategory.model} ${carCategory.model_year}`}
                                </Button>
                            ))}

                            <Row className="mb-3">
                                <Col>
                                    <Button size="sm" variant="link" onClick={() => setCarCategoriesModal(true)}>Update Car Categories</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Credits Deduction</Form.Label>
                                <Form.Control as="select" defaultValue={user.credits_deduction_option ?? ''} onChange={(e) => setCreditsDeduction(e.target.value)}>
                                    <option value="" disabled>Select Deduction Option</option>
                                        <option>FIXED-4-CHF</option>
                                        <option>15%</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {/* <Col lg="6">
                            <Form.Group>
                                <Form.Label>Invoicing Option</Form.Label>
                                <Form.Control as="select" defaultValue={invoicingOption} onChange={(e) => setInvoicingOption(e.target.value)}>
                                    <option value="" disabled>Select Invoicing Option</option>
                                    {invoiceOptions.map((invoicOption, index) => (
                                        <option key={`invoiceoption-${index}`} value={invoicOption.value} >
                                            {invoicOption.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col> */}
                        {/* {
                            invoicingOption === 'FIXED-CHARGE' &&           
                            <Col lg="6">
                                
                                <Form.Group>
                                    <Form.Label>Fixed Charge Amount</Form.Label>
                                    <Form.Control required type='text' placeholder='Enter amount' defaultValue={fixedChargeAmount} onChange={e => setFixedChargeAmount(e.target.value)} />
                                </Form.Group>   
                            </Col>
                        } */}
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Taxi Company</Form.Label>
                                <Form.Control as="select" onChange={(e) => setSelectedTaxiCompanyId(e.target.value)}>
                                    <option value="">Select Taxi Company</option>
                                    {taxiCompanies.map((company, index) => (
                                        <option key={`taxicompany-${index}`} value={company._id} >
                                            {company.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <hr />
                    <Card.Title className="mb-3">Change Password</Card.Title>
                    <Form.Group>
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control disabled={_currentUser.role_name === 'system-admin'} type='text' placeholder='Enter old password' value={user.doc.password} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter new password' onChange={e => setNewPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control type='password' placeholder='Conform new password' onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Group>
                    
                    {error && 
                    <p className="text-danger text-center"><small>{error}</small></p>
                    }
                    <Button className="w-100" onClick={() => handleSave()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
        </>
  )
}

export default UpdateAccountTaxiModal

