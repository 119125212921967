import { parseGoogleAddressComponent } from '../models/googleMapAddressComponent';

export const handleAutocompleteAddressChange = (data, setter) => {

    const prettyVal = { formatted_address: data.formatted_address }

    if(data.address_components){
        var parsed = parseGoogleAddressComponent(data.address_components)
        prettyVal.address = parsed
        prettyVal.address.lat = data.geometry.location.lat()
        prettyVal.address.lng = data.geometry.location.lng()
    }
    
    const isString = typeof data === 'string' || data instanceof String
    
    setter(isString ? data : prettyVal)
    
}