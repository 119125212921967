import { isToday } from 'date-fns';
import Moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Dropdown, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';
import SocketContext from '../../context/socket';
import { getCurrentUser, logout } from '../../services/auth';
import { getSupportTickets } from '../../services/contactSupport';
import { getUnresolvedIssues } from '../../services/logs';
import { updateBookingAsConfirmed } from '../../services/shuttleBookings';
import { getUser } from '../../services/users';
import { get } from '../../services/airlineCrews'
import { isAirlineAdmin, isHotelAccount, isHotelAdmin, isSystemAdmin } from '../../utils/utils';
import ConfirmationModal from '../modals/confirmation';
import ConfirmationTimerModal from '../modals/confirmationTimer';
import GuestShuttleOrderNoticeModal from '../modals/guestShuttleOrderNotice';
import Navigation from './navigation';
import './navigationbar.css';

const NavigationBar = ({ container, setDrawerOpen, hotelData, airlineData }) => {

    const socket = useContext(SocketContext);

    const [guestBookingModal, setGuestBookingModal] = useState(false)
    const [guestBookingNoticeModal, setGuestBookingNoticeModal] = useState(false)
    const [guestBookingId, setGuestBookingId] = useState()
    const [guestBookingMessage, setGuestBookingMessage] = useState('')

    const [issues, setIssues] = useState([])
    const [tickets, setTickets] = useState([])
    const [airCrews, setAirCrews] = useState([])
    const [displayDangerBadgeIssues, setDisplayDangerBadgeIssues] = useState(false)
    const [displayDangerBadgeTickets, setDisplayDangerBadgeTickets] = useState(false)

    const [user, setUser] = useState()
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (currentUser) {

            if (isSystemAdmin()) {
                fetchSystemIssues()
                fetchTickets()
                subscribeToAdminSpecificSockets()
            }
            else if (isHotelAccount()) {
                subscribeToHotelSpecificSockets()
            }

            checkUser()
            fetchAirCrews()
        }
    }, [])

    const subscribeToAdminSpecificSockets = () => {

        socket.on(`new-support-ticket`, data => {
            fetchTickets()
        });

        socket.on(`taxi-booking-confirmed`, data => {

            if (data.booking_source_user_id === currentUser._id) {

                toast.success(() =>
                    <LinkContainer to={`/taxi-booking/${data._id}`}>
                        <div className="p-2">
                            <p>Confirmed!</p>
                            <hr style={{ color: 'white' }} />
                            <p>A taxi booking for {data.customer.first_name} {data.customer.last_name} is confirmed. Click to view booking information</p>
                        </div>
                    </LinkContainer>
                    , {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

            }
        });
    }

    const subscribeToHotelSpecificSockets = () => {

        socket.on(`taxi-booking-confirmed-${currentUser.hotel_id}`, data => {

            toast.success(() =>
                <LinkContainer to={`/taxi-booking/${data._id}`}>
                    <div className="p-2">
                        <p>Confirmed!</p>
                        <hr style={{ color: 'white' }} />
                        <p>A taxi booking for {data.customer.first_name} {data.customer.last_name} is confirmed. Click to view booking information</p>
                    </div>
                </LinkContainer>
                , {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        });

        socket.on(`new-shuttle-booking-${currentUser.hotel_id}`, data => {

            const dateMessage = isToday(new Date(data.booking_date)) ? "today" : `on ${Moment.utc(data.booking_date).format('DD.MM.YYYY')}`

            if ((data.reception && data.reception.is_confirmed)
                || data.expected_guest_payment) {
                setGuestBookingNoticeModal(true)
            }
            else {
                if (!data.paid_by_guest) {
                    setGuestBookingModal(true)
                    setTimeout(() => { setGuestBookingModal(false) }, 30000);
                }
            }

            setGuestBookingId(data._id)
            setGuestBookingMessage(`A shuttle is booked by ${data.customer.first_name} ${data.customer.last_name} from ${data.origin}. The guest will arrive ${dateMessage}`)

        });

        //MOVED NOTIFICATION TO SIMUL ADMIN
        // socket.on(`taxi-booking-availability-failed-${currentUser.hotel_id}`, data => {

        //     toast.error(() => 
        //         <LinkContainer to={`/taxi-booking/${data._id}`}>
        //             <div className="p-2">
        //                 <p>Taxi Booking Failed</p>
        //                 <hr style={{color:'white'}}/>
        //                 <p>A taxi booking for {data.customer.first_name} {data.customer.last_name} failed.</p>
        //                 <p>No available taxi drivers at this time. Please try again later.</p>
        //             </div>
        //         </LinkContainer>
        //         , {
        //             position: "top-right",
        //             autoClose: false,
        //             hideProgressBar: true,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        // });
    }

    const confirmGuestBooking = async (_id = guestBookingId) => {
        const { data: { code, message } } = await updateBookingAsConfirmed(_id)
        if (code !== 200) {
            toast.error(` Unable to confirm booking!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toast.success(` Booking successfully confirmed!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setGuestBookingModal(false)
        }
    }

    const checkUser = async () => {
        const { data: { code, message } } = await getUser(currentUser._id)
       
        if (code !== 200) {
            handleLogout()
        } else {
            setUser(message)
        }
    }

    const fetchSystemIssues = async () => {
        const { data: { code, message } } = await getUnresolvedIssues();
        if (code === 200) {
            setIssues(message)
            updateBadgeDispay(message, setDisplayDangerBadgeIssues)
        }
    }

    const fetchTickets = async () => {
        const { data: { code, message } } = await getSupportTickets()
        if (code === 200) {
            const filteredItems = message.filter(x => x.status === 'new')
            setTickets(filteredItems)
            updateBadgeDispay(filteredItems, setDisplayDangerBadgeTickets)
        }
    }

    const fetchAirCrews = async () => {
        const { data: { code, message } } = await get()
        if (code === 200) {
            setAirCrews(message)
        }
    }

    const updateBadgeDispay = (data, action) => {
        if (currentUser && isSystemAdmin() && data.length > 0) {
            action(true)
        }
    }

    const handleLogout = () => {
        logout()
        window.location.reload(false);
    }

    const displayName = () => {
        return (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : currentUser.username
    }

    return (
        <>
            <ConfirmationTimerModal size="md" display={guestBookingModal} toggle={setGuestBookingModal}
                title="New Guest Booking"
                message={guestBookingMessage}
                confirmAction={confirmGuestBooking}
                confirmText="Confirm"
                cancelText="Close"
                countdown={30}
                bellIcon={true}
            />

            <GuestShuttleOrderNoticeModal size="md" display={guestBookingNoticeModal} toggle={setGuestBookingNoticeModal}
                title="New Guest Booking"
                message={guestBookingMessage}
                confirmText="Ok"
                // cancelText="Close"
                bellIcon={true}
            />

            <Navbar className="navbar-light" expand="lg">
                <div className="rounded shadow-sm bg-white w-100">
                    <Container fluid={container === "fluid" ? true : false} className="py-2 px-3">

                        <button onClick={() => setDrawerOpen(true)} aria-controls="basic-navbar-nav" type="button" className="btn btn-outline-light d-inline-block d-sm-none">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {/* <Navbar.Collapse id="basic-navbar-nav">
                        <Navigation />
                    </Navbar.Collapse> */}

                        <Navigation hotelData={hotelData} airlineData={airlineData} airCrews={airCrews}/>

                        <div className="form-inline align-items-center">

                            <div className="px-lg-4">
                                <p className="mb-0 color-primary"><i className="mr-2">Central Reservation:</i> <strong>044 222 22  22</strong></p>
                            </div>

                            <Dropdown className="no-caret">
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                    <span className="cursor-pointer" title="notification">
                                        <i className="fas fa-bell"></i>
                                        {
                                            (displayDangerBadgeIssues || displayDangerBadgeTickets > 0) &&
                                            <sup><span className="badge badge-danger">!</span></sup>
                                        }
                                    </span>
                                </Dropdown.Toggle>
                                {currentUser && isSystemAdmin() &&
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/system-issues">
                                            View Issues {displayDangerBadgeIssues && <sup><span className="badge badge-danger">{issues.length}</span></sup>}
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/support-tickets">
                                            Support Tickets {displayDangerBadgeTickets && <sup><span className="badge badge-danger">{tickets.length}</span></sup>}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                }
                            </Dropdown>

                            <Dropdown>
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                    <i className="far fa-user-circle"></i> <span style={{ fontWeight: "bold" }}>Hello, {user && displayName()}!</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                    {currentUser && isHotelAdmin() &&
                                        <Dropdown.Item href="/hotel-settings">Hotel Settings</Dropdown.Item>
                                    }
                                    {currentUser && isAirlineAdmin() &&
                                        <Dropdown.Item href="/airline-settings">Airline Settings</Dropdown.Item>
                                    }
                                    {currentUser && isHotelAccount() &&
                                        <Dropdown.Item href="/contact">Contact Support</Dropdown.Item>
                                    }
                                    <Dropdown.Item href="" onClick={() => handleLogout()}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Container>
                </div>
            </Navbar>
        </>
    )
}
export default NavigationBar