import http from './http'
import { getToken } from './auth'

const base = 'zones'

export async function getZones() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function createZone(payload) {
    return await http.post(`${base}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function deleteZone(id) {
    return await http.delete(`${base}/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function editZone(id, payload) {
    return await http.patch(`${base}/${id}`, payload, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}
