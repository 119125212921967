import http from './http'
import { getToken } from './auth'

const base = 'taxiDestinations'

export async function getAllTaxiDestinations() {
    return await http.get(`${base}/`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getTaxiDestinationsByHotelId(hotelId) {
    return await http.get(`${base}/hotel-id/${hotelId}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function addTaxiDestination(payload){
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    return await http.post(`${base}`, payload, config)
}

export async function editTaxiDestination(id, payload){
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }

    return await http.patch(`${base}/${id}`, payload, config)
}

export async function deleteTaxiDestination(id) {
    
    return await http.delete(`${base}/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}