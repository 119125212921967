
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Moment from 'moment';
import React from 'react';
import simulLogo from '../../assets/images/logo_colored.png'

const TaxiBookingPosTicketPdf = ({ booking, destinations }) => {

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            fontSize: 7
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        col: {
            display: 'flex',
            flexDirection: 'column'
        },
        logo: {
            marginTop: 5,
            height: 20,
            alignItems: 'center',
            justifyContent: 'center'
        },
        simulinfo: {
            marginTop: 5,
            alignItems: 'center',
            justifyContent: 'center'
        },
        qr: {
            paddingTop:0,
            marginTop:0,
            width: 30,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        item: {
            paddingHorizontal: 20,
            marginRight: 20,
            marginTop: 4
        },
        note: {
            marginTop: 5,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 25,
            textAlign: 'center',
            fontSize: 6,
            fontStyle: 'italic'
        },
    });

    const getDestination = (booking) => {
        if(!booking.has_destination){
            return 'OPEN';
        }

        if(booking.destination.is_free_input){
            return booking.destination.free_input_address;
        }

        return booking.destination.fixed_destination_name;
    }

    return (
        <>
            <Document>
                <Page size="A8" style={styles.page}>
                    <View wrap={true}>
                        <View style={[styles.row, styles.logo]}>
                            <Image src={simulLogo} style={{ height: '100%' }} />
                        </View>
                        <View style={styles.simulinfo}>
                            <Text>Simul Group GmbH</Text>
                            <Text>044 222 22 22</Text>
                            <Text>www.simul-group.ch</Text>
                        </View>

                        <View style={[styles.row, styles.item, { marginTop: 7 }]}>
                            <Image style={styles.qr} src={booking.qrcode} />
                            <View style={{marginTop:3}}>
                                <Text>{`${booking.customer.first_name} ${booking.customer.last_name}`}</Text>
                                <Text>Room #: {booking.customer.booking_source_number ?? ''}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Date:</Text>
                            <Text>{Moment(booking.booking_date).format('DD.MM.YYYY')}</Text>
                        </View>
                        <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Time:</Text>
                            <Text>{Moment(booking.booking_date).format('HH:mm')}</Text>
                        </View>
                        <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Origin:</Text>
                            <Text>{booking.origin}</Text>
                        </View>
                        <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Destination:</Text>
                            <Text>{getDestination(booking)}</Text>
                        </View>
                        <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Personen:</Text>
                            <Text>{booking.number_of_persons}</Text>
                        </View>
                        {/* <View style={[styles.row, styles.item]}>
                            <Text style={{ width: 38 }}>Preis:</Text>
                            <Text>{booking.sale_info && booking.sale_info.amount > 0 ? booking.sale_info.amount.toFixed(2) + ' CHF': 'NA'}</Text>
                        </View> */}
                        <View style={styles.note}>
                            <Text style={{marginBottom:3}}>Please be ready outside 5 minutes before the departure time. The shuttle bus leaves on time!</Text>
                            <Text>Please keep your ticket and give it to the driver.</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    );
}

export default TaxiBookingPosTicketPdf