
import enGb from 'date-fns/locale/en-GB';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import { getHotels } from '../../../services/hotels';
import AdminBookTaxiDestinations from '../bookTaxiDestinations';

const AdminBookTaxiSelectDestinationPage = () => {

  
    const [isWalkIn, setIsWalkIn] = useState('yes')
    const [hotels, setHotels] = useState([])

    registerLocale('en-gb', enGb);
    
    useEffect(() => {
        fetchHotels();
    }, [])

    useEffect(() => {
       // setSelectedBookingDate(isWalkIn === 'yes' ? '' : new Date())
    }, [isWalkIn])

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()
        
        if(Array.isArray(message)){
            setHotels(message);
        }
    }

    return <>
        
        <section>
            <Col>
                <AdminBookTaxiDestinations hotels={hotels} />
            </Col>
        </section>
    </>
}

export default AdminBookTaxiSelectDestinationPage
