import http from './http'
import { getToken } from './auth'

const base = 'airlineCrewBookings'

export async function getAirlineCrewBooking(id) {
    return await http.get(`${base}/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function bookAirlineCrew(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getPaginatedAirlineCrewBookings(airlineCrewId, pageNumber, pageSize, searchText) {

    const params = new URLSearchParams()
    airlineCrewId && params.append('airlineCrewId', airlineCrewId)
    params.append('pageNumber', pageNumber)
    params.append('pageSize', pageSize)

    searchText && params.append('searchText', searchText)

    return await http.get(`${base}/paginated`, {
        headers: {
            'x-auth-token': getToken()
        },
        params
    })
}

export async function cancelAirlineCrewBooking(id) {

    return await http.patch(`${base}/cancel/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function deleteAirlineCrewBooking(id) {

    return await http.delete(`${base}/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateAirlineCrewBooking(id, payload) {

    return await http.patch(`${base}/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}


// Public Endpoints 
export async function searchAirlineCrewBookingPublic(pickupTime, flightNumber, airlineCrewId, key) {

    const params = new URLSearchParams()
    params.append('pickupTime', pickupTime)
    params.append('flightNumber', flightNumber)
    airlineCrewId && params.append('airlineCrewId', airlineCrewId)
    key && params.append('key', key)

    return await http.get(`${base}/public`, {
        headers: {},
        params
    })
}

export async function bookAirlineCrewPublic(payload, key) {

    const params = new URLSearchParams()
    key && params.append('key', key)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
        params
    }
    return await http.post(`${base}/public`, payload, config)
}

export async function updateAirlineCrewBookingPublic(id, payload, key) {

    const params = new URLSearchParams()
    key && params.append('key', key)

    return await http.patch(`${base}/public/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        params
    })
}