import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { cancelAirlineCrewBooking, getAirlineCrewBooking } from '../../services/airlineCrewBookings';
import ConfirmationModal from '../../components/modals/confirmation';
import { toast } from 'react-toastify';
import PageLoader from '../../components/common/pageLoader';
import { isDateTimePassedCurrentTime } from '../../utils/utils';

const AirlineCrewBookingPage = (props) => {

    const { _id } = props.match.params

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [booking, setBooking] = useState({})

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            const { data: { code, message } } = await getAirlineCrewBooking(_id)
            setIsLoading(false)
            if (code == 200) {
                setBooking(message)
            } else {
                //TODO: toast
            }
        }
        fetch()
    }, [])

    const handleCancelBooking = async () => {
        const { data: { code, message } } = await cancelAirlineCrewBooking(_id)
    
        if (code == 200) {
            let _booking = booking
            _booking.is_cancelled = true
            setBooking({ ..._booking })
            toast.success('Successfully cancelled booking.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }


    return <>

        <ConfirmationModal
            title="Cancel Booking"
            message="Are you sure you want to cancel this this?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleCancelBooking(_id)} />

        <Card className="mb-4">
            <Card.Body>

                {isLoading ?
                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                    :
                    <Row className="mb-3">
                        <Col lg="7" className="px-0 mx-n2">

                            <div className="px-4">
                                {booking.is_cancelled &&
                                    <Button className="btn-lg no-pointer-events my-4" variant="outline-danger"><strong>CANCELLED</strong></Button>
                                }

                                <div className="booking-details">

                                    <p className="mb-3"><strong>BOOKING DETAILS</strong></p>

                                    <p className="pl-2">{booking.origin} <i className="mx-2 fas fa-arrow-circle-right"></i> {booking.destination}</p>

                                    <Table className="booking-table mb-4" responsive>
                                        <tbody>
                                            <tr>
                                                <td className="booking-table-label">Pickup Time</td>
                                                <td><i className="fas fa-calendar-alt mr-2"></i>{Moment(booking.pickup_time).format('DD.MM.YYYY')} @ {Moment(booking.pickup_time).format('HH:mm')}</td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Number of Persons</td>
                                                <td><i className="fas fa-user-friends mr-2"></i>{`${booking.number_of_persons}`}</td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Seat Option</td>
                                                <td><i className="fas fa-user-friends mr-2"></i>{`${booking.seat_name}`}</td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Price</td>
                                                <td>{`${booking?.seat_price?.toFixed(2)} CHF`}</td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Crew Names</td>
                                                <td>
                                                    {
                                                        booking?.crew_names && booking?.crew_names.length > 0 &&
                                                        booking?.crew_names.map((crew_name, index) =>
                                                            <span key={`crew_name-${index}`}>
                                                                <Badge variant="primary" className="guest-route-badge mb-1 mr-1" key={`${crew_name}-${index}`}>{crew_name}</Badge>
                                                            </span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Created on</td>
                                                <td><i className="fas fa-calendar-alt mr-2"></i>{Moment(booking.createdAt).format('DD.MM.YYYY')} @ {Moment(booking.createdAt).format('HH:mm')}</td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Arrival</td>
                                                <td>
                                                {
                                                    booking?.flight_arrival_date && 
                                                    <><i className="fas fa-calendar-alt mr-2"></i>{Moment(booking.flight_arrival_date).format('DD.MM.YYYY')} @ {Moment(booking.flight_arrival_date).format('HH:mm')}</>
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="booking-table-label">Arrival Flight #</td>
                                                <td>{booking.flight_arrival_number}</td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                    {!booking.is_cancelled && !isDateTimePassedCurrentTime(booking.pickup_time) &&
                                        <Button variant="outline-primary" onClick={() => setConfirmationModal(true)}>Cancel Booking</Button>
                                    }

                                </div>
                            </div>

                        </Col>
                    </Row>
                }



            </Card.Body>
        </Card>

    </>
}

export default AirlineCrewBookingPage