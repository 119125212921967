import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { getSimulAccounts } from '../../services/accounts';
import { getAllDestinations } from '../../services/shuttleDestinations';
import { getDestinationName, isSimulAccount } from '../../utils/utils';

const CancelledBookings = ({bookings}) => {

    const [destinations, setDestinations] = useState()
    const [simulAccounts, setSimulAccounts] = useState()

    Moment.locale('en');

    useEffect(() => {
        fetchDestinations()

        if(isSimulAccount())
        {
            fetchSimulAccounts();
        }
        
    }, [])

    const fetchDestinations = async () => {
        const { data: { message } } = await getAllDestinations()
        setDestinations(message)
    }

    const fetchSimulAccounts = async () => {
        const { data: { message } } = await getSimulAccounts()
        if(message == 'No simul accounts found.'){
            return;
        }
        setSimulAccounts(message)
    }

    const getDriverName = (id) => {
        
        if(!simulAccounts || simulAccounts.length == 0){
            return '';
        }
        const driver = simulAccounts.find(x => x._id == id)
        if(driver) {
            if (driver.first_name && driver.last_name)
                return `${driver.first_name} ${driver.last_name}`
            else
                return driver.username
        } else {
            return 'no driver'
        }
    }

    return <>
        <Card className='w-100 cancelled-bookings'>
            <Card.Header className='text-center bg-primary text-white'>Cancelled Bookings</Card.Header>
            <Card.Body>
                <Table responsive>
                    <thead className="border-top-0">
                        <tr>
                            <th>Booked Date</th>
                            <th>Time</th>
                            <th>Guest Name</th>
                            <th>Hotel Name</th>
                            <th>Room #</th>
                            <th>Seats</th>
                            <th>Destination</th>
                            <th>Driver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking, index) =>
                            <React.Fragment key={`bookinglist-${index}`}>
                            <tr>
                                <td>{Moment.utc(booking.booking_date).format('DD.MM.YYYY')}</td>
                                <td>{Moment.utc(booking.booking_date).format('HH:mm')}</td>
                                <td>{booking.customer.first_name + " " + booking.customer.last_name}</td>
                                <td>{booking.origin}</td>
                                <td>{booking.customer.booking_source_number}</td>
                                <td>{booking.number_of_persons}</td>
                                <td>{destinations && getDestinationName(booking, destinations)}</td>
                                
                                <td>{simulAccounts && getDriverName(booking.assigned_driver_id)}</td>
                                
                            </tr>
                            </React.Fragment>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default CancelledBookings