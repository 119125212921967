import React, { useState } from 'react'
import { Badge, Card, Table } from 'react-bootstrap'
import { deleteSeatOption } from '../../../services/airlineCrewSeatOptions'
import ConfirmationModal from '../../modals/confirmation'
import { toast } from 'react-toastify'
import UpdateAirlineCrewSeatOptionModal from '../../modals/airline-crew/updateAirlineCrewSeatOptionModal'

const AirlineCrewSeatOptionList = ({ seatOptions, setSeatOptions, airCrews }) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [selectedSeatOption, setSelectedSetSeatOption] = useState()

    const handleDeleteSeatOption = async (id) => {
        const { data: { code, message } } = await deleteSeatOption(id)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this seat option. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else {
            deleted()
        }
    }

    const updated = (seatOption) => {
        const index = seatOptions.findIndex(x => x._id === seatOption._id)
        let _seatOptions = seatOptions

        if (seatOption?.name) _seatOptions[index].name = seatOption?.name
        if (seatOption?.seats) _seatOptions[index].seats = seatOption?.seats
        if (seatOption?.price) _seatOptions[index].price = seatOption?.price
        if (seatOption?.per_person_price) _seatOptions[index].per_person_price = seatOption?.per_person_price
        _seatOptions[index].applicable_route = seatOption?.applicable_route
        _seatOptions[index].airline_crew_id = seatOption?.airline_crew_id
        _seatOptions[index].vehicle_type = seatOption?.vehicle_type

        setSeatOptions(_seatOptions)
    }

    const handleUpdateModal = async (seatOption) => {
        setSelectedSetSeatOption(seatOption)
        setUpdateModal(true)
    }

    const deleted = () => {
        const index = seatOptions.indexOf(selectedSeatOption)
        let _seatOptions = seatOptions
        _seatOptions.splice(index, 1)
        setSeatOptions([..._seatOptions])
    }

    const handleConfirmationModal = (seatOption) => {
        setSelectedSetSeatOption(seatOption)
        setConfirmationModal(true)
    }

    return <>

        <UpdateAirlineCrewSeatOptionModal display={updateModal} toggle={setUpdateModal} selectedSeatOption={selectedSeatOption} seatOptionUpdated={updated}/>

        <ConfirmationModal
            title="Delete Seat Option"
            message="Are you sure you want to delete this seat option?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteSeatOption(selectedSeatOption._id)} />

        <Card className='w-100'>
            <Card.Header className='text-center bg-primary text-white'>Booking Seat Options</Card.Header>
            <Card.Body>
                <Table responsive striped bordered hover variant='light'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Airline Crew</th>
                            <th>Applicable Route</th>
                            <th>Vehicle Type</th>
                            <th>Seats</th>
                            <th>Price</th>
                            <th>Price Per Person</th>
                            <th style={{ width: "65px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            seatOptions.length > 0 &&
                            seatOptions.map((seatOption, index) =>
                                <tr key={`seatOption-${index}`}>
                                    <td>{seatOption?.name}</td>
                                    <td>{airCrews.find(x => x._id === seatOption.airline_crew_id)?.airline_name ?? ''}</td>
                                    <td>{seatOption?.applicable_route}</td>
                                    <td>{seatOption?.vehicle_type}</td>
                                    <td>{seatOption?.seats}</td>
                                    <td>{seatOption?.price}</td>
                                    <td>{seatOption?.per_person_price}</td>
                                    <th className="actions text-center">
                                        <i title="update seatOption" className="fas fa-edit mr-2" onClick={() => handleUpdateModal(seatOption)}></i>
                                        <i onClick={() => handleConfirmationModal(seatOption)} className="far fa-trash-alt cursor-pointer hover-primary"></i>
                                    </th>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default AirlineCrewSeatOptionList