import http from './http'
import { getToken } from './auth'

const base = 'systemAdmin'

export async function createAccount(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/create-admin`, payload, config)
}

export async function createShuttleAdmin(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/create-shuttle-admin`, payload, config)
}

export async function createHotelAdmin(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/create-hotel-admin`, payload, config)
}

export async function createAirlineAdmin(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/create-airline-admin`, payload, config)
}




