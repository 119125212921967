import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Row, Col } from 'react-bootstrap'
import { getAirlines } from '../../services/airlines'

const AirlineAdminCreator = ({createAirlineAdmin}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [airlines, setAirlines] = useState([])
    const [airlineId, setAirlineId] = useState('')
    const [airlineName, setAirlineName] = useState('')
    
    useEffect(() => {
        fetchAirlines()
    }, [])

    const handleAccountCreationSubmit = () => {

        createAirlineAdmin({ username: username, password: password, airline_id: airlineId }, airlineName, password)
        document.getElementById('airline-admin-creator').reset();
    }

    const fetchAirlines = async () => {
        const { data: { message } } = await getAirlines()
        
        if(Array.isArray(message))
            setAirlines(message)
        else {
            setAirlines([])
        }
    }

    const handleSelectAirline = (airlineId) => {

        setAirlineId(airlineId)

        const _airlineName = airlines.find((airline => airline._id == airlineId)).name
        setAirlineName(_airlineName)        
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Airline Admin Account</Card.Header>
            <Card.Body>
                <Form id="airline-admin-creator">
                    <Row className="mb-3">
                        <Col lg="4">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Airline</Form.Label>
                                <Form.Control as="select" defaultValue={airlineId} onChange={(e) => handleSelectAirline(e.target.value)}>
                                    <option value="" disabled>Select Airline</option>
                                    {airlines && airlines.map((airline, index) => (
                                        <option key={`airline-${index}`} value={airline._id} >
                                            {airline.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center">
                    <Button variant='primary' onClick={() => handleAccountCreationSubmit()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default AirlineAdminCreator