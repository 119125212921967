import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CancelledBookings from '../../components/common/cancelledBookings';
import { getCancelledBookings } from '../../services/shuttleBookings';

const CancelledBookingsPage = () => {

    const [canCelledBookings, setBookings] = useState([])

    useEffect(() => {
        fetch()
    })

    const fetch = async () => {

        const { data: { message } } =  await getCancelledBookings()
        
        if(Array.isArray(message))
        {
            setBookings(message)
        }
    }

    return <>
        <section>
            <Row>
                <Col>
                    <CancelledBookings bookings={canCelledBookings} />
                </Col>
            </Row>
        </section>
    </>
}

export default CancelledBookingsPage