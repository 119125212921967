
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getAllTaxiDestinations } from '../../services/taxiDestinations';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../components/common/pageLoader';
import HotelsSelect from '../../components/common/hotelsSelect';

const AdminBookTaxiDestinations = ({hotels, selectDestination}) => {

    const params = new URLSearchParams(window.location.search)
    const destination_id = params.get('destination_id')
    const booking_type = params.get('booking_type')

    const [destinations, setDestinations] = useState()
    const [filteredDestinations , setFilteredDestinations] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory()
    
    useEffect(() => {
        fetchDestinations()
    }, [])

    const fetchDestinations = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getAllTaxiDestinations()
        setIsLoading(false)
   
        if(code === 200) {
            setDestinations(message)
            setFilteredDestinations(message)
            booking_type && selectDestination(booking_type, message.find( x => x._id === destination_id) || 'open-destination')
        }
    }

    const handlSelectDestination = (bookingType, destination = 'open-destination') => {
        selectDestination(bookingType, destination)
        history.push(`/admin-book-taxi?booking_type=${bookingType}&destination_id=${destination._id}`)
    }

    const filterDestinations = (hotelId) => {
        let _destinations = destinations
        const filteredDestinations = hotelId ? _destinations.filter(x => x.establishment_origin_id === hotelId) : _destinations
        setFilteredDestinations([...filteredDestinations])
    }

    return (
        <div>
            {
                isLoading ?
                <PageLoader />
                :
                <>
                <Row className="mb-4">
                    <Col xs="auto" className="pr-0">
                        <HotelsSelect hotels={hotels} label='Filter by Hotel' style={{minWidth:'200px'}}
                            initialOption='All Hotels' onChange={(e) => filterDestinations(e.target.value)}
                        />
                    </Col>
                </Row>
                
                <Row className="row-eq-height">
                    <Col lg="3" className="mb-4">
                        <Card className="h-100 border border-primary">
                            <Card.Body>
                                <Card.Title className="text-center mb-4 color-primary">Open Destination</Card.Title>
                                <div className="mb-3">
                                    <Button className="w-100" variant="outline-primary" onClick={() => handlSelectDestination('reservation')}>Reservation</Button>
                                </div>
                                <div className="mb-3">
                                    <Button className="w-100" variant="outline-primary" onClick={() => handlSelectDestination('walk-in')}>Walk In</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {filteredDestinations && filteredDestinations.map((destination, index) => (
                        <Col key={`destination-${index}`} lg="3" className="mb-4">
                            <Card className="h-100">
                                <Card.Body>
                                    <Card.Title className="text-center mb-4">{destination.establishment_origin_name} <i className=" fas fa-arrow-circle-right"></i> {destination.destination_name}</Card.Title>
                                    <div className="mb-3">
                                        <Button className="w-100" onClick={() => handlSelectDestination('reservation', destination)}>Reservation</Button>
                                    </div>
                                    <div className="mb-3">
                                        <Button className="w-100" onClick={() => handlSelectDestination('walk-in', destination)}>Walk In</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                </>
            }
        </div>
    )
}

export default AdminBookTaxiDestinations
