import React from 'react'
import { Card, Form, Button } from 'react-bootstrap'

const RegistrationForm = ({ setUsername, setPassword, submitHandler }) => {
    return <>
        <Card>
            <Card.Body>
                <Form.Control className="mb-2" placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                <Form.Control className="mb-3" placeholder="password" type='password' onChange={(e) => setPassword(e.target.value)} />
                <Button onClick={() => submitHandler()}>Create System Admin</Button>
            </Card.Body>
        </Card>
    </>
}

export default RegistrationForm