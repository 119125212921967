import http from './http'
import { getToken } from './auth'

const base = 'logs'

export async function getUnresolvedIssues() {

    return await http.get(`${base}/unresolved`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function debugText(text) {

    return await http.post(`${base}/debug?text=${text}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function markErrorResolved(id) {

    return await http.patch(`${base}/update-as-resolved/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

