import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { getAllHotelStaffs, getHotelAdmins, getSystemAdmins } from '../../services/users'

const CancelledTaxiBookings = ({bookings}) => {

    const [allHotelStaffs, setAllHotelStaffs] = useState()
    const [hotelAdmins, setHotelAdmins] = useState()
    const [systemAdmins, setSystemAdmins] = useState()

    Moment.locale('en');

    useEffect(() => {
        fetchAllHotelStaffs()
        fetchHotelAdmins()
        fetchSystemAdmins()
    }, [])

    const fetchAllHotelStaffs = async () => {
        const { data: { message } } = await getAllHotelStaffs()
        setAllHotelStaffs(message) 
    }

    const fetchHotelAdmins = async () => {
        const { data: { message } } = await getHotelAdmins()
        setHotelAdmins(message) 
    }

    const fetchSystemAdmins = async () => {
        const { data: { message } } = await getSystemAdmins()
        setSystemAdmins(message) 
    }
    

    const getCancelledBy = (id) => {
        var fullName = "";

        if( checkExist(id,allHotelStaffs) ){
            var foundUser = allHotelStaffs.filter(user => (user._id === id))[0]

            fullName = getNameFromUserDetails(foundUser)
        } 
        if( checkExist(id,hotelAdmins) ){
            var foundUser = hotelAdmins.filter(user => (user._id === id))[0]

            fullName = getNameFromUserDetails(foundUser)
        } 
        if( checkExist(id,systemAdmins)) {
            var foundUser = systemAdmins.filter(user => (user._id === id))[0]

            fullName = getNameFromUserDetails(foundUser)
        }


        return fullName;
    }

    const getNameFromUserDetails = (user) => {

        let name = user.username;

        if(user.info && user.info.first_name){

            name = user.info.first_name;

            if(user.info.last_name){
                name = name + " " + user.info.last_name;
            }
        }

        return name;
            
    }    

    const checkExist = (id,users) => {

        return users && users.some(user => user._id === id);

    }

    

    return <>
        <Card className='w-100 cancelled-taxi-bookings'>
            <Card.Header className='text-center bg-primary text-white'>Cancelled Taxi Bookings</Card.Header>
            <Card.Body>
                <Table responsive>
                    <thead className="border-top-0">
                        <tr>
                            <th>Booked Date</th>
                            <th>Time</th>
                            <th>Guest Name</th>
                            <th>Hotel Name</th>
                            <th>Room #</th>
                            <th>Seats</th>
                            <th>Cancelled By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking, index) =>
                            <React.Fragment key={`bookinglist-${index}`}>
                            <tr>
                                <td>{Moment.utc(booking.booking_date).format('DD.MM.YYYY')}</td>
                                <td>{Moment.utc(booking.booking_date).format('HH:mm')}</td>
                                <td>{booking.customer.first_name + " " + booking.customer.last_name}</td>
                                <td>{booking.origin}</td>
                                <td>{booking.customer.booking_source_number}</td>
                                <td>{booking.number_of_persons}</td>
                                <td>{getCancelledBy(booking.cancellation_info.user_id )}</td>
                            </tr>
                            </React.Fragment>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </>
}

export default CancelledTaxiBookings