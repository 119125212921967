import React, { useEffect, useState } from 'react';
import PageLoader from '../../components/common/pageLoader';
import ConfirmationModal from '../../components/modals/confirmation';
import { cancelTaxiBooking, getUnassignedTaxiBookings } from '../../services/taxiBookings';
import { isDateTimePassedCurrentTime, isSimulAccount } from '../../utils/utils';
import { toast } from 'react-toastify';
import Moment from 'moment';
import LinkTD from '../../components/common/linkTd';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import ChangeTaxiBookingDateModal from '../../components/modals/changeTaxiBookingDate';
import AssignTaxiDriverModal from '../../components/modals/assignTaxiDriverModal';
import { getTaxiDrivers } from '../../services/taxiAccounts';

const SytemAdminUnassignedTaxiBookings = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [assignDriverModal, setAssignDriverModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)

    const [bookings, setBookings] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState()
    const [selectedBooking, setSelectedBooking] = useState()

    useEffect(() => {
        fetch()
        fetchTaxiDrivers()
    }, [])

    const fetch = async () => {
        setIsLoading(true)
        const { data: { code, message } } = await getUnassignedTaxiBookings()
        setIsLoading(false)
        if(code === 200) {
            if(Array.isArray(message))
            {
                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date))
                setBookings(sortedItems.filter(booking => !booking.isCancelled))
            }
        } else {
            //TODO: toast
        }
    }

    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if(code === 200){
            setTaxiDrivers(message)
        } else {
            //TODO: toast
        }  
    }

    const handleCancelBooking = async () => {
        const {data: {code, message}} = await cancelTaxiBooking(selectedBooking._id)
        if (code == 200) {
            var _bookings = bookings
            var index = _bookings.indexOf(selectedBooking);
            _bookings.splice(index, 1);
            setBookings([..._bookings])
            toast.success('Successfully cancelled booking.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toastError(`An error occured on cancelling taxi bookings. ${message}.`)
         }
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const handleAssignDriverModal = (booking) => {
        setSelectedBooking(booking)
        setAssignDriverModal(true)
    }

    const canChangeTime = (booking) => {
        return !Moment(Moment().add(30, 'minutes').utc().format()).isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canCancel = (booking) => {
        return (!booking.hasOwnProperty('sale_info'))
    }

    const bookingDateUpdated = (newBookingDate) => {
        const bookingIndex = bookings.indexOf(selectedBooking)
        let _bookings = bookings
        _bookings[bookingIndex].booking_date = newBookingDate
        setBookings(_bookings)
    }

    const driverUpdated = (driverId) => {
        let _bookings = bookings
        const index = _bookings.indexOf(selectedBooking)
        _bookings[index].admin_assigned_candidate_driver_id = driverId
        setBookings(_bookings)
    }

    const renderAssignDriverButton = (booking) => {

        //if(!booking.admin_assigned_candidate_driver_id && !booking.driver_assignment?.assigned_driver_id)
        if(!booking.driver_assignment?.notified_candidate_driver_latest) {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                    <i className="fas fa-plus"></i> Assign
                </Button>)
        } else {
            return (
                <Button size="sm" variant="outline-primary" className="ml-2" onClick={() => handleAssignDriverModal(booking)}>
                   <i className="fas fa-pencil-alt"></i> {getAssignedDriverName(booking)}
                </Button>)
        }  
    }

    const getAssignedDriverName = (booking) => {

        if(!taxiDrivers || taxiDrivers.length === 0){
            return "";
        }
        if(booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest) {
            const driverId = booking.driver_assignment?.assigned_driver_id || booking.admin_assigned_candidate_driver_id || booking.driver_assignment?.notified_candidate_driver_latest;
            var _taxiDriver = taxiDrivers.find(driver => driver._id === driverId)
            return  _taxiDriver ? _taxiDriver.first_name + " " + _taxiDriver.last_name : '';
        }
        return "";
    } 

    return <>
        {isLoading && <PageLoader />}
        {selectedBooking && 
        <>
        <ChangeTaxiBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} bookingDateUpdated={bookingDateUpdated} />
        <AssignTaxiDriverModal display={assignDriverModal} toggle={setAssignDriverModal} booking={selectedBooking} taxiDrivers={taxiDrivers} driverUpdated={driverUpdated} />
        </>
        }
        <ConfirmationModal 
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />
        <section>
           <Row>
                <Col>
                <Card className='w-100 bookings-list'>
                    <Card.Header className='text-center bg-primary text-white'>Unassigned Taxi Bookings</Card.Header>
                        <Card.Body>
                        <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th></th>
                                        <th>Booked Date</th>
                                        <th>Guest Name</th>
                                        <th>Booked By</th>
                                        <th>Origin</th>
                                        <th>Room #</th>
                                        <th>Seats</th>
                                        {isSimulAccount() && <th>Driver</th>}
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    bookings.map((booking, index) =>
                                        <React.Fragment key={`bookingstaxi-${index}`}>
                                        <tr className={isDateTimePassedCurrentTime(booking.booking_date) ? 'booking-past' : ''}>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>
                                                {   
                                                    <i style={{color:(booking.is_confirmed || booking.fulfilled ? 'Tomato': 'LightGray')}} 
                                                        className={(booking.fulfilled ? 'fas fa-check-circle': 'fas fa-check')} 
                                                        title={(booking.fulfilled ? 'Completed': (booking.is_confirmed ? 'Confirmed' : 'Not Confirmed'))}></i>
                                                }
                                            </LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{Moment(booking.booking_date).format('DD.MM.YYYY HH:mm')}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.booking_source_username}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>
                                                <span>{booking.origin}</span>
                                            </LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                            {isSimulAccount() && 
                                            <td>
                                                {
                                                    booking.fulfilled && getAssignedDriverName(booking)
                                                }
                                                {
                                                    !booking.fulfilled && renderAssignDriverButton(booking)
                                                }
                                            </td>
                                            }
                                            <td>
                                                {canChangeTime(booking) && isSimulAccount() && 
                                                    <Button className="mr-2" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>
                                                        <i className="fas fa-clock"></i> Change
                                                    </Button>
                                                }
                                                {canCancel(booking) &&
                                                    <Button size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                                }
                                            </td>
                                        </tr>
                                        </React.Fragment>
                                    )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
           </Row>
        </section>
    </>
}

export default SytemAdminUnassignedTaxiBookings