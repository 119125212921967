import http from './http'
import { getToken } from './auth'

const base = 'airlineCrewRoutePlaces'

export async function createAirlineCrewRoutePlaces(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function getAllAirlineCrewRoutePlaces() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateAirlineCrewRoutePlace(payload) {

    return await http.patch(`${base}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function deleteAirlineCrewRoutePlace(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        }
    }
    return await http.delete(`${base}/${id}`, config)
}