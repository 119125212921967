import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageLoader from '../../components/common/pageLoader';
import { get } from '../../services/airlineCrews';
import { getCurrentUser } from '../../services/auth';

const AirlineCrewSelect = ({ selectAirCrew }) => {

    const currentUser = getCurrentUser()

    const [isLoading, setIsLoading] = useState(false)

    const [airCrews, setAirCrews] = useState([])

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        setIsLoading(true)

        const { data: { message } } = await get()

        let allowedAirCrews = message
        if (currentUser?.role_name === "airline-admin") {
            allowedAirCrews = allowedAirCrews.filter(x => x.allowed_crew_bookings_airlines.includes(currentUser.airline_id))
        }
        else if (currentUser?.role_name === "hotel-admin")
            allowedAirCrews = message.filter(x => x.allowed_crew_bookings_hotels.includes(currentUser.hotel_id))
        else
            allowedAirCrews = message

        setAirCrews(allowedAirCrews)
        setIsLoading(false)
    }

    const handleSelectAirCrew = (airCrew) => {
        selectAirCrew(airCrew)
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <Row className="justify-content-between">
                        <Col>Book Airline Crew <i className="mr-2 fas fa-arrow-circle-right"></i> Select Crew</Col>
                    </Row>
                </Card.Title>
                {isLoading ?
                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                    :
                    <div className="bg-secondary p-4">
                        <Row className="row-eq-height">
                            {airCrews.length > 0 && airCrews.map((airCrew) => (
                                <Col sm="4" md="3" lg="2" className="mb-3" key={`crew-${airCrew.id}`}>

                                    <Card className="h-100 cursor-pointer" onClick={() => handleSelectAirCrew(airCrew)}>
                                        <Card.Body>
                                            <div className="position-relative center-xy">
                                                <img className="w-100" src={airCrew.airline_logo} />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-center">
                                            {airCrew.airline_name}
                                        </Card.Footer>
                                    </Card>

                                </Col>
                            ))}
                        </Row>
                    </div>
                }
            </Card.Body>
        </Card>
    );
};

export default AirlineCrewSelect;