import { isSameDay, subMinutes } from 'date-fns';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify'
import { Card, Col, Row, Table, Button, Pagination, InputGroup, Form } from 'react-bootstrap';
import { cancelTaxiBooking, getTaxiBookingsByUserHotelId } from '../../services/taxiBookings';
import { isDateTimePassedCurrentTime, isHotelAccount } from '../../utils/utils';
import LinkTD from '../../components/common/linkTd';
import PageLoader from '../../components/common/pageLoader';
import { getCurrentUser } from '../../services/auth';
import ConfirmationModal from '../../components/modals/confirmation';
import ChangeTaxiBookingDateModal from '../../components/modals/changeTaxiBookingDate';

const HotelTaxiBookings = () => {

    const PAGE_SIZE = 20

    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changeBookingDateModal, setChangeBookingDateModal] = useState(false)

    const [bookings, setBookings] = useState([])
    const [selectedBooking, setSelectedBooking] = useState()

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [previouses,setPreviouses] = useState([])
    const [disableNext,setDisableNext] = useState(true)

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if(currentPageNumber===1){
                setPreviouses([]);
                setBookings([]);
                fetch();

            }
        }, 30000);
        return () => clearInterval(interval);
    });

    const fetch = async () => {

        setIsLoading(true)

        const { data: { code, message } } =  await getTaxiBookingsByUserHotelId(PAGE_SIZE+1, undefined, searchText)
        
        if(code == 200){
            if(Array.isArray(message))
            {
                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date)).filter(booking => !booking.isCancelled)

                setBookings(sortedItems.slice(0, PAGE_SIZE))

                setDisableNext(sortedItems.length <= PAGE_SIZE)
                //checkNextPage(sortedItems)
            }
        }
        else {
            toastError(`An error occurerd getting taxi bookings. ${message}.`)
        }

        setIsLoading(false)
    }

    // const checkNextPage = async (sortedItems) =>{

    //     let tempBookings = sortedItems.filter(booking => !booking.isCancelled)
    //     const tempCurrentBooking = tempBookings[tempBookings.length-1] 

    //     const { data: {  message } } =  await getTaxiBookingsByUserHotelId('20',tempCurrentBooking?.booking_date, searchText)
        
    //     if(Array.isArray(message) && message.length >0){
            
    //         setDisableNext(false)
    //     }
    // }

    const renderPaginationButtons = () => {
        let items = []
        
        items.push(<Pagination.First className="pagination" disabled={currentPageNumber===1} key={`page-first`}  onClick={() => changePage('first')} title="first page"  style={{fontSize:20}}/>)
        items.push(<Pagination.Prev className="pagination" disabled={currentPageNumber===1} key={`page-previous`}  onClick={() => changePage('previous')} title="previous page"  style={{fontSize:20}}/>)

        items.push(<Pagination.Next className="pagination" disabled={disableNext}  key={`page-next`} onClick={() => changePage('next')} title="next page"  style={{fontSize:20}}/>)

        return items
    }

    const changePage =  (page) => {


        if(page === 'next'){
            renderNext()
            setCurrentPageNumber(currentPageNumber + 1)
            return
        }
        
        if(page === 'previous'){
            renderPrevious()
            setCurrentPageNumber(currentPageNumber - 1)
            return
        }

        if(page === 'first'){
            setPreviouses([]);
            setBookings([]);
            fetch();
            setCurrentPageNumber(1)
            return
        }
       
    }

    const renderNext = async () => {

        setIsLoading(true)

        const currentBooking = bookings.slice(-1)[0] 
        
        const previous = {
            'pageNumber': currentPageNumber,
            'items': bookings
        }

        const tempPreviouses = previouses
        tempPreviouses.push(previous)
        setPreviouses(tempPreviouses)

        const { data: { code, message } } =  await getTaxiBookingsByUserHotelId(PAGE_SIZE+1,currentBooking.booking_date, searchText)
        setDisableNext(true)
        if(code === 200) {
            if(Array.isArray(message) )
            {
                
                let sortedItems = message.sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date)).filter(booking => !booking.isCancelled)

                setBookings(sortedItems.slice(0, PAGE_SIZE))

                setDisableNext(sortedItems.length <= PAGE_SIZE)

                //checkNextPage(sortedItems)

            }
        }

        setIsLoading(false)
    }

    const renderPrevious = () => {

        const previousItem = previouses[currentPageNumber-2]
        setBookings(previousItem['items'])
        previouses.splice(currentPageNumber-2, 1)
        setCurrentPageNumber(currentPageNumber - 1 )
        setDisableNext(false)

    }

    const handleConfirmationModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleChangeBookingDateModal = (booking) => {
        setSelectedBooking(booking)
        setChangeBookingDateModal(true)
    }

    const handleCancelBooking = async () => {
        const {data: {code, message}} = await cancelTaxiBooking(selectedBooking._id)
        if (code == 200) {
            var _bookings = bookings
            var index = _bookings.indexOf(selectedBooking);
            _bookings.splice(index, 1);
            setBookings([..._bookings])
            toast.success('Successfully cancelled booking.', {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            toastError(`An error occured on cancelling taxi bookings. ${message}.`)
         }
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    const canChangeTime = (booking) => {
        // return (!isDateTimePassedCurrentTime(subMinutes(new Date(booking.booking_date), 30)))
        return !Moment(Moment().add(30, 'minutes').utc().format()).isSameOrAfter(Moment.utc(booking.booking_date))
    }

    const canCancel = (booking) => {
        const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(booking.booking_date));
        return !currentTimeHasPassedBookingTime && (!booking.hasOwnProperty('sale_info'))
    }

    const bookingDateUpdated = (newBookingDate) => {
        const bookingIndex = bookings.indexOf(selectedBooking)
        let _bookings = bookings
        _bookings[bookingIndex].booking_date = newBookingDate
        setBookings(_bookings)
    }

    const getRowClassName = (booking, index) => {

        var rowClassName = ''
        if(isDateTimePassedCurrentTime(booking.booking_date))
            rowClassName += 'booking-past '

        if(bookings[index+1] && (!isSameDay(new Date(bookings[index].booking_date), new Date(bookings[index+1].booking_date))))
            rowClassName += 'row-breakpoint '

        return rowClassName
    }

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            fetch()
        }
    }

    return <>
        {isLoading && <PageLoader />}
        {selectedBooking && 
        <ChangeTaxiBookingDateModal display={changeBookingDateModal} toggle={setChangeBookingDateModal} booking={selectedBooking} bookingDateUpdated={bookingDateUpdated} />
        }
        <ConfirmationModal 
            title="Cancel Booking"
            message="Are you sure you want to cancel this booking?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={handleCancelBooking} />
        <section>
            <Row className="mb-4">
                <Col>
                    <Card className='w-100 bookings-list'>
                        <Card.Header className='text-center bg-primary text-white'>Taxi Bookings</Card.Header>
                        <Card.Body>

                            <Row className="justify-content-center">
                                <Col lg="6">
                                    <InputGroup className="mb-3 rounded-right">
                                        <InputGroup.Text ><i className="fa fa-search" aria-hidden="true"></i></InputGroup.Text>
                                        <Form.Control value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search" onKeyPress={onKeyUp} placeholder="Search bookings" />
                                        <div className="input-group-append rounded-right">
                                            <Button className="rounded-right" onClick={() => fetch()}>search</Button>
                                        </div>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th></th>
                                        <th>Booked Date</th>
                                        <th>Guest Name</th>
                                        <th>Booked By</th>
                                        <th>Origin</th>
                                        <th>Payment</th>
                                        <th>Room #</th>
                                        <th>Seats</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookings.length > 0 ?
                                    <>
                                    {
                                    bookings.map((booking, index) =>
                                        <React.Fragment key={`bookingstaxi-${index}`}>
                                        <tr className={getRowClassName(booking, index)}>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>
                                                {   
                                                    <i style={{color:(booking.is_confirmed || booking.fulfilled ? 'Tomato': 'LightGray')}} 
                                                        className={(booking.fulfilled ? 'fas fa-check-circle': 'fas fa-check')} 
                                                        title={(booking.fulfilled ? 'Completed': (booking.is_confirmed ? 'Confirmed' : 'Not Confirmed'))}></i>
                                                }
                                            </LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{Moment(booking.booking_date).format('DD.MM.YYYY HH:mm')}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.first_name + " " + booking.customer.last_name}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.booking_source_username}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.origin}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.payment_info && booking.payment_info.paid_over_counter ? 'PAID OVER COUNTER': ''}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.customer.booking_source_number}</LinkTD>
                                            <LinkTD to={`/taxi-booking/${booking._id}`}>{booking.number_of_persons}</LinkTD>
                                            <td>
                                                {canChangeTime(booking) && isHotelAccount() && 
                                                <Button className="mr-2" variant="outline-primary" size="sm" onClick={() => handleChangeBookingDateModal(booking)}>Change Time</Button>
                                                }
                                                {canCancel(booking) &&
                                                <Button size="sm" onClick={() => handleConfirmationModal(booking)}>Cancel</Button>
                                                }
                                            </td>
                                        </tr>
                                        </React.Fragment>
                                    )
                                    }
                                    </>
                                    :
                                    <tr>
                                        <td>{!isLoading && <i>No bookings found.</i> }</td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>

                            <Pagination className="mb-0 justify-content-center override-pagination">

                                {renderPaginationButtons()}

                            </Pagination>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default HotelTaxiBookings