import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { getHotels } from '../../services/hotels';

const HotelsSelect = ({ hotels, onFetchSuccess = () => {}, onLoading = () => {}, label, initialOption, ...rest }) => {

    useEffect(() => {
        const fetchHotels = async () => {
            onLoading()
            const { data: { message } } = await getHotels()

            if (Array.isArray(message)) {
                onFetchSuccess(message);
            }
        }
        // if hotel is undefined or null it will fetch hotels
        !hotels && fetchHotels()
    }, [hotels])

    return (
        <Form.Group>
            <Form.Label><strong>{label}</strong></Form.Label>
            <Form.Control as='select' {...rest} >
                <option value="">{initialOption}</option>
                {hotels && hotels.map((hotel, index) => (
                    <option key={`hotel-${index}`} value={hotel._id}>{hotel.hotel_name}</option>
                ))}
            </Form.Control>
        </Form.Group>
    )
}

export default HotelsSelect