import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { getAvailableSeats, updateBookingDate } from '../../services/shuttleBookings'
import { toast } from 'react-toastify'

const ChangeBookingDateModal = ({ display, toggle, booking, destinations, bookingDateUpdated }) => {

    const [selectedTime, setSelectedTime] = useState(getTimeFromISODateTime(booking.booking_date))

    const targetDestination = destinations.find(x => x._id == booking.shuttle_destination_id)
    const times = targetDestination.timetable.map(x => x.time).sort((a, b) => parseInt((a).replace(':','')) - parseInt((b).replace(':','')))

    const saveBookingDate = async () => {

        const newDateTime = booking.booking_date.split('T')[0]+'T'+selectedTime+':00.000Z'
        const { data: { message : {total, available} } } = await getAvailableSeats(newDateTime, targetDestination._id);   
       
        if (available > 0) {

            const {data: {code, message}} = await updateBookingDate(booking._id, newDateTime)
            if (code !== 200) {
                toast.error(`An error occured while updating the booking date.`, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                return
            } else {
                bookingDateUpdated(newDateTime)
                toggle(false)
            }
        } else {
            toast.error(` No available seats!.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    function getTimeFromISODateTime(isoDateTime) {
        return isoDateTime.split('T')[1].substring(0,5)
    }
    
    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        size="sm"
        >
            <Modal.Body className="change-booking-time-modal">
                <Card.Title className="mb-3">Change Booking Time</Card.Title>
                <div className="change-booking-time">
                    <Form.Control as="select" className="mb-3" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                        {times.length > 0 && times.map((time, index) => (
                            <option key={`time-${index}`} value={time}>{time}</option>
                        ))}
                    </Form.Control>
                    <Button className="w-100" onClick={() => saveBookingDate()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default ChangeBookingDateModal

