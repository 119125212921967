import React, { useState } from 'react';
import ExcelReader from '../../../components/common/excelReader';
import { Button, Card, Table } from 'react-bootstrap';

const ImportAirCrewBooking = () => {

    const [dataToImport, setDataToImport] = useState([])
    const [columns, setColumns] = useState([])

    const handleReadExcel = (data) => {
        setColumns(Object.keys(data[0]))
        setDataToImport(data)
    }

    return (
        <div>
            <div className="mb-3">

            </div>
            <ExcelReader onDataRead={(data) => handleReadExcel(data)} />

            <div className="mb-5">
                <Card className='w-100 mb-4'>
                    <Card.Header className='text-center bg-primary text-white'>Excel Data Preview</Card.Header>
                    <Card.Body>
                        {columns.length > 0 && dataToImport.length > 0 ?
                            <Table responsive hover>
                                <thead className="border-top-0">
                                    <tr>
                                        {columns.length > 0 && columns.map((col, index) => (
                                            <th key={`import-${index}`}>{col}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataToImport.length > 0 && dataToImport.map((data, dataIndex) => (
                                        <tr key={`data-to-import-${dataIndex}`}>
                                            {columns.length > 0 && columns.map((col, colIndex) => (
                                                <td key={`import-${dataIndex}-${colIndex}`}>{data[col]}</td>
                                            ))}
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                            :
                            <p className="text-center"><i>no data.</i></p>
                        }
                    </Card.Body>
                </Card>
                <div className="text-right">
                    {columns.length > 0 && dataToImport.length > 0 &&
                        <Button className="px-4"><i className="fa fa-upload" aria-hidden="true"></i> Import</Button>
                    }
                </div>
            </div>

        </div >
    );
};

export default ImportAirCrewBooking;