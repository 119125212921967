import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCurrentUser } from '../../services/auth'
import { updateUser } from '../../services/users'

const ChangeUsernameModal = ({ display, toggle, user, usernameChanged, setIsLoading }) => {

    const [newUsername, setNewUsername] = useState()
    const [error, setError] = useState('')

    let _currentUser = getCurrentUser()

    const handleChangeUsername = async () => {

        setError('')

        const payload = {
            "old_username": user.username,
            "new_username": newUsername
        }

        setIsLoading(true)
        const {data: {code, message}} = await updateUser(user._id, payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            usernameChanged(payload.new_username)
            toggle(false)
        }
    }
    
    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body className="change-username-modal">
                <Card.Title className="mb-3">Change Username</Card.Title>
                <div className="change-username">
                    <Form.Group>
                        <Form.Label>New Username</Form.Label>
                        <Form.Control type='username' placeholder='Enter new username' onChange={e => setNewUsername(e.target.value)} />
                    </Form.Group>
                    {error && 
                    <p className="text-danger text-center"><small>{error}</small></p>
                    }
                    <Button className="w-100" onClick={() => handleChangeUsername()}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default ChangeUsernameModal

