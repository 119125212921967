import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { createCarCategory } from '../../services/carCategories'

const CreateCarCategoryModal = ({ display, toggle, categoryCreated }) => {

    const [carName, setCarName] = useState()
    const [seats, setSeats] = useState()

    const handleCreateCarCategory = async () => {

        const payload = {
            "name": carName,
            "seats": seats
        }

        const {data: {code, message}} = await createCarCategory(payload)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        } else {
            payload["_id"] = message._id
            payload["taxi_drivers"] = []
            categoryCreated(payload)
            toggle(false)
        }
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        centered
        >
            <Modal.Body>
                <Card.Title className="mb-2">
                    Create Car Category
                </Card.Title>    
                <Row>
                    <Col lg="8">
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" onChange={(e) => setCarName(e.target.value)} placeholder="Enter category name" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Seats</Form.Label>
                            <Form.Control type="number" onChange={(e) => setSeats(e.target.value)} placeholder="seats" />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleCreateCarCategory()}>Save</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default CreateCarCategoryModal

