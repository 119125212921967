import http from './http'
import { getToken } from './auth'

const base = 'taxiDriverCredits'

export async function addCredits(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/add`, payload, config)
}