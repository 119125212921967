import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/auth';
import SystemAdminTaxiBookings from './admin/bookingsTaxi';
import HotelTaxiBookings from './hotelUser/bookingsTaxi'

const BookingsTaxiPage = () => {

    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && (user.role_name === 'system-admin' || user.role_name === 'shuttle-admin') &&
            <SystemAdminTaxiBookings />
        }
        
        {
            user && (user.role_name === 'hotel-admin' || user.role_name === 'hotel-staff') &&
            <HotelTaxiBookings />
        }
    </>
}

export default BookingsTaxiPage