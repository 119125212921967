import React, { useState, useContext, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';
import SocketContext from '../../context/socket';
import { getCurrentUser } from '../../services/auth';
import { isSystemAdmin } from '../../utils/utils';
import NameWithRole from '../common/nameWithRole';

const SidebarAdmin = () => {

    const socket = useContext(SocketContext);

    const currentUser = getCurrentUser();

    useEffect(() => {

        if (currentUser && isSystemAdmin()) {
            subscribeToSocket();
        }

    }, [])

    const subscribeToSocket = () => {

        socket.on(`taxi-booking-availability-failed`, data => {

            toast.error(() =>
                <LinkContainer to={`/taxi-booking/${data._id}`}>
                    <div className="p-2">
                        <p>No Available Taxi Driver</p>
                        <hr style={{ color: 'white' }} />
                        <p>A taxi booking from {data.origin} for {data.customer.first_name} {data.customer.last_name} failed. Click here for more information.</p>
                    </div>
                </LinkContainer>
                , {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        });
    }

    const defaultStateClasses = {
        'shuttle': {
            'stateClass': ''
        },
        'taxi': {
            'stateClass': ''
        },
        'airline-crew': {
            'stateClass': ''
        },
    }

    const [subMenus, setSubMenus] = useState(defaultStateClasses)

    const handleMenuStateClasses = (subMenu) => {
        setSubMenus(defaultStateClasses)
        let _subMenus = subMenus
        _subMenus[subMenu]['stateClass'] = _subMenus[subMenu]['stateClass'] === 'active' ? '' : 'active'
        setSubMenus({ ..._subMenus })
    }

    return (
        <>
            <NameWithRole />
            <ul className='nav flex-column'>
                <LinkContainer to='/dashboard'>
                    <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
                </LinkContainer>
                <li>
                    <ul className={`sub-menu  ${subMenus['shuttle']['stateClass']}`}>
                        <a href="#" className="nav-link" onClick={() => handleMenuStateClasses('shuttle')}><i className="fas fa-bus mr-2"></i> Shuttle</a>
                        <div className="sub-menu-items">
                            <LinkContainer to='/vehicles'>
                                <li><a className='nav-link'><i className="fas fa-bus mr-2"></i> Vehicles</a></li>
                            </LinkContainer>
                            <LinkContainer to='/timetable'>
                                <li><a className='nav-link'><i className='fas fa-tachometer-alt mr-2'></i> Timetable</a></li>
                            </LinkContainer>
                            <LinkContainer to='/destinations'>
                                <li><a className='nav-link'><i className="fas fa-map-marker-alt mr-2"></i> Destinations</a></li>
                            </LinkContainer>
                            <LinkContainer to='/bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Bookings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/cancelled-bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Cancelled Bookings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/shuttle-invoices'>
                                <li><a className='nav-link'><i className="fas fa-receipt mr-2"></i> Invoices</a></li>
                            </LinkContainer>
                        </div>
                    </ul>
                </li>
                <li>
                    <ul className={`sub-menu  ${subMenus['taxi']['stateClass']}`}>
                        <a href="#" className="nav-link" onClick={() => handleMenuStateClasses('taxi')}><i className="fas fa-car mr-2"></i> Taxi</a>
                        <div className="sub-menu-items">
                            <LinkContainer to='/car-categories'>
                                <li><a className='nav-link'><i className="fas fa-car mr-2"></i> Car Categories</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-destinations'>
                                <li><a className='nav-link'><i className="fas fa-map-marker-alt mr-2"></i> Destinations</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Bookings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/cancelled-taxi-bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Cancelled Bookings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/unassigned-taxi-bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Unassigned Bookings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-invoices/establishment'>
                                <li><a className='nav-link'><i className="fas fa-receipt mr-2"></i> Establishment Invoices</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-invoices/driver'>
                                <li><a className='nav-link'><i className="fas fa-receipt mr-2"></i> Taxi Driver Invoices</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-companies'>
                                <li><a className='nav-link'><i className="fas fa-briefcase mr-2"></i> Companies</a></li>
                            </LinkContainer>
                            <LinkContainer to='/taxi-zones'>
                                <li><a className='nav-link'><i className="fas fa-road mr-2"></i> Zones</a></li>
                            </LinkContainer>
                        </div>
                    </ul>
                </li>
                <LinkContainer to='/accounts'>
                    <li><a className='nav-link'><i className="fas fa-user-friends mr-2"></i> Accounts</a></li>
                </LinkContainer>
                <LinkContainer to='/hotels'>
                    <li><a className='nav-link'><i className="fas fa-building mr-2"></i> Hotels</a></li>
                </LinkContainer>
                <LinkContainer to='/airlines'>
                    <li><a className='nav-link'><i className="fas fa-plane-departure mr-2"></i> Airlines</a></li>
                </LinkContainer>
                <li>
                    <ul className={`sub-menu  ${subMenus['airline-crew']['stateClass']}`}>
                        <a href="#" className="nav-link" onClick={() => handleMenuStateClasses('airline-crew')}><i className="fas fa-plane-departure mr-2"></i> Airline Crew</a>
                        <div className="sub-menu-items">
                            <LinkContainer to='/airline-crew-settings'>
                                <li><a className='nav-link'><i className="fas fa-cog mr-2"></i>Settings</a></li>
                            </LinkContainer>
                            <LinkContainer to='/airline-crew-invoices'>
                                <li><a className='nav-link'><i className="fas fa-receipt mr-2"></i>Invoices</a></li>
                            </LinkContainer>
                            <LinkContainer to='/airline-crew-bookings'>
                                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i>Bookings</a></li>
                            </LinkContainer>
                            {/* <LinkContainer to='/airline-crew-import'>
                                <li><a className='nav-link'><i className="fa fa-upload  mr-2"></i>Import</a></li>
                            </LinkContainer> */}
                        </div>
                    </ul>
                </li>

                <LinkContainer to='/location-tracking'>
                    <li><a className='nav-link'><i className="fas fa-map-marked-alt mr-2"></i>Location Tracking</a></li>
                </LinkContainer>
                <LinkContainer to='/sales'>
                    <li><a className='nav-link'><i className="fas fa-coins mr-2"></i> Sales</a></li>
                </LinkContainer>
                <LinkContainer to='/commissions'>
                    <li><a className='nav-link'><i className="fas fa-coins mr-2"></i> Payable Commissions</a></li>
                </LinkContainer>
                <LinkContainer to='/support-tickets'>
                    <li><a className='nav-link'><i className="fas fa-ticket-alt mr-2"></i> Support Tickets</a></li>
                </LinkContainer>
                <LinkContainer to='/releases'>
                    <li><a className='nav-link'><i className="fas fa-exclamation-circle mr-2"></i> Releases</a></li>
                </LinkContainer>
                <LinkContainer to='/system-issues'>
                    <li><a className='nav-link'><i className="fas fa-exclamation-triangle mr-2"></i> System Issues</a></li>
                </LinkContainer>
                <LinkContainer to='/contacts'>
                    <li><a className='nav-link'><i className="fas fa-envelope mr-2"></i> Contact Messages</a></li>
                </LinkContainer>
                {/* <LinkContainer to='/timetable'>
                    <li><a className='nav-link'><i className='fas fa-tachometer-alt text-muted mr-2'></i> Timetable</a></li>
                </LinkContainer>
                <LinkContainer to='/sales'>
                    <li><a className='nav-link'><i className='fas fa-tachometer-alt text-muted mr-2'></i> Sales</a></li>
                </LinkContainer>
                <LinkContainer to='/invoice'>
                    <li><a className='nav-link'><i className='fas fa-tachometer-alt text-muted mr-2'></i> Invoice</a></li>
                </LinkContainer> */}
            </ul>
        </>
    );
}


export default SidebarAdmin;
