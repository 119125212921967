import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';

const ExcelReader = ({ onDataRead }) => {
    const [excelData, setExcelData] = useState([]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const headers = parsedData[0];
            const rowData = parsedData.slice(1);

            const formattedData = rowData.map((row) =>
                headers.reduce((obj, header, index) => {
                    obj[header] = row[index];
                    return obj;
                }, {})
            );

            onDataRead(formattedData)
            setExcelData(formattedData);
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>Upload Excel File</Form.Label>
                <div>
                    <input type="file" onChange={handleFileUpload} />
                </div>
            </Form.Group>

            {/* <pre>{JSON.stringify(excelData, null, 2)}</pre> */}
        </div>
    );
};

export default ExcelReader;