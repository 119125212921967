import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import NameWithRole from '../common/nameWithRole';
import { useState } from 'react';
import { useEffect } from 'react';
import { getUnconfirmedBookingsCount } from '../../services/shuttleBookings'

const SidebarHotelAdmin = ({ hotel }) => {

    const [unconfirmedBookingsCount, setUnconfirmedBookingsCount] = useState(0)

    useEffect(() => {
        fetchUnconfirmedHotelBookingsCount()
    }, [])

    const fetchUnconfirmedHotelBookingsCount = async () => {
        const { data: { code, message } } = await getUnconfirmedBookingsCount();
        if (code === 200) setUnconfirmedBookingsCount(message)
    }

    return (
        <>
            {hotel && hotel.hasOwnProperty('hotel_logo') ?
                <div className="mx-3">
                    <img id="hotel-logo" src={hotel.hotel_logo} className="w-100" alt="hotel-logo" />
                </div>
                :
                <h1 className="px-2 text-center">{hotel?.hotel_name}</h1>
            }
            <NameWithRole />
            <ul className='nav flex-column'>
                <LinkContainer to='/dashboard'>
                    <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
                </LinkContainer>
                {/* <LinkContainer to='/timetable'>
                <li><a className='nav-link'><i className="far fa-calendar-alt mr-2"></i> Book A Shuttle</a></li>
            </LinkContainer>
            <LinkContainer to='/book-taxi'>
                <li><a className='nav-link'><i className="fas fa-car-alt mr-2"></i> Book A Taxi</a></li>
            </LinkContainer> */}
                <LinkContainer to='/accounts'>
                    <li><a className='nav-link'><i className="fas fa-user-friends mr-2"></i> Accounts</a></li>
                </LinkContainer>
                <LinkContainer to='/bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Shuttle Bookings</a></li>
                </LinkContainer>
                {
                    unconfirmedBookingsCount > 0 ?
                        <LinkContainer to='/unconfirmed-bookings'>
                            <li><a className='nav-link'><span className="badge badge-danger">{unconfirmedBookingsCount}</span> Unconfirmed Shuttle Bookings</a></li>
                        </LinkContainer>
                        :
                        <LinkContainer to='/unconfirmed-bookings'>
                            <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Unconfirmed Shuttle Bookings</a></li>
                        </LinkContainer>
                }
                <LinkContainer to='/shuttle-invoices'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Shuttle Invoices</a></li>
                </LinkContainer>
                <LinkContainer to='/taxi-bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Taxi Bookings</a></li>
                </LinkContainer>
                <LinkContainer to='/taxi-invoices/driver'>
                    <li><a className='nav-link'><i className="fas fa-receipt mr-2"></i> Taxi Invoices</a></li>
                </LinkContainer>
                {/* <LinkContainer to='/unassigned-taxi-bookings'>
                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Unassigned Bookings</a></li>
            </LinkContainer> */}
                {/* <LinkContainer to='/commissions'>
                    <li><a className='nav-link'><i className="fas fa-coins mr-2"></i> Commissions</a></li>
                </LinkContainer> */}
            </ul>
        </>
    );
}


export default SidebarHotelAdmin;
