import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Row, Col } from 'react-bootstrap'
import { getHotels } from '../../services/hotels'

const HotelAdminCreator = ({createHotelAdmin}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [hotels, setHotels] = useState([])
    const [hotelId, setHotelId] = useState('')
    const [hotelName, setHotelName] = useState('')
    
    useEffect(() => {
        fetchHotels()
    }, [])

    const handleAccountCreationSubmit = () => {

        createHotelAdmin({ username: username, password: password, hotel_id: hotelId }, hotelName, password)
        document.getElementById('hotel-admin-creator').reset();
    }

    const fetchHotels = async () => {
        const { data: { message } } = await getHotels()
        
        if(Array.isArray(message))
            setHotels(message)
        else {
            setHotels([])
        }
    }

    const handleSelectHotel = (hotelId) => {

        setHotelId(hotelId)

        const _hotelName = hotels.find((hotel => hotel._id == hotelId)).hotel_name
        setHotelName(_hotelName)        
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Hotel Admin Account</Card.Header>
            <Card.Body>
                <Form id="hotel-admin-creator">
                    <Row className="mb-3">
                        <Col lg="4">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Hotel</Form.Label>
                                <Form.Control as="select" defaultValue={hotelId} onChange={(e) => handleSelectHotel(e.target.value)}>
                                    <option value="" disabled>Select Hotel</option>
                                    {hotels && hotels.map((hotel, index) => (
                                        <option key={`hotel-${index}`} value={hotel._id} >
                                            {hotel.hotel_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center">
                    <Button variant='primary' onClick={() => handleAccountCreationSubmit()}>Create</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}

export default HotelAdminCreator