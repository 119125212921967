import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createAirlineAdmin } from '../../services/systemAdmin'
import { getAirlineAdmins } from '../../services/users'
import AirlineAdminList from './airlineAdminList'
import AirlineAdminCreator from './airlineAdmineCreator'

const AirlineAdminCreation = () => {

    let history = useHistory()

    const [airlineAdmins, setAirlineAdmins] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsTableLoading(true)
        const { data: { message } } = await getAirlineAdmins()
    
        setIsTableLoading(false)
        if(Array.isArray(message))
            setAirlineAdmins(message)
        else {
            setAirlineAdmins([])
            //TODO: toast when getting airline admins error
        }
    }

    const handleCreateAirlineAdmin = async (payload, airlineName, password) => {
        setIsLoading(true)
        const {data :{code,message}} = await createAirlineAdmin(payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newAirlineAdmin = {
                '_id': message['airline-account-info']._id,
                'airline_name': airlineName,
                'username': message['user-info'].username,
                'password': password
            }

            let _airlineAdmins = airlineAdmins
            _airlineAdmins.push(_newAirlineAdmin)
            setAirlineAdmins([..._airlineAdmins])
        }
    }

    return (
        <>
            <Row className="mb-4">
                <Col className="text-left">
                    <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/accounts')}>{'<'} Back</Button>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="8" >
                    <AirlineAdminCreator createAirlineAdmin={handleCreateAirlineAdmin} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <AirlineAdminList airlineAdmins={airlineAdmins} setAirlineAdmins={setAirlineAdmins} 
                        isTableLoading={isTableLoading}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading} />
                </Col>
            </Row>
        </>
    )
}

export default AirlineAdminCreation