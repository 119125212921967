import http from './http'
import { getToken } from './auth'

const base = 'taxiBookings'

export async function bookTaxi(payload) {

    return await http.post(`${base}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function getAllTaxiBookings(searchText) {

    let url = `${base}/all?excludeCancelled=true`
    if (searchText)
        url += `&searchText=${searchText}`

    return await http.get(url, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getNextTaxiBookings(pageSize, bookingDate, searchText) {

    let url = `${base}/all?excludeCancelled=true`
    if (pageSize)
        url += `&pageSize=${pageSize}`
    if (bookingDate)
        url += `&bookingDate=${bookingDate}`
    if (searchText)
        url += `&searchText=${searchText}`

    return await http.get(url, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getInitialTaxiBookings(pageSize, searchText) {

    let url = `${base}/all?excludeCancelled=true`
    if (pageSize)
        url += `&pageSize=${pageSize}`
    if (searchText)
        url += `&searchText=${searchText}`

    return await http.get(url, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getTaxiBookingsToday() {

    const startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date()
    endDate.setHours(23, 59, 59, 999)

    return await http.get(`${base}/filter?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getUnassignedTaxiBookings() {

    return await http.get(`${base}/unassigned`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getTaxiBooking(id) {

    return await http.get(`${base}/by-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getTaxiBookingsByUserHotelId(pageSize, bookingDate, searchText) {

    let url = `${base}/by-user-hotel-id?excludeCancelled=true`
    if (pageSize)
        url += `&pageSize=${pageSize}`
    if (bookingDate)
        url += `&bookingDate=${bookingDate}`
    if (searchText)
        url += `&searchText=${searchText}`

    return await http.get(url, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function printTaxiTicket(id) {

    return await http.patch(`${base}/print-ticket/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


export async function cancelTaxiBooking(id) {

    return await http.patch(`${base}/cancel/${id}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateTaxiBookingDateTime(id, isoDateTime) {

    return await http.patch(`${base}/update-booking-date/${id}/${isoDateTime}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function updateTaxiDriver(bookingId, driverId) {

    return await http.patch(`${base}/assign-driver/${bookingId}/driver/${driverId}`, {}, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getCancelledTaxiBookings() {
    return await http.get(`${base}/cancelled`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}