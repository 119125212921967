import http from './http'
import { getToken } from './auth'

const base = 'releases'

export async function getReleases() {

    return await http.get(`${base}/`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function createRelease(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}