import React, { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { deleteUser } from '../../services/users'
import ConfirmationModal from '../modals/confirmation'
import UpdateAccountModal from '../modals/updateAccount'
import { toast } from 'react-toastify'
import PageLoader from '../common/pageLoader'
import { shuttleAccountsCrewBookingsAllowed } from '../../services/accounts'

const ShuttleDriverList = ({ shuttleDrivers, setShuttleDrivers, isTableLoading, isLoading, setIsLoading }) => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [user, setUser] = useState()

    const handleRemoveUser = async (id) => {
        setIsLoading(true)
        const { data: { code, message } } = await deleteUser(id)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(`An error occured. Unable delete this user. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else {
            userRemoved()
        }
    }

    const accountUpdated = (account) => {
        const userIndex = shuttleDrivers.indexOf(user)
        let _shuttleDrivers = shuttleDrivers
        if (account.new_password) _shuttleDrivers[userIndex].password = account.new_password
        if (account.first_name) _shuttleDrivers[userIndex]['info'].first_name = account.first_name
        if (account.last_name) _shuttleDrivers[userIndex]['info'].last_name = account.last_name
        setShuttleDrivers(_shuttleDrivers)
    }

    const handleChangePasswordModal = async (user) => {
        setUser(user)
        setChangePasswordModal(true)
    }

    const userRemoved = () => {
        const userIndex = shuttleDrivers.indexOf(user)
        let _shuttleDrivers = shuttleDrivers
        _shuttleDrivers.splice(userIndex, 1)
        setShuttleDrivers([..._shuttleDrivers])
    }

    const handleConfirmationModal = (user) => {
        setUser(user)
        setConfirmationModal(true)
    }

    const handleAllowCrewBookings = async (shuttleDriver, index) => {

        const shuttleDriverId = shuttleDriver._id
        const isCrewBookingsAllowed = shuttleDriver?.info?.crewBookingsAllowed ? true : false

        const payload = {
            crewBookingsAllowed: !isCrewBookingsAllowed
        }

        performCrewBookingsLoader(index, true)
        const { data: { code, message } } = await shuttleAccountsCrewBookingsAllowed(shuttleDriverId, payload)
        performCrewBookingsLoader(index, false)

        if (code !== 200) {
            toast.error(`An error occured. '${message}'`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        else {
            let _shuttleDrivers = shuttleDrivers
            _shuttleDrivers[index].info.crewBookingsAllowed = !isCrewBookingsAllowed
            setShuttleDrivers([..._shuttleDrivers])
        }
    }

    const performCrewBookingsLoader = (index, value) => {
        let _shuttleDrivers = shuttleDrivers
        _shuttleDrivers[index].isAllowingCrewBookings = value
        setShuttleDrivers([..._shuttleDrivers])
    }

    return <>

        {isLoading && <PageLoader />}

        {user &&
            <UpdateAccountModal display={changePasswordModal} toggle={setChangePasswordModal} user={user} accountUpdated={accountUpdated} setIsLoading={setIsLoading} />
        }

        <ConfirmationModal
            title="Remove Shuttle Driver"
            message="Are you sure you want to delete this user?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleRemoveUser(user._id)} />

        <Card className='w-100'  >
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Shuttle Drivers</Card.Header>
            <Card.Body>
                {!isTableLoading ?
                    <Table striped bordered hover variant='light'>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Password</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Crew Bookings Allowed</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                shuttleDrivers.length > 0 &&
                                shuttleDrivers.map((shuttleDriver, index) =>
                                    <tr key={`hotelstaff-${index}`}>
                                        <td>{shuttleDriver.username}</td>
                                        <td>{shuttleDriver.password}</td>
                                        <td>{shuttleDriver?.info?.first_name}</td>
                                        <td>{shuttleDriver?.info?.last_name}</td>
                                        <td>
                                            {shuttleDriver.isAllowingCrewBookings ?
                                                <i className="fa fa-spinner fa-spin text-muted" aria-hidden="true"></i>
                                                :
                                                <i className={`fa fa-check ${shuttleDriver?.info?.crewBookingsAllowed ? 'color-primary' : 'text-muted'}`} title={`${!shuttleDriver?.info?.crewBookingsAllowed ? 'not' : ''} allowed`} onClick={() => handleAllowCrewBookings(shuttleDriver, index)}></i>
                                            }

                                        </td>
                                        <td className="actions text-center">
                                            <i title="update account" className="fas fa-user-edit mr-2" onClick={() => handleChangePasswordModal(shuttleDriver)}></i>
                                            <i title="delete user" className="fas fa-trash-alt" onClick={() => handleConfirmationModal(shuttleDriver)}></i>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    :
                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                }
            </Card.Body>
        </Card>
    </>
}


export default ShuttleDriverList
