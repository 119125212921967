import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ConfirmationModal from '../components/modals/confirmation';
import EditDestinationModal from '../components/modals/editDestination';
import { deleteShuttleDestinations, getAllDestinations } from '../services/shuttleDestinations';

const Destinations = () => {

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [editDestinationModal, setEditDestinationModal] = useState(false)

    const [destinations, setDestinations] = useState([])
    const [selectedDestination, setSelectedDestination] = useState()

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {

        const { data: { code, message } } =  await getAllDestinations()
        if (code == 200) {
            setDestinations(message)
        }
    }

    const handleDeleteDestination = async () => {
        const { data: { code, message } } = await deleteShuttleDestinations(selectedDestination._id)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _destinations = destinations
            const index = _destinations.indexOf(selectedDestination)
            _destinations.splice(index, 1)
            setDestinations([..._destinations])
        }
    }

    const handleConfirmationModal = (destination) => {
        setSelectedDestination(destination)
        setConfirmationModal(true)
    }

    return ( 
        <>
        <ConfirmationModal
            title="Delete Shuttle Destination"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleDeleteDestination()} />

        {selectedDestination &&
        <EditDestinationModal display={editDestinationModal} toggle={setEditDestinationModal} destination={selectedDestination} />
        }
        <section>
            <Col>
                <Row>
                    {destinations.length > 0 &&
                    destinations.map((destination, index) => (
                        <Col key={`destination-${index}`} md="6" lg="4" className="mb-4">
                            {/* <Card>
                                <Card.Body>
                                    <h4>{destination.is_common_origin ? 'Multiple Establishments' : destination.destination}</h4>
                                    <hr />
                                    <p className="mb-0"><strong>Origin:</strong> {destination.origin}</p>
                                </Card.Body>
                            </Card> */}
                            <Card className="h-100">
                                <Card.Body className="text-center">

                                <div className="border border-primary p-2" style={{borderRadius:'10px'}}>
                                    <span style={{whiteSpace: 'nowrap'}}>
                                        <i className="mr-2 fas fa-plane-departure color-primary"></i>
                                        <span>{destination.origin}</span>
                                    </span>
                                        <i className="mx-2 fas fa-arrow-right color-primary"></i>
                                    <span style={{whiteSpace: 'nowrap'}}>
                                        <span>{destination.is_common_origin ? 'Multiple Establishments' : destination.destination}</span>
                                        <i className="ml-2 fas fa-hotel color-primary"></i>
                                    </span>
                                </div>
                                    
                                {/* <h4>{destination.is_common_origin ? 'Multiple Establishments' : destination.destination}</h4>
                                <hr />
                                <p className="mb-0"><strong>Origin:</strong> {destination.origin}</p> */}
                                    
                                </Card.Body>
                                <Card.Footer className="text-right">
                                    <Button size="sm" variant="outline-primary" onClick={() => handleConfirmationModal(destination)}>Delete</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
                
            </Col>
        </section>
        </>
     );
}
 
export default Destinations;