import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getSupportTicketById, markTicketAsOpen, markTicketAsSolved } from '../../services/contactSupport';

const SupportTicketPage = (props) => {

    const [ticket, setTicket] = useState()

    const { _id } = props.match.params

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getSupportTicketById(_id)
        if (code == 200) {
            setTicket(message)
            if (message.status !== 'closed')
                handleOpenTicket()
        }
    }

    const handleOpenTicket = async () => {
        const { data: { code, message } } = await markTicketAsOpen(_id)
        if (code == 200) {
            setTicket({...message})
        }
    }

    const handleSolveTicket = async () => {
        const { data: { code, message } } = await markTicketAsSolved(_id)
        if (code == 200) {
            setTicket({...message})
        }
    }

    const getHeaderClass = () => {
        if(ticket.status === 'new')
            return 'bg-success'
        else if (ticket.status === 'open')
            return 'bg-primary'
        else
            return 'bg-darkgrey'
    }

    return <>
        <section>
            <Row>
                <Col>
                    <Row>
                        <Col lg="10">
                           
                           {ticket && 
                            <Card>
                                <Card.Header className={`${getHeaderClass()} text-white`}>
                                    <span className="text-uppercase font-weight-bold">{ticket.status}</span>
                                    {ticket.status !==  'closed' && 
                                    <Button size="sm" variant="outline-light" className="float-right" onClick={() => handleSolveTicket()}>Mark as Solved</Button>
                                    }
                                </Card.Header>
                                <Card.Body>
                                    <h3 className="mb-0">{ticket.topic}</h3>
                                    <p><small>{ticket.email}</small></p>
                                    <p>{ticket.message}</p>
                                </Card.Body>
                            </Card>
                            }
                               
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    </>
}

export default SupportTicketPage