import React, { useState } from 'react';
import { Button, Card, Modal, Row, Col, Form } from 'react-bootstrap';

const EditOperatingCitiesModal = ({display, toggle, operatingCities, setOperatingCities, setCitiesUpdated}) => {

    const [cities, setCities] = useState([])

    const removeInput = (index) => {
        let _cities = operatingCities
        _cities.splice(index, 1)
        setCities([..._cities])
    }

    const addInput = () => {
        let _cities = operatingCities
        _cities.push('')
        setCities([..._cities])
    }

    const setCity = (city, index) => {
        let _cities = operatingCities
        _cities[index] = city 
        setCities([..._cities])
    }

    const saveOperatingCities = () => {
        let _cities = cities
        var cleanCities = _cities.filter(function (x) {
            return (x !== null && x !== '');
        });
        setOperatingCities([...cleanCities])
        setCitiesUpdated && setCitiesUpdated(true)
        toggle(false)
    }

    return ( 
        <>
        <Modal
        show={display}
        onHide={() => toggle(false)}
        backdropClassName="level-2"
        centered
        >
            
            <Modal.Body>
                <Card.Title className="mb-2">
                    Operating Cities
                </Card.Title>     

                <div className="cities mb-3">
                {operatingCities && operatingCities.map((city, index) => (
                    <Row key={`city-${index}`} className="mb-2">
                        <Col>
                            <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value, index)} placeholder="Enter a city name" />
                        </Col>
                        <Col xs="2">
                            <div className="center-xy">
                                <span onClick={() => removeInput(index)} title="remove" className="cursor-pointer"><i className="fas fa-times"></i></span>
                            </div>
                        </Col>
                    </Row>
                ))}
                </div>
                <Row>
                    <Col>
                        <Button className="px-3" size="sm" onClick={() => addInput()}>Add</Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => saveOperatingCities()}>Ok</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
 
export default EditOperatingCitiesModal;