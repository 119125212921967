import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createShuttleAdmin } from '../../services/systemAdmin'
import { getShuttleAdmins } from '../../services/users'
import ShuttleAdminCreator from './shuttleAdminCreator'
import ShuttleAdminList from './shuttleAdminList'

const ShuttleAdminCreation = () => {

    let history = useHistory()

    const [shuttleAdmins, setShuttleAdmins] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        setIsTableLoading(true)
        const { data: { message } } = await getShuttleAdmins()
        setIsTableLoading(false)
        if(Array.isArray(message))
            setShuttleAdmins(message)
        else {
            setShuttleAdmins([])
            //TODO: toast when getting shuttle admins error
        }
    }

    const handleCreateShuttleAdmin = async (payload, password) => {
        setIsLoading(true)
        const {data :{code,message}} = await createShuttleAdmin(payload)
        setIsLoading(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            const _newShuttleAdmin = {
                '_id': message['shuttle-account-info']._id,
                'username': message['user-info'].username,
                'password': password,
                'info' :{
                    first_name: message['shuttle-account-info'].first_name,
                    last_name: message['shuttle-account-info'].last_name
                }
            }

            let _shuttleAdmins = shuttleAdmins
            _shuttleAdmins.push(_newShuttleAdmin)
            setShuttleAdmins([..._shuttleAdmins])
        }
    }

    return (
        <>
        <Row className="mb-4">
            <Col className="text-left">
                <Button size="sm" className="px-3" variant="outline-dark" onClick={() => history.push('/accounts')}>{'<'} Back</Button>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col lg="8" >
                <ShuttleAdminCreator createShuttleAdmin={handleCreateShuttleAdmin} />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <ShuttleAdminList shuttleAdmins={shuttleAdmins} setShuttleAdmins={setShuttleAdmins} 
                    isTableLoading={isTableLoading}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}  />
            </Col>
        </Row>
        </>
    )
}

export default ShuttleAdminCreation