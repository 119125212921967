import jwtDecode from 'jwt-decode'
import http from './http'

const base = 'auth'

export async function login(account) {
  return await http.post(base, account)
}

export const logout = () => {
  localStorage.removeItem('simul-token')
}

export function getCurrentUser() {
  try {
    const token = localStorage.getItem('simul-token')
    return jwtDecode(token)
  } catch (error) {
    return null
  }
}

export function getToken() {
  return localStorage.getItem('simul-token')
}