import { differenceInMinutes, format } from "date-fns"
import { getCurrentUser } from "../services/auth"
import Moment from 'moment';

export function getDestinationName(booking, destinations) {

    if(booking.shuttle_destination && booking.shuttle_destination.is_common_origin &&
        booking.dest_place)
        return booking.dest_place

    if(booking.shuttle_destination && booking.shuttle_destination.is_common_origin)
        return booking.booking_source_name

    const _destination = destinations.find(x => x._id == booking.shuttle_destination_id)
    if(!_destination) return ''

    return _destination.destination
}

export function getTwelveHourTime(time) {

    var _time = {
        'hourMinute': '',
        'amPm': ''
    }

    var hour = parseInt(time.split(':')[0])
    var minute = time.split(':')[1]
    if (hour > 11) {
        hour = hour - 12
        if (hour == 0)
            hour = hour + 12
        _time.hourMinute = `${hour}:${minute}`
        _time.amPm = 'PM'
    } else {
        if (hour == 0)
            hour = hour + 12
        _time.hourMinute = `${hour}:${minute}`
        _time.amPm = 'AM'
    }

    return _time
}

export function removeFromArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function isSystemAdmin() {
    const user = getCurrentUser()
    return user?.role_name === 'system-admin'
}

export function isSimulAccount() {
    const user = getCurrentUser()
    return user?.role_name === 'system-admin' || user?.role_name === 'shuttle-admin'
}

export function isHotelAdmin() {
    const user = getCurrentUser()
    return user?.role_name === 'hotel-admin'
}

export function isHotelStaff() {
    const user = getCurrentUser()
    return user?.role_name === 'hotel-staff'
}

export function isHotelAccount() {
    const user = getCurrentUser()
    return user?.role_name === 'hotel-admin' || user?.role_name === 'hotel-staff'
}

export function isAirlineAdmin() {
    const user = getCurrentUser()
    return user?.role_name === 'airline-admin'
}

export function isAirlineAccount() {
    const user = getCurrentUser()
    return user?.role_name === 'airline-admin' || user?.role_name === 'airline-staff'
}

export function ignoreOffsetISOString(dateTime) {
    return new Date(
        dateTime.getTime() - dateTime.getTimezoneOffset() * 60000
    ).toISOString();
}

export function ignoreTimeZoneISOString(dateTime) {
    return dateTime.toISOString().slice(0, 19).replace('T', ' ');
}

export function toLocaleISOString(date) {
    function pad(n) { return ("0"+n).substr(-2); }

    var day = [date.getFullYear(), pad(date.getMonth()+1), pad(date.getDate())].join("-"),
        time = [date.getHours(), date.getMinutes(), date.getSeconds()].map(pad).join(":");
    if (date.getMilliseconds())
        time += "."+date.getMilliseconds();
    var o = date.getTimezoneOffset(),
        h = Math.floor(Math.abs(o)/60),
        m = Math.abs(o) % 60,
        o = o==0 ? "Z" : (o<0 ? "+" : "-") + pad(h) + ":" + pad(m);
    return day+"T"+time+o;
}

export function combineDateAndTime(date, time) {

    var timeString = time;

    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);

    return combined;
};

//support for all browsers including Safari
export function combineDateAndTimeV2(date, time) {

    var timeSplits = time.split(':');

    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var combined = new Date(year, month, day, timeSplits[0], timeSplits[1], '00');

    return combined;
};

export function isDateTimePassedCurrentTime(dateTime) {

    var dateTimeCompare = dateTime
    if (typeof dateTime === 'string' || dateTime instanceof String)
        dateTimeCompare = new Date(dateTime)

    const currentTimeHasPassedBookingTime = Moment(Moment().utc().format()).isSameOrAfter(Moment.utc(dateTimeCompare));
    return currentTimeHasPassedBookingTime;
}

export function isDateToday(date) {

    const dateToday = ignoreOffsetISOString(new Date()).split('T')[0]
    const dateGiven = date.split('T')[0]
    return dateToday == dateGiven
}

export function isDateTimePassedMidnight(dateTime) {

    var dateTimeCompare = dateTime
    if (typeof dateTime === 'string' || dateTime instanceof String)
        dateTimeCompare = new Date(dateTime)

    var dateTimeMidnight = combineDateAndTime(dateTimeCompare, '23:59')
    var dateTimeNow = new Date()

    const minutesDifference = differenceInMinutes(dateTimeMidnight, dateTimeNow)
    return minutesDifference < 0 ? true : false
}

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export function sortDescendingDayAscendingTime(dateTime) {

    return dateTime.sort(function (x, y) {

        var dateTimeX = x.booking_date
        var dateTimeY = y.booking_date

        function compare(x, y) {
            return x > y || - (x < y);
        }
    
        return compare(dateTimeY.slice(0, 10), dateTimeX.slice(0, 10))
            || compare(dateTimeX.slice(11, 19), dateTimeY.slice(11, 19));
    });
}

export function debounce(func, milliseconds = 1000) {

    let timer = null;
  
    return function (...args) {
      const onComplete = () => {
        func.apply(this, args);
        timer = null;
      }
  
      if (timer) {
        clearTimeout(timer);
      }
  
      timer = setTimeout(onComplete, milliseconds);
    };
}

export const toLocalDateString = (date, stringformat = 'dd.MM.yyyy HH:mm') => date && format(new Date(date), stringformat)

export const ignoreOffsetDateString = (date, format = 'DD.MM.YYYY HH:mm') => {
    const noOffsetISODate = ignoreTimeZoneISOString(new Date(date))
    return Moment(noOffsetISODate).format(format)
}

export const displayAddress = (address) => {
    let _address = []
    address.street && _address.push(address.street)
    address.city && _address.push(address.city)
    address.province && _address.push(address.province)
    address.zipCode && _address.push(address.zipCode)
    // address.country && _address.push(address.country)
  
    return _address.join(', ')
}

export function isNullOrEmpty(str) {
    return !str || str.trim() === '';
}
  