import http from './http'
import { getToken } from './auth'

const base = 'hotelAccounts'

export async function getHotelAccounts() {
    return await http.get(`${base}/all`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}