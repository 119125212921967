import React from 'react'
import { useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { updateSeatPrice } from '../../services/shuttleDestinations'
import { toast } from 'react-toastify'

const EditDestinationModal = ({ display, toggle, destination }) => {

    const [price, setPrice] = useState()

    const handleUpdateSeatPrice = async () => {

        const { data: { code, message } } =  await updateSeatPrice(destination._id, price)
        if (code == 200) {
            toggle(false)
        } else {
            toast.error(` Error: Unable to update.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
                <h3 className="text-center">{destination.destination}</h3>
                <hr />
                <Row className="mb-2">
                    <Col>
                        <div className="form-inline mb-1">
                            <strong className="mr-2">Price:</strong>
                            <Form.Control size="sm" style={{width:'60px'}} onChange={(e) => setPrice(e.target.value)} defaultValue={destination.seat_price} />
                        </div>
                    </Col>
                    <Col>
                        <p><strong>Currency:</strong> {destination.seat_price_currency}</p>
                    </Col>
                </Row>
                <Button className="w-100" onClick={() => handleUpdateSeatPrice()}>Save</Button>
            </Modal.Body>
        </Modal>
  )
}

export default EditDestinationModal

