import http from './http'
import { getToken } from './auth'

const base = 'taxiCompanies'

export async function createTaxiCompany(payload) {

    return await http.post(`${base}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    })
}

export async function getTaxiCompanies() {

    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}