import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { getDailyBookingSales, getMonthlyBookingSales, getWeeklyBookingSales } from '../../services/bookingSales';
import { getDailyTaxiBookingSales, getMonthlyTaxiBookingSales, getWeeklyTaxiBookingSales } from '../../services/taxiBookingFares';

const SalesOverview = () => {

    const [monthlyBookingSales, setMonthlyBookingSales] = useState("")
    const [weeklyBookingSales, setWeeklyBookingSales] = useState("")
    const [dailyBookingSales, setDailyBookingSales] = useState("")
    
    const [monthlySalesLoaded, setMonthlySalesLoaded] = useState(false);
    const [weeklySalesLoaded, setWeeklySalesLoaded] = useState(false);
    const [dailySalesLoaded, setDailySalesLoaded] = useState(false);

    const history = useHistory()

    useEffect(() => {
        fetchMonthlyBookingSales()
        fetchWeeklyBookingSales()
        fetchDailyBookingSales()
    }, []) //TODO: palihug replace ang "" to [] if naa moy makit-an.

    const fetchMonthlyBookingSales = async () => {
        const [{data: data1}, {data: data2}] = await Promise.all([
            getMonthlyBookingSales(),
            getMonthlyTaxiBookingSales(),
        ])
        
        if (data1.code === 200 && data2.code === 200) {
            setMonthlyBookingSales(data1.message + data2.message);
            setMonthlySalesLoaded(true);
        }
    }

    const fetchWeeklyBookingSales = async () => {
        const [{data: data1}, {data: data2}] = await Promise.all([
            getWeeklyBookingSales(),
            getWeeklyTaxiBookingSales(),
        ])
        
        if (data1.code == 200 && data2.code === 200) {
            setWeeklyBookingSales(data1.message + data2.message);
            setWeeklySalesLoaded(true);
        } 
    }

    const fetchDailyBookingSales = async () => {
        const [{data: data1}, {data: data2}] = await Promise.all([
            getDailyBookingSales(),
            getDailyTaxiBookingSales(),
        ])

        if (data1.code == 200 && data2.code === 200) {
            setDailyBookingSales(data1.message + data2.message);
            setDailySalesLoaded(true);
        }
    }

    const getCurrentMonth = () => {
        return new Date().toLocaleString('default', { month: 'long' })
    }

    function getWeekNumber(d) {
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return [weekNo];
    }

    return ( 
        <Card className="cursor-pointer" onClick={() => history.push('/sales')}>
            <Card.Body>
                <Card.Title className="mb-3">Total Sales</Card.Title>
                <Row>
                    <Col md="6" lg="4">
                    {
                        monthlySalesLoaded &&
                        <div className="total-sales-content text-center">
                            <h1 className="color-primary"><strong>{monthlyBookingSales?.toLocaleString('en-US')} CHF</strong></h1>
                            <h5>{getCurrentMonth()}</h5>
                        </div>
                    }
                    </Col>
                    <Col md="6" lg="4">
                    {
                        weeklySalesLoaded &&
                        <div className="total-sales-content text-center">
                            <h1 className="color-primary"><strong>{weeklyBookingSales?.toLocaleString('en-US')} CHF</strong></h1>
                            <h5>Week {getWeekNumber(new Date())}</h5>
                        </div>
                    }
                    </Col>
                    <Col md="6" lg="4">
                    {
                        dailySalesLoaded &&
                        <div className="total-sales-content text-center">
                            <h1 className="color-primary"><strong>{dailyBookingSales?.toLocaleString('en-US')} CHF</strong></h1>
                            <h5>Today</h5>
                        </div>
                    }  
                    </Col>
                </Row>          
            </Card.Body>
        </Card>
     );
}
 
export default SalesOverview;