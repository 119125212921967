import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import EnterSeparatedTextbox from '../../components/common/enterSeparatedTextbox';
import PageLoader from '../../components/common/pageLoader';
import ConfirmationModal from '../../components/modals/confirmation';
import Pages from '../../components/pages';
import { bookAirlineCrew, cancelAirlineCrewBooking, getPaginatedAirlineCrewBookings } from '../../services/airlineCrewBookings';
import { getAllAirlineCrewRoutePlaces } from '../../services/airlineCrewRoutePlaces';
import { getSeatOptions } from '../../services/airlineCrewSeatOptions';
import { combineDateAndTime, isDateTimePassedCurrentTime, isNullOrEmpty } from '../../utils/utils';
import EditAirlineCrewBookingModal from './editAirlineCrewBooking';

const BookAirlineCrewForm = ({ airCrew, setHasSelectedAirCrew }) => {

    const [seatOptions, setSeatOptions] = useState([])
    const [filteredSeatOptions, setFilteredSeatOptions] = useState([])
    const [routePlaces, setRoutePlaces] = useState([])

    const [confirmationModal, setConfirmationModal] = useState(false)
    const [editAirlineCrewBookingModal, setEditAirlineBookingCrewModal] = useState(false)

    const [isLoadingBookings, setIsLoadingBookings] = useState(false)
    const [isBookingAirCrew, setIsBookingAirCrew] = useState(false)

    const [booking, setBooking] = useState({})
    const [bookings, setBookings] = useState([])
    const [selectedBooking, setSelectedBooking] = useState()

    const [pagination, setPagination] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        fetchSeatOptions()
        fetchRoutePlaces()
        // fetchHotels()
    }, [])

    useEffect(() => {
        fetchAirlineCrewBookings()
    }, [pageNumber])

    const fetchSeatOptions = async () => {
        const { data: { message } } = await getSeatOptions()

        let _seatOptions = [...message]
        _seatOptions = _seatOptions.filter(x => x.airline_crew_id === airCrew.id)
        setSeatOptions(_seatOptions)
    }

    const fetchRoutePlaces = async () => {
        const { data: { message } } = await getAllAirlineCrewRoutePlaces()
        if (Array.isArray(message)) {
            setRoutePlaces(message)
        }
        else {
            setRoutePlaces([])
        }
    }

    const cancelBooking = async () => {
        const { data: { code, message } } = await cancelAirlineCrewBooking(selectedBooking.id)

        if (code == 200) {
            let _bookings = bookings
            const cancelBookingIndex = _bookings.findIndex(x => x.id === selectedBooking.id)
            _bookings.splice(cancelBookingIndex, 1)
            setBookings([..._bookings])
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const fetchAirlineCrewBookings = async () => {
        setIsLoadingBookings(true)
        const { data: { message } } = await getPaginatedAirlineCrewBookings(airCrew.id, pageNumber, 10, searchText)
        setIsLoadingBookings(false)
        setPagination(message)
        setBookings(message.bookings)
    }

    const handleChange = (objVal) => {
        let _booking = { ...booking, ...objVal }
        setBooking(_booking)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const originPlace = routePlaces.find(x => x.id === booking.origin_id) ?? null
        const destinationPlace = routePlaces.find(x => x.id === booking.destination_id) ?? null
        const seatOption = seatOptions.find(x => x.id === booking.seatId) ?? null

        if (seatOption === null) {
            toast.error(` No seat price matched.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            return
        }

        let _booking = {
            seat_option_id: seatOption.id,
            seat_name: seatOption.name,
            origin: originPlace.name,
            origin_place_type: originPlace.type,
            origin_place_id: originPlace.id,
            destination: destinationPlace.name,
            destination_place_type: destinationPlace.type,
            destination_place_id: destinationPlace.id,
            airline_crew_id: airCrew.id,
            //pickup_time: combineDateAndTime(booking.pickup_date, ignoreOffsetDateString(booking.pickup_time, 'HH:mm')),
            pickup_time: combineDateAndTime(booking.pickup_date, Moment(booking.pickup_time).format('HH:mm')),
            crew_names: booking.crew_names,
            number_of_persons: booking.number_of_persons,
            seat_price: booking.usePricePerHead ? booking.number_of_persons * seatOption.per_person_price : seatOption.price
        }

        if (booking.flight_arrival_time) {
            _booking['flight_arrival_date'] = combineDateAndTime(booking.pickup_date, Moment(booking.flight_arrival_time).format('HH:mm'))
        }
        if (booking.flight_arrival_number) {
            _booking['flight_arrival_number'] = booking.flight_arrival_number
        }
        if (originPlace.type === "hotel") {
            _booking['origin_hotel_id'] = originPlace.hotelId
        }
        if (destinationPlace.type === "hotel") {
            _booking['destination_hotel_id'] = destinationPlace.hotelId
        }

        setIsBookingAirCrew(true)
        const { data: { code, message } } = await bookAirlineCrew(_booking)
        setIsBookingAirCrew(false)

        if (code !== 200) {
            toast.error(`Error! ${message}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            setBooking({})
            fetchAirlineCrewBookings()
            toast.success(`Booking successful!`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const onKeyUp = (event) => {
        if (event.key === "Enter") {
            fetchAirlineCrewBookings()
        }
    }

    const handleEditAirCrewBookingModal = (booking) => {
        setSelectedBooking(booking)
        setEditAirlineBookingCrewModal(true)
    }

    const handleCancelAirCrewBookingModal = (booking) => {
        setSelectedBooking(booking)
        setConfirmationModal(true)
    }

    const handleChangeOrigin = (value) => {
       
        let _selectedOriginType = routePlaces.find(x => x.id === value).type

        let _seatOptions = seatOptions
        if (_selectedOriginType === "airport") {
            _seatOptions = _seatOptions.filter(x => x.applicable_route === 'airport-hotel' || isNullOrEmpty(x.applicable_route))
        }
        else if (_selectedOriginType === "hotel") {
            _seatOptions = _seatOptions.filter(x => x.applicable_route === 'hotel-airport' || isNullOrEmpty(x.applicable_route))
        }

        setFilteredSeatOptions([..._seatOptions])

        let _change = {
            origin_id: value,
            number_of_persons: ''
        }
        if (_seatOptions.length === 0)
            _change.seatId = ''

        handleChange(_change)
    }

    const handleSetNumberOfPersons = (value) => {
        const taxiSeatOptionId = filteredSeatOptions.find(x => x.vehicle_type === "taxi")?.id ?? ''
        const shuttleSeatOptionId = filteredSeatOptions.find(x => x.vehicle_type === "shuttle")?.id ?? ''
        let _change = {
            number_of_persons: value,
            seatId: filteredSeatOptions.length > 0 ? (value < 5 && taxiSeatOptionId ? taxiSeatOptionId : shuttleSeatOptionId) : ''
        }
        handleChange(_change)
    }

    return (
        <div>
            <ConfirmationModal
                title="Cancel Booking"
                message="Are you sure you want to cancel this booking?"
                display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => cancelBooking()} />

            <EditAirlineCrewBookingModal display={editAirlineCrewBookingModal} toggle={setEditAirlineBookingCrewModal} selectedBooking={selectedBooking} onSuccess={fetchAirlineCrewBookings} />

            <p><strong>Book Airline Crew</strong></p>

            <hr />

            <Row>
                <Col lg="5">
                    <Form onSubmit={handleSubmit} >

                        <div className="mb-4">
                            <p><strong>Route</strong></p>
                            <Form.Group>
                                <Form.Label>Origin</Form.Label>
                                <FormControl required value={booking?.origin_id ?? ''} as="select" onChange={(e) => handleChangeOrigin(e.target.value)}>
                                    <option value="" disabled>Select Origin</option>
                                    {routePlaces.length > 0 && routePlaces.map((routePlace) => (
                                        <React.Fragment key={`routeplace-origin-${routePlace.id}`}>
                                            {routePlace.id !== booking?.destination_id &&
                                                <option value={routePlace.id}>{routePlace.name}</option>
                                            }
                                        </React.Fragment>
                                    ))}
                                </FormControl>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Destination</Form.Label>
                                <FormControl required value={booking?.destination_id ?? ''} as="select" onChange={(e) => handleChange({ destination_id: e.target.value })}>
                                    <option value="" disabled>Select Destination</option>
                                    {routePlaces.length > 0 && routePlaces.map((routePlace) => (
                                        <React.Fragment key={`routeplace-destination-${routePlace.id}`}>
                                            {routePlace.id !== booking?.origin_id &&
                                                <option value={routePlace.id}>{routePlace.name}</option>
                                            }
                                        </React.Fragment>
                                    ))}
                                </FormControl>
                            </Form.Group>
                        </div>

                        <table className="w-100 mb-3">
                            <tbody>
                                <tr>
                                    <td className="p-2" style={{ width: '150px' }}>Pickup Time</td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2 react-datepicker-flex1">
                                                <DatePicker required
                                                    className="form-control"
                                                    selected={booking.pickup_date}
                                                    onChange={date => handleChange({ pickup_date: date })}
                                                    dateFormat="dd.MM.yyyy"
                                                />
                                            </div>
                                            <div className="react-datepicker-flex1">
                                                <DatePicker required
                                                    className="form-control"
                                                    selected={booking.pickup_time}
                                                    onChange={date => handleChange({ pickup_time: date })}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    timeFormat="HH:mm"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="p-2" style={{ width: '160px' }}>
                                        Flug-Nr/Arrival Time&nbsp;
                                        {routePlaces.find(x => x.id === booking.origin_id)?.type === "airport" && <span className="text-danger">*</span>}
                                    </td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2 react-datepicker-flex1">
                                                <FormControl
                                                    value={booking?.flight_arrival_number ?? ''}
                                                    onChange={(e) => handleChange({ flight_arrival_number: e.target.value })}
                                                    required={routePlaces.find(x => x.id === booking.origin_id)?.type === "airport"}
                                                />
                                            </div>
                                            <div className="react-datepicker-flex1">
                                                <DatePicker required={routePlaces.find(x => x.id === booking.origin_id)?.type === "airport"}
                                                    className="form-control"
                                                    selected={booking.flight_arrival_time}
                                                    onChange={date => handleChange({ flight_arrival_time: date })}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    timeFormat="HH:mm"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2">Number of Seats</td>
                                    <td className="p-2">
                                        <div className="d-flex">
                                            <div className="mr-2">
                                                <FormControl
                                                    value={booking?.number_of_persons ?? ''}
                                                    onChange={(e) => handleSetNumberOfPersons(e.target.value)}
                                                    type="number" style={{maxWidth:'70px'}}
                                                />
                                            </div>
                                            <div className="mr-2 react-datepicker-flex1">
                                                <FormControl disabled required value={booking?.seatId ?? ''} as="select" onChange={(e) => handleChange({ seatId: e.target.value })}>
                                                    <option value="" disabled>Select Seat</option>
                                                    {filteredSeatOptions.length > 0 && filteredSeatOptions.map((option) => (
                                                        <option key={`aircrew-seatoption-${option.id}`} value={option.id}>{option.name}</option>
                                                    ))}
                                                </FormControl>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2"></td>
                                    <td className="p-2">
                                        <Form.Check
                                            inline
                                            name="use-price-per-head"
                                            id="use-price-per-head"
                                            label="Use per head pricing"
                                            checked={booking?.usePricePerHead ?? false}
                                            onChange={(e) => handleChange({ usePricePerHead: e.target.checked })}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2">Personnel Details</td>
                                    <td className="p-2">
                                        <div>
                                            <EnterSeparatedTextbox value={booking?.crew_names ?? []} onChange={(arr) => handleChange({ crew_names: arr })} />
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <Button variant="outline-dark mr-2" onClick={() => setHasSelectedAirCrew(false)}>Back</Button>
                        <Button type="submit" disabled={isBookingAirCrew}>{isBookingAirCrew ? 'Submitting...' : 'Submit'}</Button>
                    </Form>
                </Col>
                <Col>
                    <img src={airCrew.airline_logo} alt={airCrew.airline_name} />
                    <hr />

                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <h4 className="mb-0"><strong>Bookings</strong></h4>
                                <div style={{ width: '500px' }}>
                                    <InputGroup className="mb-3 rounded-right">
                                        <InputGroup.Text ><i className="fa fa-search" aria-hidden="true"></i></InputGroup.Text>
                                        <Form.Control value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search" onKeyPress={onKeyUp} placeholder="Search bookings" />
                                        <div className="input-group-append rounded-right">
                                            <Button className="rounded-right" onClick={() => fetchAirlineCrewBookings()}>search</Button>
                                        </div>
                                    </InputGroup>
                                </div>
                            </div>


                            {isLoadingBookings ?
                                <>
                                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                                </>
                                :
                                <>
                                    {bookings.length > 0 ?
                                        <>
                                            {bookings
                                                // .sort((a, b) => new Date(a.pickup_time) - new Date(b.pickup_time))
                                                .map((aircrewBooking) => (
                                                    <React.Fragment key={`aircrew-booking-${aircrewBooking.id}`}>
                                                        <hr />
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <Link target="_blank" to={`/aircrew-booking/${aircrewBooking.id}`}>
                                                                    <p className="color-primary mb-0"><strong>{aircrewBooking.seat_name}</strong></p>
                                                                    <p className="mb-0 "><strong>{Moment(aircrewBooking.pickup_time).format('DD.MM.YYYY HH:mm')}</strong></p>
                                                                    {/* <p className="mb-0 "><strong>{ignoreOffsetDateString(aircrewBooking.pickup_time, 'DD.MM.YYYY HH:mm')}</strong></p> */}
                                                                    <p>{aircrewBooking.origin} <i className="mx-2 fas fa-arrow-circle-right"></i> {aircrewBooking.destination}</p>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <p className="text-uppercase"><strong>{aircrewBooking.flight_arrival_number}</strong></p>
                                                            </div>
                                                            <div className="text-right" style={{ width: '100px' }}>
                                                                {!isDateTimePassedCurrentTime(aircrewBooking.pickup_time) &&
                                                                    <>
                                                                        <i title="update booking" className="fas fa-edit cursor-pointer hover-primary mr-2" onClick={() => handleEditAirCrewBookingModal(aircrewBooking)}></i>
                                                                        <i title="cancel booking" className="fas fa-times cursor-pointer hover-primary" onClick={() => handleCancelAirCrewBookingModal(aircrewBooking)}></i>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                    </React.Fragment>
                                                ))}

                                        </>
                                        :
                                        <p><i>No bookings.</i></p>
                                    }
                                </>
                            }

                            {pagination?.totalPages > 0 &&
                                <div className="mt-3">
                                    <Pages paginationData={pagination} changePage={setPageNumber} pageNumber={pageNumber} />
                                </div>
                            }

                        </Card.Body>
                    </Card>


                </Col>
            </Row>
        </div>
    );
};

export default BookAirlineCrewForm;