
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { getHotels } from '../../services/hotels';
import { getAllDestinations } from '../../services/shuttleDestinations';
import PageLoader from '../../components/common/pageLoader';

const HotelDestinations = ({ setHotel, selectDestination }) => {

    const [hotels, setHotels] = useState([])
    const [filteredHotels, setFilteredHotels] = useState([])
    const [destinations, setDestinations] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchDestinations()
        fetchHotels()
    }, [])

    useEffect(() => {
        fetchDestinations()
    }, [])

    const fetchDestinations = async () => {
        const { data: { code, message } } = await getAllDestinations()
        if (code === 200) {
            //switch the two destinations (hotel origin should go first)
            setDestinations(message)
        } else {
            //TODO: toast
        }
    }

    const fetchHotels = async () => {
        setIsLoading(true)
        const { data: { message } } = await getHotels()
        setIsLoading(false)
        if (Array.isArray(message)) {
            setHotels(message);
            setFilteredHotels(message)
        }
    }

    const handleSelectHotel = (hotel) => {
        setHotel(hotel)
        let _destination = destinations.find(x => x.origin === "Zurich Airport" && x.is_common_origin)
        selectDestination(_destination)
    }

    const handleSearch = (searchText) => {
        let searchResults = hotels.filter(x => x.hotel_name.toLowerCase().includes(searchText.toLowerCase()))
        setFilteredHotels([...searchResults])
    }

    return <>

        <Card>
            <Card.Body>
                <Card.Title>
                    <Row className="justify-content-between">
                        <Col lg="8">Book Shuttle <i className="mr-2 fas fa-arrow-circle-right"></i> Select Hotel</Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    onChange={(e) => handleSearch(e.target.value)}
                                    placeholder="Search Hotel"
                                />
                                <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Card.Title>
                {isLoading ?
                    <PageLoader position="relative" backgroundColor="#f2ecec" />
                    :
                    <div className="bg-secondary p-4">
                        <Row className="row-eq-height">
                            {filteredHotels.length > 0 && filteredHotels.map((hotel, index) => (
                                <Col md="6" lg="4" className="mb-3" key={`hotel-${index}`}>

                                    <Card className="h-100 cursor-pointer" onClick={() => handleSelectHotel(hotel)}>
                                        <Card.Body>
                                            <div className="position-relative center-xy">
                                                <img className="w-100" src={hotel.hotel_logo} />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-center">
                                            {hotel.hotel_name}
                                        </Card.Footer>
                                    </Card>

                                </Col>
                            ))}
                        </Row>
                    </div>
                }
            </Card.Body>
        </Card>

    </>
}

export default HotelDestinations
