
export const getInvoiceRecipientName = (invoice) =>{
    const name = invoice.invoiced_hotel.invoice_info?.name ? invoice.invoiced_hotel.invoice_info.name : invoice.invoiced_hotel.hotel_name
    return name
  }

export const  getInvoiceRecipientAddress = (invoice) =>{
    const address = invoice.invoiced_hotel.invoice_info?.address ? invoice.invoiced_hotel.invoice_info.address : 
      `${invoice.invoiced_hotel.address?.street} ${invoice.invoiced_hotel.address?.city} ${invoice.invoiced_hotel.address?.zip} ${invoice.invoiced_hotel.address?.country}`
    return address
  }

export const getTaxiDriverInvoiceOptions = () =>{
  const invoiceOptions = [
      {
          label: 'Fixed Charge',
          value: 'FIXED-CHARGE',
      },
      {
          label: '30%',
          value: '30%',
      }
  ];
  return invoiceOptions;
}