import http from './http'
import { getToken } from './auth'

const base = 'contacts'

export async function getContactMessages() {
    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}