
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react';
import { toast } from 'react-toastify'
import SocketContext from '../../../context/socket'
import CarImage from '../../../assets/images/car-marker.png';
import CarOffline from '../../../assets/images/car-marker-offline.png';
import CarWaiting from '../../../assets/images/car-marker-waiting.png';
import CarOnJob from '../../../assets/images/car-marker-onjob.png';
import { getTaxiDrivers } from '../../../services/taxiAccounts'
import { relativeTimeRounding } from 'moment';
import { getShuttleDrivers } from '../../../services/users';
import { getShuttleAccounts } from '../../../services/accounts';
import { toBeDisabled } from '@testing-library/jest-dom';

const Driver = ({ name, status }) => <div className="center-xy" style={{ flexDirection: 'column' }}>
    <img style={{ width: '1.5rem' }} src={status === 'ON-JOB' ? CarOnJob : (status === 'WAITING-JOB' ? CarWaiting : CarOffline)} alt={name} text={name} />
    <p><strong>{name}</strong></p>
</div>;

const DriversTrackingMap = ({ height = '85vh', width = '100%' }) => {

    const socket = useContext(SocketContext);

    // const mapDefaultCenter = {
    //     lat: 47.3769,
    //     lng: 8.5417
    // };

    const [mapCenter, setMapCenter] = useState()

    const [rawTaxiDrivers, setRawTaxiDrivers] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState([])
    const [taxiDriverElems, setTaxiDriverElems] = useState()
    const taxiDriverElemsRef = useRef();

    // const [rawShuttleDrivers, setRawShuttleDrivers] = useState([])
    const [shuttleDrivers, setShuttleDrivers] = useState([])
    const [shuttleDriverElems, setShuttleDriverElems] = useState()
    const shuttleDriverElemsRef = useRef();

    const [taxiDriversLoaded, setTaxiDriversLoaded] = useState(false)
    const [shuttleDriversLoaded, setShuttleDriversLoaded] = useState(false)
    const [mapLoaded, setMapLoaded] = useState(false)

    const newLocationDataReceived = (data) => {
        setSocketData(data);
    }

    const updateMap = (data, tds, sds) => {

        if (tds && tds.length > 0) {

            let _taxiDrivers = tds;
            const targetIndex = _taxiDrivers.findIndex(x => x._id === data.user_id);

            if (targetIndex >= 0) {
                _taxiDrivers[targetIndex].geo_location.latitude = data.latitude;
                _taxiDrivers[targetIndex].geo_location.longitude = data.longitude;
                _taxiDrivers[targetIndex].availability_status = data.availability_status;

                const filtered = _taxiDrivers.filter(x => x.hasOwnProperty('availability_status') && x.availability_status !== 'OFF-DUTY');
                setTaxiDrivers(filtered)

                //setTaxiDriverElems(getTaxiDriverElements(filtered))
                taxiDriverElemsRef.current = getDriverElements(filtered);
            }
        }

        if (sds && sds.length > 0) {

            let _shuttleDrivers = sds;
            const targetIndex = _shuttleDrivers.findIndex(x => x._id === data.user_id);

            if (targetIndex >= 0) {
                _shuttleDrivers[targetIndex].geo_location.latitude = data.latitude;
                _shuttleDrivers[targetIndex].geo_location.longitude = data.longitude;
                _shuttleDrivers[targetIndex].availability_status = data.availability_status;

                setShuttleDrivers(_shuttleDrivers)

                //setTaxiDriverElems(getTaxiDriverElements(filtered))
                shuttleDriverElemsRef.current = getDriverElements(_shuttleDrivers);
            }
        }

        setSocketData(data);
    }

    useEffect(() => {
        _getTaxiDrivers()
        _getShuttleDrivers()
    }, [])

    useEffect(() => {

        socket.on(`new-geolocation-update`, (data) => newLocationDataReceived(data));

        return () => {
            socket.off(`new-geolocation-update`, () => { });
        }

    }, [])

    const [socketData, setSocketData] = useState();
    useEffect(() => {

        updateMap(socketData, rawTaxiDrivers, shuttleDrivers);

    }, [socketData])

    const _getTaxiDrivers = async () => {

        const { data: { code, message } } = await getTaxiDrivers();

        if (code === 200) {

            setRawTaxiDrivers(message);
            const filtered = message.filter(x => x.hasOwnProperty('availability_status') && x.availability_status !== 'OFF-DUTY');
            setTaxiDrivers(filtered);
            _setMapDefaultCenter(filtered);

            //setTaxiDriverElems(getDriverElements(filtered));
            taxiDriverElemsRef.current = getDriverElements(filtered);
        }
        else {
            _setMapDefaultCenter([]);
            toastError(`An error occurerd getting taxi drivers. ${message}.`);
        }

        setTaxiDriversLoaded(true);
    }

    const _getShuttleDrivers = async () => {

        const { data: { code, message } } = await getShuttleAccounts();

        if (code === 200) {

            setShuttleDrivers(message);
            _setMapDefaultCenter(message);

            shuttleDriverElemsRef.current = getDriverElements(message);
        }
        else {
            _setMapDefaultCenter([]);
            toastError(`An error occurerd getting taxi drivers. ${message}.`);
        }

        setShuttleDriversLoaded(true);
    }

    const _setMapDefaultCenter = (tds) => {

        let defaultCenter = {
            lat: 47.3769,
            lng: 8.5417
        }

        if (tds && tds.length > 0 && tds.filter((td) => td.geo_location && td.geo_location.latitude).length > 0) {

            const sortedTds = tds.filter((td) => td.geo_location && td.geo_location.latitude).sort((a, b) => { return new Date(b.geo_location.update_time) - new Date(a.geo_location.update_time) })
            defaultCenter = { lat: sortedTds[0].geo_location.latitude, lng: sortedTds[0].geo_location.longitude }
        }

        setMapCenter(defaultCenter)
    }

    const toastError = (error) => {
        toast.error(error, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    const handleApiLoaded = (map, maps) => {
        setMapLoaded(true)
    }

    const getDriverElements = (drivers) => {

        const _driverElems = drivers &&
            drivers.filter((x) => x.geo_location && x.geo_location.latitude)
                .map((x, i) => {
                    return (
                        <Driver
                            key={`${x._id}`}
                            lat={x.geo_location.latitude}
                            lng={x.geo_location.longitude}
                            name={`${x.first_name} ${x.last_name}`}
                            status={x.availability_status}
                        />
                    )
                })

        return _driverElems;
    }

    const [showDriverInfo, setShowDriverInfo] = useState(false);
    const [baloonInfo, setBaloonInfo] = useState({});
    const onMouseEnter = (key) => {
        const _taxiDrivers = taxiDrivers.filter(x => x._id === key);
        const _shuttleDrivers = shuttleDrivers.filter(x => x._id === key);

        const targetDrivers = _taxiDrivers.concat(_shuttleDrivers)

        if (targetDrivers && targetDrivers.length > 0) {
            let statusColor = '#e8e8e8';
            if (targetDrivers[0].availability_status === 'ON-JOB') {
                statusColor = '#f2784b';
            }
            else if (targetDrivers[0].availability_status === 'WAITING-JOB') {
                statusColor = '#00e640';
            }
            targetDrivers[0].statusColor = statusColor;
            setBaloonInfo(targetDrivers[0]);
        }
        setShowDriverInfo(true);
    }

    const onMouseLeave = (x) => {
        setShowDriverInfo(false);
        setBaloonInfo({});
    }

    const onClick = (x) => {
        console.log(`${x.lat},${x.lng}`)
    }

    {/* Important! Always set the container height explicitly */ }
    return <>
        {
            <div style={{ height, width, zIndex: -1 }}>
                <GoogleMapReact
                    // bootstrapURLKeys={{ key: 'AIzaSyByjT-kfojFHzn7VWxaM931kA2jEW-RVzo' }}//TODO: key??
                    //defaultCenter={mapCenter}
                    center={mapCenter}
                    defaultZoom={15}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    onChildMouseEnter={(x) => onMouseEnter(x)}
                    onChildMouseLeave={(x) => onMouseLeave(x)}
                    onClick={(x) => onClick(x)}
                //onChange={(e) => console.log(mapCenter)}
                >
                    {taxiDriverElemsRef.current}
                    {shuttleDriverElemsRef.current}
                </GoogleMapReact>

                {
                    showDriverInfo &&

                    <div style={{ position: 'fixed', bottom: '25px', right: '30px', width: '300px', backgroundColor: '#fff', zIndex: 999, padding: '15px' }}>
                        <Row>
                            <Col md={4}><i style={{ color: "Tomato" }} className="fas fa-user-circle fa-5x"></i></Col>
                            <Col>
                                <span>{`${baloonInfo?.first_name} ${baloonInfo?.last_name}`}</span>
                                <br />
                                <span>Status: <i style={{ color: baloonInfo.statusColor }} className="fas fa-circle"></i></span>
                            </Col>
                        </Row>
                    </div>
                }

            </div>
        }

    </>
}

export default DriversTrackingMap