import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { generateTaxiInvoiceByMonth } from '../../services/invoicesTaxi';
import { getTaxiDrivers } from '../../services/taxiAccounts';
import { toLocaleISOString } from '../../utils/utils';
import TaxiInvoicePreviewModal from '../modals/taxiInvoicePreview';

const GenerateTaxiDriverInvoiceModal = ({display, toggle}) => {

    const [invoiceDataPreviewModal, setInvoiceDataPreviewModal] = useState(false)
    const [taxiDrivers, setTaxiDrivers] = useState([])
    const [taxiDriverId, setTaxiDriverId] = useState('')
    const [monthFilter, setMonthFilter] = useState(("0" + (new Date().getMonth() + 1)).slice(-2))
    const [yearFilter, setYearFilter] = useState(new Date().getFullYear())
    const [taxiDriverDateType, setTaxiDriverDateType] = useState('single')
    const [taxiDriverDateRange, setTaxiDriverDateRange] = useState([null, null]);
    const [taxiDriverStartDate, taxiDriverEndDate] = taxiDriverDateRange;
    const [invoiceDataPreview, setInvoiceDataPreview] = useState()

    const months = [
        { "value": "", "label": "All" },
        { "value": "01", "label": "January" },
        { "value": "02", "label": "February" },
        { "value": "03", "label": "March" },
        { "value": "04", "label": "April" },
        { "value": "05", "label": "May" },
        { "value": "06", "label": "June" },
        { "value": "07", "label": "July" },
        { "value": "08", "label": "August" },
        { "value": "09", "label": "September" },
        { "value": "10", "label": "October" },
        { "value": "11", "label": "November" },
        { "value": "12", "label": "December" }
    ]
    const years = []
    for (var i = 2019; i <= new Date().getFullYear(); i++)
        years.push(i.toString())

    useEffect(() => {
        fetchTaxiDrivers()
    }, [])

    const fetchTaxiDrivers = async () => {
        const { data: { code, message } } = await getTaxiDrivers()
        if(code === 200){
            setTaxiDrivers(message)
        } else {
            //TODO: toast
        }  
    }

    const handleGenerateInvoiceByDate = async () => {

        var payload = {
            "recipient_type": "taxi-driver",
            "recipient_person_id": taxiDriverId,
        }
        if(taxiDriverDateType === 'single') {
            payload['month'] = monthFilter
            payload['year'] = yearFilter.toString()
        } else {
            payload['start_date'] = toLocaleISOString(new Date(taxiDriverStartDate.setHours(0,0,0,0)))
            payload['end_date'] = toLocaleISOString(new Date(taxiDriverEndDate.setHours(23,59,59,999)))
        }
        
        return await generateTaxiInvoiceByMonth(payload)
    }

    const handleInvoiceDataPreview = async () => {

        const { data: { code, message } } = await handleGenerateInvoiceByDate()

        if(code === 200) {
        let _invoiceData = message
        
        _invoiceData['title'] = getTaxiDriverNameById(taxiDriverId)

        setInvoiceDataPreview(_invoiceData)
        setInvoiceDataPreviewModal(true)
        }
        else {
        toast.error(` ${message}.`, {
            position: toast.POSITION.BOTTOM_RIGHT
        })
        }
        
    }

    const getTaxiDriverNameById = (driverId) => {
        const taxiDriver = taxiDrivers.find(x => x._id === driverId)
        return `${taxiDriver.first_name} ${taxiDriver.last_name}`
    }

    const toggleTaxiDriverDateType = (type) => {
        if(type === 'single')
        setTaxiDriverDateRange([null, null])
        else {
        setMonthFilter(("0" + (new Date().getMonth() + 1)).slice(-2))
        setYearFilter(new Date().getFullYear())
        }

        setTaxiDriverDateType(type)
    }

  return (
    <>
      
        {invoiceDataPreview && <TaxiInvoicePreviewModal display={invoiceDataPreviewModal} toggle={setInvoiceDataPreviewModal} invoice={invoiceDataPreview} /> }

        <Modal
        show={display}
        onHide={() => toggle(false)}
        size="sm"
        centered
        >
            <Modal.Body>
                
                <h4>Invoice to Taxi Driver</h4>

                <Form.Group>
                    <Form.Label>Select Taxi Driver</Form.Label>
                    <Form.Control as="select" defaultValue={taxiDriverId} onChange={(e) => setTaxiDriverId(e.target.value)}>
                    <option value="" disabled>Select Taxi Driver</option>
                    {taxiDrivers && taxiDrivers.map((driver, index) => (
                        <option key={`driver-${index}`} value={driver._id} >
                        {driver?.first_name} {driver?.last_name}
                        </option>
                    ))}
                    </Form.Control>
                </Form.Group>

                <Row className="mb-3">
                    <Col>
                        <Button variant={taxiDriverDateType === 'single' ? 'primary' : 'outline-primary'} size="sm" className="mr-2" onClick={() => toggleTaxiDriverDateType('single')}>By Month</Button>
                        <Button variant={taxiDriverDateType === 'dateRange' ? 'primary' : 'outline-primary'} size="sm" onClick={() => toggleTaxiDriverDateType('dateRange')}>By Date Range</Button>
                    </Col>
                </Row>

                {taxiDriverDateType === 'single' ?
                <>
                    <Form.Group>
                    <Form.Label>Select Month</Form.Label>
                    <Form.Control as="select" defaultValue={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                        {months && months.map((month, index) => (
                        <option key={`month-${index}`} value={month.value}>
                            {month.label}
                        </option>
                        ))}
                    </Form.Control>
                    </Form.Group>

                    <>
                    {monthFilter &&
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <Form.Control as="select" defaultValue={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                        {years && years.map((year) => (
                            <option key={`year-${year}`} value={year}>
                            {year}
                            </option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    </>
                </>
                :
                <Form.Group>
                    <Form.Label>Select Date Range</Form.Label>
                    <div className="react-datepicker-w100">
                    <DatePicker
                        selectsRange={true}
                        startDate={taxiDriverStartDate}
                        endDate={taxiDriverEndDate}
                        onChange={(update) => {
                            setTaxiDriverDateRange(update);
                        }}
                        isClearable={true}
                        className="form-control"
                        style={{width:'400px'}}
                        dateFormat="MMM d, yyyy"
                        />
                    </div>
                </Form.Group>
                }

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => toggle(false)}>Cancel</Button>
                <Button variant="primary" onClick={() => handleInvoiceDataPreview()}>Generate</Button>
            </Modal.Footer>
        </Modal>

    </>
  );
};

export default GenerateTaxiDriverInvoiceModal;