import http from './http'
import { getToken } from './auth'

const base = 'contactSupport'

export async function getSupportTickets() {
    return await http.get(`${base}/open`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function getSupportTicketById(id) {
    return await http.get(`${base}/by-id/${id}`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}

export async function createSupportTicket(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}`, payload, config)
}

export async function markTicketAsSolved(id) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`${base}/update-as-solved/${id}`, {}, config)
}

export async function markTicketAsOpen(id) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.patch(`${base}/update-as-open/${id}`, {}, config)
}