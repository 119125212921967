import React from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import { useState } from 'react';
import { getUser } from '../../services/users';
import { getCurrentUser } from '../../services/auth';
import { useEffect } from 'react';
import { getHotelById } from '../../services/hotels';
import HotelOptions from '../../components/hotelAdmin/hotelOptions';
import HotelInfo from '../../components/hotelAdmin/hotelInfo';
import { toast } from 'react-toastify'

const HotelSettings = () => {

    const [user, setUser] = useState()

    const [hotel, setHotel] = useState(null)

    const currentUser = getCurrentUser()

    useEffect(() => {
        fetchUser()
        fetchHotel()
    }, [])

    const fetchUser = async () => {
        const { data: { code, message } } = await getUser(currentUser._id)
        if (code == 200) {
            setUser(message)
        } else {
            //TODO: toast
        }
    }

    const fetchHotel = async () => {
        const { data: { message } } = await getHotelById(currentUser.hotel_id);
        setHotel(message);
    }

    return (
        <section>
            {user &&
            <Col>
                {   currentUser.role_name == "hotel-admin" && hotel &&
                    <>
                        <Row className="mb-4">
                            <Col>
                                <HotelInfo hotel={hotel}/>
                            </Col>
                        </Row>
                        
                        <Row className="mb-4">
                            <Col>
                                <HotelOptions hotel={hotel}/>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
            }
        </section>
    );
};

export default HotelSettings;