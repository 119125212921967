import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import GenerateAirCrewInvoiceModal from '../../components/modals/generateAirCrewInvoice';
import { deleteAirCrewInvoice, getAirCrewInvoices } from '../../services/airlineCrewInvoices';
import { isSystemAdmin } from '../../utils/utils';
import Moment from 'moment';
import LinkTD from '../../components/common/linkTd';
import ConfirmationModal from '../../components/modals/confirmation';

const AirCrewInvoicesPage = () => {

    const [generateAirCrewInvoiceModal, setGenerateAirCrewInvoiceModal] = useState(false)
    const [invoices, setInvoices] = useState()

    const [selectedInvoice, setSelectedInvoice] = useState()
    const [confirmationModal, setConfirmationModal] = useState(false)

    useEffect(() => {
        fetch()
    }, [])

    const fetch = async () => {
        const { data: { code, message } } = await getAirCrewInvoices()
        if (code === 200)
            setInvoices(message)
        else {
            toast.error(`${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const handleCancelInvoice = async () => {
        const { data: { code, message } } = await deleteAirCrewInvoice(selectedInvoice._id)
        if (code == 200) {
            let _invoices = invoices
            const index = _invoices.findIndex(x => x._id === selectedInvoice._id)
            _invoices.splice(index, 1)
            setInvoices([..._invoices])
        } else {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    const handleConfirmationModal = (invoice) => {
        setSelectedInvoice(invoice)
        setConfirmationModal(true)
    }

    const invoiceGenerated = (data) => {
        let _invoices = invoices
        _invoices.push(data)
        setInvoices([..._invoices])
    }

    return <>

        <GenerateAirCrewInvoiceModal display={generateAirCrewInvoiceModal} toggle={setGenerateAirCrewInvoiceModal} update={invoiceGenerated} />

        <ConfirmationModal
            title="Cancel Invoice"
            message="Are you sure you want to cancel this invoice?"
            display={confirmationModal} toggle={setConfirmationModal} confirmAction={() => handleCancelInvoice()} />

        <section>
            <Row>
                <Col>
                    {
                        isSystemAdmin() &&
                        <Row className="mb-3">
                            <Col>
                                <Button onClick={() => setGenerateAirCrewInvoiceModal(true)}>Generate Invoice</Button>
                            </Col>
                        </Row>
                    }
                    <Card>
                        <Card.Header className='text-center bg-primary text-white'>Shuttle Invoices</Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead className="border-top-0">
                                    <tr>
                                        <th>Invoice Number</th>
                                        <th>Period</th>
                                        <th>Number Of Bookings</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices ?
                                        <>
                                            {invoices.map((invoice, index) => (
                                                <tr key={`aircrew-invoice-${index}`}>
                                                    <LinkTD to={`/airline-crew-invoices/${invoice._id}`}>{invoice.invoice_no}</LinkTD>
                                                    <LinkTD to={`/airline-crew-invoices/${invoice._id}`}>{Moment.utc(invoice.period_from).format('DD.MM.YYYY') + " - " + Moment.utc(invoice.period_to).format('DD.MM.YYYY')}</LinkTD>
                                                    <LinkTD to={`/airline-crew-invoices/${invoice._id}`}>{invoice.number_of_sales}</LinkTD>
                                                    <LinkTD to={`/airline-crew-invoices/${invoice._id}`}>{invoice.amount}</LinkTD>
                                                    <td>
                                                        <Button size="sm" onClick={() => handleConfirmationModal(invoice)}>Cancel</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                        :
                                        <tr>
                                            <td colSpan="5">No invoices found.</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    </>
}

export default AirCrewInvoicesPage