import React, { useState } from 'react'
import { Button, Card, Form, FormInput, Modal, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { create } from '../../../services/airlineCrews'

const AddAirlineCrewModal = ({ display, toggle, airlines, hotels, airCrews, setAirCrews }) => {

    const [logo, setLogo] = useState()
    const [name, setName] = useState()
    const [allowedAirlines, setAllowedAirlines] = useState([])
    const [allowedHotels, setAllowedHotels] = useState([])
    const [isCreating, setIsCreating] = useState(false)

    const handleAdd = async (e) => {
        e.preventDefault()
        setIsCreating(true)
        const so = {
            "airline_name": name,
            "allowed_crew_bookings_airlines": allowedAirlines.map(x => x.value),
            "allowed_crew_bookings_hotels": allowedHotels.map(x => x.value),
            'airline_logo': logo
        }

        let { data: { code, message } } = await create(so)
        setIsCreating(false)
        if (code !== 200) {
            toast.error(` ${message}.`, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            let _airCrews = airCrews
            message['allowed_crew_bookings_airlines_temp'] = allowedAirlines.map(x => airlines.find(y => y.value === x.value).label)
            message['allowed_crew_bookings_hotels_temp'] = allowedHotels.map(x => hotels.find(y => y.value === x.value).label)
            _airCrews.push(message)
            setAirCrews([..._airCrews])
            onClose()
        }
    }

    const handleChangeLogo = () => {
        const file = document.querySelector('#file-upload').files[0];;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            const img = new Image();
    
            img.onload = function () {
                const MAX_SIZE = 150; // Set your desired maximum size
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    if (width > MAX_SIZE) {
                        height *= MAX_SIZE / width;
                        width = MAX_SIZE;
                    }
                } else {
                    if (height > MAX_SIZE) {
                        width *= MAX_SIZE / height;
                        height = MAX_SIZE;
                    }
                }
    
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
    
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                const resizedBase64 = canvas.toDataURL('image/png', 0.8); // Adjust quality as needed
                setLogo(resizedBase64);
            };
    
            img.src = reader.result;
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const onClose = () => {
        setName('')
        setLogo('')
        setAllowedAirlines([])
        setAllowedHotels([])
        toggle(false)
    }

    const imgPreview = {
        'maxWidth': '100%',
        'maxHeight': '200px'
    }

    return (
        <Modal
            show={display}
            onHide={() => toggle(false)}
            centered
        >
            <Modal.Body>
                <Card.Title className="mb-3">Create Air Crew</Card.Title>
                <Form onSubmit={handleAdd} style={{ position: 'relative' }}>
                    <Row>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='text' onChange={e => setName(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Allowed Airlines</Form.Label>
                                <Select value={allowedAirlines} isMulti={true} onChange={(value) => setAllowedAirlines(value)} placeholder={"Allowed airlines"}
                                    options={airlines} />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <Form.Group>
                                <Form.Label>Allowed Hotels</Form.Label>
                                <Select value={allowedHotels} isMulti={true} onChange={(value) => setAllowedHotels(value)} placeholder={"Allowed hotels"}
                                    options={hotels} />
                            </Form.Group>
                        </Col>
                        <Col lg="12">
                            <input className="mb-3" id="file-upload" type="file" onChange={() => handleChangeLogo()} /><br />
                            {logo ?
                                <img src={logo} style={imgPreview} alt="Image preview..." />
                                :
                                <div className="center-xy" style={{ height: '150px', width: '150px', backgroundColor: '#eee' }}><span>LOGO</span></div>
                            }
                        </Col>
                        <Col lg="12" className="text-right">
                            <Button type="submit" disabled={isCreating} className="mt-2">
                                {isCreating ? 'Saving...' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddAirlineCrewModal

