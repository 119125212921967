import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import  NameWithRole  from '../common/nameWithRole';

const SidebarHotelStaff = ({hotel}) => {

    return ( 
        <>
        {hotel && hotel.hasOwnProperty('hotel_logo') ? 
        <div className="mx-3">
            <img src={hotel.hotel_logo} className="w-100" alt="hotel-logo" />
        </div>
        :
        <h1 className="px-2 text-center">{hotel?.hotel_name}</h1>
        }
        <NameWithRole/>
        <ul className='nav flex-column'>
            <LinkContainer to='/dashboard'>
                <li><a className='nav-link'><i className="fas fa-th-large mr-2"></i> Dashboard</a></li>
            </LinkContainer>
            <LinkContainer to='/bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Shuttle Bookings</a></li>
            </LinkContainer>
            <LinkContainer to='/taxi-bookings'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Taxi Bookings</a></li>
            </LinkContainer>
            {/* <LinkContainer to='/unassigned-taxi-bookings'>
                <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Unassigned Bookings</a></li>
            </LinkContainer> */}
            {/* <LinkContainer to='/commissions'>
                    <li><a className='nav-link'><i className="fas fa-calendar-check mr-2"></i> Commissions</a></li>
            </LinkContainer> */}
        </ul>
        </>
     );
}
 
export default SidebarHotelStaff;
