import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import simulLogo from '../../assets/images/logo_hor.svg'

const ShuttleTimetablePrint = React.forwardRef(({timetable, destination, hotel}, ref) => (

    <div ref={ref} className="shuttle-timetable-print">
        <Row className="justify-content-center">
            <Col xs="6" className="mt-3">

                <Card>
                    <Card.Body>
                        <div className="text-center mb-4">
                            <div className="mb-3">
                                <i className="fas fa-bus fa-4x"></i>
                            </div>
                            <div className="color-primary py-1 px-2 h3 d-inline-block" style={{border:'1px solid tomato',borderRadius:'8px'}}>SHUTTLE TIMETABLE</div>
                        </div>
                        <h4 className="text-center mt-3 mb-2">
                        <>
                        {destination.origin} <i className="mr-2 fas fa-arrow-circle-right"></i> 
                        </>
                        {destination.is_common_origin ? hotel.hotel_name : destination.destination}
                        </h4>

                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-center">Time</th>
                                    <th className="text-center">Seat Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {timetable && timetable.map((timeObj, index) => (
                                <tr key={`time-${index}`}>
                                    <td className="text-center">{timeObj.time}</td>
                                    <td className="text-center">{timeObj.seat_price}</td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="text-center">
                            <img src={simulLogo} style={{height:'60px'}} />
                        </div>
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    </div>
));
 
export default ShuttleTimetablePrint;