import http from './http'
import { getToken } from './auth'

const base = 'taxiAccounts'

export async function createTaxiDriver(payload) {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
        }
    }
    return await http.post(`${base}/create-taxi-driver`, payload, config)
}

export async function getTaxiDrivers() {

    return await http.get(`${base}/all`, {
        headers: {
            'x-auth-token': getToken()
        }
    })
}


