import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import AirlineCrewSelect from './airlineCrewSelect';
import BookAirlineCrewForm from './bookAirlineCrewForm';

const BookAirlineCrewPage = () => {

    const [hasSelectedAirCrew, setHasSelectedAirCrew] = useState(false)
    const [selectedAirCrew, setSelectedAirCrew] = useState({})

    const selectAirCrew = (airCrew) => {
        setHasSelectedAirCrew(true)
        setSelectedAirCrew(airCrew)
    }

    return (
        <section>
            <Col>
                <>
                    {!hasSelectedAirCrew ?
                        <>
                            <AirlineCrewSelect selectAirCrew={selectAirCrew} />
                        </>
                        :
                        <>
                            <BookAirlineCrewForm airCrew={selectedAirCrew} setHasSelectedAirCrew={setHasSelectedAirCrew} />
                        </>
                    }
                </>

            </Col>
        </section>
    );
};

export default BookAirlineCrewPage;