import http from './http'
import { getToken } from './auth'
import { toLocalDateString } from '../utils/utils'

const base = 'airlineCrewInvoices'

export async function getAirCrewInvoices() {

    return await http.get(`${base}`, {
        headers: {
            'x-auth-token': getToken()
        },
    })
}

export async function getAirCrewInvoiceById(id) {

    return await http.get(`${base}/${id}`, {
        headers: {
            'x-auth-token': getToken()
        },
    })
}

export async function generateAirCrewInvoice(payload) {

    let _payload = payload
    _payload.startDate = toLocalDateString(new Date(_payload.startDate), 'yyyy-MM-dd HH:mm')
    _payload.endDate = toLocalDateString(new Date(_payload.endDate), 'yyyy-MM-dd HH:mm')
    _payload.companyId = _payload.companyId
    _payload.companyType = _payload.companyType

    const config = {
        headers: {
            'x-auth-token': getToken()
        },
    }
    return await http.post(base + '/' + 'generate', _payload, config)
}

export async function deleteAirCrewInvoice(id) {
    const config = {
        headers: {
            'x-auth-token': getToken()
        },
    }
    return await http.delete(base + '/' + id, config)
}