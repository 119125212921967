import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/auth';
import SystemAdminCommissionsPage from './admin/systemAdminCommissionsPage';
import HotelUserCommissionsPage from './hotelUser/hotelUserCommissionsPage';

const CommissionsPage = () => {
    const [user, setUser] = useState({})
    const _user = getCurrentUser()

    useEffect(() => {
        setUser(_user)
    }, [_user && _user.username])

    return <>

        {
            user && user.role_name === 'system-admin' &&
            <SystemAdminCommissionsPage />
        }
        
        {/* {
            user && (user.role_name === 'hotel-admin' || user.role_name === 'hotel-staff') &&
            <HotelUserCommissionsPage />
        } */}
        
    </>
};

export default CommissionsPage;