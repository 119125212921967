import React from 'react'
import ShuttleDriverCreation from '../../components/shuttleCompany/shuttleDriverCreation'

const ShuttleCompanyAccountsPage = () => {
    return <>
        <section>
            <ShuttleDriverCreation />
        </section>
    </>
}

export default ShuttleCompanyAccountsPage