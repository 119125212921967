import React, { useState } from 'react'
import { Button, Card, Form, Row, Col } from 'react-bootstrap'

const ShuttleAdminCreator = ({createShuttleAdmin}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

    const handleAccountCreationSubmit = async (e) => {
        e.preventDefault()
        createShuttleAdmin({ username, password, first_name: firstname, last_name: lastname }, password)
        document.getElementById('shuttle-admin-creator').reset();
    }

    return <>
        <Card className='w-100'>
            <Card.Header className='bg-primary text-center ' style={{ color: 'white' }}>Create Shuttle Admin</Card.Header>
            <Card.Body>
                <Form id="shuttle-admin-creator" onSubmit={(e) => handleAccountCreationSubmit(e)}>
                    <Row className="mb-3">
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control required type='text' placeholder='Enter username' onChange={e => setUsername(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type='password' placeholder='Enter password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg="6">
                            <Form.Group>
                                <Form.Label>Firstname</Form.Label>
                                <Form.Control required type='text' placeholder='Enter firstname' onChange={e => setFirstname(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Lastname</Form.Label>
                                <Form.Control required type='text' placeholder='Enter lastname' onChange={e => setLastname(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button type="submit" variant='primary'>Create</Button>
                    </div>
                </Form>
                
            </Card.Body>
        </Card>
    </>
}

export default ShuttleAdminCreator