//reference
//https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes

export const parseGoogleAddressComponent = (address_components) => {

    var address = {
      street_number: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: ''
    };

    var mappings = {
        'street_number': 'street_number',
        'postal_code': 'postal_code',
        'street_address': 'street',
        'route': 'street',
        'administrative_area_level_1': 'region',
        'administrative_area_level_2': 'region',
        'administrative_area_level_3': 'region',
        'administrative_area_level_4': 'region',
        'administrative_area_level_5': 'region',
        'locality': 'city',
        'sublocality': 'city',
        'sublocality_level_1': 'city',
        'sublocality_level_2': 'city',
        'sublocality_level_3': 'city',
        'sublocality_level_4': 'city',
        'country': 'country'
      }

    address_components.forEach(component => {

      var fieldName = mappings[component.types[0]];

      if(fieldName === undefined) { return; }

      if(address[fieldName]) return;
  
      address[fieldName] = component.long_name;

    });

    return address;
}